import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, Box, InputLabel, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import {
  GetOrganizations,
  MapStoreWithOrg,
} from "../../../services/organization.handlers";
import { CreateStores, GetStores } from "../../../services/store.handlers";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { GetSystems } from "../../../services/systems.handlers";

const AddStores = () => {
  const navigate = useNavigate();
  const param = useParams();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: _getCountryStates({ value: "CA" }),
    city: [],
  });
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [storeData, setStoreData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [archiveStoreId, setArchiveStoreId] = React.useState("");
  const [formData, setFormData] = React.useState({
    store_Id: "",
    org_Id: param.id,
    store_Name: "",
    store_Email: "",
    store_Number: "",
    store_Address_1: "",
    store_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: _getCountry()[1],
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    store_Id: "",
    org_Id: "",
    store_Name: "",
    store_Email: "",
    store_Number: "",
    store_Address_1: "",
    store_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    system_Sn: "",
    archiveId: "",
  });

  React.useEffect(() => {
    fetchOrganisations();
    fetchStore();
  }, []);

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };
  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setStoreData(response.data);
        setFormData({
          ...formData,
          store_Id:
            response.data && response.data.length > 0
              ? Number(response.data[response.data.length - 1].store_Id) + 1
              : response.data.length + 1,
        });
      }
    });
  };
  const handleOpen = (prop) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setOrgID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        org_Id: "Organization ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        org_Id: "",
      });
    }
    setFormData({ ...formData, org_Id: "" + value });
  };

  const setStoreID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Id: "Store ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Id: "",
      });
    }
    setFormData({ ...formData, store_Id: value });
  };

  const setStoreName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        store_Name: "Store Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Name: "",
      });
    }
    setFormData({ ...formData, store_Name: value });
  };

  const setStoreEmail = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Email: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          store_Email: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          store_Email: "Invalid email id",
        });
      }
    }
    setFormData({ ...formData, store_Email: value });
  };

  const setStoreNumber = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Number: "Store Number cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Number: "",
      });
    }
    setFormData({ ...formData, store_Number: value });
  };
  const storeArchive = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        archiveId: "Store  cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        archiveId: "",
      });
    }
    setArchiveStoreId(value);
  };

  const submitArchiveStore = () => {
    if (!archiveStoreId) {
      setArchiveStoreId(archiveStoreId);
      return;
    }
    setError("");
    setSuccess("");

    setLoader(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const archiveStoreData = {
      organizationId: param.id,
      storeId: archiveStoreId,
    };
    MapStoreWithOrg(archiveStoreData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setLoader(false);
        setOpen(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/dashboard");
        }, 2000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  const submitForm = () => {
    if (!formData.store_Id) {
      setStoreID(formData.store_Id);
      return;
    }
    if (!formData.org_Id) {
      setOrgID(formData.org_Id);
      return;
    }

    if (!formData.store_Name) {
      setStoreName(formData.store_Name);
      return;
    }
    if (!formData.store_Email) {
      setStoreEmail(formData.store_Email);
      return;
    }
    if (!formData.store_Number) {
      setStoreNumber(formData.store_Number);
      return;
    }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateStores(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          store_Id: "",
          org_Id: "",
          store_Name: "",
          store_Email: "",
          store_Number: "",
          store_Address_1: "",
          store_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: _getCountry()[1],
        });
        setFormErrors({
          store_Id: "",
          org_Id: "",
          store_Name: "",
          store_Email: "",
          store_Number: "",
          store_Address_1: "",
          store_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/dashboard");
        }, 2000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 350,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  console.log("storeData", storeData);
  return (
    <DashboardLayout>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              <Grid item xs={12}>
                {/* <Stack spacing={2} style={{ marginTop: 15 }}> */}
                <div className={"form-group"} style={{ marginTop: 10 }}>
                  <InputLabel className={"form-label"} htmlFor="select-active">
                    Store
                  </InputLabel>
                  <Select
                    defaultOpen
                    fullWidth
                    className={"form-input"}
                    id="select-active"
                    value={archiveStoreId}
                    onChange={(e) => {
                      storeArchive(e.target.value);
                    }}
                    error
                    helperText={formErrors.org_Id}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  >
                    {storeData.map(
                      (item, index) =>
                        item.isAssociatedWithOrg === false && (
                          <MenuItem key={index} value={item.id}>
                            {item.store_Name}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </div>
              </Grid>
              <Stack spacing={2} direction={"row"} style={{ marginTop: 210 }}>
                <Button
                  disabled={loader}
                  onClick={submitArchiveStore}
                  variant="contained"
                >
                  Add
                  {loader && <Loader color={"#fff"} />}
                </Button>

                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={handleClose}
                    color="grey"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </ButtonThemeProvider>
              </Stack>
              {/* </Stack> */}
            </div>
          </Grid>
        </Box>
      </Modal>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3 style={{ marginTop: 0 }}>
            Add New Store : Store ID-{formData.store_Id}
            <Link style={{ marginLeft: 20 }} onClick={handleOpen}>
              Archive Store
            </Link>
          </h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Organization
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.org_Id}
                  onChange={(e) => {
                    setOrgID(e.target.value);
                  }}
                  error
                  helperText={formErrors.org_Id}
                >
                  {organizations.map(
                    (item, index) =>
                      param.id == item.id && (
                        <MenuItem key={index} value={item.id}>
                          {item.org_Name}
                        </MenuItem>
                      )
                  )}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Name">
                  Store Name
                </InputLabel>
                <TextField
                  id="store_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Name}
                  onChange={(e) => setStoreName(e.target.value)}
                  error
                  helperText={formErrors.store_Name}
                />
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Id">
                  Store ID
                </InputLabel>
                <TextField
                  id="store_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Id}
                  onChange={(e) => setStoreID(e.target.value)}
                  error
                  helperText={formErrors.store_Id}
                />
              </div>
            </Grid> */}

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="genRev_No">
                  System Id
                </InputLabel>

                <Select
                  fullWidth
                  multiple
                  className={"form-input"}
                  id="select-active"
                  value={personName}
                  onChange={handleChange}
                  error
                  helperText={formErrors.system_Sn}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {systemData.map((item) => (
                    <MenuItem key={item.system_Sn} value={item.system_Sn}>
                      {item.system_Sn}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({
                      ...formData,
                      country: value ? JSON.stringify(value) : "",
                    });
                    setCsc({ ...csc, state: _getCountryStates(value) });
                  }}
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Email">
                  Store Email
                </InputLabel>
                <TextField
                  id="store_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Email}
                  onChange={(e) => setStoreEmail(e.target.value)}
                  error
                  helperText={formErrors.store_Email}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Address_1">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 1"
                    : "Additional Address Info"} */}
                  Address 1
                </InputLabel>
                <TextField
                  id="store_Address_1"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Address_1}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      store_Address_1: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Number">
                  Store Contact Number
                </InputLabel>
                <TextField
                  id="store_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Number}
                  onChange={(e) => setStoreNumber(e.target.value)}
                  error
                  helperText={formErrors.store_Number}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Address_2">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 2"
                    : "Store Civic Address"} */}
                  Address 2
                </InputLabel>
                <TextField
                  id="store_Address_2"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Address_2}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      store_Address_2: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Code"}
                </InputLabel>
                <TextField
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Store
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/stores")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddStores;
