import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetEmployees } from "../../../services/employee.handlers";
import { GetStore, GetStores } from "../../../services/store.handlers";
import { jsonCheck } from "../../../constants";
import ReactDateRange from "../../../Components/ReactDateRange/ReactDateRange";
import { addDays } from "date-fns";
import { Box, Grid, Modal } from "@mui/material";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import moment from "moment";
import {
  GenerateBilling,
  StoreBilling,
} from "../../../services/billing.calculation.handlers";
import LogoImage from "../../../assets/images/Logo-header.png";
import { jsPDF } from "jspdf";
const TableRowData = [
  "Store Id",
  "System Id",
  "Date",
  "Per Minute Cost",
  "Total Time Usage",
  "Total Cost",
  "",
];

const GenerateInvoice = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [storeData, setStoreData] = React.useState({});
  const [storeBillingData, setStoreBillingData] = React.useState({});
  React.useEffect(() => {
    // fetchStore();
    getStoreBilling();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const fetchStore = () => {
  //   var options = {
  //     Authorization: "Bearer " + authToken,
  //   };
  //   GetStore(state.id, options).then((response) => {
  //     if (response.status) {
  //       setStoreData(response.data);
  //     }
  //   });
  // };
  const getStoreBilling = () => {
    // setLoader(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const payloadData = {
      organizationId: 0,
      storeId: state?.id,
      month_No: state?.date.getMonth() + 1,
      year: state?.date.getFullYear(),
    };
    // GenerateBilling(payloadData, options).then((response) => {
    StoreBilling(payloadData, options).then((response) => {
      if (response.status) {
        // setLoader(false);
        // setOpen(false);
        setStoreBillingData(response.data);
      } else {
        navigate("/billingGenerate");
      }
    });
  };
  // console.log(storeBillingData.store);
  const generatePDF = () => {
    var rep = new jsPDF("p", "pt", "a4");
    rep.html(document.querySelector("#rep1"), {
      callback: function (pdf) {
        pdf.save("report.pdf");
      },
    });
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // console.log(state);
  return (
    <DashboardLayout>
      <div id="pdf" style={{ visibility: "hidden", height: 0 }}>
        <div
          id="rep1"
          style={{ marginLeft: "20px", maxWidth: "570px", minHeight: "200mm" }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "50px",

              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={LogoImage} alt="Macumira" title={"Macumira"} />
              <p>
                Suite #507 234 Pine St. SW <br />
                Toronto ON S74 8MN
                <br />
                Canada
                <br />
                <strong>Contact No: </strong>999-999-9999
              </p>
            </div>
            <p style={{ marginTop: "4rem" }}>
              {storeBillingData.store?.store_Name}
              <br />
              {storeBillingData.store?.store_Address_1 +
                " " +
                storeBillingData.store?.store_Address_2 +
                ", "}{" "}
              <br />
              {jsonCheck(storeBillingData.store?.city)
                ? JSON.parse(storeBillingData.store?.city).label
                : storeBillingData.store?.city}{" "}
              {", "}
              {jsonCheck(storeBillingData.store?.state)
                ? JSON.parse(storeBillingData.store?.state).label
                : storeBillingData.store?.state}{" "}
              {", "}
              <br />
              {storeBillingData.store?.zip + " - "}
              {jsonCheck(storeBillingData.store?.country)
                ? JSON.parse(storeBillingData.store?.country).label
                : storeBillingData.store?.country}
              <br />
              {storeBillingData.store?.store_Number}
            </p>
          </div>
          <div style={{ border: "1px solid black", marginTop: "20px" }}>
            <p style={{ padding: "5px" }}>
              <strong>Store Id : </strong>
              {storeBillingData.store?.store_Id}
              <br />
              <strong>System Id : </strong>
              {storeBillingData.store?.systemIds.map((e) => e + ",")}
              <br />
              {/* <strong>Start Date : </strong>
              {moment(state.dateRange[0].startDate).format("YYYY/MM/DD")}
              <br />
              <strong>End Date : </strong>
              {moment(state.dateRange[0].endDate).format("YYYY/MM/DD")} */}
              <strong>Date : </strong>
              {monthNames[state.date.getMonth()]} {state.date.getFullYear()}
              <br />
              <strong>Per Minute Cost : </strong>
              10$ USD
              <br />
              <strong>Total Time Usage : </strong>
              {/* {state.response.totalTimeUsage} */}
              <br />
              <div style={{ borderTop: "1px solid black" }}>
                <strong>Total Cost : </strong>
                {storeBillingData.totalCost}
              </div>
            </p>
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: -1,
              bottom: "300px",
              marginLeft: "300px",
            }}
          >
            <img
              width="200px"
              height="200px"
              src="/MM_Opacity-03.png"
              alt=""
              style={{ opacity: 0.3 }}
            />
          </div>
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {storeBillingData ? (
              <TableRow>
                <TableCell>{storeBillingData.store?.store_Id}</TableCell>
                <TableCell>
                  {storeBillingData.store?.systemIds.map((e) => e + ",")}
                </TableCell>
                <TableCell>
                  {monthNames[state?.date.getMonth()]}{" "}
                  {state?.date.getFullYear()}
                </TableCell>
                <TableCell>10$ USD</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{storeBillingData.totalCost}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                  >
                    Download Invoice
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow hover tabIndex={-1}>
                <TableCell
                  colSpan={6}
                  align={"center"}
                  // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </DashboardLayout>
  );
};

export default GenerateInvoice;
