import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetEmployees } from "../../../services/employee.handlers";
import { GetStores } from "../../../services/store.handlers";
import { jsonCheck } from "../../../constants";
import ReactDateRange from "../../../Components/ReactDateRange/ReactDateRange";
import { addDays } from "date-fns";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import moment from "moment";
import {
  GenerateBilling,
  StoreBilling,
} from "../../../services/billing.calculation.handlers";
import Loader from "../../../Components/Loader";
import { GetOrganizations } from "../../../services/organization.handlers";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
const TableRowData = [
  "ID",
  "Name",
  "Contact Info",
  "Address",
  "Status",
  "Action",
];

const BillingGenerate = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  const [open, setOpen] = React.useState(false);
  const [systemId, setSystemId] = React.useState("");
  const [storeId, setStoreId] = React.useState("");
  const [id, setId] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [checkedId, setCheckedId] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("Store");
  const [organizations, setOrganization] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  // var date = new Date();
  // const [state, setState] = React.useState([
  //   {
  //     startDate: new Date(date.getFullYear(), date.getMonth(), 1),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);
  const [formErrors, setFormErrors] = React.useState(false);
  React.useEffect(() => {
    fetchData();
    fetchOrganisations();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };
  const onChangeType = (value) => {
    if (value === "Store") {
      setSelectedItem(value);
      return;
    }
    if (value === "Organization") {
      setSelectedItem(value);
      return;
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: 500,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const handleOpen = (system_Id, store_Id, id) => {
    setOpen(true);
    // setSystemId(system_Id);
    setStoreId(store_Id);
    setId(id);
  };

  // const handleCheckBox = (event) => {
  //   if (event.target.value === "") {
  //     setFormErrors(true);
  //   } else {
  //     setFormErrors(false);
  //   }
  //   setCheckedId(event.target.value);
  // };
  // const handleStore = (props) => {
  //   console.log(props);
  //   setStoreId(props);
  // };

  const handleClose = () => {
    setOpen(false);
    setCheckedId("");
    setFormErrors(false);
    setId("");
  };

  const submitForm = () => {
    setLoader(true);
    // var options = {
    //   Authorization: "Bearer " + authToken,
    // };
    // const payloadData = {
    //   organizationId: 0,
    //   storeId: id,
    //   month_No: date.getMonth() + 1,
    //   year: date.getFullYear(),
    // };
    // GenerateBilling(payloadData, options).then((response) => {
    // StoreBilling(payloadData, options).then((response) => {
    //   if (response.status) {
    //     setLoader(false);
    //     setOpen(false);
    navigate("/generateInvoice", {
      state: {
        // response: response.data,
        date: date,
        storeId: storeId,
        systemId: checkedId,
        id: id,
      },
    });
    //   } else {
    //     setLoader(false);
    //     setOpen(false);
    //   }
    // });
  };
  return (
    <DashboardLayout>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "end" }}
      >
        <div className={"form-group"} style={{ margin: "0", width: "20%" }}>
          <Select
            fullWidth
            className={"form-input"}
            id="select-active"
            defaultValue={"Store"}
            onChange={(e) => {
              onChangeType(e.target.value);
            }}
          >
            <MenuItem value="Store">Store</MenuItem>
            <MenuItem value="Organization">Organization</MenuItem>
          </Select>
        </div>
      </Stack>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          {/* <form> */}
          <Stack direction={"row"} spacing={2}>
            <div style={{ marginTop: 8 }}>
              <>Select Year and Month</>
              {/* <ReactDateRange state={state} setState={setState} /> */}
              <Calendar
                onChange={setDate}
                value={date}
                defaultView="decade"
                maxDetail="year"
              />
            </div>
          </Stack>

          <Stack spacing={2} direction={"row"} style={{ marginTop: 15 }}>
            <Button
              disabled={loader}
              onClick={() => submitForm()}
              variant="contained"
            >
              Apply
              {loader && <Loader color={"#fff"} />}
            </Button>
            <ButtonThemeProvider>
              <Button
                // disabled={loader}
                onClick={() => handleClose()}
                color="grey"
                variant="contained"
              >
                cancel
              </Button>
            </ButtonThemeProvider>
          </Stack>
          {/* </form> */}
        </Box>
      </Modal>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              selectedItem === "Store" ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      //   selected={isItemSelected}
                    >
                      <TableCell>{item.store_Id}</TableCell>
                      <TableCell>{item.store_Name}</TableCell>
                      <TableCell>
                        {item.store_Email}
                        <br />
                        {item.store_Number}
                      </TableCell>
                      <TableCell>
                        {item.store_Address_1 +
                          " " +
                          item.store_Address_2 +
                          ", "}
                        {jsonCheck(item.city)
                          ? JSON.parse(item.city).label
                          : item.city}{" "}
                        {", "}
                        {jsonCheck(item.state)
                          ? JSON.parse(item.state).label
                          : item.state}{" "}
                        {", "}
                        {item.zip + " - "}
                        {jsonCheck(item.country)
                          ? JSON.parse(item.country).label
                          : item.country}
                      </TableCell>

                      <TableCell>
                        {item.isActive ? (
                          <span className={"label color-green"}>Active</span>
                        ) : (
                          <span className={"label color-red"}>Blocked</span>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleOpen(item.systemId, item.store_Id, item.id);
                            // handleStore(item.store_Id);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Generate Bill
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : organizations.length > 0 ? (
                organizations
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{item.org_Id}</TableCell>
                      <TableCell>{item.org_Name}</TableCell>
                      <TableCell>
                        {item.contact_Email}
                        <br />
                        {item.contact_Number}
                      </TableCell>
                      <TableCell>
                        {item.org_Address_1 + " " + item.org_Address_2 + ", "}
                        {jsonCheck(item.city)
                          ? JSON.parse(item.city).label
                          : item.city}{" "}
                        {", "}
                        {jsonCheck(item.state)
                          ? JSON.parse(item.state).label
                          : item.state}{" "}
                        {", "}
                        {item.zip + " - "}
                        {jsonCheck(item.country)
                          ? JSON.parse(item.country).label
                          : item.country}
                      </TableCell>
                      {/* <TableCell>
                        {item.store.map((element) => element.store_Id + ",")}
                      </TableCell> */}
                      <TableCell>
                        {item.isActive ? (
                          <span className={"label color-green"}>Active</span>
                        ) : (
                          <span className={"label color-red"}>Blocked</span>
                        )}
                      </TableCell>
                      <TableCell style={{ minWidth: "180px" }}>
                        <Button
                          onClick={() => {
                            handleOpen(item.systemId, item.store_Id, item.id);
                            // handleStore(item.store_Id);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Generate Bill
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell
                    colSpan={6}
                    align={"center"}
                    // padding="checkbox"
                  >
                    No Records Available
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow hover tabIndex={-1}>
                <TableCell
                  colSpan={6}
                  align={"center"}
                  // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default BillingGenerate;
