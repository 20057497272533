import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import {
  GetTreatmentData,
  SystemUsageByDay,
  SystemUsageByMonth,
  SystemUsageByWeek,
  SystemUsageFile,
  SystemUsageFilteredData,
} from "../../../services/organization.handlers";
import moment from "moment";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import Calendar from "react-calendar";
import ReactDateRange from "../../../Components/ReactDateRange/ReactDateRange";
import { addDays } from "date-fns";
import jsPDF from "jspdf";
import { monthNames } from "../../../constants";
import { Link } from "react-router-dom";
var filename = moment().format("YYYYMMDDHHmmss");

function SystemUsagePage() {
  const pdfRef = React.useRef(null);
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [dailyMonthYear, setDailyMonthYear] = React.useState("All");
  const [dailyMonthYear, setDailyMonthYear] = React.useState("Monthly");
  const [date, setDate] = React.useState(new Date());
  const [day, setDay] = React.useState(new Date());
  const [open, setOpen] = React.useState(false);
  // const [selectedDailyMonthYear, setSelectedDailyMonthYear] =
  //   React.useState("All");
  const [selectedDailyMonthYear, setSelectedDailyMonthYear] =
    React.useState("Monthly");
  // const [state, setState] = React.useState([
  //   {
  //     startDate: addDays(new Date(), -7),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);
  const [state, setState] = React.useState(new Date());
  const authToken = useSelector((state) => state.user.authToken);
  const tableHead = [
    "System Ser. No.",
    // "Customer Id",
    "Date",
    "No of Treatment",
    // "Action",
  ];
  const ReportType = ["Monthly", "Weekly", "Daily"];

  React.useEffect(() => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    // if (
    //   dailyMonthYear === ReportType[0]
    //   // selectedDailyMonthYear === ReportType[0]
    // ) {
    //   fetchData();
    // } else
    if (selectedDailyMonthYear === ReportType[0]) {
      SystemUsageByMonth(
        `${date.getFullYear()}-${date.getMonth() + 1}`,
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (selectedDailyMonthYear === ReportType[1]) {
      SystemUsageByWeek(
        moment(state).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (selectedDailyMonthYear === ReportType[2]) {
      SystemUsageByDay(
        moment(day).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    }
  }, [page, selectedDailyMonthYear === ReportType[0], rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    SystemUsageFilteredData(page + 1, rowsPerPage, options).then((response) => {
      if (response.status) {
        setData(response.data.data);
        setAllData(response.data);
      }
    });
  };
  const _exportPdf = () => {
    const content = pdfRef.current;

    const doc = new jsPDF("landscape");
    doc.html(content, {
      callback: function (doc) {
        var pageCount = doc.internal.getNumberOfPages();

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();

          const footer = `Page ${i} of ${pageCount}`;
          doc.setFontSize(10).setFont(undefined, "bold");
          doc.text(
            // "System Usage Report",
            "System Treatment Record",
            pageWidth / 2 - doc.getTextWidth("System Treatment Record") / 2,
            5,
            { baseline: "top" }
          );
          doc.setFontSize(10).setFont(undefined, "normal");
          let paragraph =
            "All information contained herein is confidential and proprietary. All design, manufacturing, use, reproduction, & sales rights are expressly reserved. Communication of this information to others is prohibited.";
          var lines = doc.splitTextToSize(paragraph, 310 - 15 - 15);
          doc.text(lines, 10, pageHeight - 20, { baseline: "bottom" });
          doc.rect(5, pageHeight - 27, pageWidth - 10, 15);
          doc.text(
            footer,
            pageWidth / 2 - doc.getTextWidth(footer) / 2,
            pageHeight - 5,
            { baseline: "bottom" }
          );
        }
        // window.open(doc.output("bloburl"));
        doc.save(filename + "-report.pdf");
      },
      margin: [10, 60, 40, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 270, //target width in the PDF document
      windowWidth: 1200,
      // html2canvas: { scale: 0.257 },
    });
  };
  const submitForm = () => {
    setSelectedDailyMonthYear(dailyMonthYear);
    setRowsPerPage(parseInt(10));
    setPage(0);
    setOpen(false);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (dailyMonthYear === ReportType[0]) {
      SystemUsageByMonth(
        `${date.getFullYear()}-${date.getMonth() + 1}`,
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (dailyMonthYear === ReportType[1]) {
      SystemUsageByWeek(
        moment(state).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (dailyMonthYear === ReportType[2]) {
      SystemUsageByDay(
        moment(day).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    }
  };

  const downloadFile = (filePath, fileName) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };

    if (filePath) {
      SystemUsageFile(filePath, options).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "SystemUsage";

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        }
      });
    }
  };
  const handleDayMonthWeek = (e) => {
    setDailyMonthYear(e.target.value);
    if (e.target.value === ReportType[0]) {
      setOpen(true);
    } else if (e.target.value === ReportType[1]) {
      setOpen(true);
    } else if (e.target.value === ReportType[2]) {
      setOpen(true);
    }
    // else if (e.target.value === ReportType[0]) {
    //   setSelectedDailyMonthYear(e.target.value);
    //   setPage(0);
    // }
  };

  const handleClose = () => {
    setDailyMonthYear(selectedDailyMonthYear);
    setOpen(false);
  };

  // get week day, monday and sunday date by passing any of the date between week
  const getDateRange = (prop) => {
    const day = prop.getDay();
    const diff = prop.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(prop.setDate(diff));

    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    return `${moment(monday).format("YYYY/MM/DD")} - ${moment(sunday).format(
      "YYYY/MM/DD"
    )}`;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    maxHeight: 600,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <DashboardLayout>
      {/* {dailyMonthYear !== ReportType[0] && ( */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          {/* <form> */}

          {dailyMonthYear === ReportType[0] && (
            <Calendar
              onChange={setDate}
              value={date}
              defaultView="decade"
              maxDetail="year"
            />
          )}
          {dailyMonthYear === ReportType[1] && (
            // <ReactDateRange state={state} setState={setState} />
            <Calendar onChange={setState} value={state} />
          )}
          {dailyMonthYear === ReportType[2] && (
            <Calendar onChange={setDay} value={day} />
          )}

          <Stack spacing={2} direction={"row"} style={{ paddingTop: "30px" }}>
            <Button
              // disabled={loader}
              onClick={() => submitForm()}
              variant="contained"
            >
              Apply
              {/* {loader && <Loader color={"#fff"} />} */}
            </Button>
            <ButtonThemeProvider>
              <Button
                // disabled={loader}
                onClick={() => handleClose()}
                color="grey"
                variant="contained"
              >
                cancel
              </Button>
            </ButtonThemeProvider>
          </Stack>
          {/* </form> */}
        </Box>
      </Modal>
      {/* )} */}
      <Stack
        spacing={2}
        direction="row"
        style={{ justifyContent: "space-between" }}
      >
        {/* <h3>System Usage Report</h3> */}
        {/* <h3>Billing Summary by System</h3> */}
        <h3>System Treatment Record</h3>
        <div style={{ display: "flex", width: "40%", justifyContent: "end" }}>
          <div className={"form-group"} style={{ margin: "0", width: "40%" }}>
            <InputLabel className={"form-label"}>Select</InputLabel>
            <Select
              // defaultOpen
              value={dailyMonthYear}
              renderValue={() =>
                dailyMonthYear === ReportType[0]
                  ? `${monthNames[date.getMonth()]} ${date.getFullYear()}`
                  : dailyMonthYear
              }
              fullWidth
              className={"form-input"}
              id="select-active"
              // defaultValue={new Date().getMonth() + 1}
              onChange={handleDayMonthWeek}
              // onClick={() => setOpen(true)}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              {ReportType.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => setOpen(true)}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Button
            disabled={data.length <= 0 ? true : false}
            style={{ marginLeft: "10px", marginTop: "25px" }}
            onClick={() => _exportPdf()}
            variant="contained"
          >
            Export PDF
          </Button>
        </div>
      </Stack>

      <div ref={pdfRef} id={"html-pdf"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((item, index) => (
                  <TableCell key={index}>
                    {selectedDailyMonthYear === ReportType[1] && index === 1
                      ? "Date Range"
                      : item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.length > 0 ? (
                data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Link
                          to="/reports/systemUsageDetailPage"
                          state={{
                            system_Id: item.system_Id,
                            dailyMonthYear,
                            date:
                              (dailyMonthYear === ReportType[0] && date) ||
                              (dailyMonthYear === ReportType[1] && state) ||
                              (dailyMonthYear === ReportType[2] && day),
                          }}
                        >
                          {item.system_Id}
                        </Link>
                      </TableCell>
                      {/* <TableCell>{item.customer_Id}</TableCell> */}
                      <TableCell>
                        {selectedDailyMonthYear === ReportType[1] &&
                        item.startDateOfWeek &&
                        item.endDateOfWeek
                          ? `${moment(item.startDateOfWeek).format(
                              "YYYY/MM/DD"
                            )} - ${moment(item.endDateOfWeek).format(
                              "YYYY/MM/DD"
                            )}`
                          : selectedDailyMonthYear === ReportType[0]
                          ? `${
                              monthNames[date.getMonth()]
                            } ${date.getFullYear()}`
                          : moment(item.created_Date).format("YYYY/MM/DD")}
                      </TableCell>

                      <TableCell>{item.countOfSystemIds}</TableCell>
                      {/* <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            downloadFile(item.filePath, item.new_File_Name)
                          }
                        >
                          Download File
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan={4} align={"center"}>
                    No Records Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={allData.totalRecords || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default SystemUsagePage;
