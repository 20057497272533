import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const loggedInUser = async (payload) => {
  return await AxiosInstance.post(`${API_ROUTE.AUTH.LOGGED_IN}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const forgotPassword = async (payload) => {
  return await AxiosInstance.post(`${API_ROUTE.AUTH.FORGOT_PASSWORD}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const checkHash = async (payload) => {
  return await AxiosInstance.post(`${API_ROUTE.AUTH.CHECK_HASH}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const forgotChangePassword = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.AUTH.FORGOT_CHANGE_PASSWORD}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ChangePasswords = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.AUTH.CHANGE_PASSWORD}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
