import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetSystems = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_SYSTEMS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateSystem = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.CRUD_SYSTEMS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetSystem = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_SYSTEMS}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateSystem = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.CRUD_SYSTEMS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const uploadDoc = async (id, type, prefix, payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.UPLOAD_DOC}?id=${id}&type=${type}&prefix=${prefix}`,
    payload,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const viewDoc = async (id, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.VIEW_DOC}?id=${id}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const pdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getPartNumber = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.PART_NUMBER}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getRevNumber = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.REV_NUMBER}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addPartNumber = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.PART_NUMBER}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addBulkPartNumber = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.ADD_BULK_PART_NUMBER}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const addRevNumber = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.REV_NUMBER}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

// export const addSystem = async (param, payload, headers) => {
//   return await AxiosInstance.post(
//     `${API_ROUTE.SYSTEMS.ADD_SYSTEM}?System_Id=${param.system_Id}&Gen_Id=${param.gen_Id}&Hdset_Id=${param.hdset_Id}&Tablet_Id=${param.tablet_Id}&SysBuild_Date=${param.sysBuild_Date}&GenBuild_Date=${param.genBuild_Date}&HdsetBuild_Date=${param.hdsetBuild_Date}&TabletBuild_Date=${param.tabletBuild_Date}&SysBuild_Location=${param.sysBuild_Location}&GenBuild_Location=${param.genBuild_Location}&HdsetBuild_Location=${param.hdsetBuild_Location}&TabletBuild_Location=${param.tabletBuild_Location}&SysPart_No=${param.sysPart_No}&GenPart_No=${param.genPart_No}&HdsetPart_No=${param.hdsetPart_No}&TabletPart_No=${param.tabletPart_No}&SysRev_No=${param.sysRev_No}&GenRev_No=${param.genRev_No}&HdsetRev_No=${param.hdsetRev_No}&TabletRev_No=${param.tabletRev_No}&SysRouter=${param.sysRouter}&GenRouter=${param.genRouter}&HdsetRouter=${param.hdsetRouter}&TabletRouter=${param.tabletRouter}&Gen_PCB_Id=${param.gen_PCB_Id}&Hdset_PCB_Id=${param.hdset_PCB_Id}&Component_No=${param.component_No}&Variant=${param.Variant}&Country_Code=${param.country_Code}&Lot_No=${param.Lot_No}&Sl_No=${param.system_Id}`,
//     payload,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

export const addSystem = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.ADD_SYSTEM}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getGenerator = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_GENERATOR}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};
export const addGenerator = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.ADD_GENERATOR}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getGeneratorById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_GENERATOR}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const genPdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.GEN_PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateGenerator = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.GET_GENERATOR}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getHeadset = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_HEADSET}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addHeadset = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.ADD_HEADSET}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getHeadsetById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_HEADSET}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateHeadset = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.GET_HEADSET}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const hdsetPdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.HEADSET_PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getTablet = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_TABLET}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addTablet = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.ADD_TABLET}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getTabletById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_TABLET}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateTablet = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.GET_TABLET}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const tabletPdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.TABLET_PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updatePartNo = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.PART_NUMBER}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getPartNumberById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.PART_NUMBER}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};
export const getRevNumberById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.REV_NUMBER}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateRevNo = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.REV_NUMBER}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const deleteRevNo = async (id, headers) => {
  return await AxiosInstance.delete(`${API_ROUTE.SYSTEMS.REV_NUMBER}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getGeneratorPcb = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_GENERATOR_PCB}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addGeneratorPcb = async (param, payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.ADD_GENERATOR_PCB}?Generator_PCB_Id=${param.generator_PCB_Id}&Generator_PCBPart_No=${param.generator_PCBPart_No}&Generator_PCBRev_No=${param.generator_PCBRev_No}&Generator_PCBBuild_Date=${param.generator_PCBBuild_Date}&Generator_PCBRouter=${param.generator_PCBRouter}&Generator_PCBBuild_Location=${param.generator_PCBBuild_Location}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getGeneratorPcbById = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.GET_GENERATOR_PCB}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateGeneratorPcb = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.GET_GENERATOR_PCB}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const generatorPcbPdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.GEN_PCB_PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getHeadsetPcb = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_HEADSET_PCB}`, {
    headers,
  })
    .then((response) => response)
    .then((error) => error);
};

export const addHeadsetPcb = async (param, payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.ADD_HEADSET_PCB}?Headset_PCB_Id=${param.headset_PCB_Id}&Headset_PCBPart_No=${param.headset_PCBPart_No}&Headset_PCBRev_No=${param.headset_PCBRev_No}&Headset_PCBBuild_Date=${param.headset_PCBBuild_Date}&Headset_PCBRouter=${param.headset_PCBRouter}&Headset_PCBBuild_Location=${param.headset_PCBBuild_Location}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateHeadsetPcb = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.GET_HEADSET_PCB}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getHeadsetPcbById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.GET_HEADSET_PCB}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const headsetPcbPdfDownload = async (id, name, type, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.HDSET_PCB_PDF_DOWNLOAD}?id=${id}&name=${name}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getCable = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_CABLE}`, {
    headers,
  })
    .then((response) => response)
    .then((error) => error);
};

export const addCable = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.SYSTEMS.CRUD_CABLE}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};
export const updateCable = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.CRUD_CABLE}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getCableById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_CABLE}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addInvPartNumber = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.CRUD_PART_NUMBER}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getInvPartNumber = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_PART_NUMBER}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateInvPartNo = async (param, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.CRUD_PART_NUMBER}/${param.component_No}/${param.c_Code}/${param.variation}/${param.sl_No}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getInvPartNumberById = async (param, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.SYSTEMS.CRUD_PART_NUMBER}/${param.component_No}/${param.c_Code}/${param.variation}/${param.sl_No}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const addHarness = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.SYSTEMS.CRUD_HARNESS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getInvHarness = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_HARNESS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getHarnessById = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.SYSTEMS.CRUD_HARNESS}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateHarness = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.SYSTEMS.CRUD_HARNESS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
