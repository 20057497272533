import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { updateSystem, GetSystem } from "../../../services/systems.handlers";
import { useNavigate, useParams } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GetStores } from "../../../services/store.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const EditSystem = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
  });

  React.useEffect(() => {
    fetchSystems();
  }, []);

  const fetchSystems = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystem(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/system");
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateSystem(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          system_Id: "",
          gen_Id: "",
          hdset_Id: "",
          tablet_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/system");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Edit System</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="system_Id">
                  System Id
                </InputLabel>
                <TextField
                  id="system_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      system_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="gen_Id">
                  Generator Id
                </InputLabel>
                <TextField
                  id="gen_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.gen_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, gen_Id: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="hdset_Id">
                  Headset Id
                </InputLabel>
                <TextField
                  id="hdset_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.hdset_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hdset_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="tablet_Id">
                  Tablet Id
                </InputLabel>
                <TextField
                  id="tablet_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.tablet_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tablet_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update System
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/system")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditSystem;
