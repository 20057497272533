import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetSystem } from "../../../services/systems.handlers";
import { TableHead } from "@mui/material";
import { Link } from "react-router-dom";

const SystemDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystem(params.id,options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
              <TableRow>
              <TableCell colspan="5">System Details - {data.system_Id} </TableCell>
               
              </TableRow>
          </TableHead>
          {Object.keys(data).length > 0 &&
          <TableBody>
          <TableRow
                    hover
                  >
                  
                  <TableCell>{"System"}</TableCell>
                    <TableCell>{data.system_Id}</TableCell>
                    <TableCell>Category 1</TableCell>
                    <TableCell>Category 2</TableCell>
                    <TableCell>Accessories</TableCell>
                    {/* <TableCell><Link to={"/sub-assembly/1"}>{"Sub Assembly 1"}</Link></TableCell>
                    <TableCell><Link to={"/sub-assembly/2"}>{"Sub Assembly 2"}</Link></TableCell> */}
                  </TableRow>
                  <TableRow
                    hover
                  >
                    <TableCell><Link to={"/generator/" + data.gen_Id}>Generator</Link></TableCell>
                    <TableCell>{data.gen_Id}</TableCell>
                    <TableCell>{"Mechanicals"}</TableCell>
                    <TableCell>{"Electricals"}</TableCell>
                    <TableCell>{"Pwr Supply, cables"}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                  >
                   <TableCell>Headset</TableCell>
                    <TableCell>{data.hdset_Id}</TableCell>
                    <TableCell>{"Mechanicals"}</TableCell>
                    <TableCell>{"Electricals"}</TableCell>
                    <TableCell>{"Pwr Supply, cables"}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                  >
                   <TableCell>Tablet</TableCell>
                    <TableCell>{data.tablet_Id}</TableCell>
                    <TableCell>{"Mechanicals"}</TableCell>
                    <TableCell>{"Electricals"}</TableCell>
                    <TableCell>{"Pwr Supply, cables"}</TableCell>
                  </TableRow>
          </TableBody>
        }
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default SystemDetail;
