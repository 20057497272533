import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { GetEmployeesLogsByID } from "../../../services/employee.logs.handlers";
import moment from "moment";

const TableRowData = ["ID", "Name", "Status", "Date"];

const EmployeeLogsID = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetEmployeesLogsByID(options, params.id).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{item.employee_ID}</TableCell>
                    <TableCell>{item.employee_Name}</TableCell>
                    <TableCell>
                      {item.login_Logout == "Signin"
                        ? "Sign In"
                        : item.login_Logout == "SignOut"
                        ? "Sign Out"
                        : item.login_Logout}
                    </TableCell>

                    <TableCell>
                      {moment(item.created_Date).format(
                        "YYYY/MM/DD hh:mm:ss A"
                      )}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={4} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default EmployeeLogsID;
