import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  _getCountryName,
  _getNewCountryName,
  getCountryName,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  getInvHarness,
  getInvPartNumber,
  getPartNumber,
} from "../../../services/systems.handlers";
import AddNewExcelFile from "./AddNewExcelFile";
import { GetExcelFile } from "../../../services/excelFile.handlers";

function NewFormatExcelFile() {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const TableRowData = [
    "System Sn",
    "Part No.",
    "Part Name",
    "ECO/LOT",
    "Revision",
    "Serial No.",
    // "Status",
    "Action",
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    // GetExcelFile(options).then((response) => {
    getInvHarness(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  return (
    <DashboardLayout>
      <Stack
        spacing={40}
        direction="row"
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>Sub Assemblies</h3>
        <AddNewExcelFile getData={() => fetchData()} />
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{item.system_Serial_No}</TableCell>

                    <TableCell style={{ minWidth: "140px" }}>
                      {item.component_No +
                        "-" +
                        item.country_Code +
                        "-" +
                        item.variant}
                    </TableCell>
                    <TableCell>{item.part_Description_Name}</TableCell>
                    <TableCell style={{ minWidth: "100px" }}>
                      {item.lot_No}
                    </TableCell>
                    {/* <TableCell style={{ width: 140 }}>
                      <>
                        {item.country_Code && item.country_Code?.length < 4 ? (
                          <>
                            {item.country_Code}-
                            {getCountryName(item.country_Code)}
                          </>
                        ) : (
                          item.country_Code
                        )}
                      </>
                    </TableCell> */}
                    <TableCell>{item.revision}</TableCell>
                    <TableCell>{item.sl_No}</TableCell>
                    {/* <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell> */}
                    <TableCell>
                      <Button
                        onClick={() =>
                          // navigate(
                          //   `/updatePartNo/${item.component_No}/${item.c_Code}/${item.sl_No}`
                          // )
                          // navigate("/updatePartNo", {
                          //   state: {
                          //     component_No: item.component_No,
                          //     c_Code: item.c_Code,
                          //     variation: item.variation,
                          //     sl_No: item.sl_No,
                          //   },
                          // })
                          navigate("/updateExcelFile/" + item.id)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={10} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default NewFormatExcelFile;
