import React from "react";
import DashboardLayout from "../../Layout/DashboardLayout";

const ContactInfo = () => {
  
  return (
    <DashboardLayout>
      <div>
      <p>
        <span><strong>Macumira</strong></span>
        </p>
        <p>
        <span><strong>Address: </strong>Suite #507 234 Pine St. SW <br />
          Toronto ON S74 8MN
          <br />
          Canada</span>
        </p>
        <p>
          <strong>Contact No: </strong>999-999-9999
        </p>
      </div>
    </DashboardLayout>
  );
};

export default ContactInfo;
