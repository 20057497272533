import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import * as XLSX from "xlsx";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { _getCountryName } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import { getHeadsetPcb } from "../../../services/systems.handlers";
import moment from "moment";
import AddHeadsetPcb from "./AddHeadsetPcb";

function HeadsetPcb() {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [excelData, setExcelData] = React.useState([]);

  const TableRowData = [
    " Ser. No.",
    " Part No",
    " Rev",
    " Build Date",
    " Build Location",
    " Router",
    "Status",
    "Action",
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getHeadsetPcb(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  //excel file
  // const handleFileUpload = (e) => {
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(reader);
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     setExcelData(parsedData);
  //   };
  // };
  // console.log(excelData);
  return (
    <DashboardLayout>
      <h3 style={{ marginBottom: -5, marginTop: 0 }}>Headset PCB</h3>
      <hr></hr>
      <Stack
        spacing={40}
        direction="row"
        style={{
          marginTop: "16px",
          marginBottom: "30px",
          justifyContent: "end",
        }}
      >
        <AddHeadsetPcb getData={() => fetchData()} />
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Link to={"/headsetPcb-detail-page/" + item.id}>
                        {item.headset_PCB_Id}
                      </Link>
                    </TableCell>

                    <TableCell>
                      <>
                        {item.headset_PCBPart_No}
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "102px",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          {_getCountryName(item?.headset_PCBPart_No)}
                        </div>
                      </>
                    </TableCell>
                    <TableCell>{item.headset_PCBRev_No}</TableCell>
                    <TableCell>
                      {moment(item.headset_PCBBuild_Date).format("YYYY/MM/DD")}
                    </TableCell>
                    <TableCell>{item.headset_PCBBuild_Location}</TableCell>
                    <TableCell>{item.headset_PCBRouter}</TableCell>
                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/updateHeadsetPcb/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={7} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <input type="file" accept=".xlsx,.xls" onChange={handleFileUpload} /> */}
    </DashboardLayout>
  );
}

export default HeadsetPcb;
