import { Country, State, City } from "country-state-city";

export const _getCountry = () => {
  let countryArray = [
    { label: "United States", value: "US" },
    { label: "Canada", value: "CA" },
  ];
  //   let countryData = Country.getAllCountries();
  //   countryData.map((item) => {
  //     countryArray.push({
  //       label: item.name,
  //       value: item.isoCode,
  //     });
  //   });
  return countryArray;
};

export const _getCountryStates = (val) => {
  let statesArray = [];
  let stateData = State.getStatesOfCountry(val.value);
  stateData.map((item) => {
    statesArray.push({
      label: item.name,
      value: item.isoCode,
      countryCode: item.countryCode,
    });
  });

  return statesArray;
};

export const _getCityState = (val) => {
  let cityArray = [];
  let cityData = City.getCitiesOfState(val.countryCode, val.value);
  cityData.map((item) => {
    cityArray.push({
      label: item.name,
      value: item.countryCode,
    });
  });
  return cityArray;
};

export const jsonCheck = (jsonString) => {
  var isValidJSON = true;
  if (jsonString == null) {
    return false;
  }
  try {
    JSON.parse(jsonString);
  } catch {
    isValidJSON = false;
  }
  return isValidJSON;
};

export const _getCountryName = (partNo) => {
  const substringPart = partNo?.substring(7, 10);
  if (substringPart === "000") {
    return "International";
  }
  if (substringPart === "001") {
    return "USA";
  }
  if (substringPart === "002") {
    return "Canada";
  }
  if (substringPart === "003") {
    return "Mexico";
  }
  if (substringPart === "004") {
    return "Australia";
  }
};

export const _getNewCountryName = (partNo) => {
  // const substringPart = partNo?.substring(7, 10);
  if (partNo === "0") {
    return "International";
  }
  if (partNo === "1") {
    return "USA";
  }
  if (partNo === "2") {
    return "Canada";
  }
  if (partNo === "3") {
    return "Mexico";
  }
  if (partNo === "4") {
    return "Australia";
  }
};

export const getCountryName = (partNo) => {
  const substringPart = partNo;
  if (substringPart === "000") {
    return "International";
  }
  if (substringPart === "001") {
    return "USA";
  }
  if (substringPart === "002") {
    return "Canada";
  }
  if (substringPart === "003") {
    return "Mexico";
  }
  if (substringPart === "004") {
    return "Australia";
  }
};

export const sampleCsvFile = [
  {
    Component: "Generator",
    Component_No: "100164",
    Part_Desciption_Name: "Main Power Switch Harness",
    C_Code: "001",
    Variant: "1",
    // SN_Start: "1",
    // SN_End: "2",
    Quantity: "10",
    Lot_No: "LOT-01",
    // Build_Date: "2023-08-17",
    Manufacture: "Abarla",
    Purchase_Date: "2023-08-17",
    Release_Date: "2023-08-30",
  },
];

export const countryName = [
  "000-International",
  "001-USA",
  "002-Canada",
  "003-Mexico",
  "004-Australia",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const years = function () {
  var currentYear = new Date().getFullYear(),
    years = [];
  let startYear = 2019 - 20 || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const _admin = {
  Admin: 0,
};
