import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  Alert,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  getHeadsetById,
  getInvHarness,
  getInvPartNumber,
  getTabletById,
  updateHeadset,
  updateTablet,
} from "../../../services/systems.handlers";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import { getCountryName } from "../../../constants";

function EditTablet() {
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  const [partItem, setPartItem] = React.useState([]);
  const [formData, setFormData] = React.useState({
    id: "",
    tablet_Id: "",
    tabletPart_No: "",
    tabletRev_No: "",
    tabletBuild_Date: "",
    tabletBuild_Location: "",
    tabletRouter: "",
    component_No: "",
    country_Code: "",
    variant: "",
    lot_No: "",
    sl_No: "",
    guI_Rev: "",
    apI_Rev: "",
    system_Rev: "",
    os: "",
    sW_Rev: "",
    macumira: "",
    macumira_Rev: "",
    macumira_BuildDate: "",
    macumira_Api: "",
    macumira_Software_Rev: "",
    macumira_Operating_System: "",
    macumira_Api_Sw_Rev: "",
    macumira_Api_BuildDate: "",
    operating_System_BuildDate: "",
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
    partNo();
  }, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const tabletPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const tabletPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const tabletPropLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );

  const tabletPropSlNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.sl_No
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.sl_No),
    [partItem]
  );

  const defaultPropsSlNo = {
    options: tabletPropSlNo,
    getOptionLabel: (option) => option.toString(),
  };

  const defaultPropsLotNo = {
    options: tabletPropLotNo,
    getOptionLabel: (option) => option,
  };
  const tabletDefaultCode = {
    options: tabletPropCode,
    getOptionLabel: (option) => option,
  };
  const tabletDefaultVariant = {
    options: tabletPropVariant,
    getOptionLabel: (option) => option,
  };
  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
        // getDefaultValueCountryCodeVariation(response.data);
      }
    });
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getTabletById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate(-1);
      }
    });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");
    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    const fileData = new FormData();
    fileData.append("Id", formData.id);
    fileData.append("IsActive", formData.isActive);
    fileData.append("TabletRev_No", formData.tabletRev_No);
    fileData.append("Tablet_Id", formData.tablet_Id);
    fileData.append("TabletBuild_Location", formData.tabletBuild_Location);
    fileData.append("TabletBuild_Date", formData.tabletBuild_Date);
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.lot_No);
    fileData.append("Variant", formData.variant);
    fileData.append("Sl_No", formData.tablet_Id);
    fileData.append("GUI_Rev", formData.guI_Rev);
    fileData.append("API_Rev", formData.apI_Rev);
    fileData.append("System_Rev", formData.system_Rev);
    fileData.append("OS", formData.os);
    fileData.append("SW_Rev", formData.sW_Rev);
    fileData.append("Macumira", formData.macumira);
    fileData.append("Macumira_Rev", formData.macumira_Rev);
    fileData.append("Macumira_BuildDate", formData.macumira_BuildDate);
    fileData.append("Macumira_Api", formData.macumira_Api);
    fileData.append("Macumira_Api_Sw_Rev", formData.macumira_Api_Sw_Rev);
    fileData.append("Macumira_Api_BuildDate", formData.macumira_Api_BuildDate);
    fileData.append(
      "Macumira_Operating_System",
      formData.macumira_Operating_System
    );
    fileData.append("Macumira_Software_Rev", formData.macumira_Software_Rev);
    fileData.append(
      "Operating_System_BuildDate",
      formData.operating_System_BuildDate
    );
    fileData.append("TabletRouter", formData.tabletRouter);

    updateTablet(params.id, fileData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate(-1);
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            {error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
          </Grid>
          <h3>Edit Tablet</h3>
          <Grid container spacing={3}>
            <ComponentName name="Part No" value={formData.component_No} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...tabletDefaultCode}
                  value={formData.country_Code || ""}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      country_Code: newValue || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  // defaultValue={"2"}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...tabletDefaultVariant}
                  value={formData.variant || ""}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      variant: newValue || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsLotNo}
                  value={formData.lot_No || ""}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      Lot_No: newValue || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsSlNo}
                  value={formData.tablet_Id || ""}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      tablet_Id: newValue || "",
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.toString() === value.toString()
                  }
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="hdsetBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  name="tabletBuild_Location"
                  fullWidth
                  value={formData.tabletBuild_Location}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tabletRev_No">
                  Rev
                </InputLabel>

                <TextField
                  name="tabletRev_No"
                  fullWidth
                  value={formData.tabletRev_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="os">
                  OS Rev
                </InputLabel>

                <TextField
                  name="os"
                  fullWidth
                  value={formData.os}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="sW_Rev">
                  SW Rev
                </InputLabel>

                <TextField
                  name="sW_Rev"
                  fullWidth
                  value={formData.sW_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="tabletBuild_Date"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.tabletBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tabletBuild_Date: e,
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira">
                  Macumira
                </InputLabel>

                <TextField
                  name="macumira"
                  fullWidth
                  value={formData.macumira}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira_Rev">
                  Rev
                </InputLabel>

                <TextField
                  name="macumira_Rev"
                  fullWidth
                  value={formData.macumira_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="macumira_BuildDate"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.macumira_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        macumira_BuildDate: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira_Api">
                  Macumira API
                </InputLabel>

                <TextField
                  name="macumira_Api"
                  fullWidth
                  value={formData.macumira_Api}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Api_Sw_Rev"
                >
                  SW Rev
                </InputLabel>

                <TextField
                  name="macumira_Api_Sw_Rev"
                  fullWidth
                  value={formData.macumira_Api_Sw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="macumira_Api_BuildDate"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.macumira_Api_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        macumira_Api_BuildDate: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Operating_System"
                >
                  Operating System
                </InputLabel>

                <TextField
                  name="macumira_Operating_System"
                  fullWidth
                  value={formData.macumira_Operating_System}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Software_Rev"
                >
                  SW Rev
                </InputLabel>

                <TextField
                  name="macumira_Software_Rev"
                  fullWidth
                  value={formData.macumira_Software_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="operating_System_BuildDate"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.operating_System_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        operating_System_BuildDate:
                          moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="guI_Rev">
                  GUI Rev
                </InputLabel>

                <TextField
                  name="guI_Rev"
                  fullWidth
                  value={formData.guI_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="apI_Rev">
                  API Rev
                </InputLabel>

                <TextField
                  name="apI_Rev"
                  fullWidth
                  value={formData.apI_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="system_Rev">
                  System Rev
                </InputLabel>

                <TextField
                  name="system_Rev"
                  fullWidth
                  value={formData.system_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={2.6}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  style={{
                    marginTop: 24,
                    height: "40px",
                    gap: "5px",
                    alignItems: "center",
                  }}
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Tablet
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    style={{
                      marginTop: 24,
                    }}
                    onClick={() => navigate(-1)}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
}

export default EditTablet;
