import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Loader from "../../../Components/Loader";
import { CreateBillingSummary } from "../../../services/billing.summary.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddBillingSummary = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const [formData, setFormData] = React.useState({
    dr_Name: "",
    time_Used: "",
    no_Of_Treatment: "",
    date: "",
    invoice: "",
    invoice_Ddate: "",
    invoice_Start_Date: "",
    invoice_Eend_Date: "",
    cost: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    dr_Name: "",
    time_Used: "",
    no_Of_Treatment: "",
    date: "",
    invoice: "",
    invoice_Ddate: "",
    invoice_Start_Date: "",
    invoice_Eend_Date: "",
    cost: "",
  });

  const setDoctorName = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        dr_Name: "Doctor Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        dr_Name: "",
      });
    }
    setFormData({ ...formData, dr_Name: value });
  };

  const setTreatments = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        no_Of_Treatment: "No. of treatments cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        no_Of_Treatment: "",
      });
    }
    setFormData({ ...formData, no_Of_Treatment: value });
  };

  const submitForm = () => {
    if (!formData.dr_Name) {
      setDoctorName(formData.employee_ID);
      return;
    }
    if (!formData.no_Of_Treatment) {
      setTreatments(formData.no_Of_Treatment);
      return;
    }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateBillingSummary(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          dr_Name: "",
          time_Used: "",
          no_Of_Treatment: "",
          date: "",
          invoice: "",
          invoice_Ddate: "",
          invoice_Start_Date: "",
          invoice_Eend_Date: "",
          cost: "",
          isActive: 1,
        });
        setFormErrors({
          dr_Name: "",
          time_Used: "",
          no_Of_Treatment: "",
          date: "",
          invoice: "",
          invoice_Ddate: "",
          invoice_Start_Date: "",
          invoice_Eend_Date: "",
          cost: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Billing Summary</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="dr_Name">
                  Doctor Name
                </InputLabel>
                <TextField
                  id="dr_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.dr_Name}
                  onChange={(e) => setDoctorName(e.target.value)}
                  error
                  helperText={formErrors.dr_Name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="time_Used">
                  Machine Use Time
                </InputLabel>
                <TextField
                  id="time_Used"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.time_Used}
                  onChange={(e) =>
                    setFormData({ ...formData, time_Used: e.target.value })
                  }
                  error
                  helperText={formErrors.time_Used}
                />
              </div>
            </Grid>
            {/* 
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="time_Used">
                    Machine Use Time
                  </InputLabel>

                  <TimePicker
                   
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="no_Of_Treatment">
                  No. of Treatment
                </InputLabel>
                <TextField
                  id="no_Of_Treatment"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.no_Of_Treatment}
                  onChange={(e) => setTreatments(e.target.value)}
                  error
                  helperText={formErrors.no_Of_Treatment}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="date">
                    Date of Invoice
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.date}
                    onChange={(e) => setFormData({ ...formData, date: e })}
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="invoice">
                  Invoice
                </InputLabel>
                <TextField
                  id="invoice"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.invoice}
                  onChange={(e) =>
                    setFormData({ ...formData, invoice: e.target.value })
                  }
                  error
                  helperText={formErrors.invoice}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="invoice_Ddate">
                    Invoice Date
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.invoice_Ddate}
                    onChange={(e) =>
                      setFormData({ ...formData, invoice_Ddate: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="invoice_Start_Date"
                  >
                    Invoice Start Date
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.invoice_Start_Date}
                    onChange={(e) =>
                      setFormData({ ...formData, invoice_Start_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="invoice_Eend_Date"
                  >
                    Invoice End Date
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.invoice_Eend_Date}
                    onChange={(e) =>
                      setFormData({ ...formData, invoice_Eend_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="cost">
                  Cost
                </InputLabel>
                <TextField
                  id="invoice"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.cost}
                  onChange={(e) =>
                    setFormData({ ...formData, cost: e.target.value })
                  }
                  error
                  helperText={formErrors.cost}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Billing Summary
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/billing_summary")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddBillingSummary;
