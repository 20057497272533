import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetInvLotLineDescs = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.InvLotLineDesc.CRUD}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateInvLotLineDesc = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.InvLotLineDesc.CRUD}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetInvLotLineDesc = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.InvLotLineDesc.CRUD}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateInvLotLineDesc = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.InvLotLineDesc.CRUD}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
