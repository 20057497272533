import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Loader from "../../../Components/Loader";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getRevNumberById,
  updateRevNo,
} from "../../../services/systems.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

function EditRevNumber() {
  const navigate = useNavigate();
  const params = useParams();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    part_No: "",
    isDefault: 0,
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
    setFormData({ ...formData, isDefault: formData.isDefault });
  }, []);

  const handleDefault = (event) => {
    setFormData({ ...formData, isDefault: 0 });

    if (event.target.checked) {
      setFormData({ ...formData, isDefault: 1 });
    } else {
      setFormData({ ...formData, isDefault: 0 });
    }
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumberById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/rev");
      }
    });
  };

  const submitForm = () => {
    setLoader(true);
    var options = {
      Authorization: "Berrer" + authToken,
    };
    updateRevNo(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setTimeout(() => {
          setSuccess("");
          navigate("/rev");
        }, 3000);
        setLoader(false);
        setFormData({
          part_No: "",
          isDfault: 0,
          isActive: 1,
        });
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <Grid container spacing={2}>
          {error !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {success !== "" && (
            <Grid item xs={12}>
              <Alert severity="success">{success}</Alert>
            </Grid>
          )}
        </Grid>
        <h3>Edit Rev</h3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="rev_no">
                Rev
              </InputLabel>
              <TextField
                name="rev_no"
                fullWidth
                value={formData.rev_No}
                className={"form-input"}
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, rev_No: e.target.value })
                }
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "-10px" }}>
            <div className={"form-group"}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Status
              </InputLabel>
              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.isActive}
                onChange={(e) => {
                  setFormData({ ...formData, isActive: e.target.value });
                }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Blocked</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={"form-checkbox"}
              control={<Checkbox />}
              label="Make it default"
              checked={formData.isDefault === 1 ? true : false}
              onChange={handleDefault}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={loader}
                variant="contained"
                onClick={submitForm}
              >
                Update Rev
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate("/rev")}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default EditRevNumber;
