import * as React from "react";
import LogoImage from "../../assets/images/Login.png";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Layout from "../../Layout";
import { InputLabel } from "@mui/material";
import { loggedIn } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmailId } from "../../utils/validation.util";
import Alert from "@mui/material/Alert";
import Loader from "../../Components/Loader";
const Login = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const [formErrors, setFormErrors] = React.useState({
    emailId: "",
    password: "",
    error: "",
  });

  const setEmail = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        emailId: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          emailId: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          emailId: "Invalid email id",
        });
      }
    }
  };
  const setPassword = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        password: "Password cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        password: "",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formErrors.emailId && formErrors.password) {
    } else {
      const data = new FormData(event.currentTarget);
      dispatch(
        loggedIn({
          email: data.get("email"),
          password: data.get("password"),
        })
      );
    }
  };

  return (
    <Layout>
      <Container className={"login"} component="main" maxWidth="md">
        <CssBaseline />
        <Box
          className={"box"}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            // padding: "40px",
          }}
        >
          <Grid container>
            <Grid item md={4} className={"leftDiv"}>
              <div className={"loginLogo"}>
                <img
                  alt="Macumira"
                  style={{ maxWidth: "100%" }}
                  src={LogoImage}
                />
              </div>
            </Grid>
            <Grid item md={8} className={"login-form"}>
              <>
                <Typography component="h1" className="heading">
                  Sign in
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  {status === false && <Alert severity="error">{error}</Alert>}
                  <div className={"form-group"}>
                    <InputLabel className={"form-label"} htmlFor="email">
                      Your email
                    </InputLabel>
                    <TextField
                      error={true}
                      required
                      fullWidth
                      id="email"
                      name="email"
                      className={"form-input"}
                      helperText={formErrors.emailId}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@example.com"
                      autoFocus
                    />
                  </div>
                  <div className={"form-group"}>
                    <InputLabel className={"form-label"} htmlFor="password">
                      Password
                    </InputLabel>
                    <TextField
                      error
                      required
                      fullWidth
                      name="password"
                      type="password"
                      helperText={formErrors.password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className={"form-input"}
                      placeholder="*********"
                      autoComplete="current-password"
                    />
                  </div>
                  <Grid container>
                    <Grid item xs>
                      <FormControlLabel
                        className={"form-checkbox"}
                        control={<Checkbox value="remember" />}
                        label="Remember me"
                      />
                    </Grid>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Link
                        to="/forgot_password"
                        component={RouterLink}
                        variant="p"
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className={"btn-submit"}
                  >
                    Sign In
                    {loading && <Loader color={"#fff"} />}
                  </Button>
                </Box>
              </>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default React.memo(Login);
