import * as React from "react";
import LogoImage from "../../assets/images/Image.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Layout from "../../Layout";
import { InputLabel } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkHash, forgotChangePassword } from "../../services/auth.handlers";
import Alert from "@mui/material/Alert";
import Loader from "../../Components/Loader";
const ResetPassword = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formError, setFormError] = React.useState("");
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  React.useEffect(() => {
    checkHash({ hash: searchParams.get("hash") }).then((response) => {
      if (response.status) {
      } else {
        setFormError("Invalid Hash");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    });
  }, [searchParams.get("hash")]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setPassword(data.get("password"), data.get("conf_password"));
  };
  const setPassword = (value, confPassword) => {
    if (value.length < 8) {
      setFormError("Password must be atleast 8 characters");
    } else {
      setConfPassword(confPassword, value);
    }
  };
  const setConfPassword = (value, compare) => {
    if (value.length < 8) {
      setFormError("Confirm Password must be atleast 8 characters");
    } else if (value !== compare) {
      setFormError("Password and confirm are not same");
    } else {
      setFormError("");
      setLoading(true);
      const payload = {
        hash: searchParams.get("hash"),
        password: value,
      };
      forgotChangePassword(payload).then((response) => {
        if (response.status) {
          setFormSuccess(true);
          setLoading(false);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          setFormError("Invalid Details");
          setLoading(false);
        }
      });
    }
  };

  return (
    <Layout>
      <Container className={"login"} component="main" maxWidth="md">
        <CssBaseline />
        <Box
          className={"box"}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            // padding: "40px",
          }}
        >
          <Grid container>
            <Grid item md={4} className={"leftDiv"}>
              <div className={"loginLogo"}>
                <img style={{ maxWidth: "100%" }} src={LogoImage} />
              </div>
            </Grid>
            <Grid item md={8} className={"login-form"}>
              <>
                <Typography component="h1" className="heading">
                  Reset password
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  {formError !== "" && (
                    <Alert severity="error">{formError}</Alert>
                  )}
                  {formSuccess ? (
                    <Alert severity="success">
                      {"Password Successfully change"}
                    </Alert>
                  ) : (
                    <>
                      <div className={"form-group"}>
                        <InputLabel className={"form-label"} htmlFor="email">
                          Password
                        </InputLabel>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          className={"form-input"}
                          type="password"
                          id="password"
                          placeholder="*********"
                        />
                      </div>
                      <div className={"form-group"}>
                        <InputLabel className={"form-label"} htmlFor="email">
                          Confirm New Password
                        </InputLabel>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="conf_password"
                          className={"form-input"}
                          type="password"
                          id="password"
                          placeholder="*********"
                          autoComplete="current-password"
                        />
                      </div>
                      <Button
                        type="submit"
                        fullWidth
                        disabled={loading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className={"btn-submit"}
                      >
                        Change password
                        {loading && <Loader color={"#fff"} />}
                      </Button>
                    </>
                  )}
                </Box>
              </>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default React.memo(ResetPassword);
