import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
import {
  CreateMmDevice,
  GetDevicesType,
} from "../../../services/mm.devices.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddMmDevice = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [deviceData, setDeviceData] = React.useState([]);
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    device_Serial_Id: "",
    device_Type: "",
    device_Location: "",
    part_Number: "",
    hW_Version: "",
    sW_Version: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    device_Serial_Id: "",
    device_Type: "",
    device_Location: "",
    part_Number: "",
    hW_Version: "",
    sW_Version: "",
  });

  React.useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetDevicesType(options).then((response) => {
      if (response.status) {
        setDeviceData(response.data);
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateMmDevice(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          device_Serial_Id: "",
          device_Type: "",
          device_Location: "",
          part_Number: "",
          hW_Version: "",
          sW_Version: "",
        });
        setFormErrors({
          device_Serial_Id: "",
          device_Type: "",
          device_Location: "",
          part_Number: "",
          hW_Version: "",
          sW_Version: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New MM Device</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="device_Serial_Id">
                  Device Serial Id
                </InputLabel>
                <TextField
                  id="device_Serial_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.device_Serial_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      device_Serial_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="device_Type">
                  Device Type
                </InputLabel>
                <TextField
                  id="device_Type"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.device_Type}
                  onChange={(e) =>
                    setFormData({ ...formData, device_Type: e.target.value })
                  }
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="device_Type">
                  Device Type
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.device_Type}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      device_Type: "" + e.target.value,
                    });
                  }}
                >
                  {deviceData.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="device_Location">
                  Device Location
                </InputLabel>
                <TextField
                  id="device_Location"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.device_Location}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      device_Location: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="part_Number">
                  Part Number
                </InputLabel>
                <TextField
                  id="part_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.part_Number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      part_Number: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="hW_Version">
                  HW Version
                </InputLabel>
                <TextField
                  id="hW_Version"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.hW_Version}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hW_Version: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="sW_Version">
                  SW Version
                </InputLabel>
                <TextField
                  id="sW_Version"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.sW_Version}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sW_Version: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New MM Device
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/mm_device")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddMmDevice;
