import * as React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../Layout/DashboardLayout";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  GetSystems,
  genPdfDownload,
  getGeneratorById,
} from "../../../services/systems.handlers";
import moment from "moment";
import { _getCountryName } from "../../../constants";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import CountryCode from "../../../Components/DisabledTextField/CountryCode";
import SerialNumber from "../../../Components/DisabledTextField/SerialNumber";
import Variant from "../../../Components/DisabledTextField/Variant";
import LotNumber from "../../../Components/DisabledTextField/LotNumber";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import StoreDetailPage from "../Stores/StoreDetailPage";
function GeneratorDetailPage() {
  const authToken = useSelector((state) => state.user.authToken);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const params = useParams();
  const [data, setData] = React.useState({});
  const [componentData, setComponentData] = React.useState([]);
  const [AllData, setAllData] = React.useState([]);
  const [systemSn, setSystem_Sn] = React.useState("");
  const [systemId, setSystemId] = React.useState("");
  const [hdmiData, setHdmiData] = React.useState({
    pcbaToHdmi: "",
    pcbaToHdmiVariation: "",
    pcbaToHdmiCode: "",
    pcbaToHdmiSl: "",
    pcbaLot_No: "",
  });
  const [mainData, setMainData] = React.useState({
    mainPower: "",
    mainPowerVariation: "",
    mainPowerCode: "",
    mainPowerSl: "",
    mainPowerLot_No: "",
  });
  const [mainSwitchData, setSwitchMainData] = React.useState({
    mainPowerSwitch: "",
    mainPowerSwitchVariation: "",
    mainPowerSwitchCode: "",
    mainPowerSwitchSl: "",
    mainPowerSwitchLot_No: "",
  });
  const [usbcData, setUsbcData] = React.useState({
    usbcToPcba: "",
    usbcToPcbaVariation: "",
    usbcToPcbaCode: "",
    usbcToPcbaSl: "",
    usbcLot_No: "",
  });
  const [powerData, setPowerData] = React.useState({
    powerToPcba: "",
    powerToPcbaVariation: "",
    powerToPcbaCode: "",
    powerToPcbaSl: "",
    powerLot_No: "",
  });
  const [emergencyData, setEmergencyData] = React.useState({
    emergencySwitch: "",
    emergencySwitchVariation: "",
    emergencySwitchCode: "",
    emergencySwitchSl: "",
    emergencyLot_No: "",
  });
  const [statusLedData, setStatusLedData] = React.useState({
    statusLed: "",
    statusLedVariation: "",
    statusLedCode: "",
    statusLedSl: "",
    statusLot_No: "",
  });
  const [generatorPcbData, setGeneratorPcbData] = React.useState({
    generatorPCB: "",
    generatorPCBVariation: "",
    generatorPCBCode: "",
    generatorPCBSl: "",
    generatorPCBLot_No: "",
  });
  const [powerSupplyData, setPowerSupplyData] = React.useState({
    powerSupply: "",
    powerSupplyVariation: "",
    powerSupplyCode: "",
    powerSupplySl: "",
    powerSupplyLot_No: "",
  });
  const TableRowData = [
    " Ser. No.",
    " Part No",
    " Rev",
    " Router",
    " Build Date",
    " Build Location",
  ];
  const style = {
    background: " none!important",
    border: "none",
    padding: "0!important",
    color: "-webkit-link",
    textDecoration: "underline",
    cursor: "pointer",
  };
  const harnessData = [
    {
      name: "PCBA to HDMI Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Main Power Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Main Power Switch Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "USBC to PCBA Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Power to PCBA Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Emergency Switch Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Status LED Harness",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Generator PCB",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
    {
      name: "Power Supply Assembly",
      country: "Country Code",
      variant: "Variant",
      serial_No: "Ser. No.",
      lot_No: "Lot No",
    },
  ];
  const getDefaultValueCountryCodeVariation = (prop) => {
    prop?.forEach((event) => {
      if (
        event.component_No === "100167"
        // event.component_Name.toLowerCase().trim() === "pcba to hdmi harness"
      ) {
        setHdmiData({
          ...hdmiData,
          pcbaToHdmi: event.component_No,
          pcbaToHdmiCode: event.country_Code,
          pcbaToHdmiVariation: event.variant,
          pcbaLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100165"
        // event.component_Name.toLowerCase().trim() === "main power harness"
      ) {
        setMainData({
          ...mainData,
          mainPower: event.component_No,
          mainPowerCode: event.country_Code,
          mainPowerVariation: event.variant,
          mainPowerLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100164"
        // event.component_Name.toLowerCase().trim() ===
        //   "main power switch harness"
      ) {
        setSwitchMainData({
          ...mainSwitchData,
          mainPowerSwitch: event.component_No,
          mainPowerSwitchCode: event.country_Code,
          mainPowerSwitchVariation: event.variant,
          mainPowerSwitchLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100171"
        // event.component_Name.toLowerCase().trim() === "usbc to pcba harness"
      ) {
        setUsbcData({
          ...usbcData,
          usbcToPcba: event.component_No,
          usbcToPcbaCode: event.country_Code,
          usbcToPcbaVariation: event.variant,
          usbcLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100172"
        // event.component_Name.toLowerCase().trim() === "power to pcba harness"
      ) {
        setPowerData({
          ...powerData,
          powerToPcba: event.component_No,
          powerToPcbaCode: event.country_Code,
          powerToPcbaVariation: event.variant,
          powerLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100169"
        // event.component_Name.toLowerCase().trim() === "emergency switch harness"
      ) {
        setEmergencyData({
          ...emergencyData,
          emergencySwitch: event.component_No,
          emergencySwitchCode: event.country_Code,
          emergencySwitchVariation: event.variant,
          emergencyLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100170"
        // event.component_Name.toLowerCase().trim() === "status led harness"
      ) {
        setStatusLedData({
          ...statusLedData,
          statusLed: event.component_No,
          statusLedCode: event.country_Code,
          statusLedVariation: event.variant,
          statusLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100135"
        // event.component_Name.toLowerCase().trim() === "generator pcb"
      ) {
        setGeneratorPcbData({
          ...generatorPcbData,
          generatorPCB: event.component_No,
          generatorPCBCode: event.country_Code,
          generatorPCBVariation: event.variant,
          generatorPCBLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100188"
        // event.component_Name.toLowerCase().trim() === "power supply assembly"
      ) {
        setPowerSupplyData({
          ...powerSupplyData,
          powerSupply: event.component_No,
          powerSupplyCode: event.country_Code,
          powerSupplyVariation: event.variant,
          powerSupplyLot_No: event.lot_No,
        });
      }
    });
  };

  React.useEffect(() => {
    fetchData();
    // fetchSystemData();
  }, []);

  const fetchPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    genPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    });
  };

  const openPdfNewTab = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    genPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const file = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGeneratorById(state.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
        // setComponentData(response.data.harnesses);
        getDefaultValueCountryCodeVariation(response.data.harnesses);
      }
    });
  };
  // const fetchSystemData = () => {
  //   var options = {
  //     Authorization: "Bearer " + authToken,
  //   };
  //   GetSystems(options).then((response) => {
  //     if (response.status) {
  //       setSystemId(
  //         response.data.find((event) => event.gen_Sn === Number(params.id)).id
  //       );
  //       setSystem_Sn(
  //         response.data.find((event) => event.gen_Sn === Number(params.id))
  //           .system_Sn
  //       );
  //     }
  //   });
  // };

  return (
    <DashboardLayout>
      <Stack spacing={2}>
        {/* {systemId !== "" ? ( */}
        {data.isAssociated_WithSystem === true ? (
          <Breadcrumbs separator="›">
            <Link
              // to={"/systems-detail-page/" + data.systemId}
              to="/systems-detail-page"
              state={{ id: data.systemId, storeId: data.storeId }}
              key="1"
              color="inherit"
            >
              System - {data.system_Sn}
            </Link>
            <Typography key="2" color="text.primary">
              Generator Details - {data.gen_Id}
            </Typography>
            <span>
              <Typography key="2" color="text.primary">
                <StoreDetailPage storeId={state.storeId} />
              </Typography>
            </span>
          </Breadcrumbs>
        ) : (
          <Typography key="2" color="text.primary">
            Generator Details - {data.gen_Id}
          </Typography>
        )}
      </Stack>
      {/* <h4 style={{ marginBottom: -5, marginTop: 0 }}>
        Generator Details - {data.gen_Id}
  </h4> */}
      <hr></hr>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Part No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.component_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.country_Code || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.variant || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.lot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.gen_Id || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Location</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.genBuild_Location || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.4}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Rev</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.genRev_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              ECO
            </InputLabel>

            <TextField
              disabled
              fullWidth
              value={data.hw || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="sw_Rev">
              SW Rev
            </InputLabel>

            <TextField
              disabled
              fullWidth
              value={data.sW_Rev || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={moment(data.genBuild_Date).format("YYYY/MM/DD") || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Router</InputLabel>
            <button
              // href="#"
              style={style}
              onClick={() =>
                openPdfNewTab(data.id, data.genRouter, "generator")
              }
            >
              {data.genRouter}
            </button>
            {data.genRouter && (
              <span>
                <IconButton
                  aria-label="file download icon"
                  onClick={() => fetchPdf(data.id, data.genRouter, "generator")}
                >
                  <FileDownloadIcon style={{ color: "grey" }} />
                </IconButton>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Generator FW</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.generator_FW || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.fW_Country || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>FW Rev</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.fw_Rev || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={
                data.fW_BuildDate
                  ? moment(data.fW_BuildDate).format("YYYY/MM/DD")
                  : ""
              }
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        {/* <Grid item xs={2.5}></Grid> */}
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Generator PCB</InputLabel>
            <TextField
              disabled
              fullWidth
              value={generatorPcbData.generatorPCB || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={generatorPcbData.generatorPCBCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={generatorPcbData.generatorPCBVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={generatorPcbData.generatorPCBLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              Rev
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={1.2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              ECO
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              Power Supply Assembly
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerSupplyData.powerSupply || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerSupplyData.powerSupplyCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerSupplyData.powerSupplyVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerSupplyData.powerSupplyLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              Rev
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev || ""}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={1.2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              ECO
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev || ""}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              Main Power Switch Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainSwitchData.mainPowerSwitch || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainSwitchData.mainPowerSwitchCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainSwitchData.mainPowerSwitchVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainSwitchData.mainPowerSwitchLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Main Power Harness</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainData.mainPower || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainData.mainPowerCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainData.mainPowerVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={mainData.mainPowerLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              PCBA to HDMI Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdmiData.pcbaToHdmi || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdmiData.pcbaToHdmiCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdmiData.pcbaToHdmiVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdmiData.pcbaLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              Emergency Switch Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={emergencyData.emergencySwitch || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={emergencyData.emergencySwitchCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={emergencyData.emergencySwitchVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={emergencyData.emergencyLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Status LED Harness</InputLabel>
            <TextField
              disabled
              fullWidth
              value={statusLedData.statusLed || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={statusLedData.statusLedCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={statusLedData.statusLedVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={statusLedData.statusLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              USBC to PCBA Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={usbcData.usbcToPcba || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={usbcData.usbcToPcbaCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={usbcData.usbcToPcbaVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={usbcData.usbcLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              Power to PCBA Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerData.powerToPcba || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerData.powerToPcbaCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerData.powerToPcbaVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={powerData.powerLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid>
          <ButtonThemeProvider>
            <Button
              onClick={() => navigate(-1)}
              color="grey"
              variant="contained"
            >
              Back
            </Button>
          </ButtonThemeProvider>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default GeneratorDetailPage;
