import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, Box, InputLabel, Modal } from "@mui/material";
import { CreateEmployee } from "../../../services/employee.handlers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  GetOrganization,
  RemoveStore,
  updateOrganization,
} from "../../../services/organization.handlers";
import { useParams, useNavigate } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import { ProblemReportForm } from "../../../services/problem.report.admin.handlers";

const RemoveStoreToOrg = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [selectedStoreId, setSelectedStoreId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = React.useState("");
  const [formData, setFormData] = React.useState({
    id: "",
    org_Id: "",
    org_Name: "",
    contact_Email: "",
    contact_Number: "",
    org_Address_1: "",
    org_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    store: [],
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    org_Id: "",
    org_Name: "",
    contact_Email: "",
    contact_Number: "",
    org_Address_1: "",
    org_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    comment: "",
  });

  React.useEffect(() => {
    fetchOrganization();
  }, []);

  const handleChange = (e) => {
    setSelectedStoreId(e.target.value);
    if (e.target.value !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleClose = () => {
    setSelectedStoreId("");
    setOpen(false);
    setFormErrors({ ...formErrors, comment: "" });
    setRemark("");
  };
  const submitComment = () => {
    setOpen(false);
  };
  console.log("store", selectedStoreId, remark);
  const fetchOrganization = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganization(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setCsc({
          ...csc,
          state: jsonCheck(response.data.country)
            ? _getCountryStates(JSON.parse(response.data.country))
            : [],
          city: jsonCheck(response.data.state)
            ? _getCityState(JSON.parse(response.data.state))
            : [],
        });
      } else {
        navigate("/dashboard");
      }
    });
  };
  const handleComment = (e) => {
    if (e.target.value === "") {
      setFormErrors({
        ...formErrors,
        comment: "Comment Text cannot be blank",
      });
    } else {
      setFormErrors({ ...formErrors, comment: "" });
    }
    setRemark(e.target.value);
  };

  const submitForm = () => {
    if (!remark) {
      setRemark(remark);
      return;
    }
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    const payload = {
      organizationSN: formData.org_Id,
      storeSN: selectedStoreId,
      systemSN: "",
      note: remark,
    };
    const problemReportData = {
      system_Sn: selectedStoreId,
      description: "Store Return : " + remark,
      remark: "",
      status: 1,
      isActive: 1,
    };
    ProblemReportForm(problemReportData, options).then((response) => {});
    RemoveStore(payload, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          org_Id: "",
          org_Name: "",
          contact_Email: "",
          contact_Number: "",
          org_Address_1: "",
          org_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    maxHeight: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <DashboardLayout>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              {/* </Grid> */}
              <h5>Selected Store : {selectedStoreId}</h5>
              <Grid Grid item xs={12}>
                <div className={"form-group"} style={{ marginTop: "-10px" }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="remark"
                  ></InputLabel>
                  <TextField
                    // className="form-input"
                    placeholder="Comment"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "16px",
                        border: "1px",
                        borderColor: "#e5e7eb",
                      },
                    }}
                    maxRows={4}
                    minRows={4}
                    multiline
                    name="remark"
                    fullWidth
                    value={remark}
                    variant="outlined"
                    error={formErrors.comment ? true : false}
                    onChange={handleComment}
                    helperText={formErrors.comment}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"} style={{ marginTop: 30 }}>
                  <Button
                    // disabled={loader}
                    disabled={remark === "" ? true : false}
                    onClick={submitComment}
                    variant="contained"
                  >
                    Submit
                    {loader && <Loader color={"#fff"} />}
                  </Button>
                  <ButtonThemeProvider>
                    <Button
                      disabled={loader}
                      onClick={() => handleClose()}
                      color="grey"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </ButtonThemeProvider>
                </Stack>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Modal>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3 style={{ marginTop: 0 }}>
            Remove Store from Organization: Organization ID - {formData.org_Id}
          </h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            {/* <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="org_Id">
                  Organization ID
                </InputLabel>
                <TextField
                  id="org_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Id}
                  onChange={(e) => setOrgID(e.target.value)}
                  error
                  helperText={formErrors.org_Id}
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Store
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedStoreId}
                  onChange={handleChange}
                >
                  {formData.store.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.store_Id}>
                        {item.store_Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  disabled
                  id="combo-box-demo"
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  // onChange={(event, value) => {
                  //   setFormData({
                  //     ...formData,
                  //     country: JSON.stringify(value),
                  //   });
                  //   setCsc({ ...csc, state: _getCountryStates(value) });
                  // }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Name">
                  Organization Name
                </InputLabel>
                <TextField
                  disabled
                  id="org_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Name}
                  // onChange={(e) => setOrgName(e.target.value)}
                  error
                  helperText={formErrors.org_Name}
                />
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Address_1">
                  Address 1
                </InputLabel>
                <TextField
                  disabled
                  id="org_Address_1"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Address_1}
                  onChange={(e) =>
                    setFormData({ ...formData, org_Address_1: e.target.value })
                  }
                />
              </div>
            </Grid> */}

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="contact_Email">
                  Contact Email
                </InputLabel>
                <TextField
                  disabled
                  id="contact_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Email}
                  // onChange={(e) => setOrgEmail(e.target.value)}
                  error
                  helperText={formErrors.contact_Email}
                />
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Address_2">
                
                  Address 2
                </InputLabel>
                <TextField
                  disabled
                  id="org_Address_2"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Address_2}
                  onChange={(e) =>
                    setFormData({ ...formData, org_Address_2: e.target.value })
                  }
                />
              </div>
            </Grid> */}

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="contact_Number">
                  Contact Number
                </InputLabel>
                <TextField
                  disabled
                  id="contact_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Number}
                  // onChange={(e) => setOrgNumber(e.target.value)}
                  error
                  helperText={formErrors.contact_Number}
                />
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  disabled
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  value={
                    jsonCheck(formData.state)
                      ? JSON.parse(formData.state)
                      : formData.state
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, isActive: e.target.value });
                  // }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  disabled
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  value={
                    jsonCheck(formData.city)
                      ? JSON.parse(formData.city)
                      : formData.city
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Code"}
                </InputLabel>
                <TextField
                  disabled
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid> */}
            {remark && (
              <Grid Grid item xs={12}>
                <div className={"form-group"} style={{ marginTop: "-10px" }}>
                  <InputLabel className={"form-label"} htmlFor="remark">
                    Comment
                  </InputLabel>
                  <TextField
                    // disabled
                    // className="form-input"
                    placeholder="Comment"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "16px",
                        border: "1px",
                        borderColor: "#e5e7eb",
                      },
                    }}
                    maxRows={4}
                    minRows={4}
                    multiline
                    name="remark"
                    fullWidth
                    value={remark}
                    variant="outlined"
                    // error={formErrors.problemText ? true : false}
                    // onChange={handleProblemData}
                    // helperText={formErrors.problemText}
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Remove
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/dashboard")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default RemoveStoreToOrg;
