import * as React from "react";
import LogoImage from "../../assets/images/Forgot-Password.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Layout from "../../Layout";
import { InputLabel } from "@mui/material";
import { isValidEmailId } from "../../utils/validation.util";
import Alert from "@mui/material/Alert";
import Loader from "../../Components/Loader";
import { forgotPassword } from "../../services/auth.handlers";
const ResetPassword = (props) => {
  const [formError, setFormError] = React.useState("");
  const [formSuccess, setFormSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if (data.get("email") === "") {
      setFormError("Email cannot be blank");
    } else {
      if (isValidEmailId(data.get("email"))) {
        setFormError("");
        setLoading(true);
        forgotPassword({ email: data.get("email") }).then((response) => {
          if (response.status) {
            setFormSuccess("Please Check your inbox or spam");
            setLoading(false);
          } else {
            setFormError("Invalid Email ID");
            setLoading(false);
          }
        });
      } else {
        setFormError("Invalid Email Id");
      }
    }
  };

  return (
    <Layout>
      <Container className={"login"} component="main" maxWidth="md">
        <CssBaseline />
        <Box
          className={"box"}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            // padding: "40px",
          }}
        >
          <Grid container>
            <Grid item md={8} className={"login-form"}>
              <>
                <Typography component="h1" className="heading">
                  Forgot your password
                </Typography>
                <Typography component="p" variant="p">
                  Don't fret! Just type in your email and we will send you a
                  code to reset your password !
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  {formError !== "" && (
                    <Alert severity="error">{formError}</Alert>
                  )}
                  {formSuccess !== "" && (
                    <Alert severity="success">{formSuccess}</Alert>
                  )}
                  <div className={"form-group"}>
                    <InputLabel className={"form-label"} htmlFor="email">
                      Your email
                    </InputLabel>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      className={"form-input"}
                      placeholder="name@example.com"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </div>

                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className={"btn-submit"}
                  >
                    Recover password
                    {loading && <Loader color={"#fff"} />}
                  </Button>
                </Box>
              </>
            </Grid>
            <Grid item md={4} className={"leftDiv"}>
              <div className={"loginLogo"}>
                <img
                  alt="Macumira"
                  title="Macumira"
                  style={{ maxWidth: "100%" }}
                  src={LogoImage}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default React.memo(ResetPassword);
