import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import {
  GetByIdProblemReportForm,
  GetProblemReportForm,
} from "../../../services/problem.report.admin.handlers";
import { useNavigate } from "react-router-dom";
import AddProblemReportForm from "./AddProblemReportFrom";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { addDays } from "date-fns";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  DesktopDatePicker,
  LocalizationProvider,
  pickersLayoutClasses,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateRange from "../../../Components/ReactDateRange/ReactDateRange";
import ReactDateRange from "../../../Components/ReactDateRange/ReactDateRange";
// import { DateRange } from "react-date-range";

const TableRowData = [
  "Index No",
  "System/Store Ser. No.",
  "Problem",
  // "Problem Date/Time reported",
  "Resolution/Response ",
  // "Date/Time resolved",
  "Status",
  "Action",
  "",
];
const tableColumn = [
  "Index No",
  "System Ser. No.",
  "Problem",
  "Resolution/Response ",
  "Status",
];

const dropDownItems = ["All", "Open", "Close"];
const ProblemReportForm = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [dropdownValue, setDropDownValue] = React.useState("All");
  const [error, setError] = React.useState("");
  const authToken = useSelector((state) => state.user.authToken);
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetProblemReportForm(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const handleSinglePdfGenerate = (prop, indexNo) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetByIdProblemReportForm(prop, options).then((response) => {
      if (response.status) {
        const doc = new JsPDF();
        const tableRows = [];
        const tableRow = [
          indexNo,
          response.data.system_Sn,
          moment(new Date(response.data.created_Date + "Z")).format(
            "YYYY/MM/DD"
          ) +
            "," +
            moment(new Date(response.data.created_Date + "Z")).format(
              "hh:mm A"
            ) +
            "\n" +
            response.data.description,
          (response.data.resolveDate && response.data.status === 0
            ? moment(new Date(response.data.resolveDate + "Z")).format(
                "YYYY/MM/DD"
              ) +
              "," +
              moment(new Date(response.data.resolveDate + "Z")).format(
                "hh:mm A"
              )
            : "Unresolved") +
            "\n" +
            response.data.remark,
          response.data.status ? "Active" : "Resolved",
        ];
        tableRows.push(tableRow);
        doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
        doc.text(
          `Problem Report - ${
            response.data.status === 0
              ? indexNo +
                " - Rslvd - " +
                moment(new Date(response.data.resolveDate + "Z")).format(
                  "YYYY/MM/DD"
                )
              : indexNo +
                " - Open - " +
                moment(new Date(response.data.created_Date + "Z")).format(
                  "YYYY/MM/DD"
                )
          }`,
          14,
          15
        );
        doc.save(`problem_report.pdf`);
      }
    });
  };

  const ActiveProblemReports = () => {
    const doc = new JsPDF();
    const tableRows = [];
    [...data].reverse().forEach((element, index) => {
      if (element.status === 1) {
        const tableRow = [
          data.length - index,
          element.system_Sn,
          moment(new Date(element.created_Date + "Z")).format("YYYY/MM/DD") +
            "," +
            moment(new Date(element.created_Date + "Z")).format("hh:mm A") +
            "\n" +
            element.description,
          (element.resolveDate && element.status === 0
            ? moment(new Date(element.resolveDate + "Z")).format("YYYY/MM/DD") +
              "," +
              moment(new Date(element.resolveDate + "Z")).format("hh:mm A")
            : "Unresolved") +
            "\n" +
            element.remark,
          element.status ? "Active" : "Resolved",
        ];
        tableRows.push(tableRow);
      }
    });
    doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
    doc.text("Problem Report List", 14, 15);
    doc.save(`problem_report_open.pdf`);
  };
  const ResolvedProblemReports = () => {
    const doc = new JsPDF();
    const tableRows = [];
    [...data].reverse().forEach((element, index) => {
      if (element.status === 0) {
        const tableRow = [
          data.length - index,
          element.system_Sn,
          moment(new Date(element.created_Date + "Z")).format("YYYY/MM/DD") +
            "," +
            moment(new Date(element.created_Date + "Z")).format("hh:mm A") +
            "\n" +
            element.description,
          (element.resolveDate && element.status === 0
            ? moment(new Date(element.resolveDate + "Z")).format("YYYY/MM/DD") +
              "," +
              moment(new Date(element.resolveDate + "Z")).format("hh:mm A")
            : "Unresolved") +
            "\n" +
            element.remark,
          element.status ? "Active" : "Resolved",
        ];
        tableRows.push(tableRow);
      }
    });
    doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
    doc.text("Problem Report List", 14, 15);
    doc.save(`problem_report_close.pdf`);
  };
  const DateRangeProblemReports = () => {
    // if (state) {
    const doc = new JsPDF();
    const tableRows = [];
    [...data].reverse().forEach((element, index) => {
      if (
        moment(element.created_Date).format("YYYY/MM/DD") >=
          moment(state[0].startDate).format("YYYY/MM/DD") &&
        moment(element.created_Date).format("YYYY/MM/DD") <=
          moment(state[0].endDate).format("YYYY/MM/DD")
      ) {
        const tableRow = [
          data.length - index,
          element.system_Sn,
          moment(new Date(element.created_Date + "Z")).format("YYYY/MM/DD") +
            "," +
            moment(new Date(element.created_Date + "Z")).format("hh:mm A") +
            "\n" +
            element.description,
          (element.resolveDate && element.status === 0
            ? moment(new Date(element.resolveDate + "Z")).format("YYYY/MM/DD") +
              "," +
              moment(new Date(element.resolveDate + "Z")).format("hh:mm A")
            : "Unresolved") +
            "\n" +
            element.remark,
          element.status ? "Active" : "Resolved",
        ];
        tableRows.push(tableRow);
      }
    });
    if (tableRows.length > 0) {
      doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
      doc.text("Problem Report List", 14, 15);
      doc.save(`problem_report_date-range.pdf`);
    } else {
      setError("Data Not Found");
      setTimeout(() => {
        setError("");
      }, 2000);
    }

    // }
  };
  const generatePdf = () => {
    const doc = new JsPDF();
    const tableRows = [];
    if (dropdownValue === "All") {
      [...data].reverse().forEach((element, index) => {
        const tableRow = [
          data.length - index,
          element.system_Sn,
          moment(new Date(element.created_Date + "Z")).format("YYYY/MM/DD") +
            "," +
            moment(new Date(element.created_Date + "Z")).format("hh:mm A") +
            "\n" +
            element.description,
          (element.resolveDate && element.status === 0
            ? moment(new Date(element.resolveDate + "Z")).format("YYYY/MM/DD") +
              "," +
              moment(new Date(element.resolveDate + "Z")).format("hh:mm A")
            : "Unresolved") +
            "\n" +
            element.remark,
          element.status ? "Active" : "Resolved",
        ];
        tableRows.push(tableRow);
      });
      doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
      // doc.text("Problem Report List", 14, 15);
      doc.save(`problem_report_list.pdf`);
    } else if (dropdownValue === "Open") {
      ActiveProblemReports();
    } else if (dropdownValue === "Close") {
      ResolvedProblemReports();
    } else if (dropdownValue === "Date-Range") {
      DateRangeProblemReports();
    }
  };
  // element.resolveDate && element.status === 0
  //         ? moment(new Date(element.resolveDate + "Z")).format("YYYY/MM/DD")
  //         : "Unresolved" + element.resolveDate && element.status === 0
  //         ? moment(new Date(element.resolveDate + "Z")).format("hh:mm A")
  //         : ""

  return (
    <DashboardLayout>
      {/* <h3 style={{ marginBottom: -5, marginTop: 0 }}>Problem Report List</h3>
      <hr></hr> */}
      <div
        spacing={40}
        // direction="row"

        style={{
          // marginTop: "16px",
          // marginBottom: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ marginBottom: -5, marginTop: 0, marginLeft: 12 }}>
          Problem Report List
        </h3>
        <div>{error !== "" && <Alert severity="error">{error}</Alert>}</div>

        <div style={{ display: "flex" }}>
          <div className={"form-group"} style={{ margin: 0, width: 235 }}>
            <InputLabel className={"form-label"} htmlFor="gen_Id">
              Select
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={dropdownValue}
              renderValue={(selected) => {
                if (selected === "Date-Range") {
                  return (
                    moment(state[0].startDate).format("YYYY/MM/DD") +
                    " - " +
                    moment(state[0].endDate).format("YYYY/MM/DD")
                  );
                } else {
                  return selected;
                }
              }}
              onChange={(e) => {
                setDropDownValue(e.target.value);
              }}
            >
              {dropDownItems.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>{item}</div>
                  </div>
                </MenuItem>
              ))}
              <MenuItem>
                <ReactDateRange state={state} setState={setState} />
              </MenuItem>
              <MenuItem value={"Date-Range"}>
                <div style={{ display: "flex", gap: "15px" }}>
                  <Button variant="primary">
                    <div>{"Apply"}</div>
                  </Button>
                </div>
              </MenuItem>
            </Select>
          </div>

          <Button
            style={{ height: 45, marginTop: 22 }}
            onClick={generatePdf}
            variant="contained"
          >
            Download List
          </Button>
        </div>
      </div>
      <hr></hr>
      <Stack
        spacing={40}
        direction="row"
        style={{
          // marginTop: "16px",
          // marginBottom: "30px",
          justifyContent: "end",
        }}
      >
        {/* <AddProblemReportForm getData={() => fetchData()} /> */}
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              [...data]
                .reverse()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.length - index}</TableCell>
                    <TableCell>{item.system_Sn}</TableCell>
                    <TableCell>
                      <strong>
                        {item.created_Date
                          ? moment(new Date(item.created_Date + "Z")).format(
                              "YYYY/MM/DD"
                            )
                          : ""}
                        ,
                        {item.created_Date
                          ? moment(new Date(item.created_Date + "Z")).format(
                              "hh:mm A"
                            )
                          : ""}
                      </strong>
                      <br></br>
                      {item.description}
                    </TableCell>
                    {/* <TableCell>
                      {item.created_Date
                        ? moment(new Date(item.created_Date + "Z")).format(
                            "YYYY/MM/DD"
                          )
                        : ""}
                      ,
                      {item.created_Date
                        ? moment(new Date(item.created_Date + "Z")).format(
                            "hh:mm A"
                          )
                        : ""}
                    </TableCell> */}
                    <TableCell>
                      <strong>
                        {item.resolveDate && item.status === 0
                          ? moment(new Date(item.resolveDate + "Z")).format(
                              "YYYY/MM/DD"
                            )
                          : "Unresolved"}
                        ,
                        {item.resolveDate && item.status === 0
                          ? moment(new Date(item.resolveDate + "Z")).format(
                              "hh:mm A"
                            )
                          : ""}
                        <br></br>
                      </strong>
                      {item.remark}
                    </TableCell>
                    {/* <TableCell>
                      {item.resolveDate && item.status === 0
                        ? moment(new Date(item.resolveDate + "Z")).format(
                            "YYYY/MM/DD"
                          )
                        : ""}
                      ,
                      {item.created_Date && item.status === 0
                        ? moment(new Date(item.resolveDate + "Z")).format(
                            "hh:mm A"
                          )
                        : ""}
                    </TableCell> */}

                    <TableCell>
                      {item.status ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-gray"}>Resolved</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={!item.status}
                        onClick={() =>
                          navigate("/editProblemReportFrom/" + item.id)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={() =>
                          handleSinglePdfGenerate(item.id, data.length - index)
                        }
                      >
                        <DownloadForOfflineIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={9} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default ProblemReportForm;
