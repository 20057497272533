import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Alert,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import {
  SystemUsageAllFileDownload,
  SystemUsageAllFileDownloadByDay,
  SystemUsageAllFileDownloadByMonth,
  SystemUsageAllFileDownloadByWeek,
  SystemUsageDetail,
  SystemUsageDetailByDay,
  SystemUsageDetailByMonth,
  SystemUsageDetailByWeek,
  SystemUsageFile,
} from "../../../services/organization.handlers";
import moment from "moment";
import { monthNames } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
var filename = moment().format("YYYYMMDDHHmmss");

function SystemUsageDetailPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  // const param = useParams();
  const pdfRef = React.useRef(null);
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [loaderAllFile, setLoaderAllFile] = React.useState(false);
  const [error, setError] = React.useState("");
  const [clickedButton, setClickedButton] = React.useState("");
  const authToken = useSelector((state) => state.user.authToken);
  const tableHead = [
    "System Ser. No.",
    "Date",
    // "Customer Id",
    "Time",
    "Treatment Counter",
    "Action",
  ];
  const ReportType = ["All", "Monthly", "Weekly", "Daily"];
  React.useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (state.dailyMonthYear === ReportType[0]) {
      SystemUsageDetail(state.system_Id, page + 1, rowsPerPage, options).then(
        (response) => {
          if (response.status) {
            setData(response.data.data);
            setAllData(response.data);
          }
        }
      );
    } else if (state.dailyMonthYear === ReportType[1]) {
      SystemUsageDetailByMonth(
        state.system_Id,
        `${state.date.getFullYear()}-${state.date.getMonth() + 1}`,
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (state.dailyMonthYear === ReportType[2]) {
      SystemUsageDetailByWeek(
        state.system_Id,
        moment(state.date).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    } else if (state.dailyMonthYear === ReportType[3]) {
      SystemUsageDetailByDay(
        state.system_Id,
        moment(state.date).format("YYYY-MM-DD"),
        page + 1,
        rowsPerPage,
        options
      ).then((response) => {
        if (response.status) {
          setData(response.data.data);
          setAllData(response.data);
        }
      });
    }
  };

  const downloadFile = (filePath, fileName, id) => {
    setLoader(true);
    setClickedButton(id);
    var options = {
      Authorization: "Bearer " + authToken,
    };

    if (filePath) {
      SystemUsageFile(filePath, options).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "SystemUsage";
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoader(false);
          setClickedButton("");
        } else {
          setLoader(false);
          setClickedButton("");
          // navigate("/reports/systemUsageReports");
        }
      });
    } else {
      setError("File Not Available");
      setLoader(false);
      setClickedButton("");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const downloadAllFile = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    setLoaderAllFile(true);
    if (state.dailyMonthYear === ReportType[0]) {
      SystemUsageAllFileDownload(state.system_Id, options).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = `SystemUsageAllFile_${state.system_Id}`;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoaderAllFile(false);
        } else {
          setLoaderAllFile(false);
        }
      });
    } else if (state.dailyMonthYear === ReportType[1]) {
      SystemUsageAllFileDownloadByMonth(
        state.system_Id,
        `${state.date.getFullYear()}-${state.date.getMonth() + 1}`,
        options
      ).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `SystemUsageAllFile_${state.system_Id}.zip`;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoaderAllFile(false);
        } else {
          setLoaderAllFile(false);
        }
      });
    } else if (state.dailyMonthYear === ReportType[2]) {
      SystemUsageAllFileDownloadByWeek(
        state.system_Id,
        moment(state.date).format("YYYY-MM-DD"),
        options
      ).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `SystemUsageAllFile_${state.system_Id}.zip`;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoaderAllFile(false);
        } else {
          setLoaderAllFile(false);
        }
      });
    } else if (state.dailyMonthYear === ReportType[3]) {
      SystemUsageAllFileDownloadByDay(
        state.system_Id,
        moment(state.date).format("YYYY-MM-DD"),
        options
      ).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `SystemUsageAllFile_${state.system_Id}.zip`;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoaderAllFile(false);
        } else {
          setLoaderAllFile(false);
        }
      });
    }
  };
  // get week day, monday and sunday date by passing any of the date between week
  const getDateRange = (prop) => {
    const day = prop.getDay();
    const diff = prop.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(prop.setDate(diff));

    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    return `${moment(monday).format("YYYY/MM/DD")} - ${moment(sunday).format(
      "YYYY/MM/DD"
    )}`;
  };

  return (
    <DashboardLayout>
      {/* <h3>System Usage Report - {state.system_Id}</h3> */}
      {/* <h3>Billing Summary by System- {state.system_Id}</h3> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>
          Treatment Record- {state.system_Id} -
          {data.length > 0
            ? state.dailyMonthYear === ReportType[0] ||
              state.dailyMonthYear === ReportType[3]
              ? moment(data[0].created_Date).format("YYYY/MM/DD")
              : state.dailyMonthYear === ReportType[1]
              ? `${
                  monthNames[state.date.getMonth()]
                } ${state.date.getFullYear()}`
              : state.dailyMonthYear === ReportType[2]
              ? `${moment(data[0].startDateOfWeek).format(
                  "YYYY/MM/DD"
                )} - ${moment(data[0].endDateOfWeek).format("YYYY/MM/DD")}`
              : ""
            : ""}
        </h3>
        <Button
          disabled={loaderAllFile}
          variant="contained"
          style={{ height: "50px" }}
          color="primary"
          onClick={
            () => downloadAllFile()
            // item.filePath,
            // item.new_File_Name,
            // item.id
          }
        >
          Download All File
          {loaderAllFile && <Loader color={"#fff"} />}
        </Button>
      </div>
      <Grid container spacing={2}>
        {error != "" && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
      <div ref={pdfRef} id={"html-pdf"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((item, index) => (
                  <TableCell key={index}>
                    {/* {state.dailyMonthYear === ReportType[2] && index === 1
                      ? "Date Range"
                      : item} */}
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{item.system_Id}</TableCell>
                      {/* <TableCell>{item.customer_Id}</TableCell> */}
                      <TableCell>
                        {item.created_Date &&
                          moment(item.created_Date).format("YYYY/MM/DD")}
                      </TableCell>

                      {/* <TableCell>{item.customer_Id}</TableCell> */}
                      <TableCell>
                        {item.uploadTime
                          ? item.uploadTime.substring(11, 16)
                          : ""}
                      </TableCell>
                      <TableCell>{item.treatments_performed}</TableCell>
                      <TableCell>
                        <Button
                          disabled={clickedButton === item.id && loader}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            downloadFile(
                              item.filePath,
                              item.new_File_Name,
                              item.id
                            )
                          }
                        >
                          Download File
                          {clickedButton === item.id && loader && (
                            <Loader color={"#fff"} />
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan={4} align={"center"}>
                    No Records Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={allData.totalRecords || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default SystemUsageDetailPage;
