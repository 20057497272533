import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  GetOrgEmployees,
  GetOrgEmployeesAndSystems,
} from "../../../services/org.employee.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GetOrganizations } from "../../../services/organization.handlers";
import { GetStores } from "../../../services/store.handlers";
const TableRowData = [
  "ID",
  "Name",
  "Store",
  "Email",
  // "Contact No",
  // "Type",
  "System Ser. No.",
  "Status",
  "Action",
];

const AttachEmployeeToSystem = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [storeData, setStoreData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
    fetchStore();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrgEmployeesAndSystems(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setStoreData(response.data);
      }
    });
  };

  const storeName = (item) => {
    const res = storeData.find((e) => e.id == item);
    return res?.store_Name;
  };

  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (item, index) =>
                    item.type === "Doctor" && (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        //   selected={isItemSelected}
                      >
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{storeName(item?.store_Id)}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        {/* <TableCell>{item.contact_Number}</TableCell>
                        <TableCell>{item.type}</TableCell> */}
                        <TableCell>
                          {item.systemIds.map((systemSn) => systemSn + ",")}
                        </TableCell>
                        <TableCell>
                          {item.isActive ? (
                            <span className={"label color-green"}>Active</span>
                          ) : (
                            <span className={"label color-red"}>Blocked</span>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              navigate("/attachEmployee/" + item.id)
                            }
                            variant="contained"
                            color="primary"
                          >
                            Assign System
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                )
            ) : (
              <TableRow hover>
                <TableCell colSpan={7} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default AttachEmployeeToSystem;
