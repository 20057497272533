import { Grid, InputLabel, TextField } from "@mui/material";

function ComponentName(prop) {
  return (
    <Grid item xs={2.5}>
      <div className={"form-group-component"} style={{ marginTop: "-10px" }}>
        <InputLabel className={"form-label"}>{prop.name}</InputLabel>
        <TextField
          disabled
          fullWidth
          value={prop.value || ""}
          className={"form-input"}
          variant="outlined"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#111928",
            },
          }}
        />
      </div>
    </Grid>
  );
}

export default ComponentName;
