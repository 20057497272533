import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useLocation } from "react-router-dom";
import { jsonCheck, monthNames } from "../../../constants";
import { StoreBilling } from "../../../services/billing.calculation.handlers";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import jsPDF from "jspdf";
var filename = moment().format("YYYYMMDDHHmmss");
const TableRowData = [
  "Organization",
  "Store Name",
  "Contact Info",
  "Address",
  "Status",
  "",
];

const SystemUsageScreen = () => {
  const pdfRef = React.useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [storeBillingData, setStoreBillingData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const payloadData = {
      organizationId: 0,
      storeId: state?.id,
      // month_No: state.monthYear ? state.monthYear.month : "",
      // year: state.monthYear ? state.monthYear.year : "",
    };
    StoreBilling(payloadData, options).then((response) => {
      if (response.status) {
        setStoreBillingData(response.data);
      } else {
        // navigate("/orgBillingSummary");
      }
    });
  };
  const _exportPdf = () => {
    const content = pdfRef.current;

    const doc = new jsPDF("landscape");
    doc.html(content, {
      callback: function (doc) {
        var pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();

          const footer = `Page ${i} of ${pageCount}`;
          doc.setFontSize(10);

          let paragraph =
            "All information contained herein is confidential and proprietary. All design, manufacturing, use, reproduction, & sales rights are expressly reserved. Communication of this information to others is prohibited.";
          var lines = doc.splitTextToSize(paragraph, 310 - 15 - 15);
          doc.text(lines, 10, pageHeight - 20, { baseline: "bottom" });
          doc.rect(5, pageHeight - 27, pageWidth - 10, 15);
          doc.text(
            footer,
            pageWidth / 2 - doc.getTextWidth(footer) / 2,
            pageHeight - 5,
            { baseline: "bottom" }
          );
        }
        // window.open(doc.output("bloburl"));
        doc.save(filename + "-report.pdf");
      },
      margin: [5, 10, 40, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 270, //target width in the PDF document
      windowWidth: 1200,
      // html2canvas: { scale: 0.257 },
    });
  };

  return (
    <DashboardLayout>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "flex-end" }}
      >
        <Button onClick={() => _exportPdf()} variant="contained">
          Export PDF
        </Button>
      </Stack>
      <div ref={pdfRef} id={"html-pdf"}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={"6"} align={"center"}>
                  SYSTEM USAGE REPORT
                </TableCell>
              </TableRow>
              <TableRow>
                {TableRowData.map((item, index) => (
                  <TableCell key={index}>
                    {index == -1 ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            //   indeterminate={numSelected > 0 && numSelected < rowCount}
                            //   checked={rowCount > 0 && numSelected === rowCount}
                            //   onChange={onSelectAllClick}
                            inputProps={{
                              "aria-label": "select all ID",
                            }}
                          />
                        }
                        label={item}
                      />
                    ) : (
                      item
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {storeBillingData.store && (
                <TableRow hover>
                  <TableCell>
                    {storeBillingData.store?.organizationName}
                  </TableCell>
                  <TableCell>{storeBillingData.store?.store_Name}</TableCell>
                  <TableCell>
                    {storeBillingData.store?.store_Email}
                    <br />
                    {storeBillingData.store?.store_Number}
                  </TableCell>
                  <TableCell>
                    {storeBillingData.store?.store_Address_1 +
                      " " +
                      storeBillingData.store?.store_Address_2 +
                      ", "}
                    {jsonCheck(storeBillingData.store?.city)
                      ? JSON.parse(storeBillingData.store?.city).label
                      : storeBillingData.store?.city}{" "}
                    {", "}
                    {jsonCheck(storeBillingData.store?.state)
                      ? JSON.parse(storeBillingData.store?.state).label
                      : storeBillingData.store?.state}{" "}
                    {", "}
                    {storeBillingData.store?.zip + " - "}
                    {jsonCheck(storeBillingData.store?.country)
                      ? JSON.parse(storeBillingData.store?.country).label
                      : storeBillingData.store?.country}
                  </TableCell>

                  <TableCell>
                    {storeBillingData.store?.isActive ? (
                      <span className={"label color-green"}>Active</span>
                    ) : (
                      <span className={"label color-red"}>Blocked</span>
                    )}
                  </TableCell>

                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>System Ser. No.</TableCell>
                <TableCell colSpan={"2"}>Date</TableCell>
                <TableCell colSpan={"2"}>No of Treatment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storeBillingData?.systemsInvoice &&
                storeBillingData.systemsInvoice.map((item) => (
                  <TableRow>
                    <TableCell>{item.system.system_Id}</TableCell>
                    <TableCell colSpan={"2"}>
                      {state.monthYearDay?.type === "Monthly" && (
                        <>
                          {monthNames[state.monthYearDay?.date.getMonth()]}{" "}
                          {state.monthYearDay?.date.getFullYear()}
                        </>
                      )}
                      {state.monthYearDay?.type === "Daily" && (
                        <>
                          {moment(state.monthYearDay?.date).format(
                            "YYYY/MM/DD"
                          ) || ""}
                        </>
                      )}
                      {state.monthYearDay?.type === "Weekly" && (
                        <>
                          {moment(state.monthYearDay?.date[0].startDate).format(
                            "YYYY/MM/DD"
                          ) || ""}
                          {"-"}
                          {moment(state.monthYearDay?.date[0].endDate).format(
                            "YYYY/MM/DD"
                          ) || ""}
                        </>
                      )}
                    </TableCell>
                    <TableCell colSpan={"2"}>{item.totalTimeUsage}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </DashboardLayout>
  );
};

export default SystemUsageScreen;
