import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, Box, InputLabel, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import { GetOrganizations } from "../../../services/organization.handlers";
import {
  CreateStores,
  GetStore,
  RemoveSystem,
  addSystemToStore,
  attachSystemToStore,
  updateStore,
} from "../../../services/store.handlers";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate, useParams } from "react-router";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { GetSystems } from "../../../services/systems.handlers";
import { useLocation } from "react-router-dom";
import { ProblemReportForm } from "../../../services/problem.report.admin.handlers";

const RemoveSystemToStore = () => {
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [systemData, setSystemData] = React.useState([]);
  const [systemId, setSystemId] = React.useState([]);
  const [selectedSystemId, setSelectedSystemId] = React.useState(
    state?.system_Sn ? state.system_Sn : ""
  );
  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = React.useState(
    state?.description ? state.description : ""
  );
  const [reportType, setReportType] = React.useState("");
  const [formData, setFormData] = React.useState({
    id: "",
    store_Id: "",
    org_Id: "",
    store_Name: "",
    organizationName: "",
    store_Email: "",
    store_Number: "",
    store_Address_1: "",
    store_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    systemIds: [],
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    problemText: "",
  });

  React.useEffect(() => {
    // fetchOrganisations();
    fetchStore();
    // fetchSystems();
  }, []);

  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStore(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setCsc({
          ...csc,
          state: jsonCheck(response.data.country)
            ? _getCountryStates(JSON.parse(response.data.country))
            : [],
          city: jsonCheck(response.data.state)
            ? _getCityState(JSON.parse(response.data.state))
            : [],
        });
      } else {
        navigate("/stores");
      }
    });
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSystemId(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  const handleChange = (e) => {
    setSelectedSystemId(e.target.value);
    if (e.target.value !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleClose = () => {
    setSelectedSystemId("");
    setOpen(false);
    setFormErrors({ ...formErrors, problemText: "" });
    // setRemark("");
  };
  const handleProblemData = (e) => {
    if (e.target.value === "") {
      setFormErrors({
        ...formErrors,
        problemText: "Problem Text cannot be blank",
      });
    } else {
      setFormErrors({ ...formErrors, problemText: "" });
    }
    setRemark(e.target.value);
  };
  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    const data = {
      organizationSN: formData.org_Id ? formData.org_Id : "",
      storeSN: formData.store_Id,
      systemSN: selectedSystemId,
      note: remark,
    };
    //this problem report from data
    const problemReportData = {
      system_Sn: selectedSystemId,
      description: remark,
      remark: "",
      status: 1,
      isActive: 1,
    };
    //this submit problem report data
    ProblemReportForm(problemReportData, options).then((response) => {});
    RemoveSystem(data, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          store_Id: "",
          org_Id: "",
          store_Name: "",
          store_Email: "",
          store_Number: "",
          store_Address_1: "",
          store_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          problemText: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/stores");
        }, 2000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    maxHeight: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <DashboardLayout>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <div style={{ height: 280 }}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Report Type
              </InputLabel>
              <Select
                defaultOpen
                fullWidth
                className={"form-input"}
                id="select-active"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
              >
                <MenuItem value="Device Failure">Device Failure</MenuItem>
                <MenuItem value="Device Upgrade">Device Upgrade</MenuItem>
                <MenuItem value="Contract Cancellation">
                  Contract Cancellation
                </MenuItem>
                <MenuItem value="Medical Issue">Medical Issue</MenuItem>
              </Select>
            </div>
          </div>
          {/* <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              
              <h5>Selected System : {selectedSystemId}</h5>
              <Grid Grid item xs={12}>
                <div className={"form-group"} style={{ marginTop: "-10px" }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="remark"
                  ></InputLabel>
                  <TextField
                    // className="form-input"
                    placeholder="Comment"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "16px",
                        border: "1px",
                        borderColor: "#e5e7eb",
                      },
                    }}
                    maxRows={4}
                    minRows={4}
                    multiline
                    name="remark"
                    fullWidth
                    value={remark}
                    variant="outlined"
                    error={formErrors.problemText ? true : false}
                    onChange={handleProblemData}
                    helperText={formErrors.problemText}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"} style={{ marginTop: 30 }}>
                  <Button
                    disabled={loader}
                    // onClick={() => submitForm()}
                    variant="contained"
                  >
                    Submit
                    {loader && <Loader color={"#fff"} />}
                  </Button>
                  <ButtonThemeProvider>
                    <Button
                      disabled={loader}
                      onClick={() => handleClose()}
                      color="grey"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </ButtonThemeProvider>
                </Stack>
              </Grid>
            </div>
          </Grid> */}
        </Box>
      </Modal>
      {reportType &&
        navigate("/problemReportForm", {
          state: {
            header: "System Return",
            id: params.id,
            selectedSystemId,
            reportType: "System Return : " + reportType,
          },
        })}
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3 style={{ marginTop: 0 }}>
            Remove System from Store : Store ID-{formData.store_Id}
          </h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="genRev_No">
                  System Id
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedSystemId}
                  onChange={handleChange}
                  error
                  helperText={formErrors.system_Sn}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {formData.systemIds.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Name">
                  Store Name
                </InputLabel>
                <TextField
                  disabled
                  id="store_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Name}
                  // onChange={(e) => setStoreName(e.target.value)}
                  error
                  helperText={formErrors.store_Name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="organizationName">
                  Organization
                </InputLabel>
                <TextField
                  disabled
                  id="organizationName"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.organizationName}
                  // onChange={(e) => setStoreName(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Email">
                  Store Email
                </InputLabel>
                <TextField
                  disabled
                  id="store_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Email}
                  // onChange={(e) => setStoreEmail(e.target.value)}
                  error
                  helperText={formErrors.store_Email}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Number">
                  Store Number
                </InputLabel>
                <TextField
                  disabled
                  id="store_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Number}
                  // onChange={(e) => setStoreNumber(e.target.value)}
                  error
                  helperText={formErrors.store_Number}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            {state?.description && (
              <Grid Grid item xs={12}>
                <div className={"form-group"} style={{ marginTop: "-10px" }}>
                  <InputLabel className={"form-label"} htmlFor="remark">
                    Comment
                  </InputLabel>
                  <TextField
                    // disabled
                    // className="form-input"
                    placeholder="Comment"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "16px",
                        border: "1px",
                        borderColor: "#e5e7eb",
                      },
                    }}
                    maxRows={4}
                    minRows={4}
                    multiline
                    name="remark"
                    fullWidth
                    value={remark}
                    variant="outlined"
                    // error={formErrors.problemText ? true : false}
                    // onChange={handleProblemData}
                    // helperText={formErrors.problemText}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={4}>
              <Stack spacing={2} direction="row" style={{ marginTop: 18 }}>
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Remove
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/stores")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default RemoveSystemToStore;
