import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Alert,
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import {
  updateSystem,
  GetSystem,
  getGenerator,
  getHeadset,
  getTablet,
  getGeneratorPcb,
  getHeadsetPcb,
  getInvPartNumber,
  getRevNumber,
  getInvHarness,
} from "../../../services/systems.handlers";
import { useNavigate, useParams } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import moment from "moment";
import { getCountryName } from "../../../constants";

const EditSystems = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [genData, setGenData] = React.useState([]);
  const [tabletData, setTabletData] = React.useState([]);
  const [hdsetData, setHdsetData] = React.useState([]);
  const [generatorPcbData, setGeneratorPcbData] = React.useState([]);
  const [headsetPcbData, setHeadsetPcbData] = React.useState([]);
  const [sysPartItem, setSysPartItem] = React.useState([]);
  const [sysRevItem, setSysRevItem] = React.useState([]);
  const [allData, setAllData] = React.useState();

  const [formData, setFormData] = React.useState({
    system_Id: "",
    sysPart_No: "",
    sysRev_No: "",
    sysBuild_Date: "",
    sysBuild_Location: "",
    component_Name: "System",
    component_No: "",
    country_Code: "",
    lot_No: "",
    variant: "",
    sl_No: "",
    // gen_Id: "",
    // genPart_No: "",
    // genRev_No: "",
    // genBuild_Date: "",
    // genBuild_Location: "",

    hdset_Id: "",
    hdsetPart_No: "",
    hdsetRev_No: "",
    hdsetBuild_Date: "",
    hdsetBuild_Location: "",

    tablet_Id: "",
    tabletPart_No: "",
    tabletRev_No: "",
    tabletBuild_Date: "",
    tabletBuild_Location: "",

    sysRouter: "",
    genRouter: "",
    hdsetRouter: "",
    tabletRouter: "",
    isActive: 1,

    gen_PCB_Id: "",
    hdset_PCB_Id: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
  });
  const [selectedGenerator, setSelectedGenerator] = React.useState({
    id: "",
    gen_Id: "",
    genPart_No: "",
    genRev_No: "",
    genBuild_Date: "",
    genBuild_Location: "",
    genRouter: "",
  });
  const [selectedHeadset, setSelectedHeadset] = React.useState({
    id: "",
    hdset_Id: "",
    hdsetPart_No: "",
    hdsetRev_No: "",
    hdsetBuild_Date: "",
    hdsetBuild_Location: "",
    hdsetRouter: "",
  });
  const [selectedTablet, setSelectedTablet] = React.useState({
    id: "",
    tablet_Id: "",
    tabletPart_No: "",
    tabletRev_No: "",
    tabletBuild_Date: "",
    tabletBuild_Location: "",
    tabletRouter: "",
  });

  React.useEffect(() => {
    sysPartNo();
    fetchSystems();
    fetchGeneratorData();
    fetchHdsetData();
    fetchTabletData();
    sysRevNo();
    // fetchGeneratorPcbData();
    // fetchHeadsetPcbData();
  }, []);

  const systemPropCode = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [sysPartItem]
  );
  const systemPropVariant = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.variant),
    [sysPartItem]
  );
  const systemPropLotNumber = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.lot_No),
    [sysPartItem]
  );
  const systemPropSlNumber = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163"
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.sl_No),
    [sysPartItem]
  );

  const systemDefaultCode = {
    options: systemPropCode,
    getOptionLabel: (option) => option,
  };
  const systemDefaultVariant = {
    options: systemPropVariant,
    getOptionLabel: (option) => option,
  };

  const defaultPropsLotNo = {
    options: systemPropLotNumber,
    getOptionLabel: (option) => option,
  };
  const defaultPropsSlNo = {
    options: systemPropSlNumber,
    getOptionLabel: (option) => option.toString(),
  };
  const fetchSystems = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystem(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setSelectedGenerator(response.data.generator);
        setSelectedHeadset(response.data.headset);
        setSelectedTablet(response.data.tablet);
      } else {
        navigate(-1);
      }
    });
  };

  const handleOnchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sysRevNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setSysRevItem(response.data);
      }
    });
  };

  const sysPartNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setSysPartItem(response.data);
      }
    });
  };
  const fetchGeneratorData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGenerator(options).then((response) => {
      if (response.status) {
        setGenData(response.data);
      }
    });
  };

  const fetchHdsetData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadset(options).then((response) => {
      if (response.status) {
        setHdsetData(response.data);
      }
    });
  };

  const fetchTabletData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getTablet(options).then((response) => {
      if (response.status) {
        setTabletData(response.data);
      }
    });
  };

  const fetchGeneratorPcbData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGeneratorPcb(options).then((response) => {
      if (response.status) {
        setGeneratorPcbData(response.data);
      }
    });
  };
  const fetchHeadsetPcbData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadsetPcb(options).then((response) => {
      if (response.status) {
        setHeadsetPcbData(response.data);
      }
    });
  };
  const handleGeneratorData = (event) => {
    const filterData = genData.find((item) => event.target.value === item.id);
    setSelectedGenerator({
      ...selectedGenerator,
      id: filterData.id,
      gen_Id: filterData.id,
      genBuild_Date: filterData.genBuild_Date,
      genBuild_Location: filterData.genBuild_Location,
      genRev_No: filterData.genRev_No,
      genRouter: filterData.genRouter,
    });
  };

  const handleHeadsetData = (event) => {
    const filterData = hdsetData.find((item) => event.target.value === item.id);
    setSelectedHeadset({
      ...selectedHeadset,
      id: filterData.id,
      hdset_Id: filterData.id,
      hdsetBuild_Date: filterData.hdsetBuild_Date,
      hdsetBuild_Location: filterData.hdsetBuild_Location,
      hdsetRev_No: filterData.hdsetRev_No,
      hdsetRouter: filterData.hdsetRouter,
    });
  };

  const handleTabletData = (event) => {
    const filterData = tabletData.find(
      (item) => event.target.value === item.id
    );
    setSelectedTablet({
      ...selectedTablet,
      id: filterData.id,
      tablet_Id: filterData.id,
      tabletBuild_Date: filterData.tabletBuild_Date,
      tabletBuild_Location: filterData.tabletBuild_Location,
      tabletRev_No: filterData.tabletRev_No,
      tabletRouter: filterData.tabletRouter,
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const submitForm = () => {
    // setError("");
    // setSuccess("");
    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    const sendData = {
      id: formData.id,
      sysRouter: formData.sysRouter,
      system_Id: formData.sl_No,
      sysPart_No: "",
      sysRev_No: formData.sysRev_No,
      sysBuild_Date: formData.sysBuild_Date,
      sysBuild_Location: formData.sysBuild_Location,
      component_No: formData.component_No,
      country_Code: formData.country_Code,
      lot_No: formData.lot_No,
      variant: formData.variant,
      sl_No: formData.sl_No,
      gen_Id: selectedGenerator.id,
      genPart_No: "",
      genRev_No: selectedGenerator.genRev_No,
      genBuild_Date: selectedGenerator.genBuild_Date,
      genBuild_Location: selectedGenerator.genBuild_Location,
      genRouter: "",
      hdset_Id: selectedHeadset.id,
      hdsetPart_No: "",
      hdsetRev_No: selectedHeadset.hdsetRev_No || "",
      hdsetBuild_Date: selectedHeadset.hdsetBuild_Date,
      hdsetBuild_Location: selectedHeadset.hdsetBuild_Location,
      hdsetRouter: "",
      tablet_Id: selectedTablet.id,
      tabletPart_No: "",
      tabletRev_No: selectedTablet.tabletRev_No,
      tabletBuild_Date: selectedTablet.tabletBuild_Date,
      tabletBuild_Location: selectedTablet.tabletBuild_Location,
      tabletRouter: "",
      isActive: 1,
      sysRouterFile: "",
      genRouterFile: "",
      gen_PCBRouterFile: "",
      hdsetRouterFile: "",
      hdset_PCBRouterFile: "",
      tabletRouterFile: "",
      gen_PCB_Id: "",
      hdset_PCB_Id: "",
    };

    if (password === "abarla123") {
      setOpen(false);
      updateSystem(params.id, sendData, options).then((response) => {
        if (response.status) {
          setAlert({ ...alert, success: "Data has been updated successfully" });
          setFormData({
            system_Id: "",
            sysPart_No: "",
            sysRev_No: "",
            sysBuild_Date: "",
            sysBuild_Location: "",
            component_Name: "System",
            component_No: "",
            country_Code: "",
            Lot_No: "",
            Variant: "",

            gen_Id: "",
            genPart_No: "",
            genRev_No: "",
            genBuild_Date: "",
            genBuild_Location: "",

            hdset_Id: "",
            hdsetPart_No: "",
            hdsetRev_No: "",
            hdsetBuild_Date: "",
            hdsetBuild_Location: "",

            tablet_Id: "",
            tabletPart_No: "",
            tabletRev_No: "",
            tabletBuild_Date: "",
            tabletBuild_Location: "",

            sysRouter: "",
            genRouter: "",
            hdsetRouter: "",
            tabletRouter: "",
            isActive: 1,

            // gen_PCB_Id: "",
            // hdset_PCB_Id: "",
          });
          setFormErrors({
            system_Id: "",
            gen_Id: "",
            hdset_Id: "",
            tablet_Id: "",
          });
          setLoader(false);
          setTimeout(() => {
            setAlert({ ...alert, success: "" });
            navigate(-1);
          }, 3000);
        } else {
          setOpen(false);
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 3000);
        }
      });
    } else {
      setLoader(false);
      setPasswordError("Wrong Password");
      setTimeout(() => {
        setPasswordError("");
      }, 2000);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <DashboardLayout>
      <form>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, width: 600 }}>
            <Grid container spacing={2}>
              <div style={{ height: 280, width: 600, margin: 10 }}>
                <div style={{ height: 50 }}>
                  {passwordError !== "" && (
                    <Grid item xs={12}>
                      <Alert severity="error">{passwordError}</Alert>
                    </Grid>
                  )}
                </div>
                <Grid item xs={12}>
                  <div className={"form-group"}>
                    <InputLabel className={"form-label"} htmlFor="password">
                      Please enter master password to continue
                    </InputLabel>

                    <OutlinedInput
                      style={{ marginTop: 15 }}
                      value={password}
                      name="password"
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="*********"
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      onChange={handlePassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{ marginTop: 30 }}
                  >
                    <Button
                      disabled={loader}
                      onClick={() => submitForm()}
                      variant="contained"
                    >
                      Submit
                      {loader && <Loader color={"#fff"} />}
                    </Button>
                    <ButtonThemeProvider>
                      <Button
                        disabled={loader}
                        onClick={() => handleClose()}
                        color="grey"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </ButtonThemeProvider>
                  </Stack>
                </Grid>
              </div>
            </Grid>
          </Box>
        </Modal>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          {/* <h3>Edit System</h3> */}
          <Grid container spacing={2}>
            {alert.error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{alert.error}</Alert>
              </Grid>
            )}
            {alert.success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{alert.success}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <h3>Edit System</h3>
            </Grid>

            <ComponentName name="Part No" value={formData.component_No} />
            <Grid item xs={2}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...systemDefaultCode}
                  value={formData.country_Code}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      country_Code: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                        <div>{getCountryName(option)}</div>
                      </div>
                    )
                    // )
                  }
                  // defaultValue={"2"}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...systemDefaultVariant}
                  value={formData.variant}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      variant: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === hdmiData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsLotNo}
                  value={formData.lot_No}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      lot_No: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.component_No && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsSlNo}
                  value={formData.sl_No}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      sl_No: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.component_No && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="sysBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  disabled
                  name="sysBuild_Location"
                  value={formData.sysBuild_Location}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2} style={{ marginTop: -10 }}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Rev
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.sysRev_No}
                  onChange={(e) => {
                    setFormData({ ...formData, sysRev_No: e.target.value });
                  }}
                >
                  {sysRevItem.reverse().map((item, index) => (
                    <MenuItem value={item.rev_No} key={index}>
                      {item.rev_No}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel className={"form-label"} htmlFor="sysBuild_Date">
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    disabled
                    // defaultValue={moment(new Date()).format("YYYY/MM/DD")}
                    inputFormat="YYYY/MM/DD"
                    value={formData.sysBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        sysBuild_Date: moment(e).format("YYYY/MM/DD"),
                        // sysBuild_Date: new Date(e).toISOString(),
                        // sysBuild_Date: moment(e).format(),
                        // sysBuild_Date: e,
                      })
                    }
                    // onChange={handleDate}
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="gen_Id">
                  Generator
                </InputLabel>

                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedGenerator?.id}
                  renderValue={(selected) => {
                    return genData.find((x) => x.id === selectedGenerator.id)
                      ?.gen_Id;
                  }}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, gen_Id: e.target.value });
                  // }}
                  onChange={(e) => handleGeneratorData(e)}
                >
                  {genData.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <div>{item.gen_Id}</div>

                        {getCountryName(item?.country_Code)}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="headset">
                  Headset
                </InputLabel>

                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedHeadset?.id}
                  renderValue={(selected) => {
                    return hdsetData.find((x) => x.id === selectedHeadset.id)
                      ?.hdset_Id;
                  }}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, hdset_Id: e.target.value });
                  // }}
                  onChange={(e) => handleHeadsetData(e)}
                >
                  {hdsetData.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <div>{item.hdset_Id}</div>

                        <div>{getCountryName(item?.country_Code)}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tablet">
                  Tablet
                </InputLabel>

                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedTablet?.id}
                  renderValue={(selected) => {
                    return tabletData.find((x) => x.id === selectedTablet.id)
                      ?.tablet_Id;
                  }}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, tablet_Id: e.target.value });
                  // }}
                  onChange={(e) => handleTabletData(e)}
                >
                  {tabletData.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <div> {item.tablet_Id}</div>
                        <div>{getCountryName(item?.country_Code)}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            {/* <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="system_Id">
                  System Ser. No.
                </InputLabel>

                <TextField
                  name="system_Id"
                  value={formData.system_Id}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="sysPart_No">
                  Sys Part No
                </InputLabel>

                <TextField
                  name="sysPart_No"
                  value={formData.sysPart_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="sysRev_No">
                  Sys Rev
                </InputLabel>

                <TextField
                  name="sysRev_No"
                  value={formData.sysRev_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel className={"form-label"} htmlFor="sysBuild_Date">
                    Sys Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.sysBuild_Date}
                    // onChange={(e) => setFormData({ ...formData, paid_Date: e })}
                    onChange={(e) =>
                      setFormData({ ...formData, sysBuild_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="sysBuild_Location"
                >
                  Sys Build Location
                </InputLabel>

                <TextField
                  name="sysBuild_Location"
                  value={formData.sysBuild_Location}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="gen_Id">
                  Generator Ser. No.
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.gen_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, gen_Id: e.target.value })
                  }
                >
                  <MenuItem value={formData.gen_Id}>{formData.gen_Id}</MenuItem>
                  {genData.map((item) => (
                    <MenuItem value={item.gen_Id}>{item.gen_Id}</MenuItem>
                  ))}
                </Select>

               
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="genPart_No">
                  Gen Part No
                </InputLabel>

                <TextField
                  name="genPart_No"
                  value={formData.genPart_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="genRev_No">
                  Gen Rev
                </InputLabel>

                <TextField
                  name="genRev_No"
                  value={formData.genRev_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel className={"form-label"} htmlFor="genBuild_Date">
                    Gen Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.genBuild_Date}
                    // onChange={(e) => setFormData({ ...formData, paid_Date: e })}
                    onChange={(e) =>
                      setFormData({ ...formData, genBuild_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="genBuild_Location"
                >
                  Gen Build Location
                </InputLabel>

                <TextField
                  name="genBuild_Location"
                  value={formData.genBuild_Location}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="hdset_Id">
                  Headset Ser. No.
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.hdset_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, hdset_Id: e.target.value })
                  }
                >
                  <MenuItem value={formData.hdset_Id}>
                    {formData.hdset_Id}
                  </MenuItem>
                  {hdsetData.map((item) => (
                    <MenuItem value={item.hdset_Id}>{item.hdset_Id}</MenuItem>
                  ))}
                </Select>
                
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="hdsetPart_No">
                  Headset Part No
                </InputLabel>

                <TextField
                  name="hdsetPart_No"
                  value={formData.hdsetPart_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="hdsetRev_No">
                  Headset Rev
                </InputLabel>

                <TextField
                  name="hdsetRev_No"
                  value={formData.hdsetRev_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="hdsetBuild_Date"
                  >
                    Headset Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.hdsetBuild_Date}
                    // onChange={(e) => setFormData({ ...formData, paid_Date: e })}
                    onChange={(e) =>
                      setFormData({ ...formData, hdsetBuild_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="hdsetBuild_Location"
                >
                  Headset Build Location
                </InputLabel>

                <TextField
                  name="hdsetBuild_Location"
                  value={formData.hdsetBuild_Location}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tablet_Id">
                  Tablet Ser. No.
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.tablet_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, tablet_Id: e.target.value })
                  }
                >
                  <MenuItem value={formData.tablet_Id}>
                    {formData.tablet_Id}
                  </MenuItem>
                  {tabletData.map((item) => (
                    <MenuItem value={item.tablet_Id}>{item.tablet_Id}</MenuItem>
                  ))}
                </Select>
                
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tabletPart_No">
                  Tablet Part No
                </InputLabel>

                <TextField
                  name="tabletPart_No"
                  value={formData.tabletPart_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tabletRev_No">
                  Tablet Rev
                </InputLabel>

                <TextField
                  name="tabletRev_No"
                  value={formData.tabletRev_No}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="tabletBuild_Date"
                  >
                    Tablet Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.tabletBuild_Date}
                    // onChange={(e) => setFormData({ ...formData, paid_Date: e })}
                    onChange={(e) =>
                      setFormData({ ...formData, tabletBuild_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="tabletBuild_Location"
                >
                  Tablet Build Location
                </InputLabel>

                <TextField
                  name="tabletBuild_Location"
                  value={formData.tabletBuild_Location}
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleOnchange}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={3}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Generator PCB
                </InputLabel>
                <Select
                  fullWidth
                  name="isActive"
                  className={"form-input"}
                  id="select-active"
                  value={formData.gen_PCB_Id}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gen_PCB_Id: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={formData.gen_PCB_Id}>
                    {formData.gen_PCB_Id}
                  </MenuItem>
                  {generatorPcbData.map((item) => (
                    <MenuItem value={item.generator_PCB_Id}>
                      {item.generator_PCB_Id}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Headset PCB
                </InputLabel>
                <Select
                  fullWidth
                  name="isActive"
                  className={"form-input"}
                  id="select-active"
                  value={formData.hdset_PCB_Id}
                  onChange={(e) => {
                    setFormData({ ...formData, hdset_PCB_Id: e.target.value });
                  }}
                >
                  <MenuItem value={formData.hdset_PCB_Id}>
                    {formData.hdset_PCB_Id}
                  </MenuItem>
                  {headsetPcbData.map((item) => (
                    <MenuItem value={item.headset_PCB_Id}>
                      {item.headset_PCB_Id}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid> */}
            <Grid item xs={5}>
              <div className={"form-group"} style={{ marginTop: -3 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  name="isActive"
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={5}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  // onClick={() => submitForm()}
                  onClick={() => handleOpen()}
                  variant="contained"
                >
                  Update System
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate(-1)}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditSystems;
