import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { CreateEmployee } from "../../../services/employee.handlers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import Autocomplete from "@mui/material/Autocomplete";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddEmployee = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [formData, setFormData] = React.useState({
    employee_ID: "",
    employee_Name: "",
    employee_Email: "",
    password: "",
    date_Of_Joining: "",
    address: "",
    country: _getCountry()[1],
    city: "",
    state: "",
    zip: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    employee_ID: "",
    employee_Name: "",
    employee_Email: "",
    password: "",
    date_Of_Joining: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  const setEmployeeID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        employee_ID: "Employee ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        employee_ID: "",
      });
    }
    setFormData({ ...formData, employee_ID: value });
  };

  const setEmployeeName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        employee_Name: "Employee Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        employee_Name: "",
      });
    }
    setFormData({ ...formData, employee_Name: value });
  };

  const setEmployeeEmail = (value) => {
    console.log("value", value);
    if (value === "") {
      setFormErrors({
        ...formErrors,
        employee_Email: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          employee_Email: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          employee_Email: "Invalid email id",
        });
      }
    }
    setFormData({ ...formData, employee_Email: value });
  };

  const setEmployeePassword = (value) => {
    if (value.length < 8) {
      setFormErrors({
        ...formErrors,
        password: "Password must be atleast 8 characters",
      });
    } else {
      setFormErrors({
        ...formErrors,
        password: "",
      });
    }
    setFormData({ ...formData, password: value });
  };

  const setEmployeeDate = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        date_Of_Joining: "Date of joining cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        date_Of_Joining: "",
      });
    }
    setFormData({ ...formData, date_Of_Joining: value });
  };

  const submitForm = () => {
    if (!formData.employee_ID) {
      setEmployeeID(formData.employee_ID);
      return;
    }
    if (!formData.employee_Name) {
      setEmployeeName(formData.employee_Name);
      return;
    }
    if (!formData.employee_Email) {
      setEmployeeEmail(formData.employee_Email);
      return;
    }
    if (!formData.password) {
      setEmployeePassword(formData.password);
      return;
    }
    if (!formData.date_Of_Joining) {
      setEmployeeDate(formData.date_Of_Joining);
      return;
    }
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateEmployee(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          employee_ID: "",
          employee_Name: "",
          employee_Email: "",
          password: "",
          date_Of_Joining: "",
          address: "",
          country: _getCountry()[1],
          city: "",
          state: "",
          zip: "",
        });
        setFormErrors({
          employee_ID: "",
          employee_Name: "",
          employee_Email: "",
          password: "",
          date_Of_Joining: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Employee</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Employee ID
                </InputLabel>
                <TextField
                  id="employee_ID"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.employee_ID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                  error
                  helperText={formErrors.employee_ID}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_Name">
                  Employee Name
                </InputLabel>
                <TextField
                  id="employee_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.employee_Name}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  error
                  helperText={formErrors.employee_Name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_Email">
                  Email
                </InputLabel>
                <TextField
                  id="employee_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.employee_Email}
                  onChange={(e) => setEmployeeEmail(e.target.value)}
                  error
                  helperText={formErrors.employee_Email}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="password">
                  Password
                </InputLabel>
                <TextField
                  type="password"
                  required
                  id="password"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.password}
                  onChange={(e) => setEmployeePassword(e.target.value)}
                  error
                  helperText={formErrors.password}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="date_Of_Joining">
                  Date of Joining
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    id="date_Of_Joining"
                    fullWidth
                    inputFormat="yyyy-MM-DD"
                    variant="outlined"
                    value={formData.date_Of_Joining}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={"form-input"}
                      />
                    )}
                    error
                    helperText={formErrors.date_Of_Joining}
                    onChange={(e) => setEmployeeDate(e._d)}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="address">
                  Address
                </InputLabel>
                <TextField
                  id="address"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  onChange={(event, value) => {
                    setFormData({
                      ...formData,
                      country: value ? JSON.stringify(value) : "",
                    });
                    setCsc({ ...csc, state: _getCountryStates(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Provence Code"}
                </InputLabel>
                <TextField
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Employee
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/employee")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddEmployee;
