import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetMmSystems = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.MMSYSTEMS.CRUD_SYSTEMS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateMmSystem = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.MMSYSTEMS.CRUD_SYSTEMS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetMmSystem = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.MMSYSTEMS.CRUD_SYSTEMS}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateMmSystem = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.MMSYSTEMS.CRUD_SYSTEMS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
