import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  Alert,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  getGeneratorById,
  getInvHarness,
  getInvPartNumber,
  getRevNumber,
  updateGenerator,
} from "../../../services/systems.handlers";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import { countryName, getCountryName } from "../../../constants";

function EditGenerator() {
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [genPartItem, setGenPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const authToken = useSelector((state) => state.user.authToken);
  const [hdmiData, setHdmiData] = React.useState({
    id: "",
    pcbaToHdmi: "",
    pcbaToHdmiVariation: "",
    pcbaToHdmiCode: "",
    pcbaToHdmiSl: "",
    pcbaLot_No: "",
  });
  const [mainData, setMainData] = React.useState({
    id: "",
    mainPower: "",
    mainPowerVariation: "",
    mainPowerCode: "",
    mainPowerSl: "",
    mainPowerLot_No: "",
  });
  const [mainSwitchData, setSwitchMainData] = React.useState({
    id: "",
    mainPowerSwitch: "",
    mainPowerSwitchVariation: "",
    mainPowerSwitchCode: "",
    mainPowerSwitchSl: "",
    mainPowerSwitchLot_No: "",
  });
  const [usbcData, setUsbcData] = React.useState({
    id: "",
    usbcToPcba: "",
    usbcToPcbaVariation: "",
    usbcToPcbaCode: "",
    usbcToPcbaSl: "",
    usbcLot_No: "",
  });
  const [powerData, setPowerData] = React.useState({
    id: "",
    powerToPcba: "",
    powerToPcbaVariation: "",
    powerToPcbaCode: "",
    powerToPcbaSl: "",
    powerLot_No: "",
  });
  const [emergencyData, setEmergencyData] = React.useState({
    id: "",
    emergencySwitch: "",
    emergencySwitchVariation: "",
    emergencySwitchCode: "",
    emergencySwitchSl: "",
    emergencyLot_No: "",
  });
  const [statusLedData, setStatusLedData] = React.useState({
    id: "",
    statusLed: "",
    statusLedVariation: "",
    statusLedCode: "",
    statusLedSl: "",
    statusLot_No: "",
  });
  const [generatorPcbData, setGeneratorPcbData] = React.useState({
    id: "",
    generatorPCB: "",
    generatorPCBVariation: "",
    generatorPCBCode: "",
    generatorPCBSl: "",
    generatorPCBLot_No: "",
  });
  const [powerSupplyData, setPowerSupplyData] = React.useState({
    id: "",
    powerSupply: "",
    powerSupplyVariation: "",
    powerSupplyCode: "",
    powerSupplySl: "",
    powerSupplyLot_No: "",
  });
  const [formData, setFormData] = React.useState({
    gen_Id: "",
    genPart_No: "",
    genRev_No: "",
    genBuild_Date: "",
    genBuild_Location: "",
    genRouter: "",
    component_No: "",
    country_Code: "",
    lot_No: "",
    variant: "",
    hw: "",
    sW_Rev: "",
    generator_FW: "",
    fW_Country: "",
    fW_BuildDate: "",
    fw_Rev: "",
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
    genPartNo();
    revNo();
  }, []);
  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
      }
    });
  };
  const genPropCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100159" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "Generator"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const genPropVariant = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100159" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "Generator"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const genPropLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100159" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() === "Generator"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const genPropSlNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100159"
            // item.part_Desciption_Name.toLowerCase().trim() === "Generator"
          );
        })
        .map((i) => i.sl_No),
    [genPartItem]
  );
  const hdmiCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100167" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "pcba to hdmi harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );
  const hdmiVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100167" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "pcba to hdmi harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );
  const hdmiLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100167" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "pcba to hdmi harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );
  const mainLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100165" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const mainCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100165" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const mainVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100165" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const powerCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100164" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power switch harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const powerVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100164" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power switch harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const powerLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100164" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "main power switch harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );
  const usbcCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100171" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "usbc to pcba harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const usbcVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100171" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "usbc to pcba harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const usbcLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100171" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "usbc to pcba harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const powerPcbaCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100172" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power to pcba harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const powerPcbaVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100172" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power to pcba harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const powerPcbaLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100172" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power to pcba harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const emergencyCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100169" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "emergency switch harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const emergencyVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100169" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "emergency switch harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const emergencyLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100169" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "emergency switch harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const statusCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100170" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "status led harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const statusVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100170" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "status led harness"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const statusLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100170" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "status led harness"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );

  const generatorCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100135" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "generator pcb"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const generatorVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100135" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "generator pcb"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );
  const generatorLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100135" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() === "generator pcb"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );
  const supplyAssemblyCode = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100188" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power supply assembly"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [genPartItem]
  );

  const supplyAssemblyVariation = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100188" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power supply assembly"
          );
        })
        .map((i) => i.variant),
    [genPartItem]
  );

  const supplyAssemblyLotNumber = React.useMemo(
    () =>
      genPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100188" && item.lot_No
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "power supply assembly"
          );
        })
        .map((i) => i.lot_No),
    [genPartItem]
  );
  const supplyAssemblydefaultLotNo = {
    options: supplyAssemblyLotNumber,
    getOptionLabel: (option) => option,
  };
  const supplyAssemblydefaultVariation = {
    options: supplyAssemblyVariation,
    getOptionLabel: (option) => option,
  };
  const powerSupplyAssemblyCountryCode = {
    options: supplyAssemblyCode,
    getOptionLabel: (option) => option,
  };
  const generatorPCBDefaultLotNo = {
    options: generatorLotNumber,
    getOptionLabel: (option) => option,
  };
  const generatorPCBDefaultLot = {
    options: genPartItem,
    getOptionLabel: (option) => (option.lot_No ? option.lot_No : ""),
  };
  const generatorPCBDefaultVariation = {
    options: generatorVariation,
    getOptionLabel: (option) => option,
  };
  const generatorPcbCountryCode = {
    options: generatorCode,
    getOptionLabel: (option) => option,
  };
  const statusLedDefaultLotNo = {
    options: statusLotNumber,
    getOptionLabel: (option) => option,
  };

  const statusLedDefaultVariation = {
    options: statusVariation,
    getOptionLabel: (option) => option,
  };
  const statusLedCountryCode = {
    options: statusCode,
    getOptionLabel: (option) => option,
  };
  const emergencyDefaultLotNo = {
    options: emergencyLotNumber,
    getOptionLabel: (option) => option,
  };
  const emergencyDefaultVariation = {
    options: emergencyVariation,
    getOptionLabel: (option) => option,
  };
  const emergencyCountryCode = {
    options: emergencyCode,
    getOptionLabel: (option) => option,
  };
  const powerToPcbaDefaultLotNo = {
    options: powerPcbaLotNumber,
    getOptionLabel: (option) => option,
  };

  const powerToPcbaDefaultVariation = {
    options: powerPcbaVariation,
    getOptionLabel: (option) => option,
  };
  const powerPcbaCountryCode = {
    options: powerPcbaCode,
    getOptionLabel: (option) => option,
  };
  const usbcDefaultLotNo = {
    options: usbcLotNumber,
    getOptionLabel: (option) => option,
  };

  const usbcDefaultVariation = {
    options: usbcVariation,
    getOptionLabel: (option) => option,
  };

  const usbcCountryCode = {
    options: usbcCode,
    getOptionLabel: (option) => option,
  };

  const defaultPropsLotNo = {
    options: powerLotNumber,
    getOptionLabel: (option) => option,
  };

  const mainPowerSwitchDefaultVariation = {
    options: powerVariation,
    getOptionLabel: (option) => option,
  };

  const mainPowerSwitchCountryCode = {
    options: powerCode,
    getOptionLabel: (option) => option,
  };

  const mainPowerDefaultLotNo = {
    options: mainLotNumber,
    getOptionLabel: (option) => option,
  };
  const mainPowerDefaultVariation = {
    options: mainVariation,
    getOptionLabel: (option) => option,
  };
  const mainPowerCountryCode = {
    options: mainCode,
    getOptionLabel: (option) => option,
  };

  const hdmiDefaultLotNo = {
    options: hdmiLotNumber,
    getOptionLabel: (option) => option,
  };

  const hdmiDefaultVariation = {
    options: hdmiVariation,
    getOptionLabel: (option) => option,
  };

  const hdmiCountryCode = {
    options: hdmiCode,
    getOptionLabel: (option) => option,
  };
  const genDefaultCode = {
    options: genPropCode,
    getOptionLabel: (option) => option,
  };

  const genDefaultVariant = {
    options: genPropVariant,
    getOptionLabel: (option) => option,
  };

  const genDefaultLotNumber = {
    options: genPropLotNumber,
    getOptionLabel: (option) => option,
  };

  const genDefaultSlNumber = {
    options: genPropSlNumber,
    getOptionLabel: (option) => option.toString(),
  };

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGeneratorById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        getDefaultValueCountryCodeVariation(response.data.harnesses);
      } else {
        navigate(-1);
      }
    });
  };

  const submitForm = () => {
    const componentData = JSON.stringify([
      {
        // component_Name: "PCBA to HDMI Harness",
        component: "Generator",
        part_Description_Name: "PCBA to HDMI Harness",
        id: hdmiData.id,
        component_No: hdmiData.pcbaToHdmi,
        country_Code: hdmiData.pcbaToHdmiCode,
        variant: hdmiData.pcbaToHdmiVariation,
        // sl_No: hdmiData.pcbaToHdmiSl,
        lot_No: hdmiData.pcbaLot_No,
      },
      {
        // component_Name: "Main Power Harness",
        component: "Generator",
        part_Description_Name: "Main Power Harness",
        id: mainData.id,
        component_No: mainData.mainPower,
        country_Code: mainData.mainPowerCode,
        variant: mainData.mainPowerVariation,
        // sl_No: mainData.mainPowerSl,
        lot_No: mainData.mainPowerLot_No,
      },
      {
        // component_Name: "Main Power Switch Harness",
        component: "Generator",
        part_Description_Name: "Main Power Switch Harness",
        id: mainSwitchData.id,
        component_No: mainSwitchData.mainPowerSwitch,
        country_Code: mainSwitchData.mainPowerSwitchCode,
        variant: mainSwitchData.mainPowerSwitchVariation,
        // sl_No: mainSwitchData.mainPowerSwitchSl,
        lot_No: mainSwitchData.mainPowerSwitchLot_No,
      },
      {
        // component_Name: "USBC to PCBA Harness",
        component: "Generator",
        part_Description_Name: "USBC to PCBA Harness",
        id: usbcData.id,
        component_No: usbcData.usbcToPcba,
        country_Code: usbcData.usbcToPcbaCode,
        variant: usbcData.usbcToPcbaVariation,
        // sl_No: usbcData.usbcToPcbaSl,
        lot_No: usbcData.usbcLot_No,
      },
      {
        // component_Name: "Power to PCBA Harness",
        component: "Generator",
        part_Description_Name: "Power to PCBA Harness",
        id: powerData.id,
        component_No: powerData.powerToPcba,
        country_Code: powerData.powerToPcbaCode,
        variant: powerData.powerToPcbaVariation,
        // sl_No: powerData.powerToPcbaSl,
        lot_No: powerData.powerLot_No,
      },
      {
        // component_Name: "Emergency Switch Harness",
        component: "Generator",
        part_Description_Name: "Emergency Switch Harness",
        id: emergencyData.id,
        component_No: emergencyData.emergencySwitch,
        country_Code: emergencyData.emergencySwitchCode,
        variant: emergencyData.emergencySwitchVariation,
        // sl_No: emergencyData.emergencySwitchSl,
        lot_No: emergencyData.emergencyLot_No,
      },
      {
        // component_Name: "Status LED Harness",
        component: "Generator",
        part_Description_Name: "Status LED Harness",
        id: statusLedData.id,
        component_No: statusLedData.statusLed,
        country_Code: statusLedData.statusLedCode,
        variant: statusLedData.statusLedVariation,
        // sl_No: statusLedData.statusLedSl,
        lot_No: statusLedData.statusLot_No,
      },
      {
        // component_Name: "Generator PCB",
        component: "Generator",
        part_Description_Name: "Generator PCB",
        id: generatorPcbData.id,
        component_No: generatorPcbData.generatorPCB,
        country_Code: generatorPcbData.generatorPCBCode,
        variant: generatorPcbData.generatorPCBVariation,
        sl_No: generatorPcbData.generatorPCBSl,
        lot_No: generatorPcbData.generatorPCBLot_No,
        // gen_pcb_Rev: generatorPcbData.pcb_Rev,
        // gen_pcb_Eco: generatorPcbData.pcb_Eco,
      },
      {
        // component_Name: "Power Supply Assembly",
        component: "Generator",
        part_Description_Name: "Power Supply Assembly",
        id: powerSupplyData.id,
        component_No: powerSupplyData.powerSupply,
        country_Code: powerSupplyData.powerSupplyCode,
        variant: powerSupplyData.powerSupplyVariation,
        sl_No: powerSupplyData.powerSupplySl,
        lot_No: powerSupplyData.powerSupplyLot_No,
        // power_Rev: powerSupplyData.power_Rev,
        // power_Eco: powerSupplyData.power_Eco,
      },
    ]);
    const fileData = new FormData();
    // fileData.append("GenRouterFile", GenRouter);
    fileData.append("Id", params.id);
    fileData.append("Gen_Id", formData.gen_Id);
    fileData.append("GenPart_No", "");
    fileData.append("GenRouter", "");
    fileData.append("GenRev_No", formData.genRev_No);
    fileData.append("GenBuild_Location", formData.genBuild_Location);
    fileData.append("GenBuild_Date", formData.genBuild_Date);
    fileData.append("Component_Name", "Generator");
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.lot_No);
    fileData.append("Variant", formData.variant);
    fileData.append("HW", formData.hw);
    fileData.append("SW_Rev", formData.sW_Rev);
    fileData.append("Generator_FW", formData.generator_FW);
    fileData.append("FW_Country", formData.fW_Country);
    fileData.append("FW_BuildDate", formData.fW_BuildDate);
    fileData.append("Fw_Rev", formData.fw_Rev);
    fileData.append("IsAssociated_WithSystem", false);
    fileData.append("IsActive", formData.isActive);
    // fileData.append("Id");
    fileData.append("Components", componentData);
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateGenerator(params.id, fileData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate(-1);
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const getDefaultValueCountryCodeVariation = (prop) => {
    prop?.forEach((event) => {
      if (
        event.component_No === "100167"
        // event.component_Name.toLowerCase().trim() === "pcba to hdmi harness"
      ) {
        setHdmiData({
          ...hdmiData,
          id: event.id,
          pcbaToHdmi: event.component_No,
          pcbaToHdmiCode: event.country_Code,
          pcbaToHdmiVariation: event.variant,
          pcbaLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100165"
        // event.component_Name.toLowerCase().trim() === "main power harness"
      ) {
        setMainData({
          ...mainData,
          id: event.id,
          mainPower: event.component_No,
          mainPowerCode: event.country_Code,
          mainPowerVariation: event.variant,
          mainPowerLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100164"
        // event.component_Name.toLowerCase().trim() ===
        //   "main power switch harness"
      ) {
        setSwitchMainData({
          ...mainSwitchData,
          id: event.id,
          mainPowerSwitch: event.component_No,
          mainPowerSwitchCode: event.country_Code,
          mainPowerSwitchVariation: event.variant,
          mainPowerSwitchLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100171"
        // event.component_Name.toLowerCase().trim() === "usbc to pcba harness"
      ) {
        setUsbcData({
          ...usbcData,
          id: event.id,
          usbcToPcba: event.component_No,
          usbcToPcbaCode: event.country_Code,
          usbcToPcbaVariation: event.variant,
          usbcLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100172"
        // event.component_Name.toLowerCase().trim() === "power to pcba harness"
      ) {
        setPowerData({
          ...powerData,
          id: event.id,
          powerToPcba: event.component_No,
          powerToPcbaCode: event.country_Code,
          powerToPcbaVariation: event.variant,
          powerLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100169"
        // event.component_Name.toLowerCase().trim() === "emergency switch harness"
      ) {
        setEmergencyData({
          ...emergencyData,
          id: event.id,
          emergencySwitch: event.component_No,
          emergencySwitchCode: event.country_Code,
          emergencySwitchVariation: event.variant,
          emergencyLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100170"
        // event.component_Name.toLowerCase().trim() === "status led harness"
      ) {
        setStatusLedData({
          ...statusLedData,
          id: event.id,
          statusLed: event.component_No,
          statusLedCode: event.country_Code,
          statusLedVariation: event.variant,
          statusLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100135"
        // event.component_Name.toLowerCase().trim() === "generator pcb"
      ) {
        setGeneratorPcbData({
          ...generatorPcbData,
          id: event.id,
          generatorPCB: event.component_No,
          generatorPCBCode: event.country_Code,
          generatorPCBVariation: event.variant,
          generatorPCBLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100188"
        // event.component_Name.toLowerCase().trim() === "power supply assembly"
      ) {
        setPowerSupplyData({
          ...powerSupplyData,
          id: event.id,
          powerSupply: event.component_No,
          powerSupplyCode: event.country_Code,
          powerSupplyVariation: event.variant,
          powerSupplyLot_No: event.lot_No,
        });
      }
    });
  };
  const genPartNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setGenPartItem(response.data);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            {error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
          </Grid>
          <h3>Edit Generator</h3>

          <Grid container spacing={2}>
            <ComponentName name="Part No" value={formData.component_No} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...genDefaultCode}
                  value={formData.country_Code}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      country_Code: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                        <div>{getCountryName(option)}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...genDefaultVariant}
                  value={formData.variant}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      variant: newValue,
                    });
                  }}
                  renderOption={
                    (props, option) => (
                      // option.component_No === hdmiData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...genDefaultLotNumber}
                  value={formData.lot_No}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      lot_No: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.component_No && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...genDefaultSlNumber}
                  value={formData.gen_Id}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      gen_Id: newValue,
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.toString() === value.toString()
                  }
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.component_No && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="genBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  name="genBuild_Location"
                  fullWidth
                  value={formData.genBuild_Location}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>

            <Grid item xs={1.4}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="genRev_No">
                  Rev
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.genRev_No}
                  onChange={(e) =>
                    setFormData({ ...formData, genRev_No: e.target.value })
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {revItem.map((item, index) => (
                    <MenuItem key={index} value={item.rev_No}>
                      {item.rev_No}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  ECO
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  value={formData.hw}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="sw_Rev">
                  SW Rev
                </InputLabel>

                <TextField
                  name="sw_Rev"
                  fullWidth
                  value={formData.sW_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ marginTop: -10 }}>
                  <InputLabel className={"form-label"} htmlFor="genBuild_Date">
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.genBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        genBuild_Date: e,
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="generator_FW">
                  Generator FW
                </InputLabel>

                <TextField
                  name="generator_FW"
                  fullWidth
                  value={formData.generator_FW}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.7}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="fW_Country">
                  Country Code
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.fW_Country}
                  // renderValue={(selected) => {
                  //   return generator.find((x) => x.id === selected).gen_Id;
                  // }}
                  onChange={(e) => {
                    setFormData({ ...formData, fW_Country: e.target.value });
                  }}
                >
                  {countryName.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <div>{item}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="fw_Rev">
                  FW Rev
                </InputLabel>

                <TextField
                  name="fw_Rev"
                  fullWidth
                  value={formData.fw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ marginTop: -10 }}>
                  <InputLabel className={"form-label"} htmlFor="fW_BuildDate">
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.fW_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fW_BuildDate: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <ComponentName
              name="Generator PCB"
              value={generatorPcbData?.generatorPCB}
            />
            <Grid item xs={2.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...generatorPcbCountryCode}
                  value={generatorPcbData.generatorPCBCode}
                  onChange={(event, newValue) => {
                    setGeneratorPcbData({
                      ...generatorPcbData,
                      generatorPCBCode: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <>
                        <div>{option}</div>
                        <div>{getCountryName(option)}</div>
                      </>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Variant</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...generatorPCBDefaultVariation}
                  value={generatorPcbData.generatorPCBVariation}
                  onChange={(event, newValue) => {
                    setGeneratorPcbData({
                      ...generatorPcbData,
                      generatorPCBVariation: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Lot No</InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...generatorPCBDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  inputValue={generatorPcbData.generatorPCBLot_No}
                  // value={generatorPcbData.generatorPCBLot_No}
                  onChange={(event, newValue) => {
                    setGeneratorPcbData({
                      ...generatorPcbData,
                      generatorPCBLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  renderOption={(props, option) =>
                    option.component_No === generatorPcbData.generatorPCB && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  Rev
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  // value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  // onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.2}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  ECO
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  // value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  // onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <ComponentName
              name="Power Supply Assembly"
              value={powerSupplyData?.powerSupply}
            />
            <Grid item xs={2.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...powerSupplyAssemblyCountryCode}
                  value={powerSupplyData.powerSupplyCode}
                  onChange={(event, newValue) => {
                    setPowerSupplyData({
                      ...powerSupplyData,
                      powerSupplyCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <>
                        <div>{option}</div>
                        <div>{getCountryName(option)}</div>
                      </>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...supplyAssemblydefaultVariation}
                  value={powerSupplyData.powerSupplyVariation}
                  onChange={(event, newValue) => {
                    setPowerSupplyData({
                      ...powerSupplyData,
                      powerSupplyVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <Grid item xs={1.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Lot No</InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...supplyAssemblydefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={powerSupplyData.powerSupplyLot_No}
                  inputValue={powerSupplyData.powerSupplyLot_No}
                  onChange={(event, newValue) => {
                    setPowerSupplyData({
                      ...powerSupplyData,
                      powerSupplyLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === powerSupplyData.powerSupply && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  Rev
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  // value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  // onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.2}>
              <div
                className={"form-group-component"}
                style={{ marginTop: -10 }}
              >
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  ECO
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  // value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  // onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Main Power Switch Harness"
              value={mainSwitchData?.mainPowerSwitch}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...mainPowerSwitchCountryCode}
                  value={mainSwitchData.mainPowerSwitchCode}
                  onChange={(event, newValue) => {
                    setSwitchMainData({
                      ...mainSwitchData,
                      mainPowerSwitchCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Variant</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...mainPowerSwitchDefaultVariation}
                  value={mainSwitchData.mainPowerSwitchVariation}
                  onChange={(event, newValue) => {
                    setSwitchMainData({
                      ...mainSwitchData,
                      mainPowerSwitchVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...defaultPropsLotNo}
                  {...generatorPCBDefaultLot}
                  // value={mainSwitchData.mainPowerSwitchLot_No}
                  inputValue={mainSwitchData.mainPowerSwitchLot_No}
                  onChange={(event, newValue) => {
                    setSwitchMainData({
                      ...mainSwitchData,
                      mainPowerSwitchLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.lot_No === value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === mainSwitchData.mainPowerSwitch && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        {option.lot_No}
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Main Power Harness"
              value={mainData?.mainPower}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...mainPowerCountryCode}
                  value={mainData.mainPowerCode}
                  onChange={(event, newValue) => {
                    setMainData({
                      ...mainData,
                      mainPowerCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...mainPowerDefaultVariation}
                  value={mainData.mainPowerVariation}
                  onChange={(event, newValue) => {
                    setMainData({
                      ...mainData,
                      mainPowerVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...mainPowerDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={mainData.mainPowerLot_No}
                  inputValue={mainData.mainPowerLot_No}
                  onChange={(event, newValue) => {
                    setMainData({
                      ...mainData,
                      mainPowerLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === mainData.mainPower && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <ComponentName
              name="PCBA to HDMI Harness"
              value={hdmiData?.pcbaToHdmi}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...defaultPropsCountryCode}
                  {...hdmiCountryCode}
                  value={hdmiData.pcbaToHdmiCode}
                  onChange={(event, newValue) => {
                    setHdmiData({
                      ...hdmiData,
                      pcbaToHdmiCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={
                    (props, option) => (
                      // option.component_No === formData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                        <div>{getCountryName(option)}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...hdmiDefaultVariation}
                  value={hdmiData.pcbaToHdmiVariation}
                  onChange={(event, newValue) => {
                    setHdmiData({
                      ...hdmiData,
                      pcbaToHdmiVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={
                    (props, option) => (
                      // option.component_No === hdmiData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...hdmiDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={hdmiData.pcbaLot_No}
                  inputValue={hdmiData.pcbaLot_No}
                  onChange={(event, newValue) => {
                    setHdmiData({
                      ...hdmiData,
                      pcbaLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === hdmiData.pcbaToHdmi && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Emergency Switch Harness"
              value={emergencyData?.emergencySwitch}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...emergencyCountryCode}
                  value={emergencyData.emergencySwitchCode}
                  onChange={(event, newValue) => {
                    setEmergencyData({
                      ...emergencyData,
                      emergencySwitchCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...emergencyDefaultVariation}
                  value={emergencyData.emergencySwitchVariation}
                  onChange={(event, newValue) => {
                    setEmergencyData({
                      ...emergencyData,
                      emergencySwitchVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...emergencyDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={emergencyData.emergencyLot_No}
                  inputValue={emergencyData.emergencyLot_No}
                  onChange={(event, newValue) => {
                    setEmergencyData({
                      ...emergencyData,
                      emergencyLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === emergencyData.emergencySwitch && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Status LED Harness"
              value={statusLedData?.statusLed}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...statusLedCountryCode}
                  value={statusLedData.statusLedCode}
                  onChange={(event, newValue) => {
                    setStatusLedData({
                      ...statusLedData,
                      statusLedCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...statusLedDefaultVariation}
                  value={statusLedData.statusLedVariation}
                  onChange={(event, newValue) => {
                    setStatusLedData({
                      ...statusLedData,
                      statusLedVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...statusLedDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={statusLedData.statusLot_No}
                  inputValue={statusLedData.statusLot_No}
                  onChange={(event, newValue) => {
                    setStatusLedData({
                      ...statusLedData,
                      statusLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === statusLedData.statusLed && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <ComponentName
              name="USBC to PCBA Harness"
              value={usbcData?.usbcToPcba}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...usbcCountryCode}
                  value={usbcData.usbcToPcbaCode}
                  onChange={(event, newValue) => {
                    setUsbcData({
                      ...usbcData,
                      usbcToPcbaCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...usbcDefaultVariation}
                  value={usbcData.usbcToPcbaVariation}
                  onChange={(event, newValue) => {
                    setUsbcData({
                      ...usbcData,
                      usbcToPcbaVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...usbcDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={usbcData.usbcLot_No}
                  inputValue={usbcData.usbcLot_No}
                  onChange={(event, newValue) => {
                    setUsbcData({
                      ...usbcData,
                      usbcLot_No: newValue?.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === usbcData.usbcToPcba && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Power to PCBA Harness"
              value={powerData?.powerToPcba}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...powerPcbaCountryCode}
                  value={powerData.powerToPcbaCode}
                  onChange={(event, newValue) => {
                    setPowerData({
                      ...powerData,
                      powerToPcbaCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...powerToPcbaDefaultVariation}
                  value={powerData.powerToPcbaVariation}
                  onChange={(event, newValue) => {
                    setPowerData({
                      ...powerData,
                      powerToPcbaVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...powerToPcbaDefaultLotNo}
                  {...generatorPCBDefaultLot}
                  // value={powerData.powerLot_No}
                  inputValue={powerData.powerLot_No}
                  onChange={(event, newValue) => {
                    setPowerData({
                      ...powerData,
                      powerLot_No: newValue.lot_No || "",
                      id: newValue.id || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === powerData.powerToPcba && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...genDefaultSlNumber}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <Grid item xs={9}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2} direction="row" style={{ marginTop: "17px" }}>
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Generator
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate(-1)}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
}

export default EditGenerator;
