import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreateOrganization } from "../../../services/organization.handlers";
import { CreateComponent } from "../../../services/components.handlers";

const AddComponent = (props) => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    component_Name: "",
    build_Date: "",
    headset_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    component_Name: "",
    build_Date: "",
    headset_Id: "",
  });

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateComponent(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          component_Name: "",
          build_Date: "",
          headset_Id: "",
        });
        setFormErrors({
          component_Name: "",
          build_Date: "",
          headset_Id: "",
        });
        setLoader(false);
        props.getData();
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    // <DashboardLayout>
    // <form style={{ width: "100" }}>
    //   <Paper elevation={1} style={{ padding: "20px", background: "none" }}>

    //   <h3>Add New Component</h3>
    <Grid container spacing={2}>
      {error != "" && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {success != "" && (
        <Grid item xs={12}>
          <Alert severity="success">{success}</Alert>
        </Grid>
      )}

      <Grid item xs={3}>
        <div className={"form-group"}>
          <InputLabel className={"form-label"} htmlFor="component_Name">
            Component Name
          </InputLabel>
          <TextField
            id="component_Name"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.component_Name}
            onChange={(e) =>
              setFormData({
                ...formData,
                component_Name: e.target.value,
              })
            }
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className={"form-group"}>
            <InputLabel className={"form-label"} htmlFor="build_Date">
              Build Date
            </InputLabel>

            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={formData.build_Date}
              onChange={(e) => setFormData({ ...formData, build_Date: e })}
              className={"form-input"}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <div className={"form-group"}>
          <InputLabel className={"form-label"} htmlFor="headset_Id">
            Headset ID
          </InputLabel>
          <TextField
            id="headset_Id"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.headset_Id}
            onChange={(e) =>
              setFormData({ ...formData, headset_Id: e.target.value })
            }
          />
        </div>
      </Grid>

      <Grid item xs={3} style={{ alignSelf: "centerss" }}>
        <Stack
          spacing={2}
          direction="row"
          // style={{ justifyContent: "end" }}
          style={{ alignSelf: "center", marginTop: "34px" }}
        >
          <Button
            disabled={loader}
            onClick={() => submitForm()}
            variant="contained"
            style={{
              height: "44px",
              gap: "10px",
              borderRadius: "16px",
              width: "80%",
              alignItems: "center",
            }}
          >
            Add New Component
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Stack>
      </Grid>
    </Grid>
    //    </Paper>
    // </form>
    //  </DashboardLayout>
  );
};

export default AddComponent;
