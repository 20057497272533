import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { useNavigate, useParams } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  GetInvCategoryType,
  updateInvCategoryType,
} from "../../../services/InvCategoryType.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const EditInvCategoryType = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    category_Type_Id: "",
    category_Type: "",
    category_Type_Description: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    category_Type_Id: "",
    category_Type: "",
    category_Type_Description: "",
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetInvCategoryType(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/invCategoryType");
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateInvCategoryType(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          category_Type_Id: "",
          category_Type: "",
          category_Type_Description: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/invCategoryType");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Edit Category Type</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            {/* <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="category_Type_Id">
                Category Type Id
                </InputLabel>
                <TextField
                  id="category_Type_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.category_Type_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category_Type_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid> */}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="category_Type">
                  Category Type
                </InputLabel>
                <TextField
                  id="category_Type"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.category_Type}
                  onChange={(e) =>
                    setFormData({ ...formData, category_Type: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="category_Type_Description"
                >
                  Category Type Description
                </InputLabel>
                <TextField
                  id="category_Type_Description"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.category_Type_Description}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category_Type_Description: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Category Type
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/invCategoryType")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditInvCategoryType;
