import * as React from "react";

import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  addCable,
  addPartNumber,
  getPartNumber,
} from "../../../services/systems.handlers";

import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { _getCountryName } from "../../../constants";

function AddCables(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [partItem, setPartItem] = React.useState([]);
  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [partData, setpartData] = React.useState({
    part_No: "",
    isActive: 0,
  });
  const [formData, setFormData] = React.useState({
    inv_Cables_Id: "",
    inv_CablesName: "",
    inv_CablesPart_No: "",
    inv_Cables_Lot_No: "",
    inv_Cables_Name: "",
    manufacture_Name: "",
    initial_Quantity: "",
  });

  React.useEffect(() => {
    partNo();
  }, []);

  const handleOpen = (prop) => {
    setOpen({ isOpen: true, popOver: prop });
  };
  const handleClose = () => {
    setOpen({ isOpen: false, popOver: "" });
  };

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeNo = (e) => {
    if (open.popOver === "Part No") {
      setpartData({ ...partData, part_No: e.target.value });
    }
  };

  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getPartNumber(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
      }
    });
  };

  const submitNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    if (open.popOver === "Part No" && partData.part_No !== "") {
      addPartNumber(partData, options).then((response) => {
        if (response.status) {
          setOpen(false);
          setpartData({
            part_No: "",
            isActive: 0,
          });
          partNo();
        }
      });
    } else {
      setNumberError("Enter Number");
      setTimeout(() => {
        setNumberError("");
      }, 2000);
    }
  };

  const submitForm = () => {
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    addCable(formData, option).then((response) => {
      if (response.status) {
        setAlert({
          ...alert,
          success: "Data has been submitted successfully",
        });
        setFormData({
          inv_Cables_Id: "",
          inv_CablesPart_No: "",
          inv_CablesName: "",
          inv_Cables_Lot_No: "",
          inv_Cables_Name: "",
          manufacture_Name: "",
          initial_Quantity: "",
        });
        setLoader(false);
        prop.getData();
        setTimeout(() => {
          setAlert({ ...alert, success: "" });
        }, 3000);
      } else {
        setLoader(false);
        setAlert({ ...alert, error: "Invalid Details" });
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 3000);
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal open={open.isOpen} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              <div style={{ height: 50 }}>
                {errorNumber !== "" && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errorNumber}</Alert>
                  </Grid>
                )}
              </div>
              <Grid item xs={12}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="add_part">
                    {open.popOver}
                  </InputLabel>

                  <TextField
                    style={{ marginTop: 15 }}
                    type="text"
                    fullWidth
                    className={"form-input"}
                    variant="outlined"
                    onChange={handleChangeNo}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"} style={{ marginTop: 15 }}>
                  <Button
                    disabled={loader}
                    onClick={() => submitNo()}
                    variant="contained"
                  >
                    Submit
                    {loader && <Loader color={"#fff"} />}
                  </Button>
                  <ButtonThemeProvider>
                    <Button
                      disabled={loader}
                      onClick={() => handleClose()}
                      color="grey"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </ButtonThemeProvider>
                </Stack>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="inv_Cables_Id">
              Ser. No.
            </InputLabel>
            <TextField
              name="inv_Cables_Id"
              fullWidth
              value={formData.inv_Cables_Id}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="inv_CablesName">
              Name
            </InputLabel>

            <TextField
              name="inv_CablesName"
              fullWidth
              value={formData.inv_CablesName}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="inv_CablesPart_No">
              Part No
              <Link
                style={{ marginLeft: 5 }}
                onClick={() => handleOpen("Part No")}
              >
                add new
              </Link>
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={formData.inv_CablesPart_No}
              // renderValue={(selected) => {
              //   return partItem.find((x) => x.part_No === selected).part_No;
              // }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  inv_CablesPart_No: e.target.value,
                })
              }
            >
              {partItem.map((item, index) => (
                <MenuItem value={item.part_No} key={index}>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>{item.part_No}</div>
                    <div>{_getCountryName(item.part_No)}</div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="inv_Cables_Lot_No">
              Lot No.
            </InputLabel>
            <TextField
              name="inv_Cables_Lot_No"
              fullWidth
              value={formData.inv_Cables_Lot_No}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="initial_Quantity">
              Initial Quantity
            </InputLabel>
            <TextField
              name="initial_Quantity"
              fullWidth
              value={formData.initial_Quantity}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="manufacture_Name">
              Manufacture Name
            </InputLabel>
            <TextField
              name="manufacture_Name"
              fullWidth
              value={formData.manufacture_Name}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>

        <Grid item xs={1}>
          <Button
            disabled={loader}
            variant="contained"
            style={{
              marginTop: 24,
              height: "40px",
              gap: "5px",
              alignItems: "center",
            }}
            onClick={submitForm}
          >
            <AddIcon /> Add
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Grid>
        {/* <Grid
          item
          xs={3}
          style={{
            alignSelf: "center",
          }}
        >
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="sysRouter">
              Part No.
            </InputLabel>
            <TextField
              style={{ height: 44, marginTop: 2 }}
              id="raised-button-file"
              multiple
              type="file"
              // onChange={(e) => setCsvFile(e.target.files[0])}
              // error={alert.error}
              // helperText={alert.error}
              variant="standard"
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={loader}
            variant="contained"
            style={{
              marginTop: 24,
              height: "40px",
              gap: "5px",
              alignItems: "center",
            }}
            // onClick={submitCsvFile}
          >
            <AddIcon /> Add from xlsx
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Grid> */}
      </Grid>
    </>
  );
}

export default AddCables;
