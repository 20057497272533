import { Outlet } from "react-router-dom";
import LeftSidebar from "../Components/LeftSidebar";
import { Box } from "@mui/material";
const drawerWidth = 240;
const SideBarLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <LeftSidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default SideBarLayout;
