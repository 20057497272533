import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  TableBody,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AddSystems from "./AddSystems";
import { useSelector } from "react-redux";
import { GetSystem, pdfDownload } from "../../../services/systems.handlers";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { _getCountryName, getCountryName } from "../../../constants";
import StoreDetailPage from "../Stores/StoreDetailPage";

function SystemsDetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [data, setData] = React.useState({});
  const [pdffile, setPdf] = React.useState("");
  const authToken = useSelector((state) => state.user.authToken);
  const params = useParams();
  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystem(state.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const fetchPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    pdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    });
  };

  const openNewTabPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    pdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const file = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    });
  };

  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch("report.pdf").then((response) => {
  //     response.blob().then((blob) => {
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       // Setting various property values
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "report.pdf";
  //       alink.click();
  //     });
  //   });
  // };
  const TableRowData = [
    "Component",
    "Ser. No.",
    "Router",
    "Part No",
    "Rev",
    "Build Location",
    "Build Date",
  ];

  const style = {
    background: " none!important",
    border: "none",
    padding: "0!important",
    color: "-webkit-link",
    textDecoration: "underline",
    cursor: "pointer",
  };

  return (
    <DashboardLayout>
      <Stack
      // spacing={2}
      // direction="row"
      // style={{ marginBottom: "30px", justifyContent: "end" }}
      >
        {/* <Button onClick={() => navigate("/add_systems")} variant="contained">
          Add New
        </Button> */}
        {/* <AddSystems /> */}
        <StoreDetailPage storeId={state.storeId} />
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell
                  key={index}
                  // align={item === "Router" ? "center" : ""}
                >
                  {index === -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{"System"}</TableCell>
              <TableCell>{data.system_Sn}</TableCell>
              <TableCell>
                {/* <Button
                  hide
                  onClick={() => openNewTabPdf(data.sysRouter, "system")}
                > */}
                <button
                  // href="#"
                  // target="_blank"
                  // rel="noreferrer"
                  style={style}
                  onClick={() =>
                    openNewTabPdf(data.system_Sn, data.sysRouter, "system")
                  }
                >
                  {data.sysRouter}
                </button>

                {/* </Button> */}

                {data.sysRouter && (
                  <span>
                    {/* <a
                      href={pdffile}
                      download="Example-PDF-document"
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    {/* <Button>
                        <FileDownloadIcon style={{ color: "grey" }} />
                      </Button> */}
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(data.system_Sn, data.sysRouter, "system")
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                    {/* </a> */}
                  </span>
                )}
              </TableCell>
              <TableCell>
                <>
                  {/* {data.sysPart_No} */}
                  {data.component_No}-
                  {data.country_Code ? data.country_Code.substring(0, 3) : ""}-
                  {data.variant}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {/* {getCountryName(data.country_Code)} */}
                    {data.country_Code ? data.country_Code.substring(4) : ""}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.sysRev_No}</TableCell>
              <TableCell>{data.sysBuild_Location}</TableCell>
              <TableCell>
                {data.sysBuild_Date &&
                  moment(data.sysBuild_Date).format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell>{"Generator"}</TableCell> */}
              <TableCell>
                {/* <Link to={"/generator-detail-page/" + data.generator?.id}> */}
                <Link
                  to="/generator-detail-page"
                  state={{
                    id: data.generator?.id,
                    storeId: data.generator?.storeId,
                  }}
                >
                  Generator
                </Link>
              </TableCell>
              <TableCell>{data.generator?.gen_Id}</TableCell>
              <TableCell>
                {/* <a href={pdfFile} target="_blank" rel="noreferrer">
                  {data.genRouter}
                </a> */}
                <button
                  // href="#"
                  // target="_blank"
                  // rel="noreferrer"
                  style={style}
                  onClick={() =>
                    openNewTabPdf(
                      data.generator?.gen_Id,
                      data.generator?.genRouter,
                      "generator"
                    )
                  }
                >
                  {data.generator?.genRouter}
                </button>
                {data.generator?.genRouter && (
                  <span>
                    {/* <Button>
                        <FileDownloadIcon style={{ color: "grey" }} />
                      </Button> */}
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(
                          data.generator?.gen_Id,
                          data.generator?.genRouter,
                          "generator"
                        )
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                  </span>
                )}
              </TableCell>
              <TableCell>
                <>
                  {/* {data.genPart_No} */}
                  {data.generator?.component_No}-
                  {data.country_Code ? data.country_Code.substring(0, 3) : ""}-
                  {data.generator?.variant}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {/* {_getCountryName(data.genPart_No)} */}
                    {/* {getCountryName(data.generator?.country_Code)} */}
                    {data.country_Code ? data.country_Code.substring(4) : ""}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.generator?.genRev_No}</TableCell>
              <TableCell>{data.generator?.genBuild_Location}</TableCell>
              <TableCell>
                {data.generator?.genBuild_Date &&
                  moment(data.generator?.genBuild_Date).format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell>{"Headset"}</TableCell> */}
              <TableCell>
                {/* <Link to={"/headset-detail-page/" + data.headset?.id}> */}
                <Link
                  to="/headset-detail-page"
                  state={{
                    id: data.headset?.id,
                    storeId: data.headset?.storeId,
                  }}
                >
                  Headset
                </Link>
              </TableCell>
              <TableCell>{data.headset?.hdset_Id}</TableCell>
              <TableCell>
                {/* <a href={pdfFile} target="_blank" rel="noreferrer">
                  {data.hdsetRouter}
                </a> */}
                <button
                  // href="#"
                  // target="_blank"
                  // rel="noreferrer"
                  style={style}
                  onClick={() =>
                    openNewTabPdf(
                      data.headset?.hdset_Id,
                      data.headset?.hdsetRouter,
                      "headset"
                    )
                  }
                >
                  {data.headset?.hdsetRouter}
                </button>
                {data.headset?.hdsetRouter && (
                  <span>
                    {/* <Button>
                        <FileDownloadIcon style={{ color: "grey" }} />
                      </Button> */}
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(
                          data.headset?.hdset_Id,
                          data.headset?.hdsetRouter,
                          "headset"
                        )
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                  </span>
                )}
              </TableCell>
              <TableCell>
                <>
                  {/* {data.hdsetPart_No} */}
                  {data.headset?.component_No}-
                  {data.country_Code ? data.country_Code.substring(0, 3) : ""}-
                  {data.headset?.variant}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {/* {_getCountryName(data.hdsetPart_No)} */}
                    {/* {getCountryName(data.headset?.country_Code)} */}
                    {data.country_Code ? data.country_Code.substring(4) : ""}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.headset?.hdsetRev_No}</TableCell>
              <TableCell>{data.headset?.hdsetBuild_Location}</TableCell>
              <TableCell>
                {data.headset?.hdsetBuild_Date &&
                  moment(data.headset?.hdsetBuild_Date).format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell>{"Tablet"}</TableCell> */}
              <TableCell>
                {/* <Link to={"/tablet-detail-page/" + data.tablet?.id}> */}
                <Link
                  to="/tablet-detail-page"
                  state={{ id: data.tablet?.id, storeId: data.tablet?.storeId }}
                >
                  Tablet
                </Link>
              </TableCell>
              <TableCell>{data.tablet?.tablet_Id}</TableCell>

              <TableCell>
                {/* <a href={pdfFile} target="_blank" rel="noreferrer">
                  {data.tableRouter}
                </a> */}
                <button
                  // href="#"
                  // target="_blank"
                  // rel="noreferrer"
                  style={style}
                  onClick={() =>
                    openNewTabPdf(
                      data.tablet?.tablet_Id,
                      data.tablet?.tabletRouter,
                      "tablet"
                    )
                  }
                >
                  {data.tablet?.tabletRouter}
                </button>
                {data.tablet?.tabletRouter && (
                  <span>
                    {/* <Button>
                        <FileDownloadIcon style={{ color: "grey" }} />
                      </Button> */}
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(
                          data.tablet?.tablet_Id,
                          data.tablet?.tabletRouter,
                          "tablet"
                        )
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                  </span>
                )}
              </TableCell>
              <TableCell>
                <>
                  {/* {data.tabletPart_No} */}
                  {data.tablet?.component_No}-
                  {data.country_Code ? data.country_Code.substring(0, 3) : ""}-
                  {data.tablet?.variant}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {/* {_getCountryName(data.tabletPart_No)} */}
                    {/* {getCountryName(data.tablet?.country_Code)} */}
                    {data.country_Code ? data.country_Code.substring(4) : ""}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.tablet?.tabletRev_No}</TableCell>
              <TableCell>{data.tablet?.tabletBuild_Location}</TableCell>
              <TableCell>
                {data.tablet?.tabletBuild_Date &&
                  moment(data.tablet?.tabletBuild_Date).format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>{"Generator PCB"}</TableCell>
              <TableCell>{data.gen_PCB_Id}</TableCell>
            </TableRow> */}
            {/* <TableRow>
              <TableCell>{"Headset PCB"}</TableCell>
              <TableCell>{data.hdset_PCB_Id}</TableCell>
              
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonThemeProvider>
        <Button onClick={() => navigate(-1)} color="grey" variant="contained">
          Back
        </Button>
      </ButtonThemeProvider>
    </DashboardLayout>
  );
}

export default SystemsDetailPage;
