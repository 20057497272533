import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreatePatient } from "../../../services/patient.handlers";
import { CreateMmSystem } from "../../../services/mm.systems.handlers";
import { CreateAbarlaPart } from "../../../services/InvAbarlaPartRef.handlers";

const AddInvAbarlaPartRef = () => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    abarla_Part_Ref_Id: "",
    document_No: "",
    title: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    abarla_Part_Ref_Id: "",
    document_No: "",
    title: "",
  });

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateAbarlaPart(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          abarla_Part_Ref_Id: "",
          document_No: "",
          title: "",
          isActive: 1,
        });
        setFormErrors({
          abarla_Part_Ref_Id: "",
          document_No: "",
          title: ""
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Abarla Part Ref</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="abarla_Part_Ref_Id">
                Abarla Part Ref Id
                </InputLabel>
                <TextField
                  id="abarla_Part_Ref_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.abarla_Part_Ref_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      abarla_Part_Ref_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="document_No">
                Document No
                </InputLabel>
                <TextField
                  id="document_No"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.document_No}
                  onChange={(e) =>
                    setFormData({ ...formData, document_No: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="title"
                >
                  Title
                </InputLabel>
                <TextField
                  id="title"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            
           

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Abarla Part Ref
                  {loader && <Loader color={"#fff"} />}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddInvAbarlaPartRef;
