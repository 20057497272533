import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetTechnicians = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.TECHNICIANS.CRUD_TECHNICIANS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateTechnicians = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNICIANS.CRUD_TECHNICIANS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTechnician = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.TECHNICIANS.CRUD_TECHNICIANS}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateTechnician = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.TECHNICIANS.CRUD_TECHNICIANS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTechnicianStore = async (id, type, headers) => {
  return await AxiosInstance.get(
    `${
      API_ROUTE.TECHNICIANS.CRUD_TECHNICIANS +
      "/" +
      API_ROUTE.TECHNICIANS.GET_STR_TECH
    }?store_Id=${id}&type=${type}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
