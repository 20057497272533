import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetSystem } from "../../../services/systems.handlers";
import { TableHead } from "@mui/material";
import { Link } from "react-router-dom";

const SubAssembly = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  
  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
              <TableRow colspan="3">
              <TableCell colspan="3">Generator Top Enclosure Sub Assembly - {params.id}</TableCell>
               
              </TableRow>
          </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
            Top Enclosure
<br />
(ME Sub-Assmbly 1)
            </TableCell>
            <TableCell>
            <Link to={"/gen-plastic/"+params.id}>Plastic Definition
<br />
(Vendor – Material Spec)</Link>
            </TableCell>
            <TableCell>
            Fastners, Screw, etc
<br />
(BOM – Fastner Spec)
            </TableCell>
          </TableRow>
        </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default SubAssembly;
