import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { checkUserAuth } from "../redux/slices/authSlice";
const LoggedInRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user.authToken);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  let location = useLocation();
  if (isAuthenticated == null || userData == null) {
    dispatch(checkUserAuth());
    // Redirect them to the /dashboard page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

export default LoggedInRoute;
