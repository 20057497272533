import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { useSelector } from "react-redux";
import { GetStore } from "../../../services/store.handlers";

const StoreDetailPage = (props) => {
  const authToken = useSelector((state) => state.user.authToken);
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    fetchStore();
  }, []);

  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (props.storeId) {
      GetStore(props.storeId, options).then((response) => {
        if (response.status) {
          setFormData(response.data);
        } else {
          // navigate("/stores");
        }
      });
    }
  };
  return (
    <>
      {/* <div
        style={{
          display: "flex",
          width: "600px",
          justifyContent: "space-between",
        }}
      >
        <h4> Store Id - {formData.store_Id}</h4>
        {"   "}
        <h4>Store Name - {formData.store_Name}</h4>{" "}
        <h4> Store Email - {formData.store_Email}</h4>
      </div> */}
      Store Id - {formData.store_Id}
      {"  , "}
      Store Name - {formData.store_Name}
      {" ,  "}
      Store Email - {formData.store_Email}
    </>
  );
};
export default StoreDetailPage;
