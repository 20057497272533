import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetExcelFile = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.EXCEL_FILE.CRUD_EXCEL_FILE}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};
// export const UpdateExcel = async (id, payload, headers) => {
//   return await AxiosInstance.put(
//     `${API_ROUTE.EXCEL_FILE.CRUD_EXCEL_FILE}/${id}`,
//     payload,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };
export const UpdateInvHarness = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.EXCEL_FILE.CRUD_EXCEL_FILE}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

// export const GetExcelById = async (id, headers) => {
//   return await AxiosInstance.get(
//     `${API_ROUTE.EXCEL_FILE.CRUD_EXCEL_FILE}/${id}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };
export const GetInvHarnessById = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.EXCEL_FILE.CRUD_EXCEL_FILE}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

// export const UploadExcelFile = async (payload, headers) => {
//   return await AxiosInstance.post(
//     `${API_ROUTE.EXCEL_FILE.UPLOAD_EXCEL_FILE}`,
//     payload,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };
export const UploadExcelFile = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.EXCEL_FILE.UPLOAD_EXCEL_FILE}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
