import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getCableById } from "../../../services/systems.handlers";
import { _getCountryName } from "../../../constants";

function CablesDetailPage() {
  const authToken = useSelector((state) => state.user.authToken);
  const params = useParams();
  const [data, setData] = React.useState({});

  const TableRowData = ["Name", "Ser. No.", "Part No", "Lot No"];

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getCableById(params.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell colspan="6">
                Cable Details - {data?.inv_Cables_Id}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell
                  key={index}
                  // align={item === "Router" ? "center" : ""}
                >
                  {index === -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{data.inv_CablesName}</TableCell>
              <TableCell>{data.inv_Cables_Id}</TableCell>
              <TableCell>
                <>
                  {data.inv_CablesPart_No}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {_getCountryName(data?.inv_CablesPart_No)}
                  </div>
                </>
              </TableCell>
              {/* <TableCell>{data.inv_Cables_Name}</TableCell> */}
              <TableCell>{data.inv_Cables_Lot_No}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
}

export default CablesDetailPage;
