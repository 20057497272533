import * as React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import { addRevNumber } from "../../../services/systems.handlers";

function AddRevNumber(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    rev_No: "",
    isActive: 1,
    isDefault: 0,
  });

  const handleDefault = (event) => {
    setFormData({ ...formData, isDefault: 0 });

    if (event.target.checked) {
      setFormData({ ...formData, isDefault: 1 });
    } else {
      setFormData({ ...formData, isDefault: 0 });
    }
  };

  const submitForm = () => {
    setLoader(true);
    var options = {
      Authorization: "Berrer" + authToken,
    };
    if (formData.rev_No !== "") {
      addRevNumber(formData, options).then((response) => {
        if (response.status) {
          setLoader(false);
          setFormData({
            rev_No: "",
            isActive: 1,
            isDefault: 0,
          });
          prop.getData();
        }
        setLoader(false);
      });
    }
    setLoader(false);
  };
  return (
    <Grid
      // style={{ justifyContent: "end", paddingRight: "179px" }}
      container
      spacing={2}
    >
      <Grid item xs={3}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="rev_no">
            Rev
          </InputLabel>
          <TextField
            name="part_no"
            fullWidth
            value={formData.rev_No}
            className={"form-input"}
            variant="outlined"
            onChange={(e) =>
              setFormData({ ...formData, rev_No: e.target.value })
            }
          />
        </div>
      </Grid>
      <Grid item xs={2} style={{ marginTop: "25px" }}>
        <FormControlLabel
          className={"form-checkbox"}
          control={<Checkbox />}
          label="Make it default"
          onChange={handleDefault}
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={loader}
          variant="contained"
          style={{
            marginTop: 24,
            height: "40px",
            gap: "5px",
            alignItems: "center",
          }}
          onClick={submitForm}
        >
          <AddIcon /> Add
          {loader && <Loader color={"#fff"} />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default AddRevNumber;
