import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../Layout/DashboardLayout";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getHeadsetPcbById,
  headsetPcbPdfDownload,
} from "../../../services/systems.handlers";
import moment from "moment";
import { _getCountryName } from "../../../constants";

function HeadsetPcbDetailPage() {
  const authToken = useSelector((state) => state.user.authToken);
  const params = useParams();
  const [data, setData] = React.useState({});

  const TableRowData = [
    " Ser. No.",
    " Part No",
    " Rev",
    " Router",
    " Build Date",
    " Build Location",
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    headsetPcbPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    });
  };

  const openPdfNewTab = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    headsetPcbPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const file = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    });
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadsetPcbById(params.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell colspan="6">
                Headset PCB Details - {data?.headset_PCB_Id}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell
                  key={index}
                  // align={item === "Router" ? "center" : ""}
                >
                  {index === -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{data.headset_PCB_Id}</TableCell>
              <TableCell>
                <>
                  {data.headset_PCBPart_No}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {_getCountryName(data?.headset_PCBPart_No)}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.headset_PCBRev_No}</TableCell>
              <TableCell>
                <a
                  href="#"
                  onClick={() =>
                    openPdfNewTab(
                      data.id,
                      data.headset_PCBRouter,
                      "headset_PCB"
                    )
                  }
                >
                  {data.headset_PCBRouter}
                </a>
                {data.headset_PCBRouter && (
                  <span>
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(data.id, data.headset_PCBRouter, "headset_PCB")
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                  </span>
                )}
              </TableCell>
              <TableCell>
                {moment(data?.headset_PCBBuild_Date).format("YYYY/MM/DD")}
              </TableCell>
              <TableCell>{data.headset_PCBBuild_Location}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
}

export default HeadsetPcbDetailPage;
