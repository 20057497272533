import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Grid from "@mui/material/Grid";
import { Alert, InputLabel, Input, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  GetSystem,
  uploadDoc,
  viewDoc,
} from "../../../services/systems.handlers";

function UploadDoc() {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [selectedValue, setselectedValue] = React.useState("System");
  const [selectedView, setselectedView] = React.useState("System");
  const [id, setId] = React.useState({});
  const [file, setFile] = React.useState();
  const [value, setValue] = React.useState("");
  const [alert, setAlert] = React.useState({
    success: "",
    error: "",
  });
  const [viewData, setViewData] = React.useState([]);
  const [viewValue, setViewValue] = React.useState();

  const seletcted = (prop) => {
    if (prop === "System") {
      setValue(id.system_Sn);
      return;
    }
    if (prop === "Tablet") {
      setValue(id.tablet.tablet_Id);
      return;
    }
    if (prop === "Headset") {
      setValue(id.headset.hdset_Id);
      return;
    }
    if (prop === "Generator") {
      setValue(id.generator.gen_Id);
      return;
    }
  };

  // const selectedViewValue = (prop) => {
  //   if (prop === "System") {
  //     setViewValue(id.system_Id);
  //     return;
  //   }
  //   if (prop === "Tablet") {
  //     setViewValue(id.tablet_Id);
  //     return;
  //   }
  //   if (prop === "Headset") {
  //     setViewValue(id.hdset_Id);
  //     return;
  //   }
  //   if (prop === "Generator") {
  //     setViewValue(id.gen_Id);
  //     return;
  //   }
  // };

  const handleOnchange = (e) => {
    setselectedValue(e.target.value);
    seletcted(e.target.value);
  };

  // const handleViewChange = (e) => {
  //   setselectedView(e.target.value);
  //   selectedViewValue(e.target.value);
  // };
  React.useEffect(() => {
    fetchSystems();
  }, []);

  const fetchSystems = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystem(params.id, options).then((response) => {
      if (response.status) {
        setId(response.data);
        setValue(response.data.system_Sn);
        handleViewDoc(response.data.system_Sn);
        // setViewValue(response.data.system_Id);
      } else {
        navigate("/systems");
      }
    });
  };

  const uploadDocument = () => {
    const formData = new FormData();

    formData.append("File", file);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (file) {
      uploadDoc(value, selectedValue, selectedValue, formData, options).then(
        (res) => {
          if (res.status) {
            handleViewDoc(id.system_Sn);
            setAlert({ ...alert, success: res.data });
            setTimeout(() => {
              setAlert({ ...alert, success: "" });
            }, 3000);
          }
        }
      );
    } else {
      setAlert({ ...alert, error: "Please Select File" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  const handleViewDoc = (prop) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    // viewDoc(viewValue, selectedView, options).then((res) => {
    viewDoc(prop, "System", options).then((res) => {
      if (res.status) {
        setViewData(res.data);
      }
    });
  };
  // console.log(viewData);
  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        {alert.success !== "" && (
          <Grid item xs={12}>
            <Alert severity="success">{alert.success}</Alert>
          </Grid>
        )}
        {/* {alert.error !== "" && (
          <Grid item xs={12}>
            <Alert severity="error">{alert.error}</Alert>
          </Grid>
        )} */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Upload doc for System- Ser. No {id?.system_Sn}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Grid item xs={6}>
          <div className={"form-group"}>
            <InputLabel className={"form-label"} htmlFor="select-active">
              Type
            </InputLabel>
            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={selectedValue}
              onChange={handleOnchange}
            >
              <MenuItem value="System">System</MenuItem>
              <MenuItem value="Generator">Generator</MenuItem>
              <MenuItem value="Headset">Headset</MenuItem>
              <MenuItem value="Tablet">Tablet</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            alignSelf: "center",
            marginTop: "20px",
            height: "48px",
          }}
        >
          {/* <Button variant="contained" component="label">
          Upload File
          <input type="file" hidden />
        </Button> */}
          <TextField
            id="raised-button-file"
            multiple
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            error={alert.error}
            helperText={alert.error}
            variant="standard"
          />
        </Grid>
        <Grid item xs={2} style={{ alignSelf: "center", marginTop: "22px" }}>
          <Button variant="contained" component="span" onClick={uploadDocument}>
            Upload
          </Button>
        </Grid>
        <Table style={{ marginTop: "50px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Document Summary</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {/* <Grid item xs={6}>
          <div className={"form-group"}>
            <InputLabel className={"form-label"} htmlFor="select-active">
              Type
            </InputLabel>
            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={selectedView}
              onChange={handleViewChange}
            >
              <MenuItem value="System">System</MenuItem>
              <MenuItem value="Generator">Generator</MenuItem>
              <MenuItem value="Headset">Headset</MenuItem>
              <MenuItem value="Tablet">Tablet</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item xs={2} style={{ alignSelf: "center", marginTop: "22px" }}>
          <Button variant="contained" component="span" onClick={handleViewDoc}>
            View Doc
          </Button>
        </Grid>*/}
      </Grid>
      {viewData.length !== 0 && (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Ser. No.</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.new_File_Name}</TableCell>
                  <TableCell>{item.subAssembly_ID}</TableCell>
                  <TableCell>{item.subAssembly_Type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DashboardLayout>
  );
}

export default UploadDoc;
