import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
import { GetStores } from "../../../services/store.handlers";
import { CreateBilling } from "../../../services/billing.calculation.handlers";
import { GetOrgEmployees } from "../../../services/org.employee.handlers";
import { GetTechnicianStore } from "../../../services/technicians.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddBillingCalculation = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [technicians, setTechnicians] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    system_Serial: "",
    store: "",
    technician: "",
    health_Card_Id: "",
    no_Of_Treatment: "",
    per_Treatment_Cost: "",
    total_Bill_Amount: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Serial: "",
    store: "",
    technician: "",
    health_Card_Id: "",
  });

  React.useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setStores(response.data);
      }
    });
  };

  React.useEffect(() => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetTechnicianStore(formData.store, "Technician", options).then((res) => {
      if (res.status) {
        setTechnicians(res.data);
      }
    });
  }, [formData.store]);

  const setStoreID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store: "Stores ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store: "",
      });
    }
    setFormData({ ...formData, store: "" + value });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateBilling(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          system_Serial: "",
          store: "",
          technician: "",
          health_Card_Id: "",
          no_Of_Treatment: "",
          per_Treatment_Cost: "",
          total_Bill_Amount: "",
          isActive: 1,
        });
        setFormErrors({
          system_Serial: "",
          store: "",
          technician: "",
          health_Card_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Billing</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="system_Serial">
                  System Serial
                </InputLabel>
                <TextField
                  id="treatment_Cost"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Serial}
                  onChange={(e) =>
                    setFormData({ ...formData, system_Serial: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Store
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.store}
                  onChange={(e) => {
                    setStoreID(e.target.value);
                  }}
                  error
                  helperText={formErrors.store}
                >
                  {stores.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.store_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Technician
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.technician}
                  onChange={(e) => {
                    setFormData({ ...formData, technician: e.target.value });
                  }}
                  error
                  helperText={formErrors.technicians}
                >
                  {formData.store &&
                    technicians.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="health_Card_Id">
                  Health Card ID
                </InputLabel>
                <TextField
                  id="total_Amount"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.health_Card_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, health_Card_Id: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="no_Of_Treatment">
                  No Of Treatment
                </InputLabel>
                <TextField
                  id="paid_By"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.no_Of_Treatment}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      no_Of_Treatment: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="per_Treatment_Cost"
                >
                  Per Treatment Cost
                </InputLabel>
                <TextField
                  id="paid_By"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.per_Treatment_Cost}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      per_Treatment_Cost: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="total_Bill_Amount"
                >
                  Total Bill Amount
                </InputLabel>
                <TextField
                  id="payment_Index"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.total_Bill_Amount}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      total_Bill_Amount: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Billing
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/billing_calculation")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddBillingCalculation;
