import * as React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../Layout/DashboardLayout";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  GetSystems,
  getHeadsetById,
  hdsetPdfDownload,
} from "../../../services/systems.handlers";
import moment from "moment";
import { _getCountryName } from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import StoreDetailPage from "../Stores/StoreDetailPage";

function HeadsetDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const authToken = useSelector((state) => state.user.authToken);
  const params = useParams();
  const [data, setData] = React.useState({});
  const [systemSn, setSystem_Sn] = React.useState("");
  const [systemId, setSystemId] = React.useState("");
  const [hdsetData, setHdsetData] = React.useState({
    hdset: "",
    hdsetVariation: "",
    hdsetCode: "",
    hdsetSl: "",
    hdsetLot_No: "",
  });
  const [ledHdsetData, setLedHdsetData] = React.useState({
    ledHdset: "",
    ledHdsetVariation: "",
    ledHdsetCode: "",
    ledHdsetSl: "",
    ledHdsetLot_No: "",
  });
  const [cupHdsetData, setCupHdsetData] = React.useState({
    cupHdset: "",
    cupHdsetVariation: "",
    cupHdsetCode: "",
    cupHdsetSl: "",
    cupHdsetLot_No: "",
  });
  const [eyeHdsetData, setEyeHdsetData] = React.useState({
    eyeHdset: "",
    eyeHdsetVariation: "",
    eyeHdsetCode: "",
    eyeHdsetSl: "",
    eyeHdsetLot_No: "",
  });
  const [hdsetPcbData, setHdsetPcbData] = React.useState({
    hdsetPcb: "",
    hdsetPcbVariation: "",
    hdsetPcbCode: "",
    hdsetPcbSl: "",
    hdsetPcbLot_No: "",
  });

  const TableRowData = [
    " Ser. No.",
    " Part No",
    " Rev",
    " Router",
    " Build Date",
    " Build Location",
  ];
  const style = {
    background: " none!important",
    border: "none",
    padding: "0!important",
    color: "-webkit-link",
    textDecoration: "underline",
    cursor: "pointer",
  };
  React.useEffect(() => {
    fetchData();
    // fetchSystemData();
  }, []);

  function getDefaultValueCountryCodeVariation(prop) {
    prop?.forEach((event) => {
      if (
        event.component_No === "100186"
        // event.component_Name.toLowerCase().trim() === "hdst harness"
      ) {
        setHdsetData({
          ...hdsetData,
          hdset: event.component_No,
          hdsetVariation: event.variant,
          hdsetCode: event.country_Code,
          hdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100184"
        // event.component_Name.toLowerCase().trim() === "led hdst harness"
      ) {
        setLedHdsetData({
          ...ledHdsetData,
          ledHdset: event.component_No,
          ledHdsetVariation: event.variant,
          ledHdsetCode: event.country_Code,
          ledHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100183"
        // event.component_Name.toLowerCase().trim() ===
        //   "eye cup and gnd rtn hdset harness"
      ) {
        setEyeHdsetData({
          ...eyeHdsetData,
          eyeHdset: event.component_No,
          eyeHdsetVariation: event.variant,
          eyeHdsetCode: event.country_Code,
          eyeHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100182"
        // event.component_Name.toLowerCase().trim() === "eye cup harness"
      ) {
        setCupHdsetData({
          ...cupHdsetData,
          cupHdset: event.component_No,
          cupHdsetVariation: event.variant,
          cupHdsetCode: event.country_Code,
          cupHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100219"
        // event.component_Name.toLowerCase().trim() === "headset pcb"
      ) {
        setHdsetPcbData({
          ...hdsetPcbData,
          hdsetPcb: event.component_No,
          hdsetPcbVariation: event.variant,
          hdsetPcbCode: event.country_Code,
          hdsetPcbLot_No: event.lot_No,
          hdsetPcbSl: event.sl_No,
        });
      }
    });
  }
  const fetchPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    hdsetPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    });
  };

  const openPdfNewTab = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    hdsetPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const file = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadsetById(state.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
        getDefaultValueCountryCodeVariation(response.data.harnesses);
      }
    });
  };

  // const fetchSystemData = () => {
  //   var options = {
  //     Authorization: "Bearer " + authToken,
  //   };
  //   GetSystems(options).then((response) => {
  //     if (response.status) {
  //       setSystemId(
  //         response.data.find((event) => event.hdset_Sn === Number(params.id)).id
  //       );
  //       setSystem_Sn(
  //         response.data.find((event) => event.hdset_Sn === Number(params.id))
  //           .system_Sn
  //       );
  //     }
  //   });
  // };

  return (
    <DashboardLayout>
      <Stack spacing={2}>
        {/* {systemId !== "" ? ( */}
        {data.isAssociated_WithSystem === true ? (
          <Breadcrumbs separator="›">
            <Link
              // to={"/systems-detail-page/" + data.systemId}
              to="/systems-detail-page"
              state={{ id: data.systemId, storeId: data.storeId }}
              key="1"
              color="inherit"
            >
              System - {data.system_Sn}
            </Link>
            <Typography key="2" color="text.primary">
              Headset Details - {data.hdset_Id}
            </Typography>
            <span>
              <Typography key="2" color="text.primary">
                <StoreDetailPage storeId={state.storeId} />
              </Typography>
            </span>
          </Breadcrumbs>
        ) : (
          <Typography key="2" color="text.primary">
            Headset Details - {data.hdset_Id}
          </Typography>
        )}
      </Stack>
      {/* <h4 style={{ marginBottom: -5, marginTop: 0 }}>
        Headset Details - {data.hdset_Id}
      </h4> */}
      <hr></hr>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Part No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.component_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.country_Code || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.variant || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.lot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.hdset_Id || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Location</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.hdsetBuild_Location || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.4}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Rev</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.hdsetRev_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              ECO
            </InputLabel>

            <TextField
              disabled
              fullWidth
              value={data.hW_Rev || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={moment(data.hdsetBuild_Date).format("YYYY/MM/DD") || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Router</InputLabel>
            <button
              // href="#"
              style={style}
              onClick={() =>
                openPdfNewTab(data.id, data.hdsetRouter, "headset")
              }
            >
              {data.hdsetRouter}
            </button>
            {data.hdsetRouter && (
              <span>
                <IconButton
                  aria-label="file download icon"
                  onClick={() => fetchPdf(data.id, data.hdsetRouter, "headset")}
                >
                  <FileDownloadIcon style={{ color: "grey" }} />
                </IconButton>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Headset PCB</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetPcbData.hdsetPcb || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetPcbData.hdsetPcbCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetPcbData.hdsetPcbVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetPcbData.hdsetPcbLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              Rev
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={1.2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="hw_Rev">
              ECO
            </InputLabel>

            <TextField
              disabled
              name="hw_Rev"
              fullWidth
              // value={formData.hw_Rev}
              className={"form-input"}
              variant="outlined"
            />
          </div>
        </Grid>
        {/* <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetPcbData.hdsetPcbSl}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid> */}
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Eye Cup Harness</InputLabel>
            <TextField
              disabled
              fullWidth
              value={cupHdsetData.cupHdset || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={cupHdsetData.cupHdsetCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={cupHdsetData.cupHdsetVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={cupHdsetData.cupHdsetLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>
              Eye Cup AND Gnd Rtn HDST Harness
            </InputLabel>
            <TextField
              disabled
              fullWidth
              value={eyeHdsetData.eyeHdset || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={eyeHdsetData.eyeHdsetCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={eyeHdsetData.eyeHdsetVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={eyeHdsetData.eyeHdsetLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>LED HDST Harness</InputLabel>
            <TextField
              disabled
              fullWidth
              value={ledHdsetData.ledHdset || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={ledHdsetData.ledHdsetCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={ledHdsetData.ledHdsetVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={ledHdsetData.ledHdsetLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>HDST Harness</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetData.hdset || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetData.hdsetCode || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetData.hdsetVariation || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={hdsetData.hdsetLot_No || ""}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group-component"} style={{ marginTop: -10 }}>
            <InputLabel disabled className={"form-label"}>
              Ser. No.
            </InputLabel>
            <TextField
              disabled
              fullWidth
              // value={hdmiData.pcbaLot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={2}>
          <ButtonThemeProvider>
            <Button
              onClick={() => navigate(-1)}
              color="grey"
              variant="contained"
            >
              Back
            </Button>
          </ButtonThemeProvider>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default HeadsetDetailPage;
