import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { Alert, InputLabel, MenuItem, Select } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  getGeneratorPcbById,
  updateGeneratorPcb,
} from "../../../services/systems.handlers";

function EditGeneratorPcb() {
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  const [formData, setFormData] = React.useState({
    generator_PCB_Id: "",
    generator_PCBPart_No: "",
    generator_PCBRev_No: "",
    generator_PCBBuild_Date: "",
    generator_PCBBuild_Location: "",
    generator_PCBRouter: "",
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGeneratorPcbById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/generatorPcb");
      }
    });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateGeneratorPcb(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/generatorPcb");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            {error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
          </Grid>
          <h3>Edit Generator PCB</h3>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="generator_PCB_Id">
                  Ser. No.
                </InputLabel>
                <TextField
                  name="generator_PCB_Id"
                  fullWidth
                  value={formData.generator_PCB_Id}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="generator_PCBPart_No"
                >
                  Part No
                </InputLabel>

                <TextField
                  name="generator_PCBPart_No"
                  fullWidth
                  value={formData.generator_PCBPart_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="generator_PCBRev_No"
                >
                  Rev No
                </InputLabel>

                <TextField
                  name="generator_PCBRev_No"
                  fullWidth
                  value={formData.generator_PCBRev_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="generator_PCBBuild_Date"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.generator_PCBBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        generator_PCBBuild_Date: e,
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="generator_PCBBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  name="generator_PCBBuild_Location"
                  fullWidth
                  value={formData.generator_PCBBuild_Location}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row">
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Generator PCB
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/generatorPcb")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
}

export default EditGeneratorPcb;
