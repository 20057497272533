import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChangePasswords } from "../../services/auth.handlers";
import Alert from "@mui/material/Alert";
import Loader from "../../Components/Loader";
import DashboardLayout from "../../Layout/DashboardLayout";
import { useSelector } from "react-redux";
const ChangePassword = (props) => {
  const [formError, setFormError] = React.useState("");
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setOldPassword(
      data.get("old_password"),
      data.get("password"),
      data.get("conf_password")
    );
  };
  const setOldPassword = (value, password, confPassword) => {
    if (value.length < 8) {
      setFormError("Password must be atleast 8 characters");
    } else {
      setPassword(password, confPassword, value);
    }
  };

  const setPassword = (value, confPassword, oldPassword) => {
    if (value.length < 8) {
      setFormError("Password must be atleast 8 characters");
    } else {
      setConfPassword(confPassword, value, oldPassword);
    }
  };
  const setConfPassword = (value, compare, oldPassword) => {
    if (value.length < 8) {
      setFormError("Confirm Password must be atleast 8 characters");
    } else if (value !== compare) {
      setFormError("Password and confirm are not same");
    } else {
      setFormError("");
      setLoading(true);
      var options = {
        Authorization: "Bearer " + authToken,
      };
      const payload = {
        id: userData.id,
        oldPassword: oldPassword,
        newPassword: value,
      };
      ChangePasswords(payload, options).then((response) => {
        if (response.status) {
          setFormSuccess(true);
          setLoading(false);
        } else {
          setFormError("Invalid Details");
          setLoading(false);
        }
      });
    }
  };

  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <h3>Change Password</h3>
        <Grid container spacing={2}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            style={{ padding: "20px", background: "none", width: "100%" }}
          >
            {formError !== "" && <Alert severity="error">{formError}</Alert>}
            {formSuccess && (
              <Alert severity="success">{"Password Successfully change"}</Alert>
            )}
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="old_password">
                  Old Password
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="old_password"
                  className={"form-input"}
                  type="password"
                  id="old_password"
                  placeholder="*********"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="new_password">
                  New Password
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  className={"form-input"}
                  type="password"
                  id="new_password"
                  placeholder="*********"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="email">
                  Confirm New Password
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="conf_password"
                  className={"form-input"}
                  type="password"
                  id="password"
                  placeholder="*********"
                  autoComplete="current-password"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button type="submit" disabled={loading} variant="contained">
                  Change password
                  {loading && <Loader color={"#fff"} />}
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
};

export default React.memo(ChangePassword);
