import * as React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkUserAuth } from "../redux/slices/authSlice";

const LoggedOutRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user.authToken);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  let location = useLocation();
  if (isAuthenticated != null && userData != null) {
    // Redirect them to the /dashboard page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/dashboard" state={{ from: location }} />;
    return (
      <Navigate to="/reports/systemUsageReports" state={{ from: location }} />
    );
  }
  dispatch(checkUserAuth());
  return children;
};

export default LoggedOutRoute;
