import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useLocation } from "react-router-dom";
import { jsonCheck, monthNames } from "../../../constants";
import { StoreBilling } from "../../../services/billing.calculation.handlers";
import { Stack } from "@mui/material";

const TableRowData = [
  "Organization",
  "Store Name",
  "Contact Info",
  "Address",
  "",
  "Status",
  "",
];
const SystemTableData = [
  "System Ser. No.",
  "Date",
  "Per Minute Cost",
  "System Cost",
  "Total Time Usage",
  // "Total Cost",
  "No of Time Usage",
  "Unpaid/Paid",
];
const OutStandingRecords = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [storeBillingData, setStoreBillingData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const payloadData = {
      organizationId: 0,
      storeId: state?.id,
      month_No: state.monthYear ? state.monthYear.month : "",
      year: state.monthYear ? state.monthYear.year : "",
    };
    StoreBilling(payloadData, options).then((response) => {
      if (response.status) {
        setStoreBillingData(response.data);
      } else {
        navigate("/orgBillingSummary");
      }
    });
  };

  return (
    <DashboardLayout>
      <Stack direction="row">
        <h3 style={{ marginBottom: -2, marginTop: 0 }}>
          Billing Summary -{storeBillingData.store?.store_Name}
        </h3>
      </Stack>
      <hr></hr>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {storeBillingData.store && (
              <TableRow hover>
                <TableCell>
                  {storeBillingData.store?.organizationName}
                </TableCell>
                <TableCell>{storeBillingData.store?.store_Name}</TableCell>
                <TableCell>
                  {storeBillingData.store?.store_Email}
                  <br />
                  {storeBillingData.store?.store_Number}
                </TableCell>
                <TableCell colSpan={2}>
                  {storeBillingData.store?.store_Address_1 +
                    " " +
                    storeBillingData.store?.store_Address_2 +
                    ", "}
                  {jsonCheck(storeBillingData.store?.city)
                    ? JSON.parse(storeBillingData.store?.city).label
                    : storeBillingData.store?.city}{" "}
                  {", "}
                  {jsonCheck(storeBillingData.store?.state)
                    ? JSON.parse(storeBillingData.store?.state).label
                    : storeBillingData.store?.state}{" "}
                  {", "}
                  {storeBillingData.store?.zip + " - "}
                  {jsonCheck(storeBillingData.store?.country)
                    ? JSON.parse(storeBillingData.store?.country).label
                    : storeBillingData.store?.country}
                </TableCell>

                <TableCell>
                  {storeBillingData.store?.isActive ? (
                    <span className={"label color-green"}>Active</span>
                  ) : (
                    <span className={"label color-red"}>Blocked</span>
                  )}
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableHead>
            <TableRow>
              {SystemTableData.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {storeBillingData?.systemsInvoice &&
              storeBillingData.systemsInvoice.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.system.system_Id}</TableCell>
                  <TableCell>
                    {monthNames[state.monthYear.month - 1]}{" "}
                    {state.monthYear.year}
                  </TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{item.systemCost}</TableCell>
                  <TableCell>{item.totalTimeUsage}</TableCell>
                  <TableCell>{item.total_treatments_performed}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default OutStandingRecords;
