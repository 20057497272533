import * as React from "react";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import {
  GetProblemReportForm,
  ProblemReportCount,
  ProblemReportForm,
} from "../../../services/problem.report.admin.handlers";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

function AddProblemReportForm(prop) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState({});
  const [time, setTime] = React.useState(new Date().toString());
  const [prNo, setPRNo] = React.useState([]);
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [formData, setFormData] = React.useState({
    system_Sn: state?.selectedSystemId || "",
    description: state?.reportType || "",
    remark: "",
    status: 1,
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
    const interval = setInterval(() => setTime(new Date().toString()), 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    ProblemReportCount(new Date().toISOString(), options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
    GetProblemReportForm(options).then((response) => {
      if (response.status) {
        setPRNo(response.data);
      }
    });
  };

  const submitForm = () => {
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    if (formData.system_Sn !== "" && formData.description !== "") {
      //if it is remove system from store, it will goes back that page after click on add
      if (state?.selectedSystemId) {
        navigate("/removeSystemToStore/" + state.id, {
          state: {
            system_Sn: formData.system_Sn,
            description: formData.description,
          },
        });
        return;
      }
      ProblemReportForm(formData, option).then((response) => {
        if (response.status) {
          setAlert({
            ...alert,
            success: "Data has been submitted successfully",
          });
          setFormData({
            system_Sn: "",
            description: "",
            remark: "",
            status: 1,
            isActive: 1,
          });
          setLoader(false);
          // if (state?.selectedSystemId) {
          //   navigate("/removeSystemToStore/" + state.id, {
          //     state: {
          //       system_Sn: formData.system_Sn,
          //       description: formData.description,
          //     },
          //   });
          // } else {
          //   navigate("/problemReportList");
          // }

          setTimeout(() => {
            setAlert({ ...alert, success: "" });
            // prop.getData();
            navigate("/problemReportList");
          }, 2000);
        } else {
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 2000);
        }
      });
    } else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };
  // setInterval(() => setTime(time), 1000);
  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <>
          <Grid
            container
            spacing={2}
            // direction="column"
            // justifyContent="flex-start"
            // alignItems="flex-start"
          >
            {alert.error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{alert.error}</Alert>
              </Grid>
            )}
            {alert.success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{alert.success}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <h3>{state?.header ? state.header : "Problem Report Form"}</h3>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="systemId">
                  <div
                    style={{
                      display: "flex",
                      width: "70%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>PR/Index-{prNo.length + 1}</div>
                    <div>PR/Day-{data.dayCount}</div>
                    <div>PR/Week-{data.weekCount}</div>
                    <div>PR/Month-{data.monthCount}</div>
                    <div>PR/year-{data.yearCount}</div>
                  </div>
                </InputLabel>
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="system_Sn">
                  System Ser. No.
                </InputLabel>

                <TextField
                  name="system_Sn"
                  fullWidth
                  value={formData.system_Sn}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="description">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Problem{" "}
                    <div>
                      {moment().format("YYYY/MM/DD")},
                      {moment().format("hh:mm:ss A")}
                    </div>
                  </div>
                </InputLabel>

                <TextField
                  inputProps={{
                    maxLength: 1000,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "16px",
                      border: "1px",
                      borderColor: "#e5e7eb",
                    },
                  }}
                  maxRows={4}
                  minRows={4}
                  multiline
                  name="description"
                  fullWidth
                  value={formData.description}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <Button
                disabled={loader}
                variant="contained"
                style={{
                  marginTop: "6rem",
                  height: "40px",
                  gap: "5px",
                  alignItems: "center",
                }}
                onClick={submitForm}
              >
                <AddIcon /> Add
                {loader && <Loader color={"#fff"} />}
              </Button>
            </Grid>
          </Grid>
        </>
      </Paper>
    </DashboardLayout>
  );
}

export default AddProblemReportForm;
