import * as React from "react";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import {
  AddProblemReports,
  GetByProblemReports,
  UpdateProblemReports,
} from "../../../services/problem.report.admin.handlers";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../Layout/DashboardLayout";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

function EditProblemReportAdmin(prop) {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);

  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [formData, setFormData] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone_No: "",
    department: "",
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var option = {
      Authorization: "Berrer" + authToken,
    };
    GetByProblemReports(params.id, option).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/problemReoprtAdmin");
      }
    });
  };
  const submitForm = () => {
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    if (
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      formData.email !== ""
    ) {
      UpdateProblemReports(params.id, formData, option).then((response) => {
        if (response.status) {
          setAlert({
            ...alert,
            success: "Data has been submitted successfully",
          });
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone_No: "",
            department: "",
            isActive: 1,
          });
          setLoader(false);
          setTimeout(() => {
            setAlert({ ...alert, success: "" });
            navigate("/problemReoprtAdmin");
          }, 2000);
        } else {
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 2000);
        }
      });
    } else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <Grid container spacing={2}>
          {alert.error !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{alert.error}</Alert>
            </Grid>
          )}
          {alert.success !== "" && (
            <Grid item xs={12}>
              <Alert severity="success">{alert.success}</Alert>
            </Grid>
          )}
          <Grid item xs={2.5}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="firstName">
                First Name
              </InputLabel>

              <TextField
                name="firstName"
                fullWidth
                value={formData.firstName}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="lastName">
                Last Name
              </InputLabel>

              <TextField
                name="lastName"
                fullWidth
                value={formData.lastName}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="email">
                Email
              </InputLabel>

              <TextField
                name="email"
                type="email"
                fullWidth
                value={formData.email}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="phone_No">
                Phone No
              </InputLabel>

              <TextField
                type="tel"
                name="phone_No"
                fullWidth
                value={formData.phone_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="department">
                Department
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.department}
                onChange={(e) => {
                  setFormData({ ...formData, department: e.target.value });
                }}
                // onChange={handleIsActive}
              >
                <MenuItem value={"QA"}>QA</MenuItem>
                <MenuItem value={"Engineering"}>Engineering</MenuItem>
                <MenuItem value={"Administrative"}>Administrative</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={"form-group"}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Status
              </InputLabel>
              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.isActive}
                onChange={(e) => {
                  setFormData({ ...formData, isActive: e.target.value });
                }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Blocked</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={loader}
                onClick={() => submitForm()}
                variant="contained"
              >
                Update Admin
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate(-1)}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default EditProblemReportAdmin;
