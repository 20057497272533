import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import {
  DeleteFile,
  GetSystemUsageById,
  SystemUsageDetail,
  SystemUsageFile,
  UpdateFile,
} from "../../../services/organization.handlers";
import moment from "moment";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
var filename = moment().format("YYYYMMDDHHmmss");

function FilesHaveError() {
  const { state } = useLocation();
  const navigate = useNavigate();
  // const param = useParams();
  const pdfRef = React.useRef(null);
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState("");
  const [renameError, setRenameError] = React.useState("");
  const [clickedButton, setClickedButton] = React.useState("");
  const authToken = useSelector((state) => state.user.authToken);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [deleteSuccess, setDeleteSuccess] = React.useState("");

  const [formData, setFormData] = React.useState({
    id: "",
    system_Id: "",
    treatment_No: "",
    customer_Id: null,
    original_File_Name: "",
    new_File_Name: "",
    created_Date: "",
    modified_Date: "",
    startDateOfWeek: null,
    endDateOfWeek: null,
    note: "",
    date: null,
    filePath: "",
    countOfSystemIds: "",
    file_Treatment_Date: null,
    treatments_performed: "",
  });
  const tableHead = [
    // "System Ser. No.",
    "Date",
    // "Customer Id",
    // "Treatment Count",
    "File Name",
    "Action",
    "Action",
    "Action",
  ];

  const ReportType = ["All", "Monthly", "Weekly", "Daily"];
  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    SystemUsageDetail("00", page + 1, rowsPerPage, options).then((response) => {
      if (response.status) {
        setData(response.data.data);
        setAllData(response.data);
      }
    });
  };

  const downloadFile = (filePath, fileName, id) => {
    setLoader(true);
    setClickedButton(id);
    var options = {
      Authorization: "Bearer " + authToken,
    };

    if (filePath) {
      SystemUsageFile(filePath, options).then((response) => {
        if (response.status) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "SystemUsage";
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoader(false);
          setClickedButton("");
        } else {
          setLoader(false);
          setClickedButton("");
          // navigate("/reports/systemUsageReports");
        }
      });
    } else {
      setError("File Not Available");
      setLoader(false);
      setClickedButton("");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  // get week day, monday and sunday date by passing any of the date between week
  const getDateRange = (prop) => {
    const day = prop.getDay();
    const diff = prop.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(prop.setDate(diff));

    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    return `${moment(monday).format("YYYY/MM/DD")} - ${moment(sunday).format(
      "YYYY/MM/DD"
    )}`;
  };

  const handleClose = () => {
    setOpen(false);
    setRenameError("");
    setFormData({
      id: "",
      system_Id: "",
      treatment_No: "",
      customer_Id: null,
      original_File_Name: "",
      new_File_Name: "",
      created_Date: "",
      modified_Date: "",
      startDateOfWeek: null,
      endDateOfWeek: null,
      note: "",
      date: null,
      filePath: "",
      countOfSystemIds: "",
      file_Treatment_Date: null,
      treatments_performed: "",
    });
  };

  const handleRename = (id) => {
    setOpen(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystemUsageById(id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      }
    });
  };

  const handleChangeRename = (e) => {
    setFormData({ ...formData, new_File_Name: e.target.value });
  };

  const submitRename = () => {
    setSuccess("");
    setRenameError("");
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (formData.new_File_Name === "") {
      setRenameError("Enter File Name");
      setTimeout(() => {
        setRenameError("");
      }, 2000);
      return;
    }
    UpdateFile(formData.id, formData, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setSuccess("Data has been updated successfully");
        setFormData({
          id: "",
          system_Id: "",
          treatment_No: "",
          customer_Id: null,
          original_File_Name: "",
          new_File_Name: "",
          created_Date: "",
          modified_Date: "",
          startDateOfWeek: null,
          endDateOfWeek: null,
          note: "",
          date: null,
          filePath: "",
          countOfSystemIds: "",
          file_Treatment_Date: null,
          treatments_performed: "",
        });
        setTimeout(() => {
          setSuccess("");
          setOpen(false);
        }, 2000);
        fetchData();
      }
    });
  };

  const handleDelete = (id) => {
    setDeleteSuccess("");
    var options = {
      Authorization: "Bearer " + authToken,
    };
    DeleteFile(id, options).then((response) => {
      if (response.status) {
        setDeleteSuccess("Data has been deleted successfully");
        setTimeout(() => {
          setDeleteSuccess("");
          fetchData();
        }, 2000);
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minHeight: 200,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <DashboardLayout>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid container spacing={2}>
            {renameError !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{renameError}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              {success != "" && <Alert severity="success">{success}</Alert>}
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="component">
                  Rename
                </InputLabel>
                <TextField
                  name="component"
                  fullWidth
                  value={formData.new_File_Name}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeRename}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"} style={{ marginTop: 15 }}>
                <Button
                  disabled={loader}
                  onClick={() => submitRename()}
                  variant="contained"
                >
                  Submit
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => handleClose()}
                    color="grey"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {deleteSuccess != "" && (
        <Grid item xs={12}>
          <Alert severity="success">{deleteSuccess}</Alert>
        </Grid>
      )}
      <h3>Error Files </h3>
      <Grid container spacing={2}>
        {error != "" && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
      <div ref={pdfRef} id={"html-pdf"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover key={index}>
                      {/* <TableCell>{item.system_Id}</TableCell> */}
                      {/* <TableCell>{item.customer_Id}</TableCell> */}
                      <TableCell>
                        {moment(item.created_Date).format("YYYY/MM/DD")}
                      </TableCell>

                      <TableCell>{item.new_File_Name}</TableCell>
                      {/* <TableCell>{item.treatments_performed}</TableCell> */}
                      <TableCell>
                        <Button
                          disabled={clickedButton === item.id && loader}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            downloadFile(
                              item.filePath,
                              item.new_File_Name,
                              item.id
                            )
                          }
                        >
                          Download File
                          {clickedButton === item.id && loader && (
                            <Loader color={"#fff"} />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          // disabled={loader}
                          variant="contained"
                          color="primary"
                          onClick={() => handleRename(item.id)}
                        >
                          Rename file
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          // disabled={loader}
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete File
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan={4} align={"center"}>
                    No Records Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={allData.totalRecords || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default FilesHaveError;
