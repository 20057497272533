import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../Components/Loader";
import { CreatePatient } from "../../../services/patient.handlers";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddPatient = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    patient_Id: "",
    healthCard_Id: "",
    patient_Name: "",
    city: "",
    state: "",
    zip: "",
    country: _getCountry()[1],
  });
  const [formErrors, setFormErrors] = React.useState({
    patient_Id: "",
    healthCard_Id: "",
    patient_Name: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const setPatientID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        patient_Id: "Patient ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        patient_Id: "",
      });
    }
    setFormData({ ...formData, patient_Id: value });
  };

  const setHealthCardID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        healthCard_Id: "Health Card ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        healthCard_Id: "",
      });
    }
    setFormData({ ...formData, healthCard_Id: value });
  };

  const setPatientName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        patient_Name: "Patient Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        patient_Name: "",
      });
    }
    setFormData({ ...formData, patient_Name: value });
  };

  const submitForm = () => {
    if (!formData.patient_Id) {
      setPatientID(formData.patient_Id);
      return;
    }
    if (!formData.healthCard_Id) {
      setHealthCardID(formData.healthCard_Id);
      return;
    }
    if (!formData.patient_Name) {
      setPatientName(formData.patient_Name);
      return;
    }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreatePatient(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          patient_Id: "",
          healthCard_Id: "",
          patient_Name: "",
          city: "",
          state: "",
          zip: "",
          country: _getCountry()[1],
        });
        setFormErrors({
          patient_Id: "",
          healthCard_Id: "",
          patient_Name: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Patient</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="patient_Id">
                  Patient ID
                </InputLabel>
                <TextField
                  id="patient_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.patient_Id}
                  onChange={(e) => setPatientID(e.target.value)}
                  error
                  helperText={formErrors.patient_Id}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="healthCard_Id">
                  Health ID
                </InputLabel>
                <TextField
                  id="healthCard_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.healthCard_Id}
                  onChange={(e) => setHealthCardID(e.target.value)}
                  error
                  helperText={formErrors.healthCard_Id}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="patient_Name">
                  Patient Name
                </InputLabel>
                <TextField
                  id="patient_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.patient_Name}
                  onChange={(e) => setPatientName(e.target.value)}
                  error
                  helperText={formErrors.patient_Name}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({
                      ...formData,
                      country: JSON.stringify(value),
                    });
                    setCsc({ ...csc, state: _getCountryStates(value) });
                  }}
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Provence Code"}
                </InputLabel>
                <TextField
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Patient
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/patient")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddPatient;
