import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetInvCategoryTypes = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.InvCategoryType.CRUD}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateInvCategoryType = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.InvCategoryType.CRUD}`,
    payload,
    {
      headers, 
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetInvCategoryType = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.InvCategoryType.CRUD}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateInvCategoryType = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.InvCategoryType.CRUD}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
