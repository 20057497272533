import React from "react";
import DATA from "../../../Data/DATA.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { TableFooter } from "@mui/material";
import { GetSystemReport } from "../../../services/organization.handlers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoImage from "../../../assets/images/Logo-header.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { jsonCheck } from "../../../constants";
var filename = moment().format("YYYYMMDDHHmmss");

const System_Report = () => {
  const pdfRef = React.useRef(null);
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const authToken = useSelector((state) => state.user.authToken);

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystemReport(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  // console.log(data);
  const _exportPdf = () => {
    const content = pdfRef.current;

    const doc = new jsPDF("landscape");
    doc.html(content, {
      callback: function (doc) {
        var pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();

          const footer = `Page ${i} of ${pageCount}`;
          doc.setFontSize(10);

          let paragraph =
            "All information contained herein is confidential and proprietary. All design, manufacturing, use, reproduction, & sales rights are expressly reserved. Communication of this information to others is prohibited.";
          var lines = doc.splitTextToSize(paragraph, 310 - 15 - 15);
          doc.text(lines, 10, pageHeight - 20, { baseline: "bottom" });
          doc.rect(5, pageHeight - 27, pageWidth - 10, 15);
          doc.text(
            footer,
            pageWidth / 2 - doc.getTextWidth(footer) / 2,
            pageHeight - 5,
            { baseline: "bottom" }
          );
        }
        // window.open(doc.output("bloburl"));
        doc.save(filename + "-report.pdf");
      },
      margin: [5, 10, 40, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 270, //target width in the PDF document
      windowWidth: 1200,
      // html2canvas: { scale: 0.257 },
    });
  };

  return (
    <DashboardLayout>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "flex-end" }}
      >
        <Button onClick={() => _exportPdf()} variant="contained">
          Export PDF
        </Button>
      </Stack>
      <div ref={pdfRef} id={"html-pdf"}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            className={"span-table"}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={"3"} scope="row">
                  <img src={LogoImage} alt="Macumira" title={"Macumira"} />
                  <div>
                    <p>
                      Suite #507 234 Pine St. SW <br />
                      Toronto ON S74 8MN
                      <br />
                      Canada
                    </p>
                    <p>
                      <strong>Contact No: </strong>999-999-9999
                    </p>
                  </div>
                </TableCell>
                <TableCell colSpan={"3"} align="right" scope="row">
                  <img
                    src={LogoImage}
                    alt="Macumira"
                    title={"Macumira"}
                    style={{ opacity: 0 }}
                  />
                  <p>
                    <strong>Date: </strong>
                    {moment().format("MM-DD-YYYY")}
                    <br />
                    <strong>Time: </strong>
                    {moment().format("hh:mm A")}
                    <br />
                    <br />
                  </p>

                  <p>
                    <strong>Report #: </strong>
                    {filename}
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={"6"} align={"center"}>
                  SYSTEM REPORT
                </TableCell>
              </TableRow>
            </TableHead>
            {data.map((item, index) => (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span>System ID</span>
                    </TableCell>
                    <TableCell>
                      <span>Generator ID</span>
                    </TableCell>
                    <TableCell>
                      <span>Headset ID</span>
                    </TableCell>
                    <TableCell>
                      <span>Tablet ID</span>
                    </TableCell>
                    {/* <TableCell>
                      <span>Store ID</span>
                    </TableCell>
                    <TableCell>
                      <span>Doctor ID</span>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {data.map((item, index) => (
                <> */}
                  <TableRow key={"system-report-" + index}>
                    <TableCell>
                      <span>{item.system_Id} </span>
                    </TableCell>
                    <TableCell>
                      <span>{item.gen_Id} </span>
                    </TableCell>
                    <TableCell>
                      <span>{item.hdset_Id} </span>
                    </TableCell>
                    <TableCell>
                      <span>{item.tablet_Id}</span>
                    </TableCell>
                    {/* <TableCell>
                      <span>{item.store_Id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{item.doctor_Id}</span>
                    </TableCell> */}
                  </TableRow>
                  {item.session_Report.length > 0 && (
                    <TableRow key={"session-report-" + index + item.id}>
                      <TableCell colSpan={6}>
                        <Table
                          aria-labelledby="tableTitle"
                          size={"medium"}
                          className={"span-table"}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>
                                <span>Treatment Date</span>
                              </TableCell>
                              <TableCell>
                                <span>Treatment Start Time</span>
                              </TableCell>
                              <TableCell>
                                <span>Treatment End Time</span>
                              </TableCell>
                              <TableCell>
                                <span>Number Session</span>
                              </TableCell>
                              <TableCell>
                                <span>Health Card Number</span>
                              </TableCell>
                              <TableCell>
                                <span>Administrator Name</span>
                              </TableCell>
                              {/* <TableCell>
                                <span>Technician Id</span>
                              </TableCell>
                              <TableCell>
                                <span>Patient Id</span>
                              </TableCell> */}
                              <TableCell>
                                <span>Resistance Data</span>
                              </TableCell>
                              <TableCell>
                                <span>Actual Level</span>
                              </TableCell>
                              <TableCell>
                                <span>Amplitude Data</span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.session_Report.map((store, i) => (
                              <TableRow key={"report" + index + i + store.id}>
                                <TableCell></TableCell>
                                <TableCell>
                                  <span>
                                    {moment(store.treatment_Date).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span>
                                    {moment(store.treatment_Start_Time).format(
                                      "HH:mm"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span>
                                    {moment(store.treatment_End_Time).format(
                                      "HH:mm"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span>{store.number_Sessions}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{store.health_Card_Number}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{store.administrator_name}</span>
                                </TableCell>
                                {/* <TableCell></TableCell>
                                <TableCell></TableCell> */}
                                <TableCell>
                                  <span>{store.resistance}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{store.actual_Level}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{store.amplitude}</span>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            ))}
            {/* </TableBody> */}
          </Table>
        </TableContainer>
      </div>
    </DashboardLayout>
  );
};

export default System_Report;
