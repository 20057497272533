import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetComponents = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.COMPONENTS.CRUD_COMPONENTS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateComponent = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.COMPONENTS.CRUD_COMPONENTS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetComponent = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.COMPONENTS.CRUD_COMPONENTS}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateComponent = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.COMPONENTS.CRUD_COMPONENTS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
