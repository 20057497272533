import * as React from "react";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { AddProblemReports } from "../../../services/problem.report.admin.handlers";

function AddProblemReportAdmin(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);

  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_No: "",
    department: "",
    isActive: 1,
  });

  React.useEffect(() => {}, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    if (
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      formData.email !== ""
    ) {
      AddProblemReports(formData, option).then((response) => {
        if (response.status) {
          setAlert({
            ...alert,
            success: "Data has been submitted successfully",
          });
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone_No: "",
            department: "",
            isActive: 1,
          });
          setLoader(false);
          setTimeout(() => {
            setAlert({ ...alert, success: "" });
            prop.getData();
          }, 3000);
        } else {
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 3000);
        }
      });
    } else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {alert.error !== "" && (
          <Grid item xs={12}>
            <Alert severity="error">{alert.error}</Alert>
          </Grid>
        )}
        {alert.success !== "" && (
          <Grid item xs={12}>
            <Alert severity="success">{alert.success}</Alert>
          </Grid>
        )}
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="firstName">
              First Name
            </InputLabel>

            <TextField
              name="firstName"
              fullWidth
              value={formData.firstName}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="lastName">
              Last Name
            </InputLabel>

            <TextField
              name="lastName"
              fullWidth
              value={formData.lastName}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="email">
              Email
            </InputLabel>

            <TextField
              name="email"
              type="email"
              fullWidth
              value={formData.email}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="phone_No">
              Phone No
            </InputLabel>

            <TextField
              type="tel"
              name="phone_No"
              fullWidth
              value={formData.phone_No}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>

        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="department">
              Department
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={formData.department}
              onChange={(e) => {
                setFormData({ ...formData, department: e.target.value });
              }}
              // onChange={handleIsActive}
            >
              <MenuItem value={"QA"}>QA</MenuItem>
              <MenuItem value={"Engineering"}>Engineering</MenuItem>
              <MenuItem value={"Administrative"}>Administrative</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={loader}
            variant="contained"
            style={{
              marginTop: 16,
              height: "40px",
              gap: "5px",
              alignItems: "center",
            }}
            onClick={submitForm}
          >
            <AddIcon /> Add
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AddProblemReportAdmin;
