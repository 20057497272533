import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetAbarlaParts = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.AbarlaPartREF.CRUD}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateAbarlaPart = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.AbarlaPartREF.CRUD}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAbarlaPart = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.AbarlaPartREF.CRUD}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updateAbarlaPart = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.AbarlaPartREF.CRUD}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
