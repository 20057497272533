import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetOrgEmployees } from "../../../services/org.employee.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GetOrganizations } from "../../../services/organization.handlers";
import { GetStores } from "../../../services/store.handlers";
const TableRowData = [
  "ID",
  "Name",
  "Store",
  "Email",
  "Contact No",
  "Type",
  "Status",
  "Action",
];

const OrgEmployee = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    type: "All",
    organisation: "All",
  });
  const [organizations, setOrganization] = React.useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [storeData, setStoreData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
    fetchStore();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrgEmployees(options).then((response) => {
      if (response.status) {
        fetchOrganisations();
        setData(response.data);
        setTotalData(response.data);
      }
    });
  };

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };

  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setStoreData(response.data);
      }
    });
  };

  const storeName = (item) => {
    const res = storeData.find((e) => e.id == item);
    return res?.store_Name;
  };
  const onChangeOrganisation = (value) => {
    setFilter({ ...filter, organisation: value });
    if (value == "All" && filter.type == "All") {
      setData(totalData);
      return;
    } else if (value == "All" && filter.type != "All") {
      const result = totalData.filter((item) => item.type == filter.type);
      setData(result);
      return;
    } else if (value != "All" && filter.type != "All") {
      const result = totalData.filter(
        (item) => item.organisation_Id == value && item.type == filter.type
      );
      setData(result);
      return;
    }
    const result = totalData.filter((item) => item.organisation_Id == value);
    setData(result);
  };

  const onChangeType = (value) => {
    setFilter({ ...filter, type: value });
    if (value == "All" && filter.organisation == "All") {
      setData(totalData);
      return;
    } else if (value == "All" && filter.organisation != "All") {
      const result = totalData.filter(
        (item) => item.organisation_Id == filter.organisation
      );
      setData(result);
      return;
    } else if (value != "All" && filter.organisation != "All") {
      const result = totalData.filter(
        (item) =>
          item.type == value && item.organisation_Id == filter.organisation
      );
      setData(result);
      return;
    }
    const result = totalData.filter((item) => item.type == value);
    setData(result);
  };

  return (
    <DashboardLayout>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "end" }}
      >
        <div
          className={"form-group"}
          style={{ margin: "0", marginRight: 10, width: "25%" }}
        >
          <Select
            fullWidth
            className={"form-input"}
            id="select-active"
            defaultValue={"All"}
            onChange={(e) => {
              onChangeOrganisation(e.target.value);
            }}
          >
            <MenuItem value="All">All Organization</MenuItem>
            {organizations.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.org_Name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"form-group"} style={{ margin: "0", width: "15%" }}>
          <Select
            fullWidth
            className={"form-input"}
            id="select-active"
            defaultValue={"All"}
            onChange={(e) => {
              onChangeType(e.target.value);
            }}
          >
            <MenuItem value="All">All Type</MenuItem>
            <MenuItem value={"Doctor"}>Doctor</MenuItem>
            <MenuItem value={"Technician"}>Technician</MenuItem>
          </Select>
        </div>
        <Button
          onClick={() => navigate("/add_org_employee")}
          variant="contained"
        >
          <AddIcon /> Add New Org Employee
        </Button>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    //   selected={isItemSelected}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{storeName(item?.store_Id)}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.contact_Number}</TableCell>
                    <TableCell>{item.type}</TableCell>

                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          navigate("/updateorg_employee/" + item.id)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={7} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default OrgEmployee;
