import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = ({ color }) => {
  return (
    <CircularProgress
      size={25}
      thickness={4}
      style={{ zIndex: 999999 }}
      sx={{
        color: color,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px",
      }}
    />
  );
};

export default Loader;
