// export const API_PATH = "https://macumirabackendapi.azurewebsites.net/api/";
export const API_PATH = "https://macumira.api.abarlatech.com/api/";

export const authToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNYWN1TWlyYVRva2VuIiwianRpIjoiYzIwNWE2MmItMzEyNS00YjY3LWEzNjQtYzIzNjI4OWY1MjNkIiwiaWF0IjoiOC8yNi8yMDIyIDk6MDg6MTEgQU0iLCJFbXBsb3llZUlkIjoiMSIsIkVtcGxveWVlTmFtZSI6IkFsZm9uc28gU2FsYXphciIsIkVtcGxveWVlRW1haWwiOiJhbC5zYWxhemFyQGFiYXJsYXRlY2guY29tIiwiZXhwIjoxNjYxNTQwODkxLCJpc3MiOiJNYWN1TWlyYVNlcnZlciIsImF1ZCI6Ik1hY3VNaXJhQ2xpZW50In0.Xh_zzcNj7w_9YA5eC_z-YOj1Yhakqz1Rj9lcqve7Z1s";
export const API_ROUTE = {
  AUTH: {
    FORGOT_PASSWORD: "ForgotPassword",
    CHECK_HASH: "ForgotPassword/CheckHash",
    FORGOT_CHANGE_PASSWORD: "ForgotPassword/ChangePasswordUsingHash",
    LOGGED_IN: "Signin/Login",
    CHANGE_PASSWORD: "ForgotPassword/ChangePassword",
  },
  ORGANIZATIONS: {
    CRUD_ORGANIZATIONS: "Organizations",
    REPORTS: "Reports",
    STORE_REPORT: "Reports/Store_Report",
    STORE_ORG_REPORT: "Reports/Org_Store_Report",
    ORGANIZATION_STORE_REPORT: "Reports/Organisation_Store_System_Report",
    STORE_DOCTOR_REPORT: "Reports/Store_Doctor_Report",
    ORGANIZATION_BILLING_REPORT: "Reports/Org_Billing_Report",
    SYSTEM_REPORT: "Reports/System_Report",
    DEVICE_REPORT: "Reports/Device_Report",
    GET_TREATMENT_DATA: "Inv_Treament_Usage_Doc",
    REMOVE_STORE: "Organizations/de_associateOrganizationStore",
    MAP_STORE_WITH_ORG: "Organizations/MapStoreWithOrg",
    SYSTEM_USAGE_FILE_DOWNLOAD: "Inv_Treament_Usage_Doc/download",
    SYSTEM_USAGE_FILTERED: "Inv_Treament_Usage_Doc/filtered-data",
    SYSTEM_USAGE_DETAIL: "Inv_Treament_Usage_Doc/display-filtered-data",
    SYSTEM_USAGE_ALL_FILE_DOWNLOAD:
      "/Inv_Treament_Usage_Doc/download-filtered-files",
  },
  EMPLOYEES: {
    CRUD_EMPLOYEES: "Employees",
  },
  PATIENTS: {
    CRUD_PATIENTS: "Patients",
  },
  DOCTORS: {
    CRUD_DOCTORS: "Doctors",
  },
  AbarlaPartREF: {
    CRUD: "Inv_Abarla_Part_Ref",
  },
  InvLotLineDesc: {
    CRUD: "Inv_Lot_Line_Desc",
  },
  InvAssemblyCategoryType: {
    CRUD: "Inv_Assembly_Category_Type",
  },
  InvCategoryType: {
    CRUD: "Inv_Category_Type",
  },
  InvPartCategoryType: {
    CRUD: "Inv_Part_Category_Type",
  },
  COMPONENTS: {
    CRUD_COMPONENTS: "Components",
  },
  ORG_EMPLOYEE: {
    CRUD_ORG_EMPLOYEE: "Org_Employee",
    ADD_EMPLOYEE_TO_SYSTEM: "Org_Employee/AddEmployeeToSystem",
    GET_ORG_EMPLOYEES_AND_SYSTEM: "Org_Employee/GetEmployeeAndSystems",
  },
  TECHNICIANS: {
    CRUD_TECHNICIANS: "Technicians",
    GET_STR_TECH: "Get_Str_Technician",
  },
  TABS_TO_SERVER: {
    CRUD_SERVERS: "TabToServers",
  },
  EMPLOYEE_LOGS: {
    CRUD_LOGS: "EmployeeLogs",
  },
  STORES: {
    CRUD_STORES: "stores",
    STORE_ORGANISATION: "Stores/StoreForOrganisationId?OrganisationId",
    ADD_SYSTEM_TO_STORE: "Stores/AddSystemToStore",
    REMOVE_SYSTEM: "Stores/de_associateStoreSystem",
    STORE_BILLING: "Stores/systeminvoice",
  },
  MMSYSTEMS: {
    CRUD_SYSTEMS: "MMSystems",
  },
  SYSTEMS: {
    CRUD_SYSTEMS: "Inv_System",
    UPLOAD_DOC: "Inv_System/upload",
    VIEW_DOC: "Spec_Doc/getdoc",
    PDF_DOWNLOAD: "Inv_System/download",
    PART_NUMBER: "Part_Number",
    // ADD_BULK_PART_NUMBER: "Part_Number/BulkUploadPart_Number",
    ADD_BULK_PART_NUMBER: "Inv_Part_Number/BulkUploadPart_Number",
    REV_NUMBER: "Rev_Number",
    ADD_SYSTEM: "Inv_System/add_system",
    GET_GENERATOR: "Inv_Generator",
    ADD_GENERATOR: "Inv_Generator/add_generator",
    GEN_PDF_DOWNLOAD: "Inv_Generator/download",
    GET_HEADSET: "Inv_Headset",
    ADD_HEADSET: "Inv_Headset/add_headset",
    HEADSET_PDF_DOWNLOAD: "Inv_Headset/download",
    GET_TABLET: "Inv_Tablet",
    ADD_TABLET: "Inv_Tablet/add_tablet",
    TABLET_PDF_DOWNLOAD: "Inv_Tablet/download",
    GET_GENERATOR_PCB: "Inv_Generator_PCB",
    ADD_GENERATOR_PCB: "Inv_Generator_PCB/add_generator_pcb",
    GEN_PCB_PDF_DOWNLOAD: "Inv_Generator_PCB/download",
    GET_HEADSET_PCB: "Inv_Headset_PCB",
    ADD_HEADSET_PCB: "Inv_Headset_PCB/add_headset_pcb",
    HDSET_PCB_PDF_DOWNLOAD: "Inv_Headset_PCB/download",
    CRUD_CABLE: "Inv_Cables",
    CRUD_PART_NUMBER: "Inv_Part_Number",
    CRUD_HARNESS: "Inv_Harness",
  },
  MMDEVICES: {
    CRUD_DEVICES: "MMDevices",
    DEVICE_TYPE: "DeviceTypes",
  },
  INVOICE_RULES: {
    CRUD_INVOICE: "Invoice_Rules",
  },
  BILLING_CALCULTATION: {
    CRUD_BILLING: "BillingCalculation",
    GENERATE_BILLING: "Inv_System/systeminvoice",
  },
  BILLING_SUMMARY: {
    CRUD_BILLING: "BillingSummary",
  },
  PROBLEM_REPORT_ADMIN: {
    CRUD_PROBLEM_REPORT: "Inv_AdminProblem",
    CRUD_RREPORT_FORM: "Inv_ReportProblem",
    PROBLEM_REPORT_COUNT: "Inv_ReportProblem/get_reportproblem_count",
  },
  EXCEL_FILE: {
    // CRUD_EXCEL_FILE: "/Inv_Components",
    CRUD_EXCEL_FILE: "/Inv_Harness",
    // UPLOAD_EXCEL_FILE: "/Inv_Components/UploadExcelFile",
    UPLOAD_EXCEL_FILE: "/Inv_Harness/upload",
  },
  PRIVATE_USER: {
    CRUD_PRIVATE_USER: "/api/PrivateAPIs",
  },
};
