import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetSystem } from "../../../services/systems.handlers";
import { TableHead } from "@mui/material";
import { Link } from "react-router-dom";

const GenMech = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  
  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
              <TableRow colspan="3">
              <TableCell colspan="3">Gen Mech Details </TableCell>
               
              </TableRow>
              <TableRow colspan="3">
              <TableCell>Gen Mech </TableCell>
              <TableCell>Lot 1 </TableCell>
              <TableCell>Sub Assembly 2  </TableCell>
               
              </TableRow>
          </TableHead>
          <TableBody>
                  <TableRow
                    hover
                  >
                  
                    <TableCell>{"Enclosure"}</TableCell>
                    <TableCell>{"Top"}</TableCell>
                    <TableCell>{"Bottom"}</TableCell>
                  </TableRow>

                  <TableRow
                    hover
                  >
                  
                    <TableCell>{"Screws"}</TableCell>
                    <TableCell>{"P1-1/4"}</TableCell>
                    <TableCell>{"P2-1/4"}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                  >
                  
                    <TableCell>{"Washer"}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default GenMech;
