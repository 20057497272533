import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetEmployeesLogs = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.EMPLOYEE_LOGS.CRUD_LOGS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetEmployeesLogsByID = async (headers, id) => {
  return await AxiosInstance.get(
    `${API_ROUTE.EMPLOYEE_LOGS.CRUD_LOGS}/EmployeeLogsForEmployeeId?Employee_ID=${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const CreateEmployeeLogs = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.EMPLOYEE_LOGS.CRUD_LOGS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
