import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, Box, InputLabel, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import { GetOrganizations } from "../../../services/organization.handlers";
import {
  CreateStores,
  GetStore,
  updateStore,
} from "../../../services/store.handlers";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate, useParams } from "react-router";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { GetSystems } from "../../../services/systems.handlers";

const EditStores = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [systemData, setSystemData] = React.useState([]);
  const [system_Ids, setSystemIds] = React.useState([]);
  const [selectedSystemId, setSelectedSystemId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = React.useState("");
  const [formData, setFormData] = React.useState({
    store_Id: "",
    org_Id: "",
    store_Name: "",
    store_Email: "",
    store_Number: "",
    store_Address_1: "",
    store_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    systemIds: [],
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    store_Id: "",
    org_Id: "",
    store_Name: "",
    store_Email: "",
    store_Number: "",
    store_Address_1: "",
    store_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    problemText: "",
  });

  React.useEffect(() => {
    fetchOrganisations();
    fetchStore();
    fetchSystems();
  }, []);

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };
  const fetchStore = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStore(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setSystemIds(response.data.systemIds);
        setCsc({
          ...csc,
          state: jsonCheck(response.data.country)
            ? _getCountryStates(JSON.parse(response.data.country))
            : [],
          city: jsonCheck(response.data.state)
            ? _getCityState(JSON.parse(response.data.state))
            : [],
        });
      } else {
        navigate("/stores");
      }
    });
  };
  const fetchSystems = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystems(options).then((response) => {
      if (response.status) {
        setSystemData(response.data);
      }
    });
  };
  const setOrgID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        org_Id: "Organization ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        org_Id: "",
      });
    }
    setFormData({ ...formData, org_Id: "" + value });
  };

  const setStoreID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Id: "Store ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Id: "",
      });
    }
    setFormData({ ...formData, store_Id: value });
  };

  const setStoreName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        store_Name: "Store Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Name: "",
      });
    }
    setFormData({ ...formData, store_Name: value });
  };

  const setStoreEmail = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Email: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          store_Email: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          store_Email: "Invalid email id",
        });
      }
    }
    setFormData({ ...formData, store_Email: value });
  };

  const setStoreNumber = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        store_Number: "Store Number cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Number: "",
      });
    }
    setFormData({ ...formData, store_Number: value });
  };
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setFormData({
  //     ...formData,
  //     systemIds: typeof value === "string" ? value.split(",") : value,
  //   });
  //   // setPersonName(
  //   //   // On autofill we get a stringified value.
  //   //   typeof value === "string" ? value.split(",") : value
  //   // );
  // };
  const handleChange = (event) => {
    setSelectedSystemId(event.target.value);
    if (event.target.value !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleClose = () => {
    setSelectedSystemId("");
    setOpen(false);
    setFormErrors({ ...formErrors, problemText: "" });
    setRemark("");
  };
  const handleProblemData = (e) => {
    if (e.target.value === "") {
      setFormErrors({
        ...formErrors,
        problemText: "Problem Text cannot be blank",
      });
    } else {
      setFormErrors({ ...formErrors, problemText: "" });
    }
    setRemark(e.target.value);
  };

  const submitForm = () => {
    if (!formData.store_Id) {
      setStoreID(formData.store_Id);
      return;
    }
    if (!formData.org_Id) {
      setOrgID(formData.org_Id);
      return;
    }

    if (!formData.store_Name) {
      setStoreName(formData.store_Name);
      return;
    }
    if (!formData.store_Email) {
      setStoreEmail(formData.store_Email);
      return;
    }
    if (!formData.store_Number) {
      setStoreNumber(formData.store_Number);
      return;
    }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateStore(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          store_Id: "",
          org_Id: "",
          store_Name: "",
          store_Email: "",
          store_Number: "",
          store_Address_1: "",
          store_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/stores");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3 style={{ marginTop: 0 }}>
            Edit Store : Store ID-{formData.store_Id}
          </h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Id">
                  Store ID
                </InputLabel>
                <TextField
                  id="store_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Id}
                  onChange={(e) => setStoreID(e.target.value)}
                  error
                  helperText={formErrors.store_Id}
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Organization
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.org_Id}
                  onChange={(e) => {
                    setOrgID(e.target.value);
                  }}
                  error
                  helperText={formErrors.org_Id}
                >
                  {organizations.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.org_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Name">
                  Store Name
                </InputLabel>
                <TextField
                  id="store_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Name}
                  onChange={(e) => setStoreName(e.target.value)}
                  error
                  helperText={formErrors.store_Name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  onChange={(event, value) => {
                    setFormData({
                      ...formData,
                      country: value ? JSON.stringify(value) : "",
                    });
                    setCsc({ ...csc, state: _getCountryStates(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Email">
                  Store Email
                </InputLabel>
                <TextField
                  id="store_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Email}
                  onChange={(e) => setStoreEmail(e.target.value)}
                  error
                  helperText={formErrors.store_Email}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Address_1">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 1"
                    : "Additional Address Info"} */}
                  Address 1
                </InputLabel>
                <TextField
                  id="store_Address_1"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Address_1}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      store_Address_1: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Number">
                  Store Number
                </InputLabel>
                <TextField
                  id="store_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Number}
                  onChange={(e) => setStoreNumber(e.target.value)}
                  error
                  helperText={formErrors.store_Number}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store_Address_2">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 2"
                    : "Store Civic Address"} */}
                  Address 2
                </InputLabel>
                <TextField
                  id="store_Address_2"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.store_Address_2}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      store_Address_2: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="system_Id">
                  System Id
                </InputLabel>
                <TextField
                  disabled
                  // id="store_Address_2"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.systemIds}
                  // onChange={(e) =>
                  //   setFormData({
                  //     ...formData,
                  //     store_Address_2: e.target.value,
                  //   })
                  // }
                />
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="genRev_No">
                  System Id
                </InputLabel>

                <Select
                  fullWidth
                  multiple
                  className={"form-input"}
                  id="select-active"
                  value={formData.systemIds}
                  onChange={handleChange}
                  label="Select System Id to remove"
                  error
                  helperText={formErrors.system_Sn}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {system_Ids.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                  {systemData.map((item) => {
                    return (
                      !item.storeId && (
                        <MenuItem key={item.system_Sn} value={item.system_Sn}>
                          {item.system_Sn}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </div>
            </Grid> */}

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  value={
                    jsonCheck(formData.state)
                      ? JSON.parse(formData.state)
                      : formData.state
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  value={
                    jsonCheck(formData.city)
                      ? JSON.parse(formData.city)
                      : formData.city
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Code"}
                </InputLabel>
                <TextField
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={8}>
              <Stack spacing={2} direction="row" style={{ marginTop: 18 }}>
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Store
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/stores")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditStores;
