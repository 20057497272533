import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetInvCategoryTypes } from "../../../services/InvCategoryType.handlers";
import Loader from "../../../Components/Loader";
import { Alert, Grid, InputLabel, Paper, TextField } from "@mui/material";
import { CreateInvCategoryType } from "../../../services/InvCategoryType.handlers";
const TableRowData = [
  // "Category Type Id",
  "Category<br/>Type",
  "Category Type<br/>Desc.",
  "Status",
  "Action",
];

const InvCategoryType = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    category_Type_Id: "",
    category_Type: "",
    category_Type_Description: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    category_Type_Id: "",
    category_Type: "",
    category_Type_Description: "",
  });
  const submitForm = () => {
    if (!formData.category_Type) {
      setFormErrors({ ...formErrors, category_Type: "Category Type required" })
      return;
    }
    if (!formData.category_Type_Description) {
      setFormErrors({ ...formErrors,category_Type:"", category_Type_Description: "Category Description required" })
      return;
    }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateInvCategoryType(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          category_Type_Id: "",
          category_Type: "",
          category_Type_Description: "",
          isActive: 1,
        });
        setFormErrors({
          category_Type_Id: "",
          category_Type: "",
          category_Type_Description: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetInvCategoryTypes(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  return (
    <DashboardLayout>
      {/* <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "end" }}
      >
        <Button
          onClick={() => navigate("/add_InvCategoryType")}
          variant="contained"
        >
          <AddIcon /> Add New Category Type
        </Button>
      </Stack> */}

      <Grid container spacing={2} style={{ marginBottom: "30px" }}>
        {error != "" && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        {success != "" && (
          <Grid item xs={12}>
            <Alert severity="success">{success}</Alert>
          </Grid>
        )}

        <Grid item xs={3}>
          <div className={"form-group"}>
            {/* <InputLabel className={"form-label"} htmlFor="category_Type">
              
            </InputLabel> */}
            <TextField
              id="category_Type"
              fullWidth
              className={"form-input"}
              placeholder="Category Type"
              variant="outlined"
              value={formData.category_Type}
              helperText={formErrors.category_Type}
              error
              onChange={(e) =>
                setFormData({ ...formData, category_Type: e.target.value })
              }
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={"form-group"}>
            {/* <InputLabel
              className={"form-label"}
              htmlFor="category_Type_Description"
            >
              
            </InputLabel> */}
            <TextField
              id="category_Type_Description"
              fullWidth
              className={"form-input"}
              variant="outlined"
              error
              value={formData.category_Type_Description}
              placeholder="Category Type Description"
              helperText={formErrors.category_Type_Description}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  category_Type_Description: e.target.value,
                })
              }
            />
          </div>
        </Grid>

        <Grid item xs={3} style={{ marginTop: "10px" }}>
          {/* <Stack
            spacing={2}
            direction="row"
            // style={{ justifyContent: "end" }}
          >
            <Button
              disabled={loader}
              onClick={() => submitForm()}
              variant="contained"
            >
              {" "}
              Add
              {loader && <Loader color={"#fff"} />}
            </Button>
          </Stack> */}
          <Stack spacing={2} direction="row" style={{ marginBottom: "30px" }}>
            <Button
              disabled={loader}
              onClick={() => submitForm()}
              variant="contained"
              style={{ height: "44px",gap: "10px", borderRadius: "16px",width:"100%",alignItems:"center" }}
            >
              <AddIcon /> Add
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (<div dangerouslySetInnerHTML={ {__html: item} } />)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    hover
                    // //   onClick={(event) => handleClick(event, row.name)}
                    // role="checkbox"
                    // //   aria-checked={isItemSelected}
                    // tabIndex={-1}
                    key={index}
                    //   selected={isItemSelected}
                  >
                    {/* <TableCell>{item.category_Type_Id}</TableCell> */}
                    <TableCell>{item.category_Type}</TableCell>
                    <TableCell>
                      {item.category_Type_Description}
                    </TableCell>

                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          navigate("/updateInvCategoryType/" + item.id)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={5} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default InvCategoryType;
