import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Loader from "../../../Components/Loader";

import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  GetInvHarnessById,
  UpdateInvHarness,
  // GetExcelById,
  // UpdateExcel,
} from "../../../services/excelFile.handlers";

function EditExcelFile() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    id: 0,
    note: "",
    system_Serial_No: "",
    // part_No: "",
    component_No: "",
    country_Code: "",
    variant: "",
    part_Description_Name: "",
    lot_No: "",
    revision: "",
    sl_No: "",
    // isActive: 0,
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    // GetExcelById(params.id, options).then((response) => {
    GetInvHarnessById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/subAssemblies");
      }
    });
  };

  const submitForm = () => {
    setLoader(true);
    var options = {
      Authorization: "Berrer" + authToken,
    };
    // updateInvPartNo(state, formData, options).then((response) => {
    // UpdateExcel(params.id, formData, options).then((response) => {
    UpdateInvHarness(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setTimeout(() => {
          setSuccess("");
          navigate("/subAssemblies");
        }, 2000);
        setLoader(false);
        setFormData({
          id: 0,
          note: "",
          system_Serial_No: "",
          // part_No: "",
          component_No: "",
          country_Code: "",
          variant: "",
          part_Description_Name: "",
          lot_No: "",
          revision: "",
          sl_No: "",
          // isActive: 0,
        });
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <Grid container spacing={2}>
          {error !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {success !== "" && (
            <Grid item xs={12}>
              <Alert severity="success">{success}</Alert>
            </Grid>
          )}
        </Grid>
        <h3>Edit Sub Assemblies</h3>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="system_Serial_No">
                System Sn
              </InputLabel>
              <TextField
                name="system_Serial_No"
                fullWidth
                value={formData.system_Serial_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="component_No">
                Component No
              </InputLabel>
              <TextField
                name="component_No"
                fullWidth
                value={formData.component_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="variant">
                Variant
              </InputLabel>
              <TextField
                name="variant"
                fullWidth
                value={formData.variant}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="country_Code">
                Country Code
              </InputLabel>
              <TextField
                name="country_Code"
                fullWidth
                value={formData.country_Code}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel
                className={"form-label"}
                htmlFor="part_Description_Name"
              >
                Part Name
              </InputLabel>
              <TextField
                name="part_Description_Name"
                fullWidth
                value={formData.part_Description_Name}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="lot_No">
                ECO/LOT
              </InputLabel>
              <TextField
                name="lot_No"
                fullWidth
                value={formData.lot_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="revision">
                Revision
              </InputLabel>
              <TextField
                name="revision"
                fullWidth
                value={formData.revision}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="sl_No">
                Serial No.
              </InputLabel>
              <TextField
                name="sl_No"
                fullWidth
                value={formData.sl_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <div className={"form-group"} style={{ marginTop: -3 }}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Status
              </InputLabel>
              <Select
                fullWidth
                name="isActive"
                className={"form-input"}
                id="select-active"
                value={formData.isActive}
                onChange={(e) => {
                  setFormData({ ...formData, isActive: e.target.value });
                }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Blocked</MenuItem>
              </Select>
            </div>
          </Grid> */}
          <Grid item xs={4}>
            <Stack spacing={2} direction="row" style={{ marginTop: "30px" }}>
              <Button
                disabled={loader}
                variant="contained"
                onClick={submitForm}
              >
                Update
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate("/subAssemblies")}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default EditExcelFile;
