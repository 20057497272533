import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreateInvAssemblyCategoryType } from "../../../services/InvAssemblyCategoryType.handlers";

const AddInvAssemblyCategoryType = () => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    assembly_Category_Type_Id: "",
    category_Type_Id: "",
    assembly_Category_Type: "",
    assembly_Category_Type_Description:"",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    assembly_Category_Type_Id: "",
    category_Type_Id: "",
    assembly_Category_Type: "",
    assembly_Category_Type_Description:"",
  });

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateInvAssemblyCategoryType(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          assembly_Category_Type_Id: "",
    category_Type_Id: "",
    assembly_Category_Type: "",
    assembly_Category_Type_Description:"",
          isActive: 1,
        });
        setFormErrors({
          assembly_Category_Type_Id: "",
    category_Type_Id: "",
    assembly_Category_Type: "",
    assembly_Category_Type_Description:"",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Assembly Category Type</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="assembly_Category_Type_Id">
                Assembly Category Type Id
                </InputLabel>
                <TextField
                  id="assembly_Category_Type_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.assembly_Category_Type_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      assembly_Category_Type_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="category_Type_Id">
                Category Type Id
                </InputLabel>
                <TextField
                  id="category_Type_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.category_Type_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category_Type_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="assembly_Category_Type">
                Assembly Category Type
                </InputLabel>
                <TextField
                  id="assembly_Category_Type"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.assembly_Category_Type}
                  onChange={(e) =>
                    setFormData({ ...formData, assembly_Category_Type: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="assembly_Category_Type_Description"
                >
                 Assembly Category Type Description
                </InputLabel>
                <TextField
                  id="assembly_Category_Type_Description"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.assembly_Category_Type_Description}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      assembly_Category_Type_Description: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            
            
           

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Assembly Category Type
                  {loader && <Loader color={"#fff"} />}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddInvAssemblyCategoryType;
