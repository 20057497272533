import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loggedInUser } from "../../services/auth.handlers";
import { CreateEmployeeLogs } from "../../services/employee.logs.handlers";

const initialState = {
  loading: false,
  status: null,
  authToken: null,
  userData: null,
  error: null,
};
export const loggedIn = createAsyncThunk("Auth/Login", async (data) => {
  const res = await loggedInUser(data);
  if (res.status) {
    const logsData = {
      employee_ID: res.data.profile.employee_ID,
      employee_Name: res.data.profile.employee_Name,
      login_Logout: "Sign In",
    };
    var options = {
      Authorization: "Bearer " + res.data.data,
    };
    localStorage.removeItem('@submenu');
    await CreateEmployeeLogs(logsData, options);
  }
  return res;
});

export const loggedOut = createAsyncThunk("Auth/LogOut", async (data) => {
  const res = await CreateEmployeeLogs(data.data, data.header);
  localStorage.removeItem('@submenu');
  if (res.status) {
    return res;
  }
});

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkUserAuth(state) {
      state.loading = false;
      state.authToken = null;
      state.status = null;
      state.userData = null;
    },
  },
  extraReducers: {
    [loggedIn.pending]: (state) => {
      state.loading = true;
    },
    [loggedIn.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.status = payload.status;
      if (payload.status) {
        state.authToken = payload.data.data;
        state.userData = payload.data.profile;
        state.error = null;
      } else {
        state.error = "Invalid Credentials";
      }
    },
    [loggedIn.rejected]: (state) => {
      state.loading = false;
    },
    [loggedOut.pending]: (state) => {
      state.loading = true;
    },
    [loggedOut.fulfilled]: (state) => {
      state.loading = false;
      state.authToken = null;
      state.status = null;
      state.userData = null;
    },
    [loggedOut.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { checkUserAuth } = AuthSlice.actions;

export default AuthSlice.reducer;
