import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetTabServers = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.TABS_TO_SERVER.CRUD_SERVERS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};
