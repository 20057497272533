import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetOrgEmployees = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORG_EMPLOYEE.CRUD_ORG_EMPLOYEE}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const CreateOrgEmployee = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.ORG_EMPLOYEE.CRUD_ORG_EMPLOYEE}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrgEmployee = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORG_EMPLOYEE.CRUD_ORG_EMPLOYEE}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateOrgEmployee = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.ORG_EMPLOYEE.CRUD_ORG_EMPLOYEE}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const AddEmployeeToSystem = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.ORG_EMPLOYEE.ADD_EMPLOYEE_TO_SYSTEM}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrgEmployeesAndSystems = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORG_EMPLOYEE.GET_ORG_EMPLOYEES_AND_SYSTEM}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
