import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import { CreateDoctors } from "../../../services/doctors.handlers";
import { GetOrganizations } from "../../../services/organization.handlers";
import { GetOrganisationStore } from "../../../services/store.handlers";
import { CreateOrgEmployee } from "../../../services/org.employee.handlers";
import { jsonCheck } from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const EmpType = ["Doctor", "Technician"];
const AddOrgEmployee = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    type: EmpType[0],
    contact_Number: "",
    organisation_Id: "",
    store_Id: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    name: "",
    email: "",
    type: "",
    contact_Number: "",
    organisation_Id: "",
    store_Id: "",
  });

  React.useEffect(() => {
    fetchOrganisations();
  }, []);

  React.useEffect(() => {
    formData.organisation_Id && fetchStores();
  }, [formData.organisation_Id]);

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };

  const fetchStores = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganisationStore(formData.organisation_Id, options).then((response) => {
      if (response.status) {
        setStores(response.data);
      }
    });
  };

  const setOrgID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        organisation_Id: "Organization ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        organisation_Id: "",
      });
    }
    setFormData({ ...formData, organisation_Id: "" + value });
  };

  const setStoreID = (value) => {
    if (value.length > 0 === "") {
      setFormErrors({
        ...formErrors,
        store_Id: "Store ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_Id: "",
      });
    }
    // setFormData({ ...formData, store_Id: value.toString() });
    setFormData({ ...formData, store_Id: value });
  };

  const setName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        name: "Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        name: "",
      });
    }
    setFormData({ ...formData, name: value });
  };

  const setEmail = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        email: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          email: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          email: "Invalid email id",
        });
      }
    }
    setFormData({ ...formData, email: value });
  };
  const setNumber = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        contact_Number: "Contact Number cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        contact_Number: "",
      });
    }
    setFormData({ ...formData, contact_Number: value });
  };

  const submitForm = () => {
    if (!formData.name) {
      setName(formData.name);
      return;
    }
    if (!formData.email) {
      setEmail(formData.email);
      return;
    }
    if (!formData.contact_Number) {
      setNumber(formData.contact_Number);
      return;
    }
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateOrgEmployee(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          name: "",
          email: "",
          type: EmpType[0],
          contact_Number: "",
          organisation_Id: "",
          store_Id: "",
        });
        setFormErrors({
          name: "",
          email: "",
          type: "",
          contact_Number: "",
          organisation_Id: "",
          store_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  // console.log(formData.store_Id ? [] : formData.store_Id.split(","));
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Org Employee</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="name">
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.name}
                  onChange={(e) => setName(e.target.value)}
                  error
                  helperText={formErrors.name}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="email">
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.email}
                  onChange={(e) => setEmail(e.target.value)}
                  error
                  helperText={formErrors.email}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="contact_Number">
                  Contact Number
                </InputLabel>
                <TextField
                  id="contact_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Number}
                  onChange={(e) => setNumber(e.target.value)}
                  error
                  helperText={formErrors.contact_Number}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Type
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.type}
                  onChange={(e) => {
                    setFormData({ ...formData, type: e.target.value });
                  }}
                  error
                  helperText={formErrors.type}
                >
                  {EmpType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Organization
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.organisation_Id}
                  onChange={(e) => {
                    setOrgID(e.target.value);
                  }}
                  error
                  helperText={formErrors.organisation_Id}
                >
                  {organizations.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.org_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Stores
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  multiple
                  value={formData.store_Id ? formData.store_Id.split(",") : []}
                  onChange={(e) => {
                    setStoreID(e.target.value);
                  }}
                  error
                  helperText={formErrors.store_Id}
                >
                  {stores.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.store_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Stores
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  // multiple
                  value={formData.store_Id}
                  onChange={(e) => {
                    setStoreID(e.target.value);
                  }}
                  error
                  helperText={formErrors.store_Id}
                >
                  {stores.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.store_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Org Employee
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/org_employee")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddOrgEmployee;
