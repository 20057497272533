import * as React from "react";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  addHeadset,
  addPartNumber,
  addRevNumber,
  getInvHarness,
  getInvPartNumber,
  getPartNumber,
  getRevNumber,
} from "../../../services/systems.handlers";
import moment from "moment";
import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { _getCountryName, getCountryName } from "../../../constants";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { useNavigate } from "react-router-dom";

function AddHeadset(prop) {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [HdsetRouterFile, setHdsetRouterFile] = React.useState();
  const [partItem, setPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [partData, setpartData] = React.useState({
    part_No: "",
    isActive: 0,
  });
  const [revData, setrevData] = React.useState({
    rev_No: "",
    isActive: 0,
    isDefault: 0,
  });
  const [formData, setFormData] = React.useState({
    hdset_Id: "",
    hdsetPart_No: "",
    hdsetRev_No: "",
    hdsetBuild_Date: moment(new Date()).format("YYYY/MM/DD"),
    hdsetBuild_Location: "",
    hdsetRouter: "",
    component_Name: "Headset",
    component_No: "",
    country_Code: "",
    Lot_No: "",
    Variant: "",
    hw_Rev: "",
    isActive: 1,
  });
  const [hdsetData, setHdsetData] = React.useState({
    id: "",
    hdset: "",
    hdsetVariation: "",
    hdsetCode: "",
    hdsetSl: "",
    hdsetLot_No: "",
  });
  const [ledHdsetData, setLedHdsetData] = React.useState({
    id: "",
    ledHdset: "",
    ledHdsetVariation: "",
    ledHdsetCode: "",
    ledHdsetSl: "",
    ledHdsetLot_No: "",
  });
  const [cupHdsetData, setCupHdsetData] = React.useState({
    id: "",
    cupHdset: "",
    cupHdsetVariation: "",
    cupHdsetCode: "",
    cupHdsetSl: "",
    cupHdsetLot_No: "",
  });
  const [eyeHdsetData, setEyeHdsetData] = React.useState({
    id: "",
    eyeHdset: "",
    eyeHdsetVariation: "",
    eyeHdsetCode: "",
    eyeHdsetSl: "",
    eyeHdsetLot_No: "",
  });
  const [hdsetPcbData, setHdsetPcbData] = React.useState({
    id: "",
    hdsetPcb: "",
    hdsetPcbVariation: "",
    hdsetPcbCode: "",
    hdsetPcbSl: "",
    hdsetPcbLot_No: "",
  });
  React.useEffect(() => {
    partNo();
    revNo();
  }, []);

  const hdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100186" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const ledHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100184" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "led hdst harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const eyeHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100183" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "eye cup and gnd rtn hdset harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const cupHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100182" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const hdsetPCBPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219" && item.country_Code
            // item.part_Description_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const headsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173" && item.country_Code
            // item.part_Description_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const hdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100186" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const ledHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100184" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "led hdst harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const eyeHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100183" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "eye cup and gnd rtn hdset harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const cupHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100182" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const hdsetPCBPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219" && item.variant
            // item.part_Description_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const headsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173" && item.variant
            // item.part_Description_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const headsetDefaultVariant = {
    options: headsetPropVariant,
    getOptionLabel: (option) => option,
  };

  const hdsetDefaultVariant = {
    options: hdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const ledHdsetDefaultVariant = {
    options: ledHdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const eyeHdsetDefaultVariant = {
    options: eyeHdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const cupHdsetDefaultVariant = {
    options: cupHdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const hdsetPcbDefaultVariant = {
    options: hdsetPCBPropVariant,
    getOptionLabel: (option) => option,
  };

  const headsetDefaultCode = {
    options: headsetPropCode,
    getOptionLabel: (option) => option,
  };

  const hdsetDefaultCode = {
    options: hdsetPropCode,
    getOptionLabel: (option) => option,
  };
  const ledHdsetDefaultCode = {
    options: ledHdsetPropCode,
    getOptionLabel: (option) => option,
  };
  const eyeHdsetDefaultCode = {
    options: eyeHdsetPropCode,
    getOptionLabel: (option) => option,
  };

  const cupHdsetDefaultCode = {
    options: cupHdsetPropCode,
    getOptionLabel: (option) => option,
  };
  const hdsetPcbDefaultCode = {
    options: hdsetPCBPropCode,
    getOptionLabel: (option) => option,
  };
  const defaultPropsLotNo = {
    options: partItem,
    getOptionLabel: (option) => option.lot_No,
  };
  const defaultPropsSlNo = {
    options: partItem,
    getOptionLabel: (option) => option.sl_No,
  };
  const handleOpen = (prop) => {
    setOpen({ isOpen: true, popOver: prop });
  };
  const handleClose = () => {
    setOpen({ isOpen: false, popOver: "" });
  };

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeNo = (e) => {
    if (open.popOver === "Part No") {
      setpartData({ ...partData, part_No: e.target.value });
    } else if (open.popOver === "Rev No") {
      setrevData({ ...revData, rev_No: e.target.value });
    }
  };

  function getDefaultValueCountryCodeVariation(prop) {
    prop?.forEach((event) => {
      if (
        event.component_No === "100186"
        // event.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
      ) {
        setHdsetData({
          ...hdsetData,
          id: event.id,
          hdset: event.component_No,
          hdsetVariation: event.variant,
          hdsetCode: event.country_Code,
        });
      }
      if (
        event.component_No === "100184"
        // event.part_Desciption_Name.toLowerCase().trim() === "led hdst harness"
      ) {
        setLedHdsetData({
          ...ledHdsetData,
          id: event.id,
          ledHdset: event.component_No,
          ledHdsetVariation: event.variant,
          ledHdsetCode: event.country_Code,
        });
      }
      if (
        event.component_No === "100183"
        // event.part_Desciption_Name.toLowerCase().trim() ===
        //   "eye cup and gnd rtn hdset harness"
      ) {
        setEyeHdsetData({
          ...eyeHdsetData,
          id: event.id,
          eyeHdset: event.component_No,
          eyeHdsetVariation: event.variant,
          eyeHdsetCode: event.country_Code,
        });
      }
      if (
        event.component_No === "100182"
        // event.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
      ) {
        setCupHdsetData({
          ...cupHdsetData,
          id: event.id,
          cupHdset: event.component_No,
          cupHdsetVariation: event.variant,
          cupHdsetCode: event.country_Code,
        });
      }
      if (
        event.component_No === "100219"
        // event.part_Description_Name.toLowerCase().trim() === "headset pcb"
      ) {
        setHdsetPcbData({
          ...hdsetPcbData,
          id: event.id,
          hdsetPcb: event.component_No,
          hdsetPcbVariation: event.variant,
          hdsetPcbCode: event.country_Code,
        });
      }
      if (
        event.component_No === "100173"
        // event.part_Description_Name.toLowerCase().trim() === "headset"
      ) {
        setFormData({
          ...formData,
          component_No: event.component_No,
          Variant: event.variant,
          country_Code: event.country_Code,
        });
      }
    });
  }

  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
        getDefaultValueCountryCodeVariation(response.data);
      }
    });
  };

  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
      }
    });
  };

  const submitNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    if (open.popOver === "Part No" && partData.part_No !== "") {
      addPartNumber(partData, options).then((response) => {
        if (response.status) {
          setOpen(false);
          setpartData({
            part_No: "",
            isActive: 0,
          });
          partNo();
        }
      });
    } else if (open.popOver === "Rev No" && revData.rev_No !== "") {
      addRevNumber(revData, options).then((response) => {
        if (response.status) {
          if (response.data.isDefault === 1) {
            setFormData({ ...formData, hdsetRev_No: response.data.rev_No });
          }
          setOpen(false);
          setrevData({
            rev_No: "",
            isActive: 0,
            isDefault: 0,
          });
          revNo();
        }
      });
    } else {
      setNumberError("Enter Number");
      setTimeout(() => {
        setNumberError("");
      }, 2000);
    }
  };

  const submitForm = () => {
    setLoader(true);
    const componentData = JSON.stringify([
      {
        // component_Name: "HDST Harness",
        component: "Headset",
        part_Description_Name: "HDST Harness",
        id: hdsetData.id,
        component_No: hdsetData.hdset,
        country_Code: hdsetData.hdsetCode,
        variant: hdsetData.hdsetVariation,
        lot_No: hdsetData.hdsetLot_No,
      },
      {
        // component_Name: "LED HDST Harness",
        component: "Headset",
        part_Description_Name: "LED HDST Harness",
        id: ledHdsetData.id,
        component_No: ledHdsetData.ledHdset,
        country_Code: ledHdsetData.ledHdsetCode,
        variant: ledHdsetData.ledHdsetVariation,
        lot_No: ledHdsetData.ledHdsetLot_No,
      },
      {
        // component_Name: "Eye Cup AND Gnd Rtn HDST Harness",
        component: "Headset",
        part_Description_Name: "Eye Cup AND Gnd Rtn HDST Harness",
        id: eyeHdsetData.id,
        component_No: eyeHdsetData.eyeHdset,
        country_Code: eyeHdsetData.eyeHdsetCode,
        variant: eyeHdsetData.eyeHdsetVariation,
        lot_No: eyeHdsetData.eyeHdsetLot_No,
      },
      {
        // component_Name: "EYE CUP Harness",
        component: "Headset",
        part_Description_Name: "EYE CUP Harness",
        id: cupHdsetData.id,
        component_No: cupHdsetData.cupHdset,
        country_Code: cupHdsetData.cupHdsetCode,
        variant: cupHdsetData.cupHdsetVariation,
        lot_No: cupHdsetData.cupHdsetLot_No,
      },
      {
        // component_Name: "Headset PCB",
        component: "Headset",
        part_Description_Name: "Headset PCB",
        id: hdsetPcbData.id,
        component_No: hdsetPcbData.hdsetPcb,
        country_Code: hdsetPcbData.hdsetPcbCode,
        variant: hdsetPcbData.hdsetPcbVariation,
        lot_No: hdsetPcbData.hdsetPcbLot_No,
        sl_No: hdsetPcbData.hdsetPcbSl,
      },
    ]);

    const fileData = new FormData();
    fileData.append("Id", "");
    fileData.append("HdsetRouterFile", HdsetRouterFile);
    fileData.append("Hdset_Id", formData.hdset_Id);
    fileData.append("HdsetPart_No", formData.hdsetPart_No);
    fileData.append("HdsetRouter", "");
    fileData.append("HdsetBuild_Date", formData.hdsetBuild_Date);
    fileData.append("HdsetBuild_Location", formData.hdsetBuild_Location);
    fileData.append("HdsetRev_No", formData.hdsetRev_No);
    fileData.append("Component_Name", formData.component_Name);
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.Lot_No);
    fileData.append("Variant", formData.Variant);
    fileData.append("IsActive", formData.isActive);
    fileData.append("HW_Rev", formData.hw_Rev);
    fileData.append("IsAssociated_WithSystem", false);
    fileData.append("Components", componentData);

    var option = {
      Authorization: "Berrer" + authToken,
    };

    addHeadset(fileData, option).then((response) => {
      if (response.status) {
        setAlert({
          ...alert,
          success: "Data has been submitted successfully",
        });
        setFormData({
          hdset_Id: "",
          hdsetPart_No: "",
          hdsetRev_No: "",
          hdsetBuild_Date: "",
          hdsetBuild_Location: "",
          hdsetRouter: "",
        });
        setLoader(false);
        // prop.getData();
        navigate(-1);
        setTimeout(() => {
          setAlert({ ...alert, success: "" });
        }, 3000);
      } else {
        setLoader(false);
        setAlert({ ...alert, error: "Invalid Details" });
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 3000);
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <h3 style={{ marginTop: 0 }}>Add New Headset</h3>
        <Modal open={open.isOpen} onClose={handleClose}>
          <Box sx={{ ...style, width: 600 }}>
            <Grid container spacing={2}>
              <div style={{ height: 280, width: 600, margin: 10 }}>
                <div style={{ height: 50 }}>
                  {errorNumber !== "" && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errorNumber}</Alert>
                    </Grid>
                  )}
                </div>
                <Grid item xs={12}>
                  <div className={"form-group"}>
                    <InputLabel className={"form-label"} htmlFor="add_part">
                      {open.popOver}
                    </InputLabel>

                    <TextField
                      style={{ marginTop: 15 }}
                      type="text"
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      onChange={handleChangeNo}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{ marginTop: 15 }}
                  >
                    <Button
                      disabled={loader}
                      onClick={() => submitNo()}
                      variant="contained"
                    >
                      Submit
                      {loader && <Loader color={"#fff"} />}
                    </Button>
                    <ButtonThemeProvider>
                      <Button
                        disabled={loader}
                        onClick={() => handleClose()}
                        color="grey"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </ButtonThemeProvider>
                  </Stack>
                </Grid>
              </div>
            </Grid>
          </Box>
        </Modal>
        <Grid container spacing={2}>
          {/* <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="hdset_Id">
              Ser. No.
            </InputLabel>
            <TextField
              name="hdset_Id"
              fullWidth
              value={formData.hdset_Id}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="hdsetPart_No">
              Part No
               <Link
                style={{ marginLeft: 5 }}
                onClick={() => handleOpen("Part No")}
              >
                add new
              </Link> 
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={formData.hdsetPart_No}
              renderValue={(selected) => {
                return partItem.find((x) => x.part_No === selected).part_No;
              }}
              onChange={(e) =>
                setFormData({ ...formData, hdsetPart_No: e.target.value })
              }
            >
              {partItem.map((item, index) => (
                <MenuItem key={index} value={item.part_No}>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>{item.part_No}</div>
                    <div>{_getCountryName(item.part_No)}</div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid> */}
          <ComponentName name="Part No" value={formData.component_No} />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                {...headsetDefaultCode}
                value={formData.country_Code}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    country_Code: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={(props, option) => (
                  <div
                    // key={option.id}
                    style={{ display: "flex", gap: "10px" }}
                    {...props}
                  >
                    <div>{option}</div>
                    <div>{getCountryName(option)}</div>
                  </div>
                )}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...headsetDefaultVariant}
                value={formData.Variant}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Variant: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Lot_No: newValue?.lot_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    hdset_Id: newValue?.sl_No,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel
                className={"form-label"}
                htmlFor="hdsetBuild_Location"
              >
                Build Location
              </InputLabel>

              <TextField
                name="hdsetBuild_Location"
                fullWidth
                value={formData.hdsetBuild_Location}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hdsetRev_No">
                Rev
                {/* <Link
                style={{ marginLeft: 5 }}
                onClick={() => handleOpen("Rev No")}
              >
                add new
              </Link> */}
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.hdsetRev_No}
                onChange={(e) =>
                  setFormData({ ...formData, hdsetRev_No: e.target.value })
                }
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {revItem.map((item, index) => (
                  <MenuItem key={index} value={item.rev_No}>
                    {item.rev_No}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hw_Rev">
                ECO
              </InputLabel>

              <TextField
                name="hw_Rev"
                fullWidth
                value={formData.hw_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hdsetBuild_Date">
                  Build Date
                </InputLabel>
                <DesktopDatePicker
                  inputFormat="YYYY/MM/DD"
                  value={formData.hdsetBuild_Date}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hdsetBuild_Date: moment(e).format("YYYY/MM/DD"),
                    })
                  }
                  className={"form-input"}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              alignSelf: "center",
            }}
          >
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hdsetRouter">
                Router
              </InputLabel>
              <TextField
                style={{ height: 44, marginTop: 2 }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(e) => setHdsetRouterFile(e.target.files[0])}
                // error={alert.error}
                // helperText={alert.error}
                variant="standard"
              />
            </div>
          </Grid>
          <ComponentName name="Headset PCB" value={hdsetPcbData.hdsetPcb} />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...hdsetPcbDefaultCode}
                value={hdsetPcbData.hdsetPcbCode}
                onChange={(event, newValue) => {
                  setHdsetPcbData({
                    ...hdsetPcbData,
                    hdsetPcbCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...hdsetPcbDefaultVariant}
                value={hdsetPcbData.hdsetPcbVariation}
                onChange={(event, newValue) => {
                  setHdsetPcbData({
                    ...hdsetPcbData,
                    hdsetPcbVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setHdsetPcbData({
                    ...hdsetPcbData,
                    hdsetPcbLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === hdsetPcbData.hdsetPcb && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hw_Rev">
                Rev
              </InputLabel>

              <TextField
                // name="hw_Rev"
                fullWidth
                // value={formData.hw_Rev}
                className={"form-input"}
                variant="outlined"
                // onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={1.2}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hw_Rev">
                ECO
              </InputLabel>

              <TextField
                // name="hw_Rev"
                fullWidth
                // value={formData.hw_Rev}
                className={"form-input"}
                variant="outlined"
                // onChange={handleChangeData}
              />
            </div>
          </Grid>
          {/* <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setHdsetPcbData({
                    ...hdsetPcbData,
                    hdsetPcbSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === hdsetPcbData.hdsetPcb && (
                    <div
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid> */}
          <ComponentName name="Eye Cup Harness" value={cupHdsetData.cupHdset} />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...cupHdsetDefaultCode}
                value={cupHdsetData.cupHdsetCode}
                onChange={(event, newValue) => {
                  setCupHdsetData({
                    ...cupHdsetData,
                    cupHdsetCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...cupHdsetDefaultVariant}
                value={cupHdsetData.cupHdsetVariation}
                onChange={(event, newValue) => {
                  setCupHdsetData({
                    ...cupHdsetData,
                    cupHdsetVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setCupHdsetData({
                    ...cupHdsetData,
                    cupHdsetLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === cupHdsetData.cupHdset && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                // {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  // setHdmiData({
                  //   ...hdmiData,
                  //   pcbaToHdmiSl: newValue?.sl_No || "",
                  // });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Eye Cup AND Gnd Rtn HDST Harness"
            value={eyeHdsetData.eyeHdset}
          />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...eyeHdsetDefaultCode}
                value={eyeHdsetData.eyeHdsetCode}
                onChange={(event, newValue) => {
                  setEyeHdsetData({
                    ...eyeHdsetData,
                    eyeHdsetCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...eyeHdsetDefaultVariant}
                value={eyeHdsetData.eyeHdsetVariation}
                onChange={(event, newValue) => {
                  setEyeHdsetData({
                    ...eyeHdsetData,
                    eyeHdsetVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setEyeHdsetData({
                    ...eyeHdsetData,
                    eyeHdsetLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === eyeHdsetData.eyeHdset && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                // {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  // setHdmiData({
                  //   ...hdmiData,
                  //   pcbaToHdmiSl: newValue?.sl_No || "",
                  // });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="LED HDST  Harness"
            value={ledHdsetData?.ledHdset}
          />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...ledHdsetDefaultCode}
                value={ledHdsetData.ledHdsetCode}
                onChange={(event, newValue) => {
                  setLedHdsetData({
                    ...ledHdsetData,
                    ledHdsetCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...ledHdsetDefaultVariant}
                value={ledHdsetData.ledHdsetVariation}
                onChange={(event, newValue) => {
                  setLedHdsetData({
                    ...ledHdsetData,
                    ledHdsetVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setLedHdsetData({
                    ...ledHdsetData,
                    ledHdsetLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === ledHdsetData.ledHdset && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                // {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  // setHdmiData({
                  //   ...hdmiData,
                  //   pcbaToHdmiSl: newValue?.sl_No || "",
                  // });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <ComponentName name="HDST Harness" value={hdsetData.hdset} />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...hdsetDefaultCode}
                value={hdsetData.hdsetCode}
                onChange={(event, newValue) => {
                  setHdsetData({
                    ...hdsetData,
                    hdsetCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...hdsetDefaultVariant}
                value={hdsetData.hdsetVariation}
                onChange={(event, newValue) => {
                  setHdsetData({
                    ...hdsetData,
                    hdsetVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setHdsetData({
                    ...hdsetData,
                    hdsetLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === hdsetData.hdset && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                // {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  // setHdmiData({
                  //   ...hdmiData,
                  //   pcbaToHdmiSl: newValue?.sl_No || "",
                  // });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <Stack
              spacing={2}
              direction="row"
              style={{ justifyContent: "end" }}
            >
              <Button
                disabled={loader}
                variant="contained"
                style={{
                  // marginTop: 24,
                  height: "40px",
                  gap: "5px",
                  alignItems: "center",
                }}
                onClick={submitForm}
              >
                <AddIcon /> Add
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate(-1)}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default AddHeadset;
