import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreateSystem } from "../../../services/systems.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GetStores } from "../../../services/store.handlers";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme } from "@mui/material";

const AddPopSystem = (props) => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [formData, setFormData] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
  });

  //Theme change asterisk element
  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "#db3131",
            "&$error": {
              color: "#db3131",
            },
          },
        },
      },
    },
  });

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }
    setOpen(true);
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (formData.system_Id !== "") {
      CreateSystem(formData, options).then((response) => {
        if (response.status) {
          setSuccess("Data has been submitted successfully");
          setFormData({
            system_Id: "",
            gen_Id: "",
            hdset_Id: "",
            tablet_Id: "",
            isActive: 1,
          });
          setFormErrors({
            system_Id: "",
            gen_Id: "",
            hdset_Id: "",
            tablet_Id: "",
          });
          setLoader(false);
          props.getData();
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        } else {
          setLoader(false);
          setError("Invalid Details");
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setLoader(false);
      setError("Invalid Details");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };
  return (
    <Grid container spacing={2}>
      {error != "" && (
        <Grid item xs={12}>
          {/* <Alert onClose={() => {}} severity="error">
            {error}
          </Alert> */}
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              // sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          </Collapse>
        </Grid>
      )}
      {success != "" && (
        <Grid item xs={12}>
          <Alert severity="success">{success}</Alert>
        </Grid>
      )}

      <Grid item xs={3}>
        <div className={"form-group"}>
          <ThemeProvider theme={theme}>
            <InputLabel required className={"form-label"} htmlFor="system_Id">
              System Id
            </InputLabel>
          </ThemeProvider>

          <TextField
            required={true}
            id="system_Id"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.system_Id}
            onChange={(e) =>
              setFormData({
                ...formData,
                system_Id: e.target.value,
              })
            }
          />
        </div>
      </Grid>

      <Grid item xs={3}>
        <div className={"form-group"}>
          <InputLabel className={"form-label"} htmlFor="gen_Id">
            Generator Id
          </InputLabel>
          <TextField
            id="gen_Id"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.gen_Id}
            onChange={(e) =>
              setFormData({ ...formData, gen_Id: e.target.value })
            }
          />
        </div>
      </Grid>

      <Grid item xs={2}>
        <div className={"form-group"}>
          <InputLabel className={"form-label"} htmlFor="hdset_Id">
            Headset Id
          </InputLabel>
          <TextField
            id="hdset_Id"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.hdset_Id}
            onChange={(e) =>
              setFormData({
                ...formData,
                hdset_Id: e.target.value,
              })
            }
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={"form-group"}>
          <InputLabel className={"form-label"} htmlFor="tablet_Id">
            Tablet Id
          </InputLabel>
          <TextField
            id="tablet_Id"
            fullWidth
            className={"form-input"}
            variant="outlined"
            value={formData.tablet_Id}
            onChange={(e) =>
              setFormData({
                ...formData,
                tablet_Id: e.target.value,
              })
            }
          />
        </div>
      </Grid>

      <Grid item xs={2} style={{ alignSelf: "center" }}>
        <Stack
          spacing={2}
          direction="row"
          style={{ alignSelf: "center", marginTop: "24px" }}
        >
          <Button
            disabled={loader}
            onClick={() => submitForm()}
            variant="contained"
            style={{
              height: "44px",
              gap: "10px",
              borderRadius: "16px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <AddIcon /> Add
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddPopSystem;
