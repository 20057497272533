import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetSystems, getGenerator } from "../../../services/systems.handlers";
import AddPopSystem from "./AddPopSystem";
import { Link } from "react-router-dom";
import AddSystems from "./AddSystems";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { getCountryName } from "../../../constants";

const TableRowData = [
  "System ",
  "Generator ",
  // "Generator PCB",
  "Headset ",
  // "Headset PCB",
  "Tablet ",
  "Country",
  "Status",
  "Action",
];

const SystemAddSystems = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [genData, setGenData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchGeneratorData();
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setData(allData);
    }
    let serachItem = allData.filter((item) =>
      item.system_Id.includes(e.target.value)
    );
    setData(serachItem);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystems(options).then((response) => {
      if (response.status) {
        setData(response.data);
        setAllData(response.data);
      }
    });
  };

  const fetchGeneratorData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGenerator(options).then((response) => {
      if (response.status) {
        setGenData(response.data);
      }
    });
  };
  return (
    <DashboardLayout>
      <Stack
        // spacing={35}
        direction="row"
        style={{
          height: "45px",
          marginBottom: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <AddSystems getData={() => fetchData()} /> */}
        <Stack style={{ paddingLeft: "13px" }}>
          <h3>System</h3>
        </Stack>
        <Stack>
          <TextField
            type="text"
            className="search"
            placeholder="Search by System Id"
            onChange={handleSearch}
            InputProps={{
              sx: { borderRadius: 2, width: 400 },
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack style={{ paddingRight: "38px", marginLeft: "none" }}>
          <Button
            style={{ height: "45px" }}
            onClick={() => navigate("/add_systems")}
            variant="contained"
          >
            <AddIcon /> Add New System
          </Button>
        </Stack>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    <>
                      {item}
                      {index <= 3 && (
                        <>
                          <br></br>Ser. No.
                        </>
                      )}
                    </>
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Link to={"/systems-detail-page/" + item.id}>
                        {item.system_Sn}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={"/generator-detail-page/" + item.gen_Sn}>
                        {item.gen_Sl_No}
                      </Link>
                    </TableCell>
                    {/* <TableCell>
                      <Link to={"/generatorPcb-detail-page/" + item.gen_PCB_Id}>
                        {item.gen_PCB_Sl_No}
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      <Link to={"/headset-detail-page/" + item.hdset_Sn}>
                        {item.hdset_Sl_No}
                      </Link>
                    </TableCell>
                    {/* <TableCell>
                      <Link to={"/headsetPcb-detail-page/" + item.hdset_PCB_Id}>
                        {item.hdset_PCB_Sl_No}
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      <Link to={"/tablet-detail-page/" + item.tablet_Sn}>
                        {item.tablet_Sl_No}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <TableCell>
                        {item.country_Code}
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          {getCountryName(item?.country_Code)}
                        </div>
                      </TableCell>
                    </TableCell>
                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/editSystems/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/uploadDoc/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Doc
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={9} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default SystemAddSystems;
