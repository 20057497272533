import * as React from "react";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import {
  addBulkPartNumber,
  addHarness,
  addInvPartNumber,
  addPartNumber,
} from "../../../services/systems.handlers";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { sampleCsvFile } from "../../../constants";

function AddPartNumber(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);
  const [csvFile, setCsvFile] = React.useState(null);
  const [error, setError] = React.useState("");
  const [excelData, setExcelData] = React.useState([]);
  const [formData, setFormData] = React.useState({
    component: "",
    part_Description_Name: "",
    component_No: "",
    country_Code: "",
    variant: "",
    sl_No: "",
    lot_No: "",
    quantity: "",
    manufacture_Name: "",
    purchase_Date: "",
    release_Date: "",
    componentType: "",
  });

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    setLoader(true);
    var options = {
      Authorization: "Berrer" + authToken,
    };
    if (formData.sl_No !== "") {
      addHarness(formData, options).then((response) => {
        if (response.status) {
          setLoader(false);
          setFormData({
            component: "",
            part_Description_Name: "",
            component_No: "",
            country_Code: "",
            variant: "",
            sl_No: "",
            lot_No: "",
            quantity: "",
            manufacture_Name: "",
          });
          prop.getData();
        } else {
          setLoader(false);
          setError("Invalid Details");
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      });
    } else {
      setLoader(false);
      setError("Please Enter Part No");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  const submitCsvFile = () => {
    const fileData = new FormData();
    fileData.append("Part_NumberBulkDataFile", csvFile);
    if (
      !csvFile ||
      csvFile.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setError("Please Select xlsx File");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      setLoader(true);
      var options = {
        Authorization: "Berrer" + authToken,
      };
      addBulkPartNumber(fileData, options).then((response) => {
        if (response.status) {
          setLoader(false);
          prop.getData();
        } else {
          setLoader(false);
          setError("Invalid Details");
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      });
    }
  };

  const handleCSVFile = (e) => {
    setCsvFile(e.target.files[0]);
    // const reader = new FileReader();
    // reader.readAsBinaryString(e.target.files[0]);
    // console.log(reader);
    // reader.onload = (e) => {
    //   const data = e.target.result;
    //   const workbook = XLSX.read(data, { type: "binary" });
    //   const sheetName = workbook.SheetNames[0];
    //   const sheet = workbook.Sheets[sheetName];
    //   const parsedData = XLSX.utils.sheet_to_json(sheet);
    //   setExcelData(parsedData);
    // };
  };
  const countryCode = [
    "000-International",
    "001-USA",
    "002-Canada",
    "003-Mexico",
    "004-Australia",
  ];
  const handleDownload = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset_UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sampleCsvFile);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Add_Subassemblies_Example" + fileExtension);
  };

  return (
    <Grid container spacing={2}>
      {error !== "" && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={2.5}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="component">
            Component
          </InputLabel>
          <TextField
            name="component"
            fullWidth
            value={formData.component}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2.7}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="part_Description_Name">
            Part Desc
          </InputLabel>
          <TextField
            name="part_Description_Name"
            fullWidth
            value={formData.part_Description_Name}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="component_No">
            Component No.
          </InputLabel>
          <TextField
            name="component_No"
            fullWidth
            value={formData.component_No}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      {/* <Grid item xs={2}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="country_Code">
            Country Code
          </InputLabel>
          <TextField
            name="country_Code"
            fullWidth
            value={formData.country_Code}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid> */}
      <Grid item xs={2.7}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="gen_Id">
            Country Code
          </InputLabel>

          <Select
            fullWidth
            className={"form-input"}
            id="select-active"
            value={formData.country_Code}
            // renderValue={(selected) => {
            //   return generator.find((x) => x.id === selected).gen_Id;
            // }}
            onChange={(e) => {
              setFormData({ ...formData, country_Code: e.target.value });
            }}
          >
            {countryCode.map((item, index) => (
              <MenuItem value={item} key={index}>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div>{item}</div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="variant">
            Variant
          </InputLabel>
          <TextField
            name="variant"
            fullWidth
            value={formData.variant}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2.5}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="sl_No">
            Ser. Start
          </InputLabel>
          <TextField
            name="sl_No"
            fullWidth
            value={formData.sl_No}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2.7}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="quantity">
            Quantity
          </InputLabel>
          <TextField
            name="quantity"
            fullWidth
            value={formData.quantity}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="lot_No">
            Lot No.
          </InputLabel>
          <TextField
            name="lot_No"
            fullWidth
            value={formData.lot_No}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={2.7}>
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="manufacture_Name">
            Manufacture Name
          </InputLabel>
          <TextField
            name="manufacture_Name"
            fullWidth
            value={formData.manufacture_Name}
            className={"form-input"}
            variant="outlined"
            onChange={handleInput}
          />
        </div>
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={loader}
          variant="contained"
          style={{
            marginTop: 24,
            height: "40px",
            gap: "5px",
            alignItems: "center",
          }}
          onClick={submitForm}
        >
          <AddIcon /> Add
          {loader && <Loader color={"#fff"} />}
        </Button>
      </Grid>
      {/* <Grid
        item
        xs={0.8}
        style={{
          borderLeft: "1px solid black",
          marginLeft: "103px",
          marginTop: "10px",
        }}
      ></Grid> */}

      <Grid
        item
        xs={3}
        style={{
          alignSelf: "center",
        }}
      >
        <div className={"form-group"} style={{ margin: 0 }}>
          <InputLabel className={"form-label"} htmlFor="sysRouter">
            Sub Assemblies
          </InputLabel>
          <TextField
            style={{ height: 44, marginTop: 2 }}
            id="raised-button-file"
            multiple
            type="file"
            // onChange={(e) => setCsvFile(e.target.files[0])}
            onChange={(e) => handleCSVFile(e)}
            // error={alert.error}
            // helperText={alert.error}
            variant="standard"
          />
          <button
            onClick={handleDownload}
            style={{
              background: "none",
              border: "none",
              color: "#069",
              textDecoration: "underline ",
              cursor: "pointer",
            }}
            type="link"
          >
            Download Example CSV File
          </button>
        </div>
      </Grid>
      <Grid item xs={3}>
        <Button
          disabled={loader}
          variant="contained"
          style={{
            marginTop: 24,
            height: "40px",
            gap: "5px",
            alignItems: "center",
          }}
          onClick={submitCsvFile}
        >
          <AddIcon /> Add from xlsx
          {loader && <Loader color={"#fff"} />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default AddPartNumber;
