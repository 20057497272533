import React from "react";
import DashboardHeader from "../Components/Header/DashboardHeader";
import LeftSidebar from "../Components/LeftSidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

const drawerWidth = 240;

const DashboardLayout = ({ children }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DashboardHeader />
      {/* <LeftSidebar /> */}
      <Box
        // component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(DashboardLayout);
