import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetOrganizations = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.CRUD_ORGANIZATIONS}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const CreateOrganization = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.ORGANIZATIONS.CRUD_ORGANIZATIONS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrganization = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.CRUD_ORGANIZATIONS}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateOrganization = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.ORGANIZATIONS.CRUD_ORGANIZATIONS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrganizationsReport = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.ORGANIZATIONS.REPORTS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetStoreReport = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.ORGANIZATIONS.STORE_REPORT}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrganizationStoreReport = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.STORE_ORG_REPORT}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrganizationStoreSystemReport = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.ORGANIZATION_STORE_REPORT}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetStoreDoctorReport = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.STORE_DOCTOR_REPORT}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrgBillingReport = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.ORGANIZATION_BILLING_REPORT}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetSystemReport = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.ORGANIZATIONS.SYSTEM_REPORT}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetDeviceReport = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.ORGANIZATIONS.DEVICE_REPORT}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetTreatmentData = async (page, rowsPerPage, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.GET_TREATMENT_DATA}?PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const RemoveStore = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.ORGANIZATIONS.REMOVE_STORE}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const MapStoreWithOrg = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.ORGANIZATIONS.MAP_STORE_WITH_ORG}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const SystemUsageFile = async (filePath, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_FILE_DOWNLOAD}?filepath=${filePath}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageAllFileDownload = async (systemId, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_ALL_FILE_DOWNLOAD}?systemId=${systemId}`,
    {
      headers,
      responseType: "blob",
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageAllFileDownloadByDay = async (
  systemId,
  day,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_ALL_FILE_DOWNLOAD}?systemId=${systemId}&day=${day}`,
    {
      headers,
      responseType: "blob",
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageAllFileDownloadByMonth = async (
  systemId,
  monthAndYear,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_ALL_FILE_DOWNLOAD}?systemId=${systemId}&monthAndYear=${monthAndYear}`,
    {
      headers,
      responseType: "blob",
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageAllFileDownloadByWeek = async (
  systemId,
  week,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_ALL_FILE_DOWNLOAD}?systemId=${systemId}&week=${week}`,
    {
      headers,
      responseType: "blob",
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const SystemUsageFilteredData = async (page, rowsPerPage, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_FILTERED}?PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageByMonth = async (
  monthYear,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_FILTERED}?monthAndYear=${monthYear}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageByWeek = async (
  startDate,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_FILTERED}?week=${startDate}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageByDay = async (day, page, rowsPerPage, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_FILTERED}?day=${day}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const SystemUsageDetail = async (
  systemSn,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_DETAIL}?systemId=${systemSn}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const SystemUsageDetailByMonth = async (
  systemSn,
  monthYear,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_DETAIL}?systemId=${systemSn}&monthAndYear=${monthYear}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageDetailByWeek = async (
  systemSn,
  startDate,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_DETAIL}?systemId=${systemSn}&week=${startDate}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const SystemUsageDetailByDay = async (
  systemSn,
  day,
  page,
  rowsPerPage,
  headers
) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.SYSTEM_USAGE_DETAIL}?systemId=${systemSn}&day=${day}&PageNumber=${page}&PageSize=${rowsPerPage}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetSystemUsageById = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.ORGANIZATIONS.GET_TREATMENT_DATA}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateFile = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.ORGANIZATIONS.GET_TREATMENT_DATA}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const DeleteFile = async (id, headers) => {
  return await AxiosInstance.delete(
    `${API_ROUTE.ORGANIZATIONS.GET_TREATMENT_DATA}/${id}`,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
