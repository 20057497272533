import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Alert, Button, InputLabel, MenuItem, Select } from "@mui/material";
import {
  GetPrivateUser,
  UpdatePrivateUser,
} from "../../../services/private.user.handlers";
import Loader from "../../../Components/Loader";

const TableRowData = ["ID", "", "Code", "", "Action"];

const PrivateUser = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const { authToken } = useSelector((state) => state.user);
  const [selectedCode, setSelectedCode] = React.useState("");
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [loader, setLoader] = React.useState(false);
  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetPrivateUser(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const handleCode = (e) => {
    setSelectedCode(e.target.value);
  };

  const submitCode = () => {
    setLoader(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const filteredData = data.filter(
      (item) => item.id === Number(selectedCode)
    );
    // const formData = {
    //   isActive: 1,
    //   id: filteredData[0].id,
    //   code: filteredData[0].code,
    // };

    UpdatePrivateUser(selectedCode, options).then((response) => {
      if (response.status) {
        setLoader(false);
        setAlert({
          ...alert,
          success: "Data has been submitted successfully",
        });
        setTimeout(() => {
          setAlert({ ...alert, success: "" });
        }, 2000);
        fetchData();
      } else {
        setAlert({ ...alert, error: "Invalid Details" });
        setLoader(false);
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 2000);
      }
    });
  };
  return (
    <DashboardLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h3>Private User</h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {alert.error !== "" && (
            <div>
              <Alert severity="error">{alert.error}</Alert>
            </div>
          )}
          {alert.success !== "" && (
            <div>
              <Alert severity="success">{alert.success}</Alert>
            </div>
          )}
        </div>
        <div style={{ display: "flex", width: "20%", justifyContent: "end" }}>
          <div className={"form-group"} style={{ margin: "0", width: "100%" }}>
            <InputLabel className={"form-label"}>Please Select Flag</InputLabel>
            <Select
              // defaultOpen
              value={selectedCode}
              // renderValue={() =>
              //   dailyMonthYear === ReportType[0]
              //     ? `${monthNames[date.getMonth()]} ${date.getFullYear()}`
              //     : dailyMonthYear
              // }
              fullWidth
              className={"form-input"}
              id="select-active"
              // defaultValue={new Date().getMonth() + 1}
              onChange={handleCode}
              // onClick={() => setOpen(true)}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              {data.map((item, index) => (
                <MenuItem
                  key={index}
                  // onClick={() => setOpen(true)}
                  value={item.id}
                >
                  {item.code} {item.note && `-${item.note}`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Button
            variant="contained"
            disabled={(selectedCode ? false : true) || loader}
            style={{ maxHeight: "50px", marginTop: "20px" }}
            onClick={submitCode}
          >
            Save
            {loader && <Loader color={"#fff"} />}
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index} colSpan={3}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <TableRow
                  hover
                  // //   onClick={(event) => handleClick(event, row.name)}
                  // role="checkbox"
                  // //   aria-checked={isItemSelected}
                  // tabIndex={-1}
                  key={index}
                  //   selected={isItemSelected}
                >
                  <TableCell colSpan={3}>{item.id}</TableCell>
                  <TableCell colSpan={3}></TableCell>
                  <TableCell colSpan={3}>
                    {item.code}
                    {item.note && ` -${item.note}`}
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                  <TableCell colSpan={3}>
                    {item.isActive ? (
                      <span className={"label color-green"}>Active</span>
                    ) : (
                      <span className={"label color-red"}>Blocked</span>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={15} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default PrivateUser;
