import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { getTablet } from "../../../services/systems.handlers";
import { Link } from "react-router-dom";
import moment from "moment";
import AddHeadset from "./AddHeadset";
import AddTablet from "./AddTablet";
import { _getCountryName, getCountryName } from "../../../constants";

const TableRowData = [
  "Ser. No.",
  "Attached to System",
  "Part No",
  "Rev",
  "Build Date",
  "Build Location",
  "Router",
  "GUI Rev",
  "API Rev",
  "System Rev",
  "OS Rev",
  "SW Rev",
  "Status",
  "Action",
];

const Tablet = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [IsAssociated, setIsAssociated] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getTablet(options).then((response) => {
      if (response.status) {
        setData(response.data);
        setIsAssociated(
          response.data.some(
            (element) => element.isAssociated_WithSystem === true
          )
        );
      }
    });
  };

  return (
    <DashboardLayout>
      <h3 style={{ marginBottom: -5, marginTop: 0 }}>Tablet</h3>
      <hr></hr>

      {/* <Stack
        spacing={40}
        direction="row"
        style={{ marginTop: 16, marginBottom: "30px", justifyContent: "end" }}
      > */}
      {/* <AddTablet getData={() => fetchData()} /> */}

      {/* <Button onClick={() => navigate("/add_system")} variant="contained">
          <AddIcon /> Add New System
        </Button> */}
      {/* </Stack> */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 && IsAssociated ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (item, index) =>
                    item.isAssociated_WithSystem === true && (
                      <TableRow
                        hover
                        // //   onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        // //   aria-checked={isItemSelected}
                        // tabIndex={-1}
                        key={index}
                        //   selected={isItemSelected}
                      >
                        <TableCell>
                          {/* <Link to={"/tablet-detail-page/" + item.id}> */}
                          <Link
                            to="/tablet-detail-page"
                            state={{ id: item.id, storeId: item.storeId }}
                          >
                            {item.tablet_Id}
                          </Link>
                          {/* {item.system_Sn !== "" && (
                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "center",
                              // width: "102px",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            Attched to System - {item.system_Sn}
                          </div>
                        )} */}
                        </TableCell>
                        <TableCell>
                          {/* <Link to={"/systems-detail-page/" + item.systemId}> */}
                          <Link
                            to="/systems-detail-page"
                            state={{ id: item.systemId, storeId: item.storeId }}
                          >
                            {item.system_Sn}
                          </Link>
                        </TableCell>
                        <TableCell style={{ width: " 160px" }}>
                          <>
                            {/* {item.tabletPart_No} */}
                            {item.component_No}-
                            {item.country_Code
                              ? item.country_Code.substring(0, 3)
                              : ""}
                            -{item.variant}
                            <br></br>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "102px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {/* {_getCountryName(item.tabletPart_No)} */}
                              {/* {getCountryName(item.country_Code)} */}
                              {item.country_Code
                                ? item.country_Code.substring(4)
                                : ""}
                            </div>
                          </>
                        </TableCell>
                        <TableCell>{item.tabletRev_No}</TableCell>
                        <TableCell>
                          {item.tabletBuild_Date &&
                            moment(item.tabletBuild_Date).format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell>{item.tabletBuild_Location}</TableCell>
                        <TableCell>{item.tabletRouter}</TableCell>
                        <TableCell>{item.guI_Rev}</TableCell>
                        <TableCell>{item.apI_Rev}</TableCell>
                        <TableCell>{item.system_Rev}</TableCell>
                        <TableCell>{item.os}</TableCell>
                        <TableCell>{item.sW_Rev}</TableCell>
                        <TableCell>
                          {item.isActive ? (
                            <span className={"label color-green"}>Active</span>
                          ) : (
                            <span className={"label color-red"}>Blocked</span>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            // disabled={item.isAssociated_WithSystem}
                            onClick={() => navigate("/updateTablet/" + item.id)}
                            variant="contained"
                            color="primary"
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                )
            ) : (
              <TableRow hover>
                <TableCell colSpan={11} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default Tablet;
