import { configureStore } from "@reduxjs/toolkit";
import { RootReducer } from "./slices";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
