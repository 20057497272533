import * as React from "react";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import {
  addBulkPartNumber,
  addHarness,
  addInvPartNumber,
  addPartNumber,
} from "../../../services/systems.handlers";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { sampleCsvFile } from "../../../constants";
import { UploadExcelFile } from "../../../services/excelFile.handlers";

function AddNewExcelFile(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);
  const [csvFile, setCsvFile] = React.useState(null);
  const [error, setError] = React.useState("");

  const submitCsvFile = () => {
    const fileData = new FormData();
    fileData.append("excelFile", csvFile);
    if (
      !csvFile ||
      csvFile.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setError("Please Select xlsx File");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      setLoader(true);
      var options = {
        Authorization: "Berrer" + authToken,
      };
      UploadExcelFile(fileData, options).then((response) => {
        if (response.status) {
          setLoader(false);
          setCsvFile(null);
          prop.getData();
        } else {
          setLoader(false);
          setError("Invalid Details");
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      });
    }
  };

  const handleCSVFile = (e) => {
    setCsvFile(e.target.files[0]);
    // const reader = new FileReader();
    // reader.readAsBinaryString(e.target.files[0]);
    // console.log(reader);
    // reader.onload = (e) => {
    //   const data = e.target.result;
    //   const workbook = XLSX.read(data, { type: "binary" });
    //   const sheetName = workbook.SheetNames[0];
    //   const sheet = workbook.Sheets[sheetName];
    //   const parsedData = XLSX.utils.sheet_to_json(sheet);
    //   setExcelData(parsedData);
    // };
  };

  //create sample excel file
  const handleDownload = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset_UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sampleCsvFile);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Add_Subassemblies_Example" + fileExtension);
  };

  return (
    <div style={{ width: "45%" }}>
      <Grid container spacing={2}>
        {error !== "" && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid
          item
          xs={7.5}
          style={{
            alignSelf: "center",
          }}
        >
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="sysRouter">
              Excel file
            </InputLabel>
            <TextField
              style={{ height: 44, marginTop: 2 }}
              id="raised-button-file"
              multiple
              type="file"
              // onChange={(e) => setCsvFile(e.target.files[0])}
              onChange={(e) => handleCSVFile(e)}
              // error={alert.error}
              // helperText={alert.error}
              variant="standard"
            />
            {/* <button
              onClick={handleDownload}
              style={{
                background: "none",
                border: "none",
                color: "#069",
                textDecoration: "underline ",
                cursor: "pointer",
              }}
              type="link"
            >
              Download Example CSV File
            </button> */}
          </div>
        </Grid>
        <Grid item xs={4.5}>
          <Button
            disabled={loader}
            variant="contained"
            style={{
              marginTop: 24,
              height: "40px",
              gap: "5px",
              alignItems: "center",
            }}
            onClick={submitCsvFile}
          >
            <AddIcon /> Add from xlsx
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddNewExcelFile;
