import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { getHeadset } from "../../../services/systems.handlers";
import { Link } from "react-router-dom";
import moment from "moment";
import AddHeadset from "./AddHeadset";
import { _getCountryName, getCountryName } from "../../../constants";
import AddIcon from "@mui/icons-material/Add";
const TableRowData = [
  "Ser. No.",
  "Part No",
  "Rev",
  "HW_Rev",
  "Build Date",
  "Build Location",
  "Router",
  "Status",
  "Action",
];

const HeadsetAddHeadset = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [IsAssociated, setIsAssociated] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadset(options).then((response) => {
      if (response.status) {
        setData(response.data);
        setIsAssociated(
          response.data.some(
            (element) => element.isAssociated_WithSystem === false
          )
        );
      }
    });
  };

  return (
    <DashboardLayout>
      {/* <h3 style={{ marginBottom: -5, marginTop: 0 }}>Headset</h3>
      <hr></hr> */}
      <Stack
        spacing={40}
        direction="row"
        style={{
          // marginTop: "16px",
          // marginBottom: "30px",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ marginBottom: -5, marginTop: 0, marginLeft: 12 }}>
          Headset
        </h3>
        <Button
          style={{ marginRight: 86, height: 45 }}
          onClick={() => navigate("/addHeadset")}
          variant="contained"
        >
          <AddIcon /> Add Headset
        </Button>
      </Stack>
      <hr></hr>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 && IsAssociated ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (item, index) =>
                    item.isAssociated_WithSystem === false && (
                      <TableRow hover key={item.id}>
                        <TableCell>
                          {/* <Link to={"/headset-detail-page/" + item.id}> */}
                          <Link
                            to="/headset-detail-page"
                            state={{ id: item.id, storeId: item.storeId }}
                          >
                            {item.hdset_Id}
                          </Link>
                          {/* {item.system_Sn !== "" && (
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                // width: "102px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              Attched to System - {item.system_Sn}
                            </div>
                          )} */}
                        </TableCell>

                        <TableCell>
                          <>
                            {/* {item.hdsetPart_No} */}
                            {item.component_No}-
                            {item.country_Code
                              ? item.country_Code.substring(0, 3)
                              : ""}
                            -{item.variant}-{item.hdset_Id}
                            <br></br>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "102px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {/* {_getCountryName(item.hdsetPart_No)} */}
                              {/* {getCountryName(item.country_Code)} */}
                              {item.country_Code
                                ? item.country_Code.substring(4)
                                : ""}
                            </div>
                          </>
                        </TableCell>
                        <TableCell>{item.hdsetRev_No}</TableCell>
                        <TableCell>{item.hW_Rev}</TableCell>
                        <TableCell>
                          {moment(item.hdsetBuild_Date).format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell>{item.hdsetBuild_Location}</TableCell>
                        <TableCell>{item.hdsetRouter}</TableCell>
                        <TableCell>
                          {item.isActive ? (
                            <span className={"label color-green"}>Active</span>
                          ) : (
                            <span className={"label color-red"}>Blocked</span>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={item.isAssociated_WithSystem}
                            onClick={() =>
                              navigate("/updateHeadset/" + item.id)
                            }
                            variant="contained"
                            color="primary"
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                )
            ) : (
              <TableRow hover>
                <TableCell colSpan={9} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default HeadsetAddHeadset;
