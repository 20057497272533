import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreateSystem } from "../../../services/systems.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GetStores } from "../../../services/store.handlers";

const AddSystem = () => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [stores, setStores] = React.useState([]);
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
    store_ID: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
    store_ID:""
  });

  React.useEffect(() => {
    fetchStores();
  }, []);

  const setStoreID = (value) => {
    if (value.length > 0 === "") {
      setFormErrors({
        ...formErrors,
        store_ID: "Store ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        store_ID: "",
      });
    }
    setFormData({ ...formData, store_ID: value });
  };

  const fetchStores = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetStores(options).then((response) => {
      if (response.status) {
        setStores(response.data);
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateSystem(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: "",
    store_ID: 0,
    isActive: 1,
        });
        setFormErrors({
          system_Id: "",
    gen_Id: "",
    hdset_Id: "",
    tablet_Id: ""
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New System</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="system_Id">
                System Id
                </InputLabel>
                <TextField
                  id="system_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      system_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="gen_Id">
                Generator Id
                </InputLabel>
                <TextField
                  id="gen_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.gen_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, gen_Id: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="hdset_Id"
                >
                  Headset Id
                </InputLabel>
                <TextField
                  id="hdset_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.hdset_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hdset_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="tablet_Id"
                >
                  Tablet Id
                </InputLabel>
                <TextField
                  id="tablet_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.tablet_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tablet_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Stores
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  // multiple
                  // value={formData.store_Id ? formData.store_Id.split(",") : []}
                  onChange={(e) => {
                    setStoreID(e.target.value);
                  }}
                  error
                  // helperText={formErrors.store_Id}
                >
                  {stores.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.store_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New System
                  {loader && <Loader color={"#fff"} />}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddSystem;
