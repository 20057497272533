import * as React from "react";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  addPartNumber,
  addRevNumber,
  addTablet,
  getInvHarness,
  getInvPartNumber,
  getPartNumber,
  getRevNumber,
} from "../../../services/systems.handlers";
import moment from "moment";
import Loader from "../../../Components/Loader";
import { Link, useNavigate } from "react-router-dom";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { _getCountryName, getCountryName } from "../../../constants";
import DashboardLayout from "../../../Layout/DashboardLayout";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";

function AddTablet(prop) {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [TabletRouterFile, setTabletRouterFile] = React.useState();
  const [partItem, setPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  // const [partData, setpartData] = React.useState({
  //   part_No: "",
  //   isActive: 0,
  // });
  // const [revData, setrevData] = React.useState({
  //   rev_No: "",
  //   isActive: 0,
  //   isDefault: 0,
  // });
  const [formData, setFormData] = React.useState({
    tablet_Id: "",
    tabletPart_No: "",
    tabletRev_No: "",
    tabletBuild_Date: moment(new Date()).format("YYYY/MM/DD"),
    tabletBuild_Location: "",
    tabletRouter: "",
    component_Name: "Tablet",
    component_No: "",
    country_Code: "",
    Lot_No: "",
    Variant: "",
    guI_Rev: "",
    apI_Rev: "",
    system_Rev: "",
    os_Rev: "",
    sw_Rev: "",
    isActive: 1,
    macumira: "",
    macumira_Rev: "",
    macumira_BuildDate: "",
    macumira_Api: "",
    macumira_Api_Sw_Rev: "",
    macumira_Api_BuildDate: "",
    macumira_Software_Rev: "",
    macumira_Operating_System: "",
    macumira_Operating_System_BuildDate: "",
  });
  React.useEffect(() => {
    partNo();
    revNo();
  }, []);

  const tabletPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const tabletPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100161" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "tablet"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const tabletDefaultVariant = {
    options: tabletPropVariant,
    getOptionLabel: (option) => option,
  };

  const tabletDefaultCode = {
    options: tabletPropCode,
    getOptionLabel: (option) => option,
  };
  const defaultPropsLotNo = {
    options: partItem,
    getOptionLabel: (option) => option.lot_No,
  };
  const defaultPropsSlNo = {
    options: partItem,
    getOptionLabel: (option) => option.sl_No.toString(),
  };
  function getDefaultValueCountryCodeVariation(prop) {
    prop?.every((event) => {
      if (
        event.component_No === "100161"
        // event.part_Desciption_Name.toLowerCase().trim() === "tablet"
      ) {
        if (
          event.country_Code === "000-International" ||
          event.country_Code === "000"
        ) {
          setFormData({
            ...formData,
            component_No: event.component_No,
            Variant: event.variant,
            country_Code: event.country_Code,
          });
          return false;
        } else {
          setFormData({
            ...formData,
            component_No: event.component_No,
            Variant: event.variant,
            country_Code: event.country_Code,
          });
        }
      }
      return true;
    });
  }

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
        getDefaultValueCountryCodeVariation(response.data);
      }
    });
  };

  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
      }
    });
  };

  const submitForm = () => {
    const fileData = new FormData();
    fileData.append("TabletRouterFile", TabletRouterFile);
    fileData.append("TabletRev_No", formData.tabletRev_No);
    fileData.append("Tablet_Id", formData.tablet_Id);
    fileData.append("TabletBuild_Location", formData.tabletBuild_Location);
    fileData.append("TabletBuild_Date", formData.tabletBuild_Date);
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.Lot_No);
    fileData.append("Variant", formData.Variant);
    fileData.append("Sl_No", formData.tablet_Id);
    fileData.append("GUI_Rev", formData.guI_Rev);
    fileData.append("API_Rev", formData.apI_Rev);
    fileData.append("System_Rev", formData.system_Rev);
    fileData.append("OS", formData.os_Rev);
    fileData.append("SW_Rev", formData.sw_Rev);
    fileData.append("Macumira", formData.macumira);
    fileData.append("Macumira_Rev", formData.macumira_Rev);
    fileData.append("Macumira_BuildDate", formData.macumira_BuildDate);
    fileData.append("Macumira_Api", formData.macumira_Api);
    fileData.append("Macumira_Api_Sw_Rev", formData.macumira_Api_Sw_Rev);
    fileData.append("Macumira_Api_BuildDate", formData.macumira_Api_BuildDate);
    fileData.append(
      "Macumira_Operating_System",
      formData.macumira_Operating_System
    );
    fileData.append("Macumira_Software_Rev", formData.macumira_Software_Rev);
    fileData.append(
      "Operating_System_BuildDate",
      formData.macumira_Operating_System_BuildDate
    );
    fileData.append("IsActive", formData.isActive);

    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    addTablet(fileData, option).then((response) => {
      if (response.status) {
        setAlert({
          ...alert,
          success: "Data has been submitted successfully",
        });
        setFormData({
          tablet_Id: "",
          tabletPart_No: "",
          tabletRev_No: "",
          tabletBuild_Date: "",
          tabletBuild_Location: "",
          tabletRouter: "",
        });
        setLoader(false);
        // prop.getData();
        setTimeout(() => {
          setAlert({ ...alert, success: "" });
          navigate("/tabletAddTablet");
        }, 2000);
      } else {
        setLoader(false);
        setAlert({ ...alert, error: "Invalid Details" });
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 3000);
      }
    });
  };

  return (
    <>
      <DashboardLayout>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Add New Tablet</h3>
            </Grid>
            <ComponentName name="Part No" value={formData.component_No} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...tabletDefaultCode}
                  value={formData.country_Code}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      country_Code: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...tabletDefaultVariant}
                  value={formData.Variant}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      Variant: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={
                    (props, option) => (
                      // option.component_No === hdmiData.pcbaToHdmi && (
                      <div
                        // key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                        {...props}
                      >
                        <div>{option}</div>
                      </div>
                    )
                    // )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsLotNo}
                  // value={formData.pcbaToHdmi}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      Lot_No: newValue?.lot_No,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === formData.component_No && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsSlNo}
                  // value={formData.pcbaToHdmi}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      tablet_Id: newValue?.sl_No || "",
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === formData.component_No && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.sl_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            {/* <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="tablet_Id">
                Ser. No.
              </InputLabel>
              <TextField
                name="tablet_Id"
                fullWidth
                value={formData.tablet_Id}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid> */}
            {/* <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="tabletPart_No">
                Part No
                <Link
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpen("Part No")}
                >
                  add new
                </Link>
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.tabletPart_No}
                // renderValue={(selected) => {
                //   return partItem.find((x) => x.part_No === selected).part_No;
                // }}
                onChange={(e) =>
                  setFormData({ ...formData, tabletPart_No: e.target.value })
                }
              >
                {partItem.map((item, index) => (
                  <MenuItem key={index} value={item.part_No}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>{item.part_No}</div>
                      <div>{_getCountryName(item.part_No)}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
           */}
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="tabletBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  name="tabletBuild_Location"
                  fullWidth
                  value={formData.tabletBuild_Location}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tabletRev_No">
                  Rev
                  {/* <Link
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpen("Rev No")}
                >
                  add new
                </Link> */}
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.tabletRev_No}
                  onChange={(e) =>
                    setFormData({ ...formData, tabletRev_No: e.target.value })
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {revItem.map((item, index) => (
                    <MenuItem key={index} value={item.rev_No}>
                      {item.rev_No}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="os_Rev">
                  OS Rev
                </InputLabel>

                <TextField
                  name="os_Rev"
                  fullWidth
                  value={formData.os_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="sw_Rev">
                  SW Rev
                </InputLabel>

                <TextField
                  name="sw_Rev"
                  fullWidth
                  value={formData.sw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="tabletBuild_Date"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.tabletBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tabletBuild_Date: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                alignSelf: "center",
              }}
            >
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="tabletRouter">
                  Router
                </InputLabel>
                <TextField
                  style={{ height: 44, marginTop: 2 }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={(e) => setTabletRouterFile(e.target.files[0])}
                  // error={alert.error}
                  // helperText={alert.error}
                  variant="standard"
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira">
                  Macumira
                </InputLabel>

                <TextField
                  name="macumira"
                  fullWidth
                  value={formData.macumira}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira_Rev">
                  Rev
                </InputLabel>

                <TextField
                  name="macumira_Rev"
                  fullWidth
                  value={formData.macumira_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="tabletBuild_Date"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.macumira_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        macumira_BuildDate: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="macumira_Api">
                  Macumira API
                </InputLabel>

                <TextField
                  name="macumira_Api"
                  fullWidth
                  value={formData.macumira_Api}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Api_Sw_Rev"
                >
                  SW Rev
                </InputLabel>

                <TextField
                  name="macumira_Api_Sw_Rev"
                  fullWidth
                  value={formData.macumira_Api_Sw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="macumira_Api_BuildDate"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.macumira_Api_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        macumira_Api_BuildDate: moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Operating_System"
                >
                  Operating System
                </InputLabel>

                <TextField
                  name="macumira_Operating_System"
                  fullWidth
                  value={formData.macumira_Operating_System}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="macumira_Software_Rev"
                >
                  SW Rev
                </InputLabel>

                <TextField
                  name="macumira_Software_Rev"
                  fullWidth
                  value={formData.macumira_Software_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ margin: 0 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="macumira_Operating_System_BuildDate"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.macumira_Operating_System_BuildDate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        macumira_Operating_System_BuildDate:
                          moment(e).format("YYYY/MM/DD"),
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}></Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="guI_Rev">
                  GUI Rev
                </InputLabel>

                <TextField
                  name="guI_Rev"
                  fullWidth
                  value={formData.guI_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="apI_Rev">
                  API Rev
                </InputLabel>

                <TextField
                  name="apI_Rev"
                  fullWidth
                  value={formData.apI_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="system_Rev">
                  System Rev
                </InputLabel>

                <TextField
                  name="system_Rev"
                  fullWidth
                  value={formData.system_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <Stack spacing={2} direction="row">
                <Button
                  disabled={loader}
                  variant="contained"
                  style={{
                    marginTop: 24,
                    height: "40px",
                    gap: "5px",
                    alignItems: "center",
                  }}
                  onClick={submitForm}
                >
                  <AddIcon /> Add
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    style={{ marginTop: 24 }}
                    disabled={loader}
                    onClick={() => navigate(-1)}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </DashboardLayout>
    </>
  );
}

export default AddTablet;
