import * as React from "react";

import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import {
  addGenerator,
  addInvPartNumber,
  addPartNumber,
  addRevNumber,
  getCable,
  getInvHarness,
  getInvPartNumber,
  getPartNumber,
  getRevNumber,
} from "../../../services/systems.handlers";
import moment from "moment";
import Loader from "../../../Components/Loader";
import { Link, useNavigate } from "react-router-dom";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  _getCountryName,
  _getNewCountryName,
  countryName,
  getCountryName,
} from "../../../constants";
import DashboardLayout from "../../../Layout/DashboardLayout";
import PartPopover from "../../../Components/PartPopover";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";

function AddGenerator(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const navigate = useNavigate();
  const [GenRouter, setGenRouterFile] = React.useState();
  const [partItem, setPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const [cables, setCables] = React.useState([]);
  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  // const [partData, setpartData] = React.useState({
  //   part_No: "",
  //   isActive: 0,
  // });

  const [revData, setrevData] = React.useState({
    rev_No: "",
    isActive: 0,
    isDefault: 0,
  });
  const [hdmiData, setHdmiData] = React.useState({
    id: "",
    pcbaToHdmi: "",
    pcbaToHdmiVariation: "",
    pcbaToHdmiCode: "",
    pcbaToHdmiSl: "",
    pcbaLot_No: "",
  });
  const [mainData, setMainData] = React.useState({
    id: "",
    mainPower: "",
    mainPowerVariation: "",
    mainPowerCode: "",
    mainPowerSl: "",
    mainPowerLot_No: "",
  });
  const [mainSwitchData, setSwitchMainData] = React.useState({
    id: "",
    mainPowerSwitch: "",
    mainPowerSwitchVariation: "",
    mainPowerSwitchCode: "",
    mainPowerSwitchSl: "",
    mainPowerSwitchLot_No: "",
  });
  const [usbcData, setUsbcData] = React.useState({
    id: "",
    usbcToPcba: "",
    usbcToPcbaVariation: "",
    usbcToPcbaCode: "",
    usbcToPcbaSl: "",
    usbcLot_No: "",
  });
  const [powerData, setPowerData] = React.useState({
    id: "",
    powerToPcba: "",
    powerToPcbaVariation: "",
    powerToPcbaCode: "",
    powerToPcbaSl: "",
    powerLot_No: "",
  });
  const [emergencyData, setEmergencyData] = React.useState({
    id: "",
    emergencySwitch: "",
    emergencySwitchVariation: "",
    emergencySwitchCode: "",
    emergencySwitchSl: "",
    emergencyLot_No: "",
  });
  const [statusLedData, setStatusLedData] = React.useState({
    id: "",
    statusLed: "",
    statusLedVariation: "",
    statusLedCode: "",
    statusLedSl: "",
    statusLot_No: "",
  });
  const [generatorPcbData, setGeneratorPcbData] = React.useState({
    id: "",
    generatorPCB: "",
    generatorPCBVariation: "",
    generatorPCBCode: "",
    generatorPCBSl: "",
    generatorPCBLot_No: "",
    pcb_Rev: "",
    pcb_Eco: "",
  });
  const [powerSupplyData, setPowerSupplyData] = React.useState({
    id: "",
    powerSupply: "",
    powerSupplyVariation: "",
    powerSupplyCode: "",
    powerSupplySl: "",
    powerSupplyLot_No: "",
    power_Rev: "",
    power_Eco: "",
  });
  const [formData, setFormData] = React.useState({
    gen_Id: "",
    genPart_No: "",
    genRev_No: "",
    genBuild_Date: moment(new Date()).format("YYYY/MM/DD"),
    genBuild_Location: "",
    genRouter: "",
    component_Name: "Generator",
    component_No: "",
    country_Code: "",
    Lot_No: "",
    Variant: "",
    hw_Rev: "",
    sw_Rev: "",
    fw_Generator: "",
    fw_Country: "",
    fw_Rev: "",
    fw_BuildDate: "",
  });

  React.useEffect(() => {
    partNo();
    revNo();
  }, []);

  // const StatusLedHarnessOptions = partItem.filter(
  //   (item, index) =>
  //     item.part_Desciption_Name.toLowerCase().trim() === "status led harness" &&
  //     index === partItem.findIndex((o) => item.component_No === o.component_No)
  // );

  const generatorC_Code = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100159" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() === "generator"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const hdmiCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100167" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "pcba to hdmi harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const mainCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100165" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "main power harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const powerCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100164" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "main power switch harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const usbcCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100171" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "usbc to pcba harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const powerPcbaCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100172" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "power to pcba harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const emergencyCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100169" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "emergency switch harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const statusCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100170" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "status led harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const generatorPcbCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100135" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() === "generator pcb"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const supplyAssemblyCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100188" && item.country_Code
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "power supply assembly"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const generatorVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100159" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() === "generator"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const hdmiVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100167" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "pcba to hdmi harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const mainVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100165" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "main power harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const powerVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100164" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "main power switch harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const usbcVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100171" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "usbc to pcba harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const powerPcbaVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100172" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "power to pcba harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const emergencyVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100169" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "emergency switch harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const statusVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100170" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "status led harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const generatorVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100135" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() === "generator pcb"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const supplyAssemblyVariation = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100188" && item.variant
            // item?.part_Description_Name.toLowerCase().trim() ===
            //   "power supply assembly"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const generatorDefaultVariant = {
    options: generatorVariant,
    getOptionLabel: (option) => option,
  };
  const hdmiDefaultVariation = {
    options: hdmiVariation,
    getOptionLabel: (option) => option,
  };

  const mainPowerDefaultVariation = {
    options: mainVariation,
    getOptionLabel: (option) => option,
  };

  const mainPowerSwitchDefaultVariation = {
    options: powerVariation,
    getOptionLabel: (option) => option,
  };

  const usbcDefaultVariation = {
    options: usbcVariation,
    getOptionLabel: (option) => option,
  };

  const powerToPcbaDefaultVariation = {
    options: powerPcbaVariation,
    getOptionLabel: (option) => option,
  };

  const emergencyDefaultVariation = {
    options: emergencyVariation,
    getOptionLabel: (option) => option,
  };

  const statusLedDefaultVariation = {
    options: statusVariation,
    getOptionLabel: (option) => option,
  };

  const supplyAssemblydefaultVariation = {
    options: supplyAssemblyVariation,
    getOptionLabel: (option) => option,
  };
  const generatorPCBDefaultVariation = {
    options: generatorVariation,
    getOptionLabel: (option) => option,
  };

  const generatorCountryCode = {
    options: generatorC_Code,
    getOptionLabel: (option) => option,
  };
  const hdmiCountryCode = {
    options: hdmiCode,
    getOptionLabel: (option) => option,
  };
  const mainPowerCountryCode = {
    options: mainCode,
    getOptionLabel: (option) => option,
  };

  const mainPowerSwitchCountryCode = {
    options: powerCode,
    getOptionLabel: (option) => option,
  };
  const usbcCountryCode = {
    options: usbcCode,
    getOptionLabel: (option) => option,
  };
  const powerPcbaCountryCode = {
    options: powerPcbaCode,
    getOptionLabel: (option) => option,
  };
  const emergencyCountryCode = {
    options: emergencyCode,
    getOptionLabel: (option) => option,
  };
  const statusLedCountryCode = {
    options: statusCode,
    getOptionLabel: (option) => option,
  };
  const generatorPcbCountryCode = {
    options: generatorPcbCode,
    getOptionLabel: (option) => option,
  };
  const powerSupplyAssemblyCountryCode = {
    options: supplyAssemblyCode,
    getOptionLabel: (option) => option,
  };

  const defaultPropsVariation = {
    options: partItem,
    getOptionLabel: (option) => option.variation,
  };
  const defaultPropsCountryCode = {
    options: partItem,
    getOptionLabel: (option) => option.country_Code,
  };

  // const defaultPropsSlNoPcbaToHdmi = {
  //   options: PcbaToHdmiSlNo,
  //   getOptionLabel: (option) => option.sl_No.toString(),
  // };

  const defaultPropsSlNo = {
    options: partItem,
    getOptionLabel: (option) => option.sl_No,
  };
  const defaultPropsLotNo = {
    options: partItem,
    getOptionLabel: (option) => option.lot_No,
  };

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getDefaultValueCountryCodeVariation = (prop) => {
    prop?.forEach((event) => {
      if (
        event.component_No === "100167"
        // event.part_Description_Name.toLowerCase().trim() ===
        //   "pcba to hdmi harness"
      ) {
        setHdmiData({
          ...hdmiData,
          id: event.id,
          pcbaToHdmi: event.component_No,
          pcbaToHdmiCode: event.country_Code,
          pcbaToHdmiVariation: event.variant,
        });
      }
      if (
        event.component_No === "100165"
        // event.part_Description_Name.toLowerCase().trim() === "main power harness"
      ) {
        setMainData({
          ...mainData,
          id: event.id,
          mainPower: event.component_No,
          mainPowerCode: event.country_Code,
          mainPowerVariation: event.variant,
        });
      }
      if (
        event.component_No === "100164"
        // event.part_Description_Name.toLowerCase().trim() ===
        //   "main power switch harness"
      ) {
        setSwitchMainData({
          ...mainSwitchData,
          id: event.id,
          mainPowerSwitch: event.component_No,
          mainPowerSwitchCode: event.country_Code,
          mainPowerSwitchVariation: event.variant,
        });
      }
      if (
        event.component_No === "100171"
        // event.part_Description_Name.toLowerCase().trim() ===
        //   "usbc to pcba harness"
      ) {
        setUsbcData({
          ...usbcData,
          id: event.id,
          usbcToPcba: event.component_No,
          usbcToPcbaCode: event.country_Code,
          usbcToPcbaVariation: event.variant,
        });
      }
      if (
        event.component_No === "100172"
        // event.part_Description_Name.toLowerCase().trim() ===
        //   "power to pcba harness"
      ) {
        setPowerData({
          ...powerData,
          id: event.id,
          powerToPcba: event.component_No,
          powerToPcbaCode: event.country_Code,
          powerToPcbaVariation: event.variant,
        });
      }
      if (
        event.component_No === "100169"
        // event.part_Description_Name.toLowerCase().trim() ===
        //   "emergency switch harness"
      ) {
        setEmergencyData({
          ...emergencyData,
          id: event.id,
          emergencySwitch: event.component_No,
          emergencySwitchCode: event.country_Code,
          emergencySwitchVariation: event.variant,
        });
      }
      if (
        event.component_No === "100170"
        // event.part_Description_Name.toLowerCase().trim() === "status led harness"
      ) {
        setStatusLedData({
          ...statusLedData,
          id: event.id,
          statusLed: event.component_No,
          statusLedCode: event.country_Code,
          statusLedVariation: event.variant,
        });
      }
      if (
        event.component_No === "100135"
        // event?.part_Description_Name.toLowerCase().trim() === "generator pcb"
      ) {
        setGeneratorPcbData({
          ...generatorPcbData,
          id: event.id,
          generatorPCB: event.component_No,
          generatorPCBCode: event.country_Code,
          generatorPCBVariation: event.variant,
        });
      }
      if (
        event.component_No === "100188"
        // event?.part_Description_Name.toLowerCase().trim() ===
        //   "power supply assembly"
      ) {
        setPowerSupplyData({
          ...powerSupplyData,
          id: event.id,
          powerSupply: event.component_No,
          powerSupplyCode: event.country_Code,
          powerSupplyVariation: event.variant,
        });
      }
      if (
        event.component_No === "100159"
        // event?.part_Description_Name.toLowerCase().trim() === "generator"
      ) {
        setFormData({
          ...formData,
          component_No: event.component_No,
          country_Code: event.country_Code,
          Variant: event.variant,
        });
      }
    });
  };

  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
        getDefaultValueCountryCodeVariation(response.data);
      }
    });
  };

  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
      }
    });
  };

  const submitForm = () => {
    const componentData = JSON.stringify([
      {
        // component_Name: "PCBA to HDMI Harness",
        component: "Generator",
        part_Description_Name: "PCBA to HDMI Harness",
        id: hdmiData.id,
        component_No: hdmiData.pcbaToHdmi,
        country_Code: hdmiData.pcbaToHdmiCode,
        variant: hdmiData.pcbaToHdmiVariation,
        // sl_No: hdmiData.pcbaToHdmiSl,
        lot_No: hdmiData.pcbaLot_No,
      },
      {
        // component_Name: "Main Power Harness",
        component: "Generator",
        part_Description_Name: "Main Power Harness",
        id: mainData.id,
        component_No: mainData.mainPower,
        country_Code: mainData.mainPowerCode,
        variant: mainData.mainPowerVariation,
        // sl_No: mainData.mainPowerSl,
        lot_No: mainData.mainPowerLot_No,
      },
      {
        // component_Name: "Main Power Switch Harness",
        component: "Generator",
        part_Description_Name: "Main Power Switch Harness",
        id: mainSwitchData.id,
        component_No: mainSwitchData.mainPowerSwitch,
        country_Code: mainSwitchData.mainPowerSwitchCode,
        variant: mainSwitchData.mainPowerSwitchVariation,
        // sl_No: mainSwitchData.mainPowerSwitchSl,
        lot_No: mainSwitchData.mainPowerSwitchLot_No,
      },
      {
        // component_Name: "USBC to PCBA Harness",
        component: "Generator",
        part_Description_Name: "USBC to PCBA Harness",
        id: usbcData.id,
        component_No: usbcData.usbcToPcba,
        country_Code: usbcData.usbcToPcbaCode,
        variant: usbcData.usbcToPcbaVariation,
        // sl_No: usbcData.usbcToPcbaSl,
        lot_No: usbcData.usbcLot_No,
      },
      {
        // component_Name: "Power to PCBA Harness",
        component: "Generator",
        part_Description_Name: "Power to PCBA Harness",
        id: powerData.id,
        component_No: powerData.powerToPcba,
        country_Code: powerData.powerToPcbaCode,
        variant: powerData.powerToPcbaVariation,
        // sl_No: powerData.powerToPcbaSl,
        lot_No: powerData.powerLot_No,
      },
      {
        // component_Name: "Emergency Switch Harness",
        component: "Generator",
        part_Description_Name: "Emergency Switch Harness",
        id: emergencyData.id,
        component_No: emergencyData.emergencySwitch,
        country_Code: emergencyData.emergencySwitchCode,
        variant: emergencyData.emergencySwitchVariation,
        // sl_No: emergencyData.emergencySwitchSl,
        lot_No: emergencyData.emergencyLot_No,
      },
      {
        // component_Name: "Status LED Harness",
        component: "Generator",
        part_Description_Name: "Status LED Harness",
        id: statusLedData.id,
        component_No: statusLedData.statusLed,
        country_Code: statusLedData.statusLedCode,
        variant: statusLedData.statusLedVariation,
        // sl_No: statusLedData.statusLedSl,
        lot_No: statusLedData.statusLot_No,
      },
      {
        // component_Name: "Generator PCB",
        component: "Generator",
        part_Description_Name: "Generator PCB",
        id: generatorPcbData.id,
        component_No: generatorPcbData.generatorPCB,
        country_Code: generatorPcbData.generatorPCBCode,
        variant: generatorPcbData.generatorPCBVariation,
        sl_No: generatorPcbData.generatorPCBSl,
        lot_No: generatorPcbData.generatorPCBLot_No,
        // gen_pcb_Rev: generatorPcbData.pcb_Rev,
        // gen_pcb_Eco: generatorPcbData.pcb_Eco,
      },
      {
        // component_Name: "Power Supply Assembly",
        component: "Generator",
        part_Description_Name: "Power Supply Assembly",
        id: powerSupplyData.id,
        component_No: powerSupplyData.powerSupply,
        country_Code: powerSupplyData.powerSupplyCode,
        variant: powerSupplyData.powerSupplyVariation,
        sl_No: powerSupplyData.powerSupplySl,
        lot_No: powerSupplyData.powerSupplyLot_No,
        // power_Rev: powerSupplyData.power_Rev,
        // power_Eco: powerSupplyData.power_Eco,
      },
    ]);
    const fileData = new FormData();
    fileData.append("GenRouterFile", GenRouter);
    fileData.append("Gen_Id", formData.gen_Id);
    fileData.append("GenPart_No", formData.genPart_No);
    fileData.append("GenRouter", "");
    fileData.append("GenRev_No", formData.genRev_No);
    fileData.append("GenBuild_Location", formData.genBuild_Location);
    fileData.append("GenBuild_Date", formData.genBuild_Date);
    fileData.append("Component_Name", formData.component_Name);
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.Lot_No);
    fileData.append("Variant", formData.Variant);
    fileData.append("HW", formData.hw_Rev);
    fileData.append("SW_Rev", formData.sw_Rev);
    fileData.append("Generator_FW", formData.fw_Generator);
    fileData.append("FW_Country", formData.fw_Country);
    fileData.append("FW_BuildDate", formData.fw_BuildDate);
    fileData.append("Fw_Rev", formData.fw_Rev);
    fileData.append("IsAssociated_WithSystem", false);
    // fileData.append("Id");
    fileData.append("Components", componentData);

    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };

    if (formData.gen_Id !== "") {
      addGenerator(fileData, option).then((response) => {
        if (response.status) {
          setAlert({
            ...alert,
            success: "Data has been submitted successfully",
          });
          setFormData({
            gen_Id: "",
            genPart_No: "",
            genRev_No: "",
            genBuild_Date: "",
            genBuild_Location: "",
            genRouter: "",
          });
          setLoader(false);
          // prop.getData();
          setTimeout(() => {
            setAlert({ ...alert, success: "" });
          }, 3000);
          navigate(-1);
        } else {
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 3000);
        }
      });
    } else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <h3 style={{ marginTop: "0px" }}>Add New Generator</h3>
        <Grid container spacing={2}>
          <ComponentName name="Part No" value={formData?.component_No} />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                // {...defaultPropsCountryCode}
                {...generatorCountryCode}
                value={formData.country_Code}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    country_Code: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...generatorDefaultVariant}
                value={formData.Variant}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Variant: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Lot_No: newValue?.lot_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    gen_Id: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="genBuild_Location">
                Build Location
              </InputLabel>

              <TextField
                name="genBuild_Location"
                fullWidth
                value={formData.genBuild_Location}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={1.4}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="genRev_No">
                Rev
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.genRev_No}
                onChange={(e) =>
                  setFormData({ ...formData, genRev_No: e.target.value })
                }
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {revItem.map((item, index) => (
                  <MenuItem key={index} value={item.rev_No}>
                    {item.rev_No}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="hw_Rev">
                ECO
              </InputLabel>

              <TextField
                name="hw_Rev"
                fullWidth
                value={formData.hw_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="sw_Rev">
                SW Rev
              </InputLabel>

              <TextField
                name="sw_Rev"
                fullWidth
                value={formData.sw_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="genBuild_Date">
                  Build Date
                </InputLabel>
                <DesktopDatePicker
                  inputFormat="YYYY/MM/DD"
                  value={formData.genBuild_Date}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      genBuild_Date: moment(e).format("YYYY/MM/DD"),
                    })
                  }
                  className={"form-input"}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              alignSelf: "center",
            }}
          >
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="genRouter">
                Router
              </InputLabel>
              <TextField
                style={{ height: 44, marginTop: 2 }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(e) => setGenRouterFile(e.target.files[0])}
                // error={alert.error}
                // helperText={alert.error}
                variant="standard"
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="fw_Generator">
                Generator FW
              </InputLabel>

              <TextField
                name="fw_Generator"
                fullWidth
                value={formData.fw_Generator}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>
          <Grid item xs={2.7}>
            <div className={"form-group"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="fw_Country">
                Country Code
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.fw_Country}
                // renderValue={(selected) => {
                //   return generator.find((x) => x.id === selected).gen_Id;
                // }}
                onChange={(e) => {
                  setFormData({ ...formData, fw_Country: e.target.value });
                }}
              >
                {countryName.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>{item}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="fw_Rev">
                FW Rev
              </InputLabel>

              <TextField
                name="fw_Rev"
                fullWidth
                value={formData.fw_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={handleChangeData}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="fw_BuildDate">
                  Build Date
                </InputLabel>
                <DesktopDatePicker
                  inputFormat="YYYY/MM/DD"
                  value={formData.fw_BuildDate}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fw_BuildDate: moment(e).format("YYYY/MM/DD"),
                    })
                  }
                  className={"form-input"}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>

          <ComponentName
            name="Generator PCB"
            value={generatorPcbData?.generatorPCB}
          />
          <Grid item xs={2.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                {...generatorPcbCountryCode}
                value={generatorPcbData.generatorPCBCode}
                onChange={(event, newValue) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    generatorPCBCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={(props, option) => (
                  <div
                    // key={option.id}
                    style={{ display: "flex", gap: "10px" }}
                    {...props}
                  >
                    <>
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </>
                  </div>
                )}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Variant</InputLabel>
              <Autocomplete
                className="form-input2"
                {...generatorPCBDefaultVariation}
                value={generatorPcbData.generatorPCBVariation}
                onChange={(event, newValue) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    generatorPCBVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === generatorPcbData.generatorPCB && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={1.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Lot No</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    generatorPCBLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === generatorPcbData.generatorPCB && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="pcb_Rev">
                Rev
              </InputLabel>

              <TextField
                name="pcb_Rev"
                fullWidth
                value={formData.pcb_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={(event) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    pcb_Rev: event.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={1.2}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="pcb_Eco">
                ECO
              </InputLabel>

              <TextField
                name="pcb_Eco"
                fullWidth
                value={formData.pcb_Eco}
                className={"form-input"}
                variant="outlined"
                onChange={(event) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    pcb_Eco: event.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setGeneratorPcbData({
                    ...generatorPcbData,
                    generatorPCBSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === generatorPcbData.generatorPCB && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Power Supply Assembly"
            value={powerSupplyData?.powerSupply}
          />
          <Grid item xs={2.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                {...powerSupplyAssemblyCountryCode}
                value={powerSupplyData.powerSupplyCode}
                onChange={(event, newValue) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    powerSupplyCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={(props, option) => (
                  <div
                    // key={option.id}
                    style={{ display: "flex", gap: "10px" }}
                    {...props}
                  >
                    <>
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </>
                  </div>
                )}
                // defaultValue={defaultPropsCountryCode}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...supplyAssemblydefaultVariation}
                value={powerSupplyData.powerSupplyVariation}
                onChange={(event, newValue) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    powerSupplyVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === powerSupplyData.powerSupply && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={1.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Lot No</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    powerSupplyLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === powerSupplyData.powerSupply && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="power_Rev">
                Rev
              </InputLabel>

              <TextField
                name="power_Rev"
                fullWidth
                value={formData.power_Rev}
                className={"form-input"}
                variant="outlined"
                onChange={(event) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    power_Rev: event.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={1.2}>
            <div className={"form-group-component"} style={{ marginTop: -10 }}>
              <InputLabel className={"form-label"} htmlFor="power_Eco">
                ECO
              </InputLabel>

              <TextField
                name="power_Eco"
                fullWidth
                value={formData.power_Eco}
                className={"form-input"}
                variant="outlined"
                onChange={(event) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    power_Eco: event.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setPowerSupplyData({
                    ...powerSupplyData,
                    powerSupplySl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === powerSupplyData.powerSupply && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Main Power Switch Harness"
            value={mainSwitchData?.mainPowerSwitch}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                {...mainPowerSwitchCountryCode}
                value={mainSwitchData.mainPowerSwitchCode}
                onChange={(event, newValue) => {
                  setSwitchMainData({
                    ...mainSwitchData,
                    mainPowerSwitchCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.mainPowerSwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Variant</InputLabel>
              <Autocomplete
                className="form-input2"
                {...mainPowerSwitchDefaultVariation}
                value={mainSwitchData.mainPowerSwitchVariation}
                onChange={(event, newValue) => {
                  setSwitchMainData({
                    ...mainSwitchData,
                    mainPowerSwitchVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === mainSwitchData.mainPowerSwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setSwitchMainData({
                    ...mainSwitchData,
                    mainPowerSwitchLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === mainSwitchData.mainPowerSwitch && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setSwitchMainData({
                    ...mainSwitchData,
                    mainPowerSwitchSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === mainSwitchData.mainPowerSwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Main Power Harness"
            value={mainData?.mainPower}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...mainPowerCountryCode}
                value={mainData.mainPowerCode}
                onChange={(event, newValue) => {
                  setMainData({
                    ...mainData,
                    mainPowerCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.mainPower && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...mainPowerDefaultVariation}
                value={mainData.mainPowerVariation}
                onChange={(event, newValue) => {
                  setMainData({
                    ...mainData,
                    mainPowerVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === mainData.mainPower && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setMainData({
                    ...mainData,
                    mainPowerLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === mainData.mainPower && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setMainData({
                    ...mainData,
                    mainPowerSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === mainData.mainPower && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <ComponentName
            name="PCBA to HDMI Harness"
            value={hdmiData?.pcbaToHdmi}
          />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                // {...defaultPropsCountryCode}
                {...hdmiCountryCode}
                value={hdmiData.pcbaToHdmiCode}
                onChange={(event, newValue) => {
                  setHdmiData({
                    ...hdmiData,
                    pcbaToHdmiCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...hdmiDefaultVariation}
                value={hdmiData.pcbaToHdmiVariation}
                onChange={(event, newValue) => {
                  setHdmiData({
                    ...hdmiData,
                    pcbaToHdmiVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setHdmiData({
                    ...hdmiData,
                    pcbaLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setHdmiData({
                    ...hdmiData,
                    pcbaToHdmiSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Emergency Switch Harness"
            value={emergencyData?.emergencySwitch}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...emergencyCountryCode}
                value={emergencyData.emergencySwitchCode}
                onChange={(event, newValue) => {
                  setEmergencyData({
                    ...emergencyData,
                    emergencySwitchCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.emergencySwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...emergencyDefaultVariation}
                value={emergencyData.emergencySwitchVariation}
                onChange={(event, newValue) => {
                  setEmergencyData({
                    ...emergencyData,
                    emergencySwitchVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === emergencyData.emergencySwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setEmergencyData({
                    ...emergencyData,
                    emergencyLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === emergencyData.emergencySwitch && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setEmergencyData({
                    ...emergencyData,
                    emergencySwitchSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === emergencyData.emergencySwitch && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Status LED Harness"
            value={statusLedData?.statusLed}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...statusLedCountryCode}
                value={statusLedData.statusLedCode}
                onChange={(event, newValue) => {
                  setStatusLedData({
                    ...statusLedData,
                    statusLedCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.statusLed && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...statusLedDefaultVariation}
                value={statusLedData.statusLedVariation}
                onChange={(event, newValue) => {
                  setStatusLedData({
                    ...statusLedData,
                    statusLedVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === statusLedData.statusLed && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setStatusLedData({
                    ...statusLedData,
                    statusLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === statusLedData.statusLed && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setStatusLedData({
                    ...statusLedData,
                    statusLedSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === statusLedData.statusLed && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <ComponentName
            name="USBC to PCBA Harness"
            value={usbcData?.usbcToPcba}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...usbcCountryCode}
                value={usbcData.usbcToPcbaCode}
                onChange={(event, newValue) => {
                  setUsbcData({
                    ...usbcData,
                    usbcToPcbaCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.usbcToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...usbcDefaultVariation}
                value={usbcData.usbcToPcbaVariation}
                onChange={(event, newValue) => {
                  setUsbcData({
                    ...usbcData,
                    usbcToPcbaVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === usbcData.usbcToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setUsbcData({
                    ...usbcData,
                    usbcLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === usbcData.usbcToPcba && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setUsbcData({
                    ...usbcData,
                    usbcToPcbaSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === usbcData.usbcToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <ComponentName
            name="Power to PCBA Harness"
            value={powerData?.powerToPcba}
          />

          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Country Code
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...powerPcbaCountryCode}
                value={powerData.powerToPcbaCode}
                onChange={(event, newValue) => {
                  setPowerData({
                    ...powerData,
                    powerToPcbaCode: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.powerToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...powerToPcbaDefaultVariation}
                value={powerData.powerToPcbaVariation}
                onChange={(event, newValue) => {
                  setPowerData({
                    ...powerData,
                    powerToPcbaVariation: newValue,
                  });
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                renderOption={
                  (props, option) => (
                    // option.component_No === powerData.powerToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setPowerData({
                    ...powerData,
                    powerLot_No: newValue?.lot_No || "",
                    id: newValue?.id,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === powerData.powerToPcba && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel
                disabled
                className={"form-label2"}
                htmlFor="pcbaToHdmi"
              >
                Ser. No.
              </InputLabel>
              <Autocomplete
                disabled
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setPowerData({
                    ...powerData,
                    powerToPcbaSl: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === powerData.powerToPcba && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={loader}
                variant="contained"
                style={{
                  // marginTop: 24,
                  height: "40px",
                  gap: "5px",
                  alignItems: "center",
                }}
                onClick={submitForm}
              >
                <AddIcon /> Add
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate(-1)}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default AddGenerator;
