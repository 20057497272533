import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { Alert, InputLabel, MenuItem, Select } from "@mui/material";
import { getCableById, updateCable } from "../../../services/systems.handlers";

function EditCables() {
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  const [formData, setFormData] = React.useState({
    inv_Cables_Id: "",
    inv_CablesPart_No: "",
    inv_CablesName: "",
    inv_Cables_Lot_No: "",
    // inv_Cables_Name: "",
    manufacture_Name: "",
    isActive: 1,
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getCableById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/cable");
      }
    });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateCable(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/cable");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            {error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
          </Grid>
          <h3>Edit Harness Assy</h3>
          <Grid container spacing={3}>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="inv_CablesName">
                  Name
                </InputLabel>
                <TextField
                  name="inv_CablesName"
                  fullWidth
                  value={formData.inv_CablesName}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="inv_Cables_Id">
                  Ser. No.
                </InputLabel>
                <TextField
                  name="inv_Cables_Id"
                  fullWidth
                  value={formData.inv_Cables_Id}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="inv_CablesPart_No"
                >
                  Part No
                </InputLabel>

                <TextField
                  name="inv_CablesPart_No"
                  fullWidth
                  value={formData.inv_CablesPart_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="inv_Cables_Lot_No"
                >
                  Lot No.
                </InputLabel>

                <TextField
                  name="inv_Cables_Lot_No"
                  fullWidth
                  value={formData.inv_Cables_Lot_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="manufacture_Name">
                  Manufacture Name
                </InputLabel>
                <TextField
                  name="manufacture_Name"
                  fullWidth
                  value={formData.manufacture_Name}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row">
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Harness Assy
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/cable")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
}

export default EditCables;
