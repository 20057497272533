import * as React from "react";
import {
  Button,
  Grid,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Alert,
  Paper,
  Stack,
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";

import AddIcon from "@mui/icons-material/Add";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import {
  CreateSystem,
  addPartNumber,
  addRevNumber,
  addSystem,
  getGenerator,
  getGeneratorPcb,
  getHeadset,
  getHeadsetPcb,
  getInvHarness,
  getInvPartNumber,
  getPartNumber,
  getRevNumber,
  getTablet,
} from "../../../services/systems.handlers";
import Loader from "../../../Components/Loader";
import moment from "moment";
import DashboardLayout from "../../../Layout/DashboardLayout";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { Link, useNavigate } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";
import { _getCountryName, getCountryName } from "../../../constants";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";

function AddSystems(props) {
  const authToken = useSelector((state) => state.user.authToken);
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });

  const [formData, setFormData] = React.useState({
    // menu_value: "System",
    system_Id: "",
    sysPart_No: "",
    sysRev_No: "",
    sysBuild_Date: moment(new Date()).format("YYYY/MM/DD"),
    sysBuild_Location: "",
    component_Name: "System",
    component_No: "",
    country_Code: "",
    Lot_No: "",
    Variant: "",

    gen_Id: "",
    genPart_No: "",
    genRev_No: "",
    genBuild_Date: "",
    genBuild_Location: "",

    hdset_Id: "",
    hdsetPart_No: "",
    hdsetRev_No: "",
    hdsetBuild_Date: "",
    hdsetBuild_Location: "",

    tablet_Id: "",
    tabletPart_No: "",
    tabletRev_No: "",
    tabletBuild_Date: "",
    tabletBuild_Location: "",

    sysRouter: "",
    genRouter: "",
    hdsetRouter: "",
    tabletRouter: "",

    gen_PCB_Id: "",
    hdset_PCB_Id: "",
    // part_No: "",
    // rev_No: "",
    // build_Location: "",
    // build_Date: "",
    // router: "000008",
  });

  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  // const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [SysRouterFile, setSysRouterFile] = React.useState();
  const [GenRouterFile, setGenRouterFile] = React.useState();
  const [HdsetRouterFile, setHdsetRouterFile] = React.useState();
  const [TabletRouterFile, setTabletRouterFile] = React.useState();
  const [sysPartItem, setSysPartItem] = React.useState([]);
  const [sysRevItem, setSysRevItem] = React.useState([]);
  const [generator, setGenerator] = React.useState([]);
  const [headset, setHeadset] = React.useState([]);
  const [tablet, setTablet] = React.useState([]);
  const [generatorPcb, setGeneratorPcb] = React.useState([]);
  const [headsetPcb, setHeadsetPcb] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [sysPartData, setSysPartData] = React.useState({
    part_No: "",
    isActive: 0,
  });
  const [sysRevData, setSysRevData] = React.useState({
    rev_No: "",
    isActive: 0,
    isDefault: 0,
  });
  const [selectedGenerator, setSelectedGenerator] = React.useState({
    Gen_Id: "",
    GenBuild_Date: "",
    GenBuild_Location: "",
    GenRev_No: "",
    GenRouter: "",
    GenCountry_Code: "",
  });
  const [selectedHeadset, setSelectedHeadset] = React.useState({
    Hdset_Id: "",
    HdsetBuild_Date: "",
    HdsetBuild_Location: "",
    HdsetRev_No: "",
    HdsetRouter: "",
    HdsetCountry_Code: "",
  });

  const [selectedTablet, setSelectedTablet] = React.useState({
    Tablet_Id: "",
    TabletBuild_Date: "",
    TabletBuild_Location: "",
    TabletRev_No: "",
    TabletRouter: "",
    TabletCountry_Code: "",
  });
  React.useEffect(() => {
    sysPartNo();
    sysRevNo();
    fetchGenerator();
    fetchHeadset();
    fetchTablet();
    // fetchGeneratorPcb();
    // fetchHeadsetPcb();
  }, []);

  const systemPropCode = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [sysPartItem]
  );

  const systemPropVariant = React.useMemo(
    () =>
      sysPartItem
        .filter((item, index) => {
          return (
            item.component_No === "100163" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "system"
          );
        })
        .map((i) => i.variant),
    [sysPartItem]
  );

  const systemDefaultVariant = {
    options: systemPropVariant,
    getOptionLabel: (option) => option,
  };

  const systemDefaultCode = {
    options: systemPropCode,
    getOptionLabel: (option) => option,
  };
  const defaultPropsLotNo = {
    options: sysPartItem,
    getOptionLabel: (option) => option.lot_No,
  };
  const defaultPropsSlNo = {
    options: sysPartItem,
    getOptionLabel: (option) => option.sl_No.toString(),
  };

  function getDefaultValueCountryCodeVariation(prop) {
    prop?.every((event) => {
      if (
        event.component_No === "100163"
        // event.part_Desciption_Name.toLowerCase().trim() === "system"
      ) {
        if (
          event.country_Code === "000-International" ||
          event.country_Code === "000"
        ) {
          setFormData({
            ...formData,
            component_No: event.component_No,
            Variant: event.variant,
            country_Code: event.country_Code,
          });
          return false;
        } else {
          setFormData({
            ...formData,
            component_No: event.component_No,
            Variant: event.variant,
            country_Code: event.country_Code,
          });
        }
      }
      return true;
    });
  }

  const handleOnchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchGenerator = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGenerator(options).then((response) => {
      if (response.status) {
        setGenerator(response.data);
      }
    });
  };

  const fetchHeadset = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadset(options).then((response) => {
      if (response.status) {
        setHeadset(response.data);
      }
    });
  };

  const fetchTablet = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getTablet(options).then((response) => {
      if (response.status) {
        setTablet(response.data);
      }
    });
  };

  const fetchGeneratorPcb = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGeneratorPcb(options).then((response) => {
      if (response.status) {
        setGeneratorPcb(response.data);
      }
    });
  };

  const fetchHeadsetPcb = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadsetPcb(options).then((response) => {
      if (response.status) {
        setHeadsetPcb(response.data);
      }
    });
  };

  const submitNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    if (open.popOver === "Part No" && sysPartData.part_No !== "") {
      addPartNumber(sysPartData, options).then((response) => {
        if (response.status) {
          setOpen(false);
          setSysPartData({
            part_No: "",
            isActive: 0,
          });
          sysPartNo();
        }
      });
    } else if (open.popOver === "Rev No" && sysRevData.rev_No !== "") {
      addRevNumber(sysRevData, options).then((response) => {
        if (response.status) {
          if (response.data.isDefault === 1) {
            setFormData({ ...formData, sysRev_No: response.data.rev_No });
          }
          setOpen(false);
          setSysRevData({
            rev_No: "",
            isActive: 0,
            isDefault: 0,
          });
          sysRevNo();
        }
      });
    } else {
      setNumberError("Enter Number");
      setTimeout(() => {
        setNumberError("");
      }, 2000);
    }
  };

  const sysPartNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setSysPartItem(response.data);
        getDefaultValueCountryCodeVariation(response.data);
      }
    });
  };

  const sysRevNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setSysRevItem(response.data);
        // response.data?.forEach((event) => {
        //   if (event.isDefault === 1) {
        //     setFormData({ ...formData, sysRev_No: event.rev_No });
        //   }
        // });
      }
    });
  };

  const submitForm = () => {
    setOpen(false);
    const fileData = new FormData();
    fileData.append("SysRouterFile", SysRouterFile);
    fileData.append("GenRouterFile", GenRouterFile);
    fileData.append("HdsetRouterFile", HdsetRouterFile);
    fileData.append("TabletRouterFile", TabletRouterFile);
    fileData.append("Gen_PCBRouterFile", "");
    fileData.append("Hdset_PCBRouterFile", "");
    fileData.append("System_Id", formData.system_Id);
    fileData.append("Gen_Id", selectedGenerator.Gen_Id);
    fileData.append("Gen_PCB_Id", "");
    fileData.append("Hdset_Id", selectedHeadset.Hdset_Id);
    fileData.append("Hdset_PCB_Id", "");
    fileData.append("Tablet_Id", selectedTablet.Tablet_Id);
    fileData.append("SysBuild_Date", formData.sysBuild_Date);
    fileData.append("GenBuild_Date", selectedGenerator.GenBuild_Date);
    fileData.append("HdsetBuild_Date", selectedHeadset.HdsetBuild_Date);
    fileData.append("TabletBuild_Date", selectedTablet.TabletBuild_Date);
    fileData.append("SysBuild_Location", formData.sysBuild_Location);
    fileData.append("GenBuild_Location", selectedGenerator.GenBuild_Location);
    fileData.append("HdsetBuild_Location", selectedHeadset.HdsetBuild_Location);
    fileData.append(
      "TabletBuild_Location",
      selectedTablet.TabletBuild_Location
    );
    fileData.append("SysPart_No", "");
    fileData.append("GenPart_No", "");
    fileData.append("HdsetPart_No", "");
    fileData.append("TabletPart_No", "");
    fileData.append("SysRev_No", formData.sysRev_No);
    fileData.append("GenRev_No", selectedGenerator.GenRev_No);
    fileData.append("HdsetRev_No", selectedHeadset.HdsetRev_No);
    fileData.append("TabletRev_No", selectedTablet.TabletRev_No);
    fileData.append("SysRouter", "");
    fileData.append("GenRouter", selectedGenerator.GenRouter);
    fileData.append("HdsetRouter", selectedHeadset.HdsetRouter);
    fileData.append("TabletRouter", selectedTablet.TabletRouter);
    fileData.append("Component_No", formData.component_No);
    fileData.append("Variant", formData.Variant);
    fileData.append("Lot_No", formData.Lot_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Sl_No", formData.system_Id);

    setLoader(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    if (formData.system_Id !== "") {
      // const partNo = formData?.sysPart_No.substring(7, 10);
      // const find_gen_partNo = generator
      //   ?.find((item) => item.id === formData.gen_Id)
      //   ?.genPart_No.substring(7, 10);

      // const find_hdset_partNo = headset
      //   ?.find((item) => item.id === formData.hdset_Id)
      //   ?.hdsetPart_No.substring(7, 10);

      // const find_tablet_partNo = tablet
      //   ?.find((item) => item.id === formData.tablet_Id)
      //   ?.tabletPart_No.substring(7, 10);

      // const find_gen_PCB_partNo = generatorPcb
      //   ?.find((item) => item.id === formData.gen_PCB_Id)
      //   ?.generator_PCBPart_No.substring(7, 10);

      // const find_hdset_PCB_partNo = headsetPcb
      //   ?.find((item) => item.id === formData.hdset_PCB_Id)
      //   ?.headset_PCBPart_No.substring(7, 10);
      // if (
      // partNo === find_gen_partNo ||
      // partNo === find_hdset_partNo ||
      // partNo === find_tablet_partNo ||
      // partNo === find_gen_PCB_partNo ||
      // partNo === find_hdset_PCB_partNo ||
      // partNo === "000"
      // ) {
      // CreateSystem(formData, options).then((response) => {
      if (
        formData.country_Code === selectedGenerator.GenCountry_Code ||
        formData.country_Code === selectedHeadset.country_Code ||
        formData.country_Code === selectedTablet.TabletCountry_Code ||
        formData.country_Code === ("000" || "000-International")
      ) {
        addSystem(fileData, options).then((response) => {
          if (response.status) {
            setAlert({
              ...alert,
              success: "Data has been submitted successfully",
            });
            setFormData({
              system_Id: "",
              sysPart_No: "",
              sysRev_No: "",
              sysBuild_Date: "",
              sysBuild_Location: "",

              gen_Id: "",
              genPart_No: "",
              genRev_No: "",
              genBuild_Date: "",
              genBuild_Location: "",

              hdset_Id: "",
              hdsetPart_No: "",
              hdsetRev_No: "",
              hdsetBuild_Date: "",
              hdsetBuild_Location: "",

              tablet_Id: "",
              tabletPart_No: "",
              tabletRev_No: "",
              tabletBuild_Date: "",
              tabletBuild_Location: "",

              sysRouter: "",
              genRouter: "",
              hdsetRouter: "",
              tabletRouter: "",

              gen_PCB_Id: "",
              hdset_PCB_Id: "",
              // id: "",
              // part_No: "",
              // rev_No: "",
              // build_Location: "",
              // build_Date: "",
              // router: "000008",
            });
            setLoader(false);

            // props.getData();
            setTimeout(() => {
              setAlert({ ...alert, success: "" });
              navigate("/systems");
            }, 3000);
          } else {
            setOpen(false);
            setLoader(false);
            setAlert({ ...alert, error: "Invalid Details" });
            setTimeout(() => {
              setAlert({ ...alert, error: "" });
            }, 3000);
          }
        });
      } else {
        setLoader(false);
        setAlert({
          ...alert,
          error: "Country should be same for Part No and Generator",
        });
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 3000);
      }
    }
    // else {
    //   setLoader(false);
    //   setAlert({
    //     ...alert,
    //     error: "Country should be same for Part No and Generator",
    //   });
    //   setTimeout(() => {
    //     setAlert({ ...alert, error: "" });
    //   }, 3000);
    // }
    else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  const handleGeneratorData = (event) => {
    const filterData = generator.find((item) => event.target.value === item.id);
    setSelectedGenerator({
      ...selectedGenerator,
      Gen_Id: filterData.id,
      GenBuild_Date: filterData.genBuild_Date,
      GenBuild_Location: filterData.genBuild_Location,
      GenRev_No: filterData.genRev_No,
      GenRouter: filterData.genRouter,
      Gen_Sl_No: filterData.gen_Id,
      GenCountry_Code: filterData.country_Code,
    });
  };

  const handleHeadsetData = (event) => {
    const filterData = headset.find((item) => event.target.value === item.id);
    setSelectedHeadset({
      ...selectedHeadset,
      Hdset_Id: filterData.id,
      HdsetBuild_Date: filterData.hdsetBuild_Date,
      HdsetBuild_Location: filterData.hdsetBuild_Location,
      HdsetRev_No: filterData.hdsetRev_No,
      HdsetRouter: filterData.hdsetRouter,
      Hdset_Sl_No: filterData.hdset_Id,
      HdsetCountry_Code: filterData.country_Code,
    });
  };

  const handleTabletData = (event) => {
    const filterData = tablet.find((item) => event.target.value === item.id);
    setSelectedTablet({
      ...selectedTablet,
      Tablet_Id: filterData.id,
      TabletBuild_Date: filterData.tabletBuild_Date,
      TabletBuild_Location: filterData.tabletBuild_Location,
      TabletRev_No: filterData.tabletRev_No,
      TabletRouter: filterData.tabletRouter,
      Tablet_Sl_No: filterData.tablet_Id,
      TabletCountry_Code: filterData.country_Code,
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  // const handleDate = (e) => {
  //   var d = new Date(e);
  //   d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  //   setFormData({ ...formData, sysBuild_Date: d });
  // };

  return (
    <DashboardLayout>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              {/* <Grid item xs={12}> */}
              <h5 style={{ marginBottom: "-25px", marginTop: "45px" }}>
                Generator - {selectedGenerator.Gen_Sl_No}
              </h5>
              <br></br>
              <h5 style={{ marginBottom: "-25px", marginTop: "5px" }}>
                Headset - {selectedHeadset.Hdset_Sl_No}
              </h5>
              <br></br>
              <h5 style={{ marginBottom: "-25px", marginTop: "5px" }}>
                Tablet - {selectedTablet.Tablet_Sl_No}
              </h5>
              <br></br>
              <h5>
                System once assembled is permanent. Are you sure to continue?
              </h5>
              {/* </Grid> */}
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"} style={{ marginTop: 30 }}>
                  <Button
                    disabled={loader}
                    onClick={() => submitForm()}
                    variant="contained"
                  >
                    Yes
                    {loader && <Loader color={"#fff"} />}
                  </Button>
                  <ButtonThemeProvider>
                    <Button
                      disabled={loader}
                      onClick={() => handleClose()}
                      color="grey"
                      variant="contained"
                    >
                      No
                    </Button>
                  </ButtonThemeProvider>
                </Stack>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Modal>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <Grid container spacing={2}>
          {alert.error != "" && (
            <Grid item xs={12}>
              <Alert severity="error">{alert.error}</Alert>
            </Grid>
          )}
          {alert.success != "" && (
            <Grid item xs={12}>
              <Alert severity="success">{alert.success}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <h3>Add New System</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <ComponentName name="Part No" value={formData.component_No} />
          <Grid item xs={2.7}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Country Code</InputLabel>
              <Autocomplete
                className="form-input2"
                {...systemDefaultCode}
                value={formData.country_Code}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    country_Code: newValue,
                  });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === formData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )
                  // )
                }
                // defaultValue={"2"}
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Variant
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...systemDefaultVariant}
                value={formData.Variant}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Variant: newValue,
                  });
                }}
                renderOption={
                  (props, option) => (
                    // option.component_No === hdmiData.pcbaToHdmi && (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )
                  // )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                Lot No
              </InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsLotNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    Lot_No: newValue?.lot_No,
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.lot_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className={"form-group2"}>
              <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
              <Autocomplete
                className="form-input2"
                {...defaultPropsSlNo}
                // value={formData.pcbaToHdmi}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    system_Id: newValue?.sl_No || "",
                  });
                }}
                renderOption={(props, option) =>
                  option.component_No === formData.component_No && (
                    <div
                      {...props}
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      {option.sl_No}
                    </div>
                  )
                }
                id="controlled-demo"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="system_Id">
                Ser. No.
              </InputLabel>

              <TextField
                name="system_Id"
                value={formData.system_Id}
                fullWidth
                className={"form-input"}
                variant="outlined"
                onChange={handleOnchange}
              />
            </div>
          </Grid>
          <Grid item xs={3} style={{ marginTop: -10 }}>
            <div className={"form-group"}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Part No
                <Link
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpen("Part No")}
                >
                  add new
                </Link>
              </InputLabel>
              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.sysPart_No}
                // renderValue={(selected) => {
                //   return sysPartItem.find((x) => x.part_No === selected)
                //     .part_No;
                // }}
                onChange={(e) =>
                  setFormData({ ...formData, sysPart_No: e.target.value })
                }
              >
                {sysPartItem.map((item, index) => (
                  <MenuItem key={index} value={item.part_No}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>{item.part_No}</div>
                      <div>{_getCountryName(item?.part_No)}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid> */}
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="sysBuild_Location">
                Build Location
              </InputLabel>

              <TextField
                name="sysBuild_Location"
                value={formData.sysBuild_Location}
                fullWidth
                className={"form-input"}
                variant="outlined"
                onChange={handleOnchange}
              />
            </div>
          </Grid>
          <Grid item xs={2} style={{ marginTop: -10 }}>
            <div className={"form-group"}>
              <InputLabel className={"form-label"} htmlFor="select-active">
                Rev
                {/* <Link
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpen("Rev No")}
                >
                  add new
                </Link> */}
              </InputLabel>
              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.sysRev_No}
                onChange={(e) => {
                  setFormData({ ...formData, sysRev_No: e.target.value });
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {sysRevItem.reverse().map((item, index) => (
                  <MenuItem value={item.rev_No} key={index}>
                    {item.rev_No}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="sysBuild_Date">
                  Build Date
                </InputLabel>
                <DesktopDatePicker
                  // defaultValue={moment(new Date()).format("YYYY/MM/DD")}
                  inputFormat="YYYY/MM/DD"
                  value={formData.sysBuild_Date}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sysBuild_Date: moment(e).format("YYYY/MM/DD"),
                      // sysBuild_Date: new Date(e).toISOString(),
                      // sysBuild_Date: moment(e).format(),
                      // sysBuild_Date: e,
                    })
                  }
                  // onChange={handleDate}
                  className={"form-input"}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={"form-input"}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              alignSelf: "center",
              // marginTop: "-15px",
              // height: "48px",
            }}
          >
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="sysRouter">
                Router
              </InputLabel>
              <TextField
                style={{ height: 44, marginTop: 2 }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(e) => setSysRouterFile(e.target.files[0])}
                // error={alert.error}
                // helperText={alert.error}
                variant="standard"
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="gen_Id">
                Generator
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={selectedGenerator.Gen_Id}
                // renderValue={(selected) => {
                //   return generator.find((x) => x.id === selected).gen_Id;
                // }}
                // onChange={(e) => {
                //   setFormData({ ...formData, gen_Id: e.target.value });
                // }}
                onChange={(e) => handleGeneratorData(e)}
              >
                {generator.map((item, index) => {
                  return (
                    item.isAssociated_WithSystem == false && (
                      <MenuItem value={item.id} key={index}>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div>{item.gen_Id}</div>
                          {item.country_Code && (
                            <>
                              {item.country_Code?.length < 4 ? (
                                <>{getCountryName(item?.country_Code)}</>
                              ) : (
                                item.country_Code?.substring(4)
                              )}
                            </>
                          )}
                        </div>
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="gen_Pcb">
                Generator PCB
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.gen_PCB_Id}
                // renderValue={(selected) => {
                //   return generator.find((x) => x.id === selected).gen_Id;
                // }}
                onChange={(e) => {
                  setFormData({ ...formData, gen_PCB_Id: e.target.value });
                }}
              >
                {generatorPcb.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>{item.generator_PCB_Id}</div>
                      <div>{_getCountryName(item?.generator_PCBPart_No)}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid> */}
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="headset">
                Headset
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={selectedHeadset.Hdset_Id}
                // renderValue={(selected) => {
                //   return headset.find((x) => x.id === selected).hdset_Id;
                // }}
                // onChange={(e) => {
                //   setFormData({ ...formData, hdset_Id: e.target.value });
                // }}
                onChange={(e) => handleHeadsetData(e)}
              >
                {headset.map((item, index) => {
                  return (
                    item.isAssociated_WithSystem == false && (
                      <MenuItem value={item.id} key={index}>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div>{item.hdset_Id}</div>
                          {item.country_Code && (
                            <>
                              {item.country_Code?.length < 4 ? (
                                <>{getCountryName(item?.country_Code)}</>
                              ) : (
                                item.country_Code?.substring(4)
                              )}
                            </>
                          )}
                        </div>
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="headset">
                Headset PCB
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={formData.hdset_PCB_Id}
                // renderValue={(selected) => {
                //   return headset.find((x) => x.id === selected).hdset_Id;
                // }}
                onChange={(e) => {
                  setFormData({ ...formData, hdset_PCB_Id: e.target.value });
                }}
              >
                {headsetPcb.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>{item.headset_PCB_Id}</div>
                      <div>{_getCountryName(item?.headset_PCBPart_No)}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid> */}
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="tablet">
                Tablet
              </InputLabel>

              <Select
                fullWidth
                className={"form-input"}
                id="select-active"
                value={selectedTablet.Tablet_Id}
                // renderValue={(selected) => {
                //   return tablet.find((x) => x.id === selected).tablet_Id;
                // }}
                // onChange={(e) => {
                //   setFormData({ ...formData, tablet_Id: e.target.value });
                // }}
                onChange={(e) => handleTabletData(e)}
              >
                {tablet.map((item, index) => {
                  return (
                    item.isAssociated_WithSystem == false && (
                      <MenuItem value={item.id} key={index}>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div> {item.tablet_Id}</div>
                          {item.country_Code && (
                            <>
                              {item.country_Code?.length < 4 ? (
                                <>{getCountryName(item?.country_Code)}</>
                              ) : (
                                item.country_Code?.substring(4)
                              )}
                            </>
                          )}
                          {/* <div>{getCountryName(item?.country_Code)}</div> */}
                        </div>
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Stack
              spacing={2}
              direction="row"
              style={{ marginTop: 25, justifyContent: "end" }}
            >
              <Button
                disabled={loader}
                variant="contained"
                style={{
                  // marginTop: 24,
                  height: "40px",
                  gap: "5px",
                  alignItems: "center",
                }}
                // onClick={submitForm}
                onClick={handleOpen}
              >
                <AddIcon /> Add
                {loader && <Loader color={"#fff"} />}
              </Button>
              {/* <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate(-1)}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider> */}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default AddSystems;
