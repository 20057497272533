import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router";
import { GetOrganizations } from "../../../services/organization.handlers";
import { GetOrganisationStore } from "../../../services/store.handlers";
import {
  AddEmployeeToSystem,
  GetOrgEmployee,
  updateOrgEmployee,
} from "../../../services/org.employee.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { GetSystems } from "../../../services/systems.handlers";

const EmpType = ["Doctor", "Technician"];
const AttachEmployee = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [systemData, setSystemData] = React.useState([]);
  const [selectedSystemSn, setSelectedSystemSn] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    type: EmpType[0],
    contact_Number: "",
    organisation_Id: "",
    store_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Sn: "",
  });

  React.useEffect(() => {
    fetchOrgEmployee();
  }, []);

  React.useEffect(() => {
    fetchOrganisations();
    fetchSystem();
  }, []);

  React.useEffect(() => {
    formData.organisation_Id && fetchStores();
  }, [formData.organisation_Id]);

  const fetchOrgEmployee = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrgEmployee(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        fetchOrganisations();
      } else {
        navigate("/attachEmployeeToSystem");
      }
    });
  };

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };

  const fetchStores = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganisationStore(formData.organisation_Id, options).then((response) => {
      if (response.status) {
        setStores(response.data);
      }
    });
  };
  const fetchSystem = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystems(options).then((response) => {
      if (response.status) {
        setSystemData(response.data);
      }
    });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSystemSn(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const submitForm = () => {
    if (selectedSystemSn.length <= 0) {
      setError("Please Select System Ser No");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    setError("");
    setSuccess("");
    setLoader(true);
    var options = {
      Authorization: "Bearer " + authToken,
    };
    const data = {
      employeeId: params.id,
      systemIds: selectedSystemSn,
    };
    AddEmployeeToSystem(data, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setFormErrors({
          name: "",
          email: "",
          type: "",
          contact_Number: "",
          organisation_Id: "",
          store_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/assignEmployeeToSystem");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Assign Employee to System</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  System Ser. No.
                </InputLabel>
                <Select
                  // disabled
                  multiple
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={selectedSystemSn}
                  onChange={handleChange}
                  error
                  // helperText={formErrors.type}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {systemData.map((item, index) => (
                    <MenuItem key={index} value={item.system_Sn}>
                      {item.system_Sn}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="name">
                  Name
                </InputLabel>
                <TextField
                  disabled
                  id="name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="email">
                  Email
                </InputLabel>
                <TextField
                  disabled
                  id="email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.email}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="contact_Number">
                  Contact Number
                </InputLabel>
                <TextField
                  disabled
                  id="contact_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Number}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"}>Type</InputLabel>
                <TextField
                  disabled
                  id="contact_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.type}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Organization
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.organisation_Id}
                >
                  {organizations.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.org_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Stores
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.store_Id}
                >
                  {stores.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.store_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Assign Employee
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/assignEmployeeToSystem")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AttachEmployee;
