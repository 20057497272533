import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  GetSystem,
  GetSystems,
  getGenerator,
} from "../../../services/systems.handlers";
import AddPopSystem from "./AddPopSystem";
import { Link } from "react-router-dom";
import AddSystems from "./AddSystems";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { getCountryName } from "../../../constants";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
// require("jspdf-autotable");

const TableRowData = [
  "System ",
  "Generator ",
  // "Generator PCB",
  "Headset ",
  // "Headset PCB",
  "Tablet ",
  "Country ",
  "Store ID",
  "Status",
  "Action",
  "",
];

const Systems = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [genData, setGenData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [generatePdfData, setGeneratePdfData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  React.useEffect(() => {
    fetchGeneratorData();
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setData(allData);
    }
    let serachItem = allData.filter((item) =>
      item.system_Sn.includes(e.target.value)
    );
    setData(serachItem);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetSystems(options).then((response) => {
      if (response.status) {
        setData(response.data);
        setAllData(response.data);
      }
    });
  };

  const fetchGeneratorData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getGenerator(options).then((response) => {
      if (response.status) {
        setGenData(response.data);
      }
    });
  };
  const getSystem = (response) => {
    const system = [
      "System",
      response.system_Sn,
      response?.sysRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.sl_No,
      response?.sysRev_No,
      response?.sysBuild_Location,
      moment(response?.sysBuild_Date).format("YYYY/MM/DD"),
    ];
    return system;
  };
  const getSysGenerator = (response) => {
    const sysGenerator = [
      "Generator",
      response?.gen_Id,
      response?.genRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.gen_Id,
      response?.genRev_No,
      response?.genBuild_Location,
      moment(response?.genBuild_Date).format("YYYY/MM/DD"),
    ];
    return sysGenerator;
  };
  const getSysHeadset = (response) => {
    const sysHeadset = [
      "Headset",
      response?.hdset_Id,
      response?.hdsetRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.hdset_Id,
      response?.hdsetRev_No,
      response?.hdsetBuild_Location,
      moment(response?.hdsetBuild_Date).format("YYYY/MM/DD"),
    ];
    return sysHeadset;
  };
  const getSysTablet = (response) => {
    const sysTablet = [
      "Tablet",
      response?.tablet_Id,
      response?.tabletRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.tablet_Id,
      response?.tabletRev_No,
      response?.tabletBuild_Location,
      moment(response?.tabletBuild_Date).format("YYYY/MM/DD"),
    ];
    return sysTablet;
  };
  const getGenRows = (response) => {
    const GenTableRows = [];
    let GenPcb;
    let PowerSupply;
    let MainPowerSwitch;
    let MainPower;
    let PcbaToHdmi;
    let EmergencySwitch;
    let StatusLed;
    let UsbcToPcba;
    let PowerToPcba;

    const gen = [
      "Generator",
      response?.gen_Id,
      response?.genRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.gen_Id,
      response?.genRev_No,
      response?.genBuild_Location,
      moment(response?.genBuild_Date).format("YYYY/MM/DD"),
      response?.lot_No,
    ];
    GenTableRows.push(gen);
    response?.harnesses.forEach((element) => {
      if (element.component_No === "100167") {
        PcbaToHdmi = [
          "PCBA to HDMI Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100165") {
        MainPower = [
          "Main Power Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100164") {
        MainPowerSwitch = [
          "Main Power Switch Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100171") {
        UsbcToPcba = [
          "USBC to PCBA Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100172") {
        PowerToPcba = [
          "Power to PCBA Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100169") {
        EmergencySwitch = [
          "Emergency Switch Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100170") {
        StatusLed = [
          "Status LED Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100135") {
        GenPcb = [
          "Generator PCB",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
      if (element.component_No === "100188") {
        PowerSupply = [
          "Power Supply Assembly",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // GenTableRows.push(gen);
      }
    });
    GenTableRows.push(
      GenPcb,
      PowerSupply,
      MainPowerSwitch,
      MainPower,
      PcbaToHdmi,
      EmergencySwitch,
      StatusLed,
      UsbcToPcba,
      PowerToPcba
    );
    return GenTableRows;
  };

  const getHdSetRows = (response) => {
    const HdsetTableRows = [];
    let HdsetPcb;
    let EyeCup;
    let EyeCupAndGnd;
    let LedHdset;
    let Hdset;
    const headset = [
      "Headset",
      response?.hdset_Id,
      response?.hdsetRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.hdset_Id,
      response?.hdsetRev_No,
      response?.hdsetBuild_Location,
      moment(response?.hdsetBuild_Date).format("YYYY/MM/DD"),
      response?.lot_No,
    ];
    HdsetTableRows.push(headset);

    response?.harnesses.forEach((element) => {
      if (element.component_No === "100186") {
        Hdset = [
          "HDST Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // HdsetTableRows.push(gen);
      }
      if (element.component_No === "100184") {
        LedHdset = [
          "LED HDST Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // HdsetTableRows.push(gen);
      }
      if (element.component_No === "100183") {
        EyeCupAndGnd = [
          "Eye Cup and Gnd Rtn Hdset Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // HdsetTableRows.push(gen);
      }
      if (element.component_No === "100182") {
        EyeCup = [
          "Eye Cup Harness",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // HdsetTableRows.push(gen);
      }
      if (element.component_No === "100219") {
        HdsetPcb = [
          "Headset PCB",
          "",
          "",
          element.component_No +
            "-" +
            (element.country_Code ? element.country_Code.substring(0, 3) : "") +
            "-" +
            element.variant,
          "",
          "",
          "",
          element.lot_No,
        ];
        // HdsetTableRows.push(gen);
      }
    });
    HdsetTableRows.push(HdsetPcb, EyeCup, EyeCupAndGnd, LedHdset, Hdset);
    return HdsetTableRows;
  };
  const getTab = (response) => {
    const tablet = [
      "Tablet",
      response?.tablet_Id,
      response?.tabletRouter,
      response?.component_No +
        "-" +
        (response?.country_Code ? response.country_Code.substring(0, 3) : "") +
        "-" +
        response?.variant +
        "-" +
        response?.tablet_Id,
      response?.tabletRev_No,
      response?.tabletBuild_Location,
      moment(response?.tabletBuild_Date).format("YYYY/MM/DD"),
      response?.lot_No,
      response?.os,
      response?.sW_Rev,
      response?.guI_Rev,
      response?.apI_Rev,
      response?.system_Rev,
    ];
    return tablet;
  };
  const generatePdf = (prop) => {
    const doc = new JsPDF("landscape");
    const tableRows = [];
    const tableRowsForGenerator = [];
    const tableRowsForHeadset = [];
    const tableRowsForTablet = [];
    const tableRowsHeadset = [];
    const tableRowsTablet = [];
    var options = {
      Authorization: "Bearer " + authToken,
    };

    GetSystem(prop, options).then((response) => {
      if (response.status) {
        setGeneratePdfData(response.data);
        const tableColumn = [
          "Component",
          "Ser. No.",
          "Router",
          "Part No",
          "Rev",
          "Build Location",
          "Build Date",
        ];
        const tableColumnGenerator = [
          "Component",
          "Ser. No.",
          "Router",
          "Part No",
          "Rev",
          "Build Location",
          "Build Date",
          "Lot No",
          "Eco",
          "SW Rev",
        ];
        const tableColumnHeadset = [
          "Component",
          "Ser. No.",
          "Router",
          "Part No",
          "Rev",
          "Build Location",
          "Build Date",
          "Lot No",
          "Eco",
        ];
        const tableColumnTablet = [
          "Component",
          "Ser. No.",
          "Router",
          "Part No",
          "Rev",
          "Build Location",
          "Build Date",
          "Lot No",
          "OS Rev",
          "HW Rev",
          "GUI Rev",
          "API Rev",
          "System Rev",
        ];

        doc.text(`System - ${response.data.system_Sn}`, 14, 15);
        const system = getSystem(response.data);
        const sysGenerator = getSysGenerator(response.data.generator);
        const sysHeadset = getSysHeadset(response.data.headset);
        const sysTablet = getSysTablet(response.data.tablet);

        const tableRowsGenerator = response.data.generator
          ? getGenRows(response.data.generator)
          : [];
        tableRowsForGenerator.push(tableRowsGenerator);

        const tableRowsHeadset = response.data.headset
          ? getHdSetRows(response.data.headset)
          : [];
        tableRowsForHeadset.push(tableRowsHeadset);

        const tablet = response.data.tablet ? getTab(response.data.tablet) : [];
        tableRows.push(system);
        tableRows.push(sysGenerator);
        tableRows.push(sysHeadset);
        tableRows.push(sysTablet);
        doc.autoTable(tableColumn, tableRows, { theme: "plain", startY: 20 });
        // doc.addPage();
        doc.line(1, 60, 300, 60);
        doc.text("Generator", 14, 75);

        doc.autoTable(tableColumnGenerator, tableRowsForGenerator, {
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 20,
        });
        doc.addPage();
        // doc.line(1, 110, 300, 110);
        doc.text("Headset", 14, 15);

        doc.autoTable(tableColumnHeadset, tableRowsForHeadset, {
          theme: "plain",
          startY: 20,
        });
        // doc.addPage();

        let finalY = doc.lastAutoTable.finalY + 15;
        let finalL = doc.lastAutoTable.finalY + 5;
        doc.line(1, finalL, 300, finalL);
        doc.text(15, finalY, "Tablet");
        tableRowsTablet.push(tablet);
        doc.autoTable(tableColumnTablet, tableRowsTablet, {
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 20,
          columnStyles: {
            //   0: { cellWidth: 21 },
            1: { cellWidth: 20 },
            //   2: { cellWidth: 24 },
            //   3: { cellWidth: 32 },
            //   4: { cellWidth: 10 },
            //   5: { cellWidth: 18 },
            //   6: { cellWidth: 22 },
            //   7: { cellWidth: 10 },
            //   8: { cellWidth: 10 },
            //   9: { cellWidth: 10 },
            //   10: { cellWidth: 10 },
            //   11: { cellWidth: 10 },
            //   12: { cellWidth: 10 },
          },
        });
        doc.save(`report_.pdf`);
      }
    });
  };

  return (
    <DashboardLayout>
      <Stack
        // spacing={35}
        direction="row"
        style={{
          height: "45px",
          marginBottom: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <AddSystems getData={() => fetchData()} /> */}
        <Stack style={{ paddingLeft: "13px" }}>
          <h3>System</h3>
        </Stack>
        <Stack>
          <TextField
            type="text"
            className="search"
            placeholder="Search by System Id"
            onChange={handleSearch}
            InputProps={{
              sx: { borderRadius: 2, width: 400 },
              endAdornment: (
                <InputAdornment>
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack style={{ paddingRight: "38px", marginLeft: "none" }}>
          {/* <Button
            style={{ height: "45px" }}
            onClick={() => navigate("/add_systems")}
            variant="contained"
          >
            <AddIcon /> Add New System
          </Button> */}
        </Stack>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    <>
                      {item}
                      {index <= 3 && (
                        <>
                          <br></br>Ser. No.
                        </>
                      )}
                    </>
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {/* <Link to={"/systems-detail-page/" + item.id}> */}
                      <Link
                        to="/systems-detail-page"
                        state={{ id: item.id, storeId: item.storeId }}
                      >
                        {item.system_Sn}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {/* <Link to={"/generator-detail-page/" + item.gen_Sn}> */}
                      <Link
                        to="/generator-detail-page"
                        state={{ id: item.gen_Sn, storeId: item.storeId }}
                      >
                        {item.gen_Sl_No}
                      </Link>
                    </TableCell>
                    {/* <TableCell>
                      <Link to={"/generatorPcb-detail-page/" + item.gen_PCB_Id}>
                        {item.gen_PCB_Sl_No}
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      {/* <Link to={"/headset-detail-page/" + item.hdset_Sn}> */}
                      <Link
                        to="/headset-detail-page"
                        state={{ id: item.hdset_Sn, storeId: item.storeId }}
                      >
                        {item.hdset_Sl_No}
                      </Link>
                    </TableCell>
                    {/* <TableCell>
                      <Link to={"/headsetPcb-detail-page/" + item.hdset_PCB_Id}>
                        {item.hdset_PCB_Sl_No}
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      {/* <Link to={"/tablet-detail-page/" + item.tablet_Sn}> */}
                      <Link
                        to="/tablet-detail-page"
                        state={{ id: item.tablet_Sn, storeId: item.storeId }}
                      >
                        {item.tablet_Sl_No}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {item.country_Code}
                      {/* <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {getCountryName(item?.country_Code)}
                      </div> */}
                    </TableCell>
                    <TableCell>{item.store_Sn}</TableCell>
                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/editSystems/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/uploadDoc/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Doc
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => generatePdf(item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={9} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default Systems;
