import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  Alert,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  getHeadsetById,
  getInvHarness,
  getInvPartNumber,
  getRevNumber,
  updateHeadset,
} from "../../../services/systems.handlers";
import ComponentName from "../../../Components/DisabledTextField/ComponentName";
import { getCountryName } from "../../../constants";

function EditHeadset() {
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const authToken = useSelector((state) => state.user.authToken);
  const [partItem, setPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const [formData, setFormData] = React.useState({
    hdset_Id: "",
    hdsetPart_No: "",
    hdsetRev_No: "",
    hdsetBuild_Date: "",
    hdsetBuild_Location: "",
    hdsetRouter: "",
    country_Code: "",
    lot_No: "",
    variant: "",
    hW_Rev: "",
    isActive: 1,
  });
  const [hdsetData, setHdsetData] = React.useState({
    id: "",
    hdset: "",
    hdsetVariation: "",
    hdsetCode: "",
    hdsetSl: "",
    hdsetLot_No: "",
  });
  const [ledHdsetData, setLedHdsetData] = React.useState({
    id: "",
    ledHdset: "",
    ledHdsetVariation: "",
    ledHdsetCode: "",
    ledHdsetSl: "",
    ledHdsetLot_No: "",
  });
  const [cupHdsetData, setCupHdsetData] = React.useState({
    id: "",
    cupHdset: "",
    cupHdsetVariation: "",
    cupHdsetCode: "",
    cupHdsetSl: "",
    cupHdsetLot_No: "",
  });
  const [eyeHdsetData, setEyeHdsetData] = React.useState({
    id: "",
    eyeHdset: "",
    eyeHdsetVariation: "",
    eyeHdsetCode: "",
    eyeHdsetSl: "",
    eyeHdsetLot_No: "",
  });
  const [hdsetPcbData, setHdsetPcbData] = React.useState({
    id: "",
    hdsetPcb: "",
    hdsetPcbVariation: "",
    hdsetPcbCode: "",
    hdsetPcbSl: "",
    hdsetPcbLot_No: "",
    hdsetPcbSl_No: "",
  });
  React.useEffect(() => {
    fetchData();
    partNo();
    revNo();
  }, []);

  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
      }
    });
  };
  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const headsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const headsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const headsetPropsLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173"
            // item.part_Desciption_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );

  const headsetPropsSlNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100173"
            // item.part_Desciption_Name.toLowerCase().trim() === "headset"
          );
        })
        .map((i) => i.sl_No),
    [partItem]
  );

  const hdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100186" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const hdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100186" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const hdsetPropsLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100186"
            // item.part_Desciption_Name.toLowerCase().trim() === "hdst harness"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );
  const ledHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100184" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "led hdst harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const ledHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100184" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "led hdst harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const ledHdsetPropLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100184"
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "led hdst harness"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );

  const eyeHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100183" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "eye cup and gnd rtn hdset harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const eyeHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100183" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "eye cup and gnd rtn hdset harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const eyeHdsetPropLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100183"
            // item.part_Desciption_Name.toLowerCase().trim() ===
            //   "eye cup and gnd rtn hdset harness"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );
  const cupHdsetPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100182" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );
  const cupHdsetPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100182" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );

  const cupHdsetPropLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100182"
            // item.part_Desciption_Name.toLowerCase().trim() === "eye cup harness"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );

  const hdsetPCBPropCode = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219" && item.country_Code
            // item.part_Desciption_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.country_Code)
        .filter((i, index, arr) => index === arr.indexOf(i)),
    [partItem]
  );

  const hdsetPCBPropVariant = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219" && item.variant
            // item.part_Desciption_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.variant),
    [partItem]
  );
  const hdsetPCBPropLotNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219"
            // item.part_Desciption_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.lot_No),
    [partItem]
  );
  const hdsetPCBPropSlNo = React.useMemo(
    () =>
      partItem
        .filter((item, index) => {
          return (
            item.component_No === "100219"
            // item.part_Desciption_Name.toLowerCase().trim() === "headset pcb"
          );
        })
        .map((i) => i.sl_No),
    [partItem]
  );

  const hdsetPcbDefaultSlNo = {
    options: hdsetPCBPropSlNo,
    getOptionLabel: (option) => option.toString(),
  };

  const hdsetPcbDefaultLotNo = {
    options: hdsetPCBPropLotNo,
    getOptionLabel: (option) => option,
  };
  const hdsetDefaultLotNo = {
    options: partItem,
    getOptionLabel: (option) => (option.lot_No ? option.lot_No : ""),
  };
  const hdsetPcbDefaultVariant = {
    options: hdsetPCBPropVariant,
    getOptionLabel: (option) => option,
  };
  const hdsetPcbDefaultCode = {
    options: hdsetPCBPropCode,
    getOptionLabel: (option) => option,
  };
  const cupHdsetDefaultLotNo = {
    options: cupHdsetPropLotNo,
    getOptionLabel: (option) => option,
  };
  const cupHdsetDefaultVariant = {
    options: cupHdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const cupHdsetDefaultCode = {
    options: cupHdsetPropCode,
    getOptionLabel: (option) => option,
  };

  const eyeHdsetDefaultLotNo = {
    options: eyeHdsetPropLotNo,
    getOptionLabel: (option) => option,
  };
  const eyeHdsetDefaultVariant = {
    options: eyeHdsetPropVariant,
    getOptionLabel: (option) => option,
  };
  const eyeHdsetDefaultCode = {
    options: eyeHdsetPropCode,
    getOptionLabel: (option) => option,
  };

  const ledHdsetDefaultLotNo = {
    options: ledHdsetPropLotNo,
    getOptionLabel: (option) => option,
  };

  const ledHdsetDefaultVariant = {
    options: ledHdsetPropVariant,
    getOptionLabel: (option) => option,
  };

  const ledHdsetDefaultCode = {
    options: ledHdsetPropCode,
    getOptionLabel: (option) => option,
  };

  const hdsetdefaultPropsLotNo = {
    options: hdsetPropsLotNo,
    getOptionLabel: (option) => option,
  };

  const hdsetDefaultVariant = {
    options: hdsetPropVariant,
    getOptionLabel: (option) => option,
  };

  const hdsetDefaultCode = {
    options: hdsetPropCode,
    getOptionLabel: (option) => option,
  };
  const defaultPropsSlNo = {
    options: headsetPropsSlNo,
    getOptionLabel: (option) => option.toString(),
  };

  const defaultPropsLotNo = {
    options: headsetPropsLotNo,
    getOptionLabel: (option) => option,
  };

  const headsetDefaultVariant = {
    options: headsetPropVariant,
    getOptionLabel: (option) => option,
  };

  const headsetDefaultCode = {
    options: headsetPropCode,
    getOptionLabel: (option) => option,
  };
  function getDefaultValueCountryCodeVariation(prop) {
    prop?.forEach((event) => {
      if (
        event.component_No === "100186"
        // event.component_Name.toLowerCase().trim() === "hdst harness"
      ) {
        setHdsetData({
          ...hdsetData,
          id: event.id,
          hdset: event.component_No,
          hdsetVariation: event.variant,
          hdsetCode: event.country_Code,
          hdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100184"
        // event.component_Name.toLowerCase().trim() === "led hdst harness"
      ) {
        setLedHdsetData({
          ...ledHdsetData,
          id: event.id,
          ledHdset: event.component_No,
          ledHdsetVariation: event.variant,
          ledHdsetCode: event.country_Code,
          ledHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100183"
        // event.component_Name.toLowerCase().trim() ===
        //   "eye cup and gnd rtn hdset harness"
      ) {
        setEyeHdsetData({
          ...eyeHdsetData,
          id: event.id,
          eyeHdset: event.component_No,
          eyeHdsetVariation: event.variant,
          eyeHdsetCode: event.country_Code,
          eyeHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100182"
        // event.component_Name.toLowerCase().trim() === "eye cup harness"
      ) {
        setCupHdsetData({
          ...cupHdsetData,
          id: event.id,
          cupHdset: event.component_No,
          cupHdsetVariation: event.variant,
          cupHdsetCode: event.country_Code,
          cupHdsetLot_No: event.lot_No,
        });
      }
      if (
        event.component_No === "100219"
        // event.component_Name.toLowerCase().trim() === "headset pcb"
      ) {
        setHdsetPcbData({
          ...hdsetPcbData,
          id: event.id,
          hdsetPcb: event.component_No,
          hdsetPcbVariation: event.variant,
          hdsetPcbCode: event.country_Code,
          hdsetPcbLot_No: event.lot_No,
          hdsetPcbSl_No: event.sl_No,
        });
      }
    });
  }
  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getHeadsetById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        getDefaultValueCountryCodeVariation(response.data.harnesses);
      } else {
        navigate(-1);
      }
    });
  };
  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getInvHarness(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
        // getDefaultValueCountryCodeVariation(response.data.harness);
      }
    });
  };
  const submitForm = () => {
    const componentData = JSON.stringify([
      {
        // component_Name: "HDST Harness",
        component: "Headset",
        part_Description_Name: "HDST Harness",
        id: hdsetData.id,
        component_No: hdsetData.hdset,
        country_Code: hdsetData.hdsetCode,
        variant: hdsetData.hdsetVariation,
        lot_No: hdsetData.hdsetLot_No,
      },
      {
        // component_Name: "LED HDST Harness",
        component: "Headset",
        part_Description_Name: "LED HDST Harness",
        id: ledHdsetData.id,
        component_No: ledHdsetData.ledHdset,
        country_Code: ledHdsetData.ledHdsetCode,
        variant: ledHdsetData.ledHdsetVariation,
        lot_No: ledHdsetData.ledHdsetLot_No,
      },
      {
        // component_Name: "Eye Cup AND Gnd Rtn HDST Harness",
        component: "Headset",
        part_Description_Name: "Eye Cup AND Gnd Rtn HDST Harness",
        id: eyeHdsetData.id,
        component_No: eyeHdsetData.eyeHdset,
        country_Code: eyeHdsetData.eyeHdsetCode,
        variant: eyeHdsetData.eyeHdsetVariation,
        lot_No: eyeHdsetData.eyeHdsetLot_No,
      },
      {
        // component_Name: "EYE CUP Harness",
        component: "Headset",
        part_Description_Name: "EYE CUP Harness",
        id: cupHdsetData.id,
        component_No: cupHdsetData.cupHdset,
        country_Code: cupHdsetData.cupHdsetCode,
        variant: cupHdsetData.cupHdsetVariation,
        lot_No: cupHdsetData.cupHdsetLot_No,
      },
      {
        // component_Name: "Headset PCB",
        component: "Headset",
        part_Description_Name: "Headset PCB",
        id: hdsetPcbData.id,
        component_No: hdsetPcbData.hdsetPcb,
        country_Code: hdsetPcbData.hdsetPcbCode,
        variant: hdsetPcbData.hdsetPcbVariation,
        lot_No: hdsetPcbData.hdsetPcbLot_No,
        sl_No: hdsetPcbData.hdsetPcbSl,
      },
    ]);

    const fileData = new FormData();
    fileData.append("Id", params.id);
    // fileData.append("HdsetRouterFile", HdsetRouterFile);
    fileData.append("Hdset_Id", formData.hdset_Id);
    fileData.append("HdsetPart_No", "");
    fileData.append("HdsetRouter", "");
    fileData.append("HdsetBuild_Date", formData.hdsetBuild_Date);
    fileData.append("HdsetBuild_Location", formData.hdsetBuild_Location);
    fileData.append("HdsetRev_No", formData.hdsetRev_No);
    fileData.append("Component_Name", "Headset");
    fileData.append("Component_No", formData.component_No);
    fileData.append("Country_Code", formData.country_Code);
    fileData.append("Lot_No", formData.lot_No);
    fileData.append("Variant", formData.variant);
    fileData.append("IsActive", formData.isActive);
    fileData.append("HW_Rev", formData.hW_Rev);
    fileData.append("IsAssociated_WithSystem", false);
    fileData.append("Components", componentData);

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateHeadset(params.id, fileData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate(-1);
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <Grid container spacing={2}>
            {error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
          </Grid>
          <h3>Edit Headset</h3>
          <Grid container spacing={3}>
            <ComponentName name="Part No" value={formData.component_No} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Country Code</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...headsetDefaultCode}
                  value={formData.country_Code}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      country_Code: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      {/* <div>{getCountryName(option)}</div> */}
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...headsetDefaultVariant}
                  value={formData.variant}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      variant: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsLotNo}
                  value={formData.lot_No}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      lot_No: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"}>Ser. No.</InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...defaultPropsSlNo}
                  value={formData.hdset_Id}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      hdset_Id: newValue,
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.toString() === value.toString()
                  }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="hdsetBuild_Location"
                >
                  Build Location
                </InputLabel>

                <TextField
                  name="hdsetBuild_Location"
                  fullWidth
                  value={formData.hdsetBuild_Location}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            {/* <Grid item xs={2}>
              <div className={"form-group"} style={{ margin: 0 }}>
                <InputLabel className={"form-label"} htmlFor="hdsetRev_No">
                  Rev
                </InputLabel>

                <TextField
                  name="hdsetRev_No"
                  fullWidth
                  value={formData.hdsetRev_No}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid> */}
            <Grid item xs={2}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hdsetRev_No">
                  Rev
                </InputLabel>

                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.hdsetRev_No}
                  onChange={(e) =>
                    setFormData({ ...formData, hdsetRev_No: e.target.value })
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {revItem.map((item, index) => (
                    <MenuItem key={index} value={item.rev_No}>
                      {item.rev_No}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  ECO
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  value={formData.hW_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"} style={{ marginTop: -10 }}>
                  <InputLabel
                    className={"form-label"}
                    htmlFor="hdsetBuild_Date"
                  >
                    Build Date
                  </InputLabel>
                  <DesktopDatePicker
                    inputFormat="YYYY/MM/DD"
                    value={formData.hdsetBuild_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        hdsetBuild_Date: e,
                      })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <ComponentName name="Headset PCB" value={hdsetPcbData.hdsetPcb} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...hdsetPcbDefaultCode}
                  value={hdsetPcbData.hdsetPcbCode}
                  onChange={(event, newValue) => {
                    setHdsetPcbData({
                      ...hdsetPcbData,
                      hdsetPcbCode: newValue,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...hdsetPcbDefaultVariant}
                  value={hdsetPcbData.hdsetPcbVariation}
                  onChange={(event, newValue) => {
                    setHdsetPcbData({
                      ...hdsetPcbData,
                      hdsetPcbVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...hdsetPcbDefaultLotNo}
                  {...hdsetDefaultLotNo}
                  // value={hdsetPcbData.hdsetPcbLot_No}
                  inputValue={hdsetPcbData.hdsetPcbLot_No}
                  onChange={(event, newValue) => {
                    setHdsetPcbData({
                      ...hdsetPcbData,
                      hdsetPcbLot_No: newValue.lot_No,
                      id: newValue.id,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === hdsetPcbData.hdsetPcb && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  Rev
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={1.2}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="hw_Rev">
                  ECO
                </InputLabel>

                <TextField
                  name="hw_Rev"
                  fullWidth
                  value={formData.hw_Rev}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            {/* <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...hdsetPcbDefaultSlNo}
                  value={hdsetPcbData.hdsetPcbSl_No || ""}
                  onChange={(event, newValue) => {
                    setHdsetPcbData({
                      ...hdsetPcbData,
                      hdsetPcbSl_No: newValue || "",
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.toString() === value.toString()
                  }
                  renderOption={(props, option) => (
                    <div
                      key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid> */}
            <ComponentName
              name="Eye Cup Harness"
              value={cupHdsetData.cupHdset}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...cupHdsetDefaultCode}
                  value={cupHdsetData.cupHdsetCode}
                  onChange={(event, newValue) => {
                    setCupHdsetData({
                      ...cupHdsetData,
                      cupHdsetCode: newValue,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...cupHdsetDefaultVariant}
                  value={cupHdsetData.cupHdsetVariation}
                  onChange={(event, newValue) => {
                    setCupHdsetData({
                      ...cupHdsetData,
                      cupHdsetVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...cupHdsetDefaultLotNo}
                  {...hdsetDefaultLotNo}
                  // value={cupHdsetData.cupHdsetLot_No}
                  inputValue={cupHdsetData.cupHdsetLot_No}
                  onChange={(event, newValue) => {
                    setCupHdsetData({
                      ...cupHdsetData,
                      cupHdsetLot_No: newValue.lot_No,
                      id: newValue.id,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === cupHdsetData.cupHdset && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsSlNo}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="Eye Cup AND Gnd Rtn HDST Harness"
              value={eyeHdsetData.eyeHdset}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...eyeHdsetDefaultCode}
                  value={eyeHdsetData.eyeHdsetCode}
                  onChange={(event, newValue) => {
                    setEyeHdsetData({
                      ...eyeHdsetData,
                      eyeHdsetCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...eyeHdsetDefaultVariant}
                  value={eyeHdsetData.eyeHdsetVariation}
                  onChange={(event, newValue) => {
                    setEyeHdsetData({
                      ...eyeHdsetData,
                      eyeHdsetVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...eyeHdsetDefaultLotNo}
                  {...hdsetDefaultLotNo}
                  // value={eyeHdsetData.eyeHdsetLot_No}
                  inputValue={eyeHdsetData.eyeHdsetLot_No}
                  onChange={(event, newValue) => {
                    setEyeHdsetData({
                      ...eyeHdsetData,
                      eyeHdsetLot_No: newValue.lot_No,
                      id: newValue.id,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === eyeHdsetData.eyeHdset && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsSlNo}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName
              name="LED HDST  Harness"
              value={ledHdsetData?.ledHdset}
            />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...ledHdsetDefaultCode}
                  value={ledHdsetData.ledHdsetCode}
                  onChange={(event, newValue) => {
                    setLedHdsetData({
                      ...ledHdsetData,
                      ledHdsetCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...ledHdsetDefaultVariant}
                  value={ledHdsetData.ledHdsetVariation}
                  onChange={(event, newValue) => {
                    setLedHdsetData({
                      ...ledHdsetData,
                      ledHdsetVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...ledHdsetDefaultLotNo}
                  {...hdsetDefaultLotNo}
                  // value={ledHdsetData.ledHdsetLot_No}
                  inputValue={ledHdsetData.ledHdsetLot_No}
                  onChange={(event, newValue) => {
                    setLedHdsetData({
                      ...ledHdsetData,
                      ledHdsetLot_No: newValue?.lot_No,
                      id: newValue.id,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === ledHdsetData.ledHdset && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsSlNo}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <ComponentName name="HDST Harness" value={hdsetData.hdset} />
            <Grid item xs={2.7}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Country Code
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...hdsetDefaultCode}
                  value={hdsetData.hdsetCode}
                  onChange={(event, newValue) => {
                    setHdsetData({
                      ...hdsetData,
                      hdsetCode: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                      <div>{getCountryName(option)}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={1.3}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Variant
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  {...hdsetDefaultVariant}
                  value={hdsetData.hdsetVariation}
                  onChange={(event, newValue) => {
                    setHdsetData({
                      ...hdsetData,
                      hdsetVariation: newValue,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel className={"form-label2"} htmlFor="pcbaToHdmi">
                  Lot No
                </InputLabel>
                <Autocomplete
                  className="form-input2"
                  // {...hdsetdefaultPropsLotNo}
                  {...hdsetDefaultLotNo}
                  // value={hdsetData.hdsetLot_No}
                  inputValue={hdsetData.hdsetLot_No}
                  onChange={(event, newValue) => {
                    setHdsetData({
                      ...hdsetData,
                      hdsetLot_No: newValue.lot_No,
                      id: newValue.id,
                    });
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.value === value.value
                  // }
                  renderOption={(props, option) =>
                    option.component_No === hdsetData.hdset && (
                      <div
                        {...props}
                        key={option.id}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>{option.lot_No}</div>
                      </div>
                    )
                  }
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={2.5}>
              <div className={"form-group2"}>
                <InputLabel
                  disabled
                  className={"form-label2"}
                  htmlFor="pcbaToHdmi"
                >
                  Ser. No.
                </InputLabel>
                <Autocomplete
                  disabled
                  className="form-input2"
                  {...defaultPropsSlNo}
                  // value={formData.pcbaToHdmi}
                  // onChange={(event, newValue) => {
                  //   setHdmiData({
                  //     ...hdmiData,
                  //     pcbaToHdmiSl: newValue?.sl_No || "",
                  //   });
                  // }}
                  renderOption={(props, option) => (
                    <div
                      // key={option.id}
                      style={{ display: "flex", gap: "10px" }}
                      {...props}
                    >
                      <div>{option.sl_No}</div>
                    </div>
                  )}
                  id="controlled-demo"
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>

            <Grid item xs={9}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2} direction="row" style={{ marginTop: "17px" }}>
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Headset
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate(-1)}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
}

export default EditHeadset;
