import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { CreateEmployee } from "../../../services/employee.handlers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import { useParams, useNavigate } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

import {
  GetComponent,
  updateComponent,
} from "../../../services/components.handlers";

const EditComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    component_Name: "",
    build_Date: "",
    headset_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    component_Name: "",
    build_Date: "",
    headset_Id: "",
  });

  React.useEffect(() => {
    fetchComponent();
  }, []);

  const fetchComponent = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetComponent(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/component");
      }
    });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateComponent(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          component_Name: "",
          build_Date: "",
          headset_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/component");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Edit Component</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="component_Name">
                  Component Name
                </InputLabel>
                <TextField
                  id="component_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.component_Name}
                  onChange={(e) =>
                    setFormData({ ...formData, component_Name: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="build_Date">
                    Build Date
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.build_Date}
                    onChange={(e) =>
                      setFormData({ ...formData, build_Date: e })
                    }
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="headset_Id">
                  Headset ID
                </InputLabel>
                <TextField
                  id="headset_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.headset_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, headset_Id: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Component
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/component")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditComponent;
