import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import {
  GetByIdProblemReportForm,
  ProblemReportForm,
  UpdateProblemReportForm,
} from "../../../services/problem.report.admin.handlers";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../Layout/DashboardLayout";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import moment from "moment";

function EditProblemReportForm(prop) {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [loader, setLoader] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [formData, setFormData] = React.useState({
    id: "",
    system_Sn: "",
    description: "",
    remark: "",
    status: "",
    isActive: 1,
  });

  React.useEffect(() => {
    getDataById();
  }, []);

  const getDataById = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetByIdProblemReportForm(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      }
    });
  };
  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    if (formData.system_Sn !== "" && formData.description !== "") {
      UpdateProblemReportForm(params.id, formData, option).then((response) => {
        if (response.status) {
          setAlert({
            ...alert,
            success: "Data has been submitted successfully",
          });
          setFormData({
            system_Sn: "",
            description: "",
            remark: "",
            status: 1,
            isActive: 1,
          });
          setLoader(false);
          setTimeout(() => {
            setAlert({ ...alert, success: "" });
            navigate("/problemReportList");
            // prop.getData();
          }, 2000);
        } else {
          setLoader(false);
          setAlert({ ...alert, error: "Invalid Details" });
          setTimeout(() => {
            setAlert({ ...alert, error: "" });
          }, 3000);
        }
      });
    } else {
      setLoader(false);
      setAlert({ ...alert, error: "Invalid Details" });
      setTimeout(() => {
        setAlert({ ...alert, error: "" });
      }, 3000);
    }
  };

  // const handleIsActive = (e) => {
  //   setFormData({ ...formData, status: e.target.value });
  //   if (e.target.value === 0) {
  //     setChecked(true);
  //   }
  // };
  // const handleClose = () => {
  //   setChecked(false);
  //   setFormData({ ...formData, status: 1 });
  // };
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   height: 300,
  //   bgcolor: "background.paper",
  //   // border: "2px solid #000",
  //   border: "none",
  //   borderRadius: 8,
  //   boxShadow: 24,
  //   pt: 2,
  //   px: 4,
  //   pb: 3,
  // };

  return (
    <DashboardLayout>
      {/* <Modal open={checked} onClose={handleClose}>
        <Box sx={{ ...style, width: 700 }}>
          <div style={{ height: 280, width: 600, margin: 10 }}>
            <InputLabel className={"form-label"} htmlFor="remark">
              Remark
            </InputLabel>
            <TextField
              InputProps={{
                sx: {
                  borderRadius: "16px",
                  border: "1px",
                  borderColor: "#e5e7eb",
                },
              }}
              maxRows={4}
              minRows={4}
              multiline
              name="remark"
              fullWidth
              value={formData.remark}
              variant="outlined"
              onChange={handleChangeData}
            />

            <Stack spacing={2} direction={"row"} style={{ marginTop: 30 }}>
              <Button
                disabled={loader}
                onClick={() => submitForm()}
                variant="contained"
              >
                Save
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => handleClose()}
                  color="grey"
                  variant="contained"
                >
                  Cancel
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </div>
        </Box>
      </Modal> */}
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <>
          <Grid
            container
            spacing={2}
            // direction="column"
            // justifyContent="flex-start"
            // alignItems="flex-start"
          >
            {alert.error !== "" && (
              <Grid item xs={12}>
                <Alert severity="error">{alert.error}</Alert>
              </Grid>
            )}
            {alert.success !== "" && (
              <Grid item xs={12}>
                <Alert severity="success">{alert.success}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="systemId"
                ></InputLabel>
              </div>
            </Grid>
            <Grid item xs={2.4}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="system_Sn">
                  System Ser. No.
                </InputLabel>

                <TextField
                  name="system_Sn"
                  fullWidth
                  value={formData.system_Sn}
                  className={"form-input"}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="description">
                  Problem
                </InputLabel>

                <TextField
                  disabled
                  InputProps={{
                    sx: {
                      borderRadius: "16px",
                      border: "1px",
                      borderColor: "#e5e7eb",
                    },
                  }}
                  maxRows={4}
                  minRows={4}
                  multiline
                  name="description"
                  fullWidth
                  value={formData.description}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
              Problem Reporting Date/time -
              {/* {moment(formData.created_Date).format("YYYY/MM/DD")}/
              {moment(formData.created_Date).format("hh:mm A")} */}
              {formData.created_Date
                ? moment(new Date(formData.created_Date + "Z")).format(
                    "YYYY/MM/DD"
                  )
                : ""}
              ,
              {formData.created_Date
                ? moment(new Date(formData.created_Date + "Z")).format(
                    "hh:mm A"
                  )
                : ""}
            </Grid>
            <Grid Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: "-10px" }}>
                <InputLabel className={"form-label"} htmlFor="remark">
                  Resolution/Response
                </InputLabel>
                <TextField
                  inputProps={{
                    maxLength: 1000,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "16px",
                      border: "1px",
                      borderColor: "#e5e7eb",
                    },
                  }}
                  maxRows={4}
                  minRows={4}
                  multiline
                  name="remark"
                  fullWidth
                  value={formData.remark}
                  variant="outlined"
                  onChange={handleChangeData}
                />
              </div>
            </Grid>
            <Grid item xs={8.3}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.status}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                  }}
                  // onChange={handleIsActive}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Resolved</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2} direction="row">
                <Button
                  disabled={loader}
                  variant="contained"
                  style={{
                    marginTop: "2.2rem",
                    height: "40px",
                    gap: "5px",
                    // alignItems: "center",
                  }}
                  onClick={submitForm}
                >
                  Submit
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate(-1)}
                    style={{
                      marginTop: "2.2rem",
                      height: "40px",
                      gap: "5px",
                      // alignItems: "center",
                    }}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </>
      </Paper>
    </DashboardLayout>
  );
}

export default EditProblemReportForm;
