import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { CreateEmployee } from "../../../services/employee.handlers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { isValidEmailId } from "../../../utils/validation.util";
import Loader from "../../../Components/Loader";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import {
  GetOrganization,
  updateOrganization,
} from "../../../services/organization.handlers";
import { useParams, useNavigate } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  _getCountry,
  _getCountryStates,
  _getCityState,
  jsonCheck,
} from "../../../constants";

const EditOrganization = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [csc, setCsc] = React.useState({
    country: _getCountry(),
    state: [],
    city: [],
  });
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    org_Id: "",
    org_Name: "",
    contact_Email: "",
    contact_Number: "",
    org_Address_1: "",
    org_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    org_Id: "",
    org_Name: "",
    contact_Email: "",
    contact_Number: "",
    org_Address_1: "",
    org_Address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  React.useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganization(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
        setCsc({
          ...csc,
          state: jsonCheck(response.data.country)
            ? _getCountryStates(JSON.parse(response.data.country))
            : [],
          city: jsonCheck(response.data.state)
            ? _getCityState(JSON.parse(response.data.state))
            : [],
        });
      } else {
        navigate("/dashboard");
      }
    });
  };

  const setOrgID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        org_Id: "Organization ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        org_Id: "",
      });
    }
    setFormData({ ...formData, org_Id: value });
  };

  const setOrgName = (value) => {
    if (!value) {
      setFormErrors({
        ...formErrors,
        org_Name: "Organization Name cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        org_Name: "",
      });
    }
    setFormData({ ...formData, org_Name: value });
  };

  const setOrgEmail = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        contact_Email: "Email cannot be blank",
      });
    } else {
      if (isValidEmailId(value)) {
        setFormErrors({
          ...formErrors,
          contact_Email: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          contact_Email: "Invalid email id",
        });
      }
    }
    setFormData({ ...formData, contact_Email: value });
  };

  const setOrgNumber = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        contact_Number: "Contact Number cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        contact_Number: "",
      });
    }
    setFormData({ ...formData, contact_Number: value });
  };

  const submitForm = () => {
    if (!formData.org_Id) {
      setOrgID(formData.org_Id);
      return;
    }
    if (!formData.org_Name) {
      setOrgName(formData.org_Name);
      return;
    }
    if (!formData.contact_Email) {
      setOrgEmail(formData.contact_Email);
      return;
    }
    if (!formData.contact_Number) {
      setOrgNumber(formData.contact_Number);
      return;
    }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateOrganization(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          org_Id: "",
          org_Name: "",
          contact_Email: "",
          contact_Number: "",
          org_Address_1: "",
          org_Address_2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3 style={{ marginTop: 0 }}>
            Edit Organization : Organization ID - {formData.org_Id}
          </h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            {/* <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="org_Id">
                  Organization ID
                </InputLabel>
                <TextField
                  id="org_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Id}
                  onChange={(e) => setOrgID(e.target.value)}
                  error
                  helperText={formErrors.org_Id}
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  Country
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  value={
                    jsonCheck(formData.country)
                      ? JSON.parse(formData.country)
                      : formData.country
                  }
                  onChange={(event, value) => {
                    setFormData({
                      ...formData,
                      country: value ? JSON.stringify(value) : "",
                    });
                    setCsc({ ...csc, state: _getCountryStates(value) });
                  }}
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Name">
                  Organization Name
                </InputLabel>
                <TextField
                  id="org_Name"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Name}
                  onChange={(e) => setOrgName(e.target.value)}
                  error
                  helperText={formErrors.org_Name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Address_1">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 1"
                    : "Additional Address Info"} */}
                  Address 1
                </InputLabel>
                <TextField
                  id="org_Address_1"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Address_1}
                  onChange={(e) =>
                    setFormData({ ...formData, org_Address_1: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="contact_Email">
                  Contact Email
                </InputLabel>
                <TextField
                  id="contact_Email"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Email}
                  onChange={(e) => setOrgEmail(e.target.value)}
                  error
                  helperText={formErrors.contact_Email}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="org_Address_2">
                  {/* {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Address 2"
                    : "Org Civic Address"} */}
                  Address 2
                </InputLabel>
                <TextField
                  id="org_Address_2"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.org_Address_2}
                  onChange={(e) =>
                    setFormData({ ...formData, org_Address_2: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="contact_Number">
                  Contact Number
                </InputLabel>
                <TextField
                  id="contact_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.contact_Number}
                  onChange={(e) => setOrgNumber(e.target.value)}
                  error
                  helperText={formErrors.contact_Number}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "State"
                    : "Provence/Territory"}
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, state: JSON.stringify(value) });
                    setCsc({ ...csc, city: _getCityState(value) });
                  }}
                  value={
                    jsonCheck(formData.state)
                      ? JSON.parse(formData.state)
                      : formData.state
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.state}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({ ...formData, isActive: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="state">
                  City
                </InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(event, value) => {
                    setFormData({ ...formData, city: JSON.stringify(value) });
                  }}
                  value={
                    jsonCheck(formData.city)
                      ? JSON.parse(formData.city)
                      : formData.city
                  }
                  className={"form-input-padding"}
                  fullWidth
                  style={{ padding: 0 }}
                  options={csc.city}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} className={"form-input"} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="zip">
                  {jsonCheck(formData.country) &&
                  JSON.parse(formData.country).value == "US"
                    ? "Zip Code"
                    : "Postal Code"}
                </InputLabel>
                <TextField
                  id="zip"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid xs={6}></Grid>
            <Grid item xs={6}>
              <div className={"form-group"} style={{ marginTop: -10 }}>
                <InputLabel className={"form-label"} htmlFor="store">
                  Store's
                </InputLabel>
                <TextField
                  disabled
                  id="store"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={
                    formData.store
                      ? formData.store.map((item) => item.store_Name)
                      : ""
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#111928",
                    },
                  }}
                  // onChange={(e) =>
                  //   setFormData({ ...formData, zip: e.target.value })
                  // }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Organization
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/dashboard")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditOrganization;
