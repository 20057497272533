import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
import { CreatePatient } from "../../../services/patient.handlers";
import { CreateMmSystem } from "../../../services/mm.systems.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddMmSystem = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    system_Serial_Id: "",
    system_Rev_Id: "",
    system_Profile_Id: "",
    generator_Index_Id: "",
    tablet_Index_Id: "",
    headset_Index_Id: "",
    cart_Index_Id: "",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    system_Serial_Id: "",
    system_Rev_Id: "",
    system_Profile_Id: "",
    generator_Index_Id: "",
    tablet_Index_Id: "",
    headset_Index_Id: "",
    cart_Index_Id: "",
  });

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateMmSystem(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          system_Serial_Id: "",
          system_Rev_Id: "",
          system_Profile_Id: "",
          generator_Index_Id: "",
          tablet_Index_Id: "",
          headset_Index_Id: "",
          cart_Index_Id: "",
        });
        setFormErrors({
          system_Serial_Id: "",
          system_Rev_Id: "",
          system_Profile_Id: "",
          generator_Index_Id: "",
          tablet_Index_Id: "",
          headset_Index_Id: "",
          cart_Index_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New MM System</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="system_Serial_Id">
                  System Serial
                </InputLabel>
                <TextField
                  id="system_Serial_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Serial_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      system_Serial_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="system_Rev_Id">
                  System Rev ID
                </InputLabel>
                <TextField
                  id="system_Rev_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Rev_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, system_Rev_Id: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="system_Profile_Id"
                >
                  System Profile Id
                </InputLabel>
                <TextField
                  id="system_Profile_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.system_Profile_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      system_Profile_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="generator_Index_Id"
                >
                  Generator Tab Index
                </InputLabel>
                <TextField
                  id="generator_Index_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.generator_Index_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      generator_Index_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="tablet_Index_Id">
                  Tablet Index Id
                </InputLabel>
                <TextField
                  id="tablet_Index_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.tablet_Index_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tablet_Index_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="headset_Index_Id">
                  Headset Index Id
                </InputLabel>
                <TextField
                  id="headset_Index_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.headset_Index_Id}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      headset_Index_Id: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="cart_Index_Id">
                  Cart Index Id
                </InputLabel>
                <TextField
                  id="cart_Index_Id"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.cart_Index_Id}
                  onChange={(e) =>
                    setFormData({ ...formData, cart_Index_Id: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New MM System
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/mm_system")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddMmSystem;
