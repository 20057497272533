import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { CreateInvPartCategoryType } from "../../../services/InvPartCategoryType.handlers";
import { GetInvCategoryTypes } from "../../../services/InvCategoryType.handlers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import AddIcon from "@mui/icons-material/Add";
const AddPopInvPartCategoryType = (props) => {
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    category_Type_Id: "",
    part_Category_Type: "",
    part_Category_Type_Description:"",
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    category_Type_Id: "",
    part_Category_Type: "",
    part_Category_Type_Description:"",
  });

  React.useEffect(()=>{
    fetchData()
  },[])

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetInvCategoryTypes(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateInvPartCategoryType(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          part_Category_Type_Id: "",
          category_Type_Id: "",
          part_Category_Type: "",
          part_Category_Type_Description:"",
          isActive: 1,
        });
        setFormErrors({
          part_Category_Type_Id: "",
          category_Type_Id: "",
          part_Category_Type: "",
          part_Category_Type_Description:"",
        });
        setLoader(false);
        props.getData();
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <Grid container spacing={2}>
    {error != "" && (
      <Grid item xs={12}>
        <Alert severity="error">{error}</Alert>
      </Grid>
    )}
    {success != "" && (
      <Grid item xs={12}>
        <Alert severity="success">{success}</Alert>
      </Grid>
    )}

    <Grid item xs={3}>
    <div className={"form-group"}>
    <InputLabel className={"form-label"} htmlFor="select-active">
        Category Type Id
        </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.category_Type_Id}
          onChange={(e) =>
            setFormData({
              ...formData,
              category_Type_Id: e.target.value,
            })
          }
                >
                  {data.map((item,index)=><MenuItem key={index+item.id} value={item.id}>{item.category_Type}</MenuItem>)}
                </Select>
              </div>
      
    </Grid>

    <Grid item xs={3}>
      <div className={"form-group"}>
        <InputLabel className={"form-label"} htmlFor="part_Category_Type">
        Part Category Type
        </InputLabel>
        <TextField
          id="part_Category_Type"
          fullWidth
          className={"form-input"}
          variant="outlined"
          value={formData.part_Category_Type}
          onChange={(e) =>
            setFormData({ ...formData, part_Category_Type: e.target.value })
          }
        />
      </div>
    </Grid>

    <Grid item xs={4}>
      <div className={"form-group"}>
        <InputLabel
          className={"form-label"}
          htmlFor="part_Category_Type_Description"
        >
         Part Category Type Description
        </InputLabel>
        <TextField
          id="part_Category_Type_Description"
          fullWidth
          className={"form-input"}
          variant="outlined"
          value={formData.part_Category_Type_Description}
          onChange={(e) =>
            setFormData({
              ...formData,
              part_Category_Type_Description: e.target.value,
            })
          }
        />
      </div>
    </Grid>
    
    
   

    <Grid item xs={2} style={{ alignSelf:"center" }}>
      <Stack
        spacing={2}
        direction="row"
        style={{ alignSelf:"center",marginTop:'24px' }}
      >
      <Button
              disabled={loader}
              onClick={() => submitForm()}
              variant="contained"
              style={{ height: "44px",gap: "10px", borderRadius: "16px",width:"100%",alignItems:"center" }}
            >
              <AddIcon /> Add
              {loader && <Loader color={"#fff"} />}
            </Button>
       
      </Stack>
    </Grid>
  </Grid>
  );
};

export default AddPopInvPartCategoryType;
