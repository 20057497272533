import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetBillingsSummary = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.BILLING_SUMMARY.CRUD_BILLING}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateBillingSummary = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.BILLING_SUMMARY.CRUD_BILLING}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetBillingSummary = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.BILLING_SUMMARY.CRUD_BILLING}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateBillingSummary = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.BILLING_SUMMARY.CRUD_BILLING}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
