import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetBillings = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.BILLING_CALCULTATION.CRUD_BILLING}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const CreateBilling = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.BILLING_CALCULTATION.CRUD_BILLING}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetBilling = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.BILLING_CALCULTATION.CRUD_BILLING}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateBilling = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.BILLING_CALCULTATION.CRUD_BILLING}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GenerateBilling = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.BILLING_CALCULTATION.GENERATE_BILLING}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const StoreBilling = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.STORES.STORE_BILLING}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
