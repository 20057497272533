import * as React from "react";

import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  addGenerator,
  addGeneratorPcb,
  addPartNumber,
  addRevNumber,
  getPartNumber,
  getRevNumber,
} from "../../../services/systems.handlers";
import moment from "moment";
import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import { _getCountryName } from "../../../constants";

function AddGeneratorPcb(prop) {
  const authToken = useSelector((state) => state.user.authToken);
  const [Generator_PCBRouterFile, setGenerator_PCBRouterFile] =
    React.useState();
  const [partItem, setPartItem] = React.useState([]);
  const [revItem, setRevItem] = React.useState([]);
  const [errorNumber, setNumberError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, popOver: "" });
  const [alert, setAlert] = React.useState({
    error: "",
    success: "",
  });
  const [partData, setpartData] = React.useState({
    part_No: "",
    isActive: 0,
  });
  const [revData, setrevData] = React.useState({
    rev_No: "",
    isActive: 0,
    isDefault: 0,
  });
  const [formData, setFormData] = React.useState({
    generator_PCB_Id: "",
    generator_PCBBuild_Date: "",
    generator_PCBBuild_Location: "",
    generator_PCBPart_No: "",
    generator_PCBRev_No: "",
    generator_PCBRouter: "",
  });

  React.useEffect(() => {
    partNo();
    revNo();
  }, []);

  const handleOpen = (prop) => {
    setOpen({ isOpen: true, popOver: prop });
  };
  const handleClose = () => {
    setOpen({ isOpen: false, popOver: "" });
  };

  const handleChangeData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeNo = (e) => {
    if (open.popOver === "Part No") {
      setpartData({ ...partData, part_No: e.target.value });
    } else if (open.popOver === "Rev No") {
      setrevData({ ...revData, rev_No: e.target.value });
    }
  };

  const partNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getPartNumber(options).then((response) => {
      if (response.status) {
        setPartItem(response.data);
      }
    });
  };

  const revNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setRevItem(response.data);
        // response.data.map((event) => {
        //   if (event.isDefault === 1) {
        //     setFormData({ ...formData, sysRev_No: event.rev_No });
        //   }
        // });
      }
    });
  };

  const submitNo = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    if (open.popOver === "Part No" && partData.part_No !== "") {
      addPartNumber(partData, options).then((response) => {
        if (response.status) {
          setOpen(false);
          setpartData({
            part_No: "",
            isActive: 0,
          });
          partNo();
        }
      });
    } else if (open.popOver === "Rev No" && revData.rev_No !== "") {
      addRevNumber(revData, options).then((response) => {
        if (response.status) {
          if (response.data.isDefault === 1) {
            setFormData({ ...formData, sysRev_No: response.data.rev_No });
          }
          setOpen(false);
          setrevData({
            rev_No: "",
            isActive: 0,
            isDefault: 0,
          });
          revNo();
        }
      });
    } else {
      setNumberError("Enter Number");
      setTimeout(() => {
        setNumberError("");
      }, 2000);
    }
  };

  const submitForm = () => {
    const fileData = new FormData();
    fileData.append("Generator_PCBRouterFile", Generator_PCBRouterFile);
    setLoader(true);
    var option = {
      Authorization: "Berrer" + authToken,
    };
    addGeneratorPcb(formData, fileData, option).then((response) => {
      if (response.status) {
        setAlert({
          ...alert,
          success: "Data has been submitted successfully",
        });
        setFormData({
          generator_PCB_Id: "",
          generator_PCBPart_No: "",
          generator_PCBRev_No: "",
          generator_PCBBuild_Date: "",
          generator_PCBBuild_Location: "",
          generator_PCBRouter: "",
        });
        setLoader(false);
        prop.getData();
        setTimeout(() => {
          setAlert({ ...alert, success: "" });
        }, 3000);
      } else {
        setLoader(false);
        setAlert({ ...alert, error: "Invalid Details" });
        setTimeout(() => {
          setAlert({ ...alert, error: "" });
        }, 3000);
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal open={open.isOpen} onClose={handleClose}>
        <Box sx={{ ...style, width: 600 }}>
          <Grid container spacing={2}>
            <div style={{ height: 280, width: 600, margin: 10 }}>
              <div style={{ height: 50 }}>
                {errorNumber !== "" && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errorNumber}</Alert>
                  </Grid>
                )}
              </div>
              <Grid item xs={12}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="add_part">
                    {open.popOver}
                  </InputLabel>

                  <TextField
                    style={{ marginTop: 15 }}
                    type="text"
                    fullWidth
                    className={"form-input"}
                    variant="outlined"
                    onChange={handleChangeNo}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"} style={{ marginTop: 15 }}>
                  <Button
                    disabled={loader}
                    onClick={() => submitNo()}
                    variant="contained"
                  >
                    Submit
                    {loader && <Loader color={"#fff"} />}
                  </Button>
                  <ButtonThemeProvider>
                    <Button
                      disabled={loader}
                      onClick={() => handleClose()}
                      color="grey"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </ButtonThemeProvider>
                </Stack>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Modal>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="gen_pcb_Id">
              Ser. No.
            </InputLabel>
            <TextField
              name="generator_PCB_Id"
              fullWidth
              value={formData.generator_PCB_Id}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="generator_PCBPart_No">
              Part No
              <Link
                style={{ marginLeft: 5 }}
                onClick={() => handleOpen("Part No")}
              >
                add new
              </Link>
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={formData.generator_PCBPart_No}
              // renderValue={(selected) => {
              //   return partItem.find((x) => x.part_No === selected).part_No;
              // }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  generator_PCBPart_No: e.target.value,
                })
              }
            >
              {partItem.map((item) => (
                <MenuItem value={item.part_No}>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>{item.part_No}</div>
                    <div>{_getCountryName(item.part_No)}</div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="generator_PCBRev_No">
              Rev
              <Link
                style={{ marginLeft: 5 }}
                onClick={() => handleOpen("Rev No")}
              >
                add new
              </Link>
            </InputLabel>

            <Select
              fullWidth
              className={"form-input"}
              id="select-active"
              value={formData.generator_PCBRev_No}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  generator_PCBRev_No: e.target.value,
                })
              }
            >
              {revItem.map((item) => (
                <MenuItem value={item.rev_No}>{item.rev_No}</MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel
                className={"form-label"}
                htmlFor="generator_PCBBuild_Date"
              >
                Build Date
              </InputLabel>
              <DesktopDatePicker
                inputFormat="YYYY/MM/DD"
                value={formData.generator_PCBBuild_Date}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    generator_PCBBuild_Date: moment(e).format("YYYY/MM/DD"),
                  })
                }
                className={"form-input"}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={"form-input"}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel
              className={"form-label"}
              htmlFor="generator_PCBBuild_Location"
            >
              Build Location
            </InputLabel>

            <TextField
              name="generator_PCBBuild_Location"
              fullWidth
              value={formData.generator_PCBBuild_Location}
              className={"form-input"}
              variant="outlined"
              onChange={handleChangeData}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            alignSelf: "center",
          }}
        >
          <div className={"form-group"} style={{ margin: 0 }}>
            <InputLabel className={"form-label"} htmlFor="generator_PCBRouter">
              Router
            </InputLabel>
            <TextField
              style={{ height: 44, marginTop: 2 }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={(e) => setGenerator_PCBRouterFile(e.target.files[0])}
              // error={alert.error}
              // helperText={alert.error}
              variant="standard"
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={loader}
            variant="contained"
            style={{
              marginTop: 24,
              height: "40px",
              gap: "5px",
              alignItems: "center",
            }}
            onClick={submitForm}
          >
            <AddIcon /> Add
            {loader && <Loader color={"#fff"} />}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AddGeneratorPcb;
