import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { GetOrganizations } from "../../../services/organization.handlers";
import { useSelector } from "react-redux";
import { GetComponents } from "../../../services/components.handlers";
import moment from "moment";
import { GetInvoices } from "../../../services/invoice.handlers";

const TableRowData = [
  "ID",
  "Treatment Cost",
  "Total Amount",
  "Paid By",
  "Paid Date",
  "Status",
  "Action",
];

const InvoiceRules = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const authToken = useSelector((state) => state.user.authToken);

  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetInvoices(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <DashboardLayout>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "end" }}
      >
        <Button
          onClick={() => navigate("/add_invoice_rules")}
          variant="contained"
        >
          <AddIcon /> Add New Invoice
        </Button>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          //   indeterminate={numSelected > 0 && numSelected < rowCount}
                          //   checked={rowCount > 0 && numSelected === rowCount}
                          //   onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    hover
                    //   onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    //   aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    //   selected={isItemSelected}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.treatment_Cost}</TableCell>
                    <TableCell>{item.total_Amount}</TableCell>
                    <TableCell>{item.paid_By}</TableCell>
                    <TableCell>
                      {moment(item.paid_Date).format("YYYY-MM-DD")}
                    </TableCell>

                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          navigate("/update_invoice_rules/" + item.id)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell
                  colSpan={7}
                  align={"center"}
                  // id={labelId}
                  scope="row"
                  // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default InvoiceRules;
