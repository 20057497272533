import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetPrivateUser = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PRIVATE_USER.CRUD_PRIVATE_USER}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdatePrivateUser = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.PRIVATE_USER.CRUD_PRIVATE_USER}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
