import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Alert, InputLabel } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Loader from "../../../Components/Loader";
import { GetOrganizations } from "../../../services/organization.handlers";
import { CreateInvoice } from "../../../services/invoice.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const AddInvoiceRules = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [organizations, setOrganization] = React.useState([]);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    treatment_Cost: "",
    organisation_Id: "",
    total_Amount: "",
    paid_By: "",
    paid_Date: "",
    paid_Time: "",
    payment_Index: "",
    tax_Charged: "",
    valid_To_Use_Machine: 1,
    isActive: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    treatment_Cost: "",
    total_Amount: "",
    paid_By: "",
    organisation_Id: "",
  });

  React.useEffect(() => {
    fetchOrganisations();
  }, []);

  const fetchOrganisations = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetOrganizations(options).then((response) => {
      if (response.status) {
        setOrganization(response.data);
      }
    });
  };

  const setOrgID = (value) => {
    if (value === "") {
      setFormErrors({
        ...formErrors,
        organisation_Id: "Organization ID cannot be blank",
      });
    } else {
      setFormErrors({
        ...formErrors,
        organisation_Id: "",
      });
    }
    setFormData({ ...formData, organisation_Id: "" + value });
  };

  const submitForm = () => {
    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    CreateInvoice(formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been submitted successfully");
        setFormData({
          treatment_Cost: "",
          organisation_Id: "",
          total_Amount: "",
          paid_By: "",
          paid_Date: "",
          paid_Time: "",
          payment_Index: "",
          tax_Charged: "",
          valid_To_Use_Machine: 1,
          isActive: 1,
        });
        setFormErrors({
          treatment_Cost: "",
          total_Amount: "",
          paid_By: "",
          organisation_Id: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Add New Invoice</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="treatment_Cost">
                  Treatment Cost
                </InputLabel>
                <TextField
                  id="treatment_Cost"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.treatment_Cost}
                  onChange={(e) =>
                    setFormData({ ...formData, treatment_Cost: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Organization
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.organisation_Id}
                  onChange={(e) => {
                    setOrgID(e.target.value);
                  }}
                  error
                  helperText={formErrors.organisation_Id}
                >
                  {organizations.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.org_Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="total_Amount">
                  Total Amount
                </InputLabel>
                <TextField
                  id="total_Amount"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.total_Amount}
                  onChange={(e) =>
                    setFormData({ ...formData, total_Amount: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="paid_By">
                  Paid By
                </InputLabel>
                <TextField
                  id="paid_By"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.paid_By}
                  onChange={(e) =>
                    setFormData({ ...formData, paid_By: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="paid_Date">
                    Paid Date
                  </InputLabel>

                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={formData.paid_Date}
                    onChange={(e) => setFormData({ ...formData, paid_Date: e })}
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={"form-group"}>
                  <InputLabel className={"form-label"} htmlFor="paid_Time">
                    Paid Time
                  </InputLabel>

                  <TimePicker
                    value={formData.paid_Time}
                    onChange={(e) => setFormData({ ...formData, paid_Time: e })}
                    className={"form-input"}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={"form-input"}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="payment_Index">
                  Payment Index
                </InputLabel>
                <TextField
                  id="payment_Index"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.payment_Index}
                  onChange={(e) =>
                    setFormData({ ...formData, payment_Index: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="tax_Charged">
                  Tax Charged
                </InputLabel>
                <TextField
                  id="tax_Charged"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.tax_Charged}
                  onChange={(e) =>
                    setFormData({ ...formData, tax_Charged: e.target.value })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel
                  className={"form-label"}
                  htmlFor="valid_To_Use_Machine"
                >
                  Valid Machine
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.valid_To_Use_Machine}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      valid_To_Use_Machine: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Add New Invoice
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/invoice_rules")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default AddInvoiceRules;
