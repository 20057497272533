import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Loader from "../../../Components/Loader";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getHarnessById,
  getInvPartNumberById,
  getPartNumberById,
  updateHarness,
  updateInvPartNo,
  updatePartNo,
} from "../../../services/systems.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

function EditPartNumber() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    id: "",
    component: "",
    part_Description_Name: "",
    component_No: "",
    country_Code: "",
    variant: "",
    sl_No: "",
    lot_No: "",
    manufacture_Name: "",
    quantity: "",
    purchase_Date: "",
    release_Date: "",
    componentType: "",
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    // getInvPartNumberById(state, options).then((response) => {
    //   if (response.status) {
    //     setFormData(response.data);
    //   } else {
    //     navigate("/part");
    //   }
    // });
    getHarnessById(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/part");
      }
    });
  };

  const submitForm = () => {
    setLoader(true);
    var options = {
      Authorization: "Berrer" + authToken,
    };
    // updateInvPartNo(state, formData, options).then((response) => {
    updateHarness(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");
        setTimeout(() => {
          setSuccess("");
          navigate("/part");
        }, 2000);
        setLoader(false);
        setFormData({
          id: "",
          component: "",
          part_Description_Name: "",
          component_No: "",
          country_Code: "",
          variant: "",
          sl_No: "",
          lot_No: "",
          manufacture_Name: "",
          quantity: "",
          purchase_Date: "",
          release_Date: "",
          componentType: "",
        });
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
        <Grid container spacing={2}>
          {error !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {success !== "" && (
            <Grid item xs={12}>
              <Alert severity="success">{success}</Alert>
            </Grid>
          )}
        </Grid>
        <h3>Edit Part No.</h3>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="component">
                Component
              </InputLabel>
              <TextField
                name="component"
                fullWidth
                value={formData.component}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel
                className={"form-label"}
                htmlFor="part_Description_Name"
              >
                Part Desc
              </InputLabel>
              <TextField
                name="part_Description_Name"
                fullWidth
                value={formData.part_Description_Name}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="component_No">
                Component No.
              </InputLabel>
              <TextField
                name="component_No"
                fullWidth
                value={formData.component_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="country_Code">
                Country Code
              </InputLabel>
              <TextField
                name="country_Code"
                fullWidth
                value={formData.country_Code}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="variant">
                Variant
              </InputLabel>
              <TextField
                name="variant"
                fullWidth
                value={formData.variant}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="sl_No">
                Ser. No.
              </InputLabel>
              <TextField
                name="sl_No"
                fullWidth
                value={formData.sl_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={2.7}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="quantity">
                Quantity
              </InputLabel>
              <TextField
                name="quantity"
                fullWidth
                value={formData.quantity}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="lot_No">
                Lot No.
              </InputLabel>
              <TextField
                name="lot_No"
                fullWidth
                value={formData.lot_No}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={"form-group"} style={{ margin: 0 }}>
              <InputLabel className={"form-label"} htmlFor="manufacture_Name">
                Manufacture
              </InputLabel>
              <TextField
                name="manufacture_Name"
                fullWidth
                value={formData.manufacture_Name}
                className={"form-input"}
                variant="outlined"
                onChange={handleInput}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={loader}
                variant="contained"
                onClick={submitForm}
              >
                Update Part No
                {loader && <Loader color={"#fff"} />}
              </Button>
              <ButtonThemeProvider>
                <Button
                  disabled={loader}
                  onClick={() => navigate("/part")}
                  color="grey"
                  variant="contained"
                >
                  Back
                </Button>
              </ButtonThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default EditPartNumber;
