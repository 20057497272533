import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetEmployees = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.EMPLOYEES.CRUD_EMPLOYEES}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateEmployee = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.EMPLOYEES.CRUD_EMPLOYEES}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetEmployee = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.EMPLOYEES.CRUD_EMPLOYEES}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateEmployee = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.EMPLOYEES.CRUD_EMPLOYEES}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
