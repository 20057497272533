import * as React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../Layout/DashboardLayout";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  GetSystems,
  getTabletById,
  tabletPdfDownload,
} from "../../../services/systems.handlers";
import moment from "moment";
import { _getCountryName, getCountryName } from "../../../constants";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";
import StoreDetailPage from "../Stores/StoreDetailPage";

function TabletDetailPage() {
  const authToken = useSelector((state) => state.user.authToken);
  const params = useParams();
  const location = useLocation();
  const state = location.state;
  const [data, setData] = React.useState({});
  const [systemSn, setSystem_Sn] = React.useState("");
  const [systemId, setSystemId] = React.useState("");
  const navigate = useNavigate();

  const TableRowData = [
    " Ser. No.",
    " Part No",
    " Rev",
    " Router",
    " Build Date",
    " Build Location",
  ];
  const style = {
    background: " none!important",
    border: "none",
    padding: "0!important",
    color: "-webkit-link",
    textDecoration: "underline",
    cursor: "pointer",
  };
  React.useEffect(() => {
    fetchData();
    // fetchSystemData();
  }, []);

  const fetchPdf = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    tabletPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    });
  };

  const openPdfNewTab = (id, name, type) => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    tabletPdfDownload(id, name, type, options).then((response) => {
      if (response.status) {
        const file = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    });
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    getTabletById(state.id, options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };
  // const fetchSystemData = () => {
  //   var options = {
  //     Authorization: "Bearer " + authToken,
  //   };
  //   GetSystems(options).then((response) => {
  //     if (response.status) {
  //       setSystemId(
  //         response.data.find((event) => event.tablet_Sn === Number(params.id))
  //           .id
  //       );
  //       setSystem_Sn(
  //         response.data.find((event) => event.tablet_Sn === Number(params.id))
  //           .system_Sn
  //       );
  //     }
  //   });
  // };
  return (
    <DashboardLayout>
      <Stack spacing={2}>
        {/* {systemId !== "" ? ( */}
        {data.isAssociated_WithSystem === true ? (
          <Breadcrumbs separator="›">
            <Link
              // to={"/systems-detail-page/" + data.systemId}
              to="/systems-detail-page"
              state={{ id: data.systemId, storeId: data.storeId }}
              key="1"
              color="inherit"
            >
              System - {data.system_Sn}
            </Link>
            <Typography key="2" color="text.primary">
              Tablet Details - {data.tablet_Id}
            </Typography>
            <span>
              <Typography key="2" color="text.primary">
                <StoreDetailPage storeId={state.storeId} />
              </Typography>
            </span>
          </Breadcrumbs>
        ) : (
          <Typography key="2" color="text.primary">
            Tablet Details - {data.tablet_Id}
          </Typography>
        )}
      </Stack>
      <hr></hr>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Part No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.component_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.7}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Country Code</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.country_Code}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Variant</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.variant}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Lot No</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.lot_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Ser. No.</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.tablet_Id}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Location</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.tabletBuild_Location}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.4}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Rev</InputLabel>
            <TextField
              disabled
              fullWidth
              value={data.tabletRev_No}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="os_Rev">
              OS Rev
            </InputLabel>

            <TextField
              disabled
              name="os_Rev"
              fullWidth
              value={data.os}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="sw_Rev">
              SW Rev
            </InputLabel>

            <TextField
              disabled
              name="sw_Rev"
              fullWidth
              value={data.sW_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={moment(data.tabletBuild_Date).format("YYYY/MM/DD")}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Router</InputLabel>
            <button
              // href="#"
              style={style}
              onClick={() =>
                openPdfNewTab(data.id, data.tabletRouter, "tablet")
              }
            >
              {data.tabletRouter}
            </button>
            {data.tabletRouter && (
              <span>
                <IconButton
                  aria-label="file download icon"
                  onClick={() => fetchPdf(data.id, data.tabletRouter, "tablet")}
                >
                  <FileDownloadIcon style={{ color: "grey" }} />
                </IconButton>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              Macumira
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              Rev
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={
                data.macumira_BuildDate
                  ? moment(data.macumira_BuildDate).format("YYYY/MM/DD")
                  : ""
              }
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}></Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              Macumira API
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira_Api}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              SW Rev
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira_Api_Sw_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={
                data.macumira_Api_BuildDate
                  ? moment(data.macumira_Api_BuildDate).format("YYYY/MM/DD")
                  : ""
              }
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}></Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              Operating System
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira_Operating_System}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              SW Rev
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.macumira_Software_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"}>Build Date</InputLabel>
            <TextField
              disabled
              fullWidth
              value={
                data.operating_System_BuildDate
                  ? moment(data.operating_System_BuildDate).format("YYYY/MM/DD")
                  : ""
              }
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}></Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="guI_Rev">
              GUI Rev
            </InputLabel>

            <TextField
              disabled
              name="guI_Rev"
              fullWidth
              value={data.guI_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="apI_Rev">
              API Rev
            </InputLabel>

            <TextField
              disabled
              name="apI_Rev"
              fullWidth
              value={data.apI_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <div className={"form-group"} style={{ marginTop: -10 }}>
            <InputLabel className={"form-label"} htmlFor="system_Rev">
              System Rev
            </InputLabel>

            <TextField
              disabled
              name="system_Rev"
              fullWidth
              value={data.system_Rev}
              className={"form-input"}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#111928",
                },
              }}
            />
          </div>
        </Grid>
        <Grid>
          <ButtonThemeProvider>
            <Button
              style={{ margin: 30 }}
              onClick={() => navigate(-1)}
              color="grey"
              variant="contained"
            >
              Back
            </Button>
          </ButtonThemeProvider>
        </Grid>
        {/* <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell colspan="6">
                Tablet Details - {data.tablet_Id}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell
                  key={index}
                  // align={item === "Router" ? "center" : ""}
                >
                  {index === -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{data.tablet_Id}</TableCell>
              <TableCell>
                <>
                  {data.tabletPart_No}
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "102px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {_getCountryName(data.tabletPart_No)}
                  </div>
                </>
              </TableCell>
              <TableCell>{data.tabletRev_No}</TableCell>
              <TableCell>
                <a
                  href="#"
                  onClick={() =>
                    openPdfNewTab(data.id, data.tabletRouter, "tablet")
                  }
                >
                  {data.tabletRouter}
                </a>
                {data.tabletRouter && (
                  <span>
                    <IconButton
                      aria-label="file download icon"
                      onClick={() =>
                        fetchPdf(data.id, data.tabletRouter, "tablet")
                      }
                    >
                      <FileDownloadIcon style={{ color: "grey" }} />
                    </IconButton>
                  </span>
                )}
              </TableCell>
              <TableCell>
                {moment(data.tabletBuild_Date).format("YYYY/MM/DD")}
              </TableCell>
              <TableCell>{data.tabletBuild_Location}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
      </Grid>
    </DashboardLayout>
  );
}

export default TabletDetailPage;
