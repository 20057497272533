import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import { GetTreatmentData } from "../../../services/organization.handlers";
import moment from "moment";

function TreatmentDataPage() {
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const authToken = useSelector((state) => state.user.authToken);
  const tableHead = [
    "Customer Id",
    "Treatment Date",
    "Treatment No.",
    "Ser. No.",
  ];

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setData(allData);
    }
    let serachItem = allData.filter(
      (item) =>
        item.customer_Id.includes(e.target.value) ||
        item.system_Id.includes(e.target.value)
    );
    setData(serachItem);
  };

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetTreatmentData(page + 1, rowsPerPage, options).then((response) => {
      if (response.status) {
        setData(response.data.data);
        setAllData(response.data.data);
        setTotalRecords(response.data);
      }
    });
  };

  return (
    <DashboardLayout>
      <Stack style={{ justifyContent: "center", alignItems: "center" }}>
        <TextField
          type="text"
          className="search"
          placeholder="Search by Customer Id or Ser. No."
          onChange={handleSearch}
          InputProps={{
            sx: { borderRadius: 2, width: 400 },
            endAdornment: (
              <InputAdornment>
                <IconButton disabled>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{item.customer_Id}</TableCell>
                    <TableCell>
                      {moment(item.created_Date).format("YYYY/MM/DD")}
                    </TableCell>
                    <TableCell>{item.treatment_No}</TableCell>
                    <TableCell>{item.system_Id}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={4} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalRecords.totalRecords || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default TreatmentDataPage;
