import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRevNo, getRevNumber } from "../../../services/systems.handlers";
import AddRevNumber from "./AddRevNumber";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

function RevNumber() {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({ isOpen: false, id: "" });
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const TableRowData = ["Rev", "Status", "Action"];

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchData = () => {
    var options = {
      Authorization: "Bearer" + authToken,
    };
    getRevNumber(options).then((response) => {
      if (response.status) {
        setData(response.data);
      }
    });
  };

  const handleOpen = (prop) => {
    setOpen({ isOpen: true, id: prop });
  };
  const handleClose = () => {
    setOpen({ isOpen: false, id: "" });
  };

  const handleDelete = (Id) => {
    setLoader(true);
    var options = {
      Authorization: "Bearer" + authToken,
    };
    deleteRevNo(Id, options).then((response) => {
      if (response.status) {
        setLoader(false);
        handleClose();
        fetchData();
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 200,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    border: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <DashboardLayout>
      <Modal open={open.isOpen} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid container spacing={2}>
            <div
              style={{
                height: 200,
                width: 500,
                margin: 10,
                padding: 20,
              }}
            >
              <h4>Are you sure to delete?</h4>
              <Stack spacing={2} direction={"row"} style={{ marginTop: 15 }}>
                <Button
                  disabled={loader}
                  onClick={() => handleDelete(open.id)}
                  variant="contained"
                  color="error"
                >
                  Confirm
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => handleClose()}
                    color="grey"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </div>
          </Grid>
        </Box>
      </Modal>
      <h3 style={{ marginBottom: -5, marginTop: 0 }}>Rev</h3>
      <hr></hr>
      <Stack
        spacing={40}
        direction="row"
        style={{
          marginTop: "16px",
          marginBottom: "30px",
          justifyContent: "end",
        }}
      >
        <AddRevNumber getData={() => fetchData()} />
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell key={index}>
                  {index == -1 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all ID",
                          }}
                        />
                      }
                      label={item}
                    />
                  ) : (
                    item
                  )}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{item.rev_No}</TableCell>
                    <TableCell>
                      {item.isActive ? (
                        <span className={"label color-green"}>Active</span>
                      ) : (
                        <span className={"label color-red"}>Blocked</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => navigate("/updateRevNo/" + item.id)}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleOpen(item.id)}
                        variant="contained"
                        color="error"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hover>
                <TableCell colSpan={6} align={"center"}>
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
}

export default RevNumber;
