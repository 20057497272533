import { ThemeProvider, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";

const ButtonThemeProvider = ({ children }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          grey: {
            main: grey[300],
            dark: grey[400],
          },
        },
      })}
    >
      <Stack spacing={2} direction="row">
        {children}
      </Stack>
    </ThemeProvider>
  );
};

export default ButtonThemeProvider;
