import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { useNavigate, useParams } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  GetInvLotLineDesc,
  updateInvLotLineDesc,
} from "../../../services/InvLotLineDesc.handlers";
import ButtonThemeProvider from "../../../Components/ButtonThemeProvider";

const EditInvLotLineDesc = () => {
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.user.authToken);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    abarla_Lot_ID: "",
    purchase_Date: "",
    pO_Number: "",
    vendor: "",
    vendor_No: "",
    manufacturer: "",
    manufacturer_No: "",
    lot_No: "",
    line_No: "",
    qty: 0,
    unitPrice: 0,
    status: 1,
  });
  const [formErrors, setFormErrors] = React.useState({
    abarla_Lot_ID: "",
    purchase_Date: "",
    pO_Number: "",
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var options = {
      Authorization: "Bearer " + authToken,
    };
    GetInvLotLineDesc(params.id, options).then((response) => {
      if (response.status) {
        setFormData(response.data);
      } else {
        navigate("/invLotLineDesc");
      }
    });
  };

  const submitForm = () => {
    // if (!formData.patient_Id) {
    //   setPatientID(formData.patient_Id);
    //   return;
    // }
    // if (!formData.healthCard_Id) {
    //   setHealthCardID(formData.healthCard_Id);
    //   return;
    // }
    // if (!formData.patient_Name) {
    //   setPatientName(formData.patient_Name);
    //   return;
    // }

    setError("");
    setSuccess("");

    setLoader(true);

    var options = {
      Authorization: "Bearer " + authToken,
    };
    updateInvLotLineDesc(params.id, formData, options).then((response) => {
      if (response.status) {
        setSuccess("Data has been updated successfully");

        setFormErrors({
          abarla_Lot_ID: "",
          purchase_Date: "",
          pO_Number: "",
        });
        setLoader(false);
        setTimeout(() => {
          setSuccess("");
          navigate("/invLotLineDesc");
        }, 3000);
      } else {
        setLoader(false);
        setError("Invalid Details");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  return (
    <DashboardLayout>
      <form>
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Edit Lot Line Desc</h3>
          <Grid container spacing={2}>
            {error != "" && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success != "" && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="abarla_Lot_ID">
                  Abarla Lot Id
                </InputLabel>
                <TextField
                  id="abarla_Lot_ID"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.abarla_Lot_ID}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      abarla_Lot_ID: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="purchase_Date">
                  Purchase Date
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    id="purchase_Date"
                    fullWidth
                    inputFormat="yyyy-MM-DD"
                    variant="outlined"
                    value={formData.purchase_Date}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={"form-input"}
                      />
                    )}
                    error
                    helperText={formErrors.purchase_Date}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        purchase_Date: e._d,
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="pO_Number">
                  PO No
                </InputLabel>
                <TextField
                  id="pO_Number"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.pO_Number}
                  onChange={(e) =>
                    setFormData({ ...formData, pO_Number: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="vendor">
                  Vendor
                </InputLabel>
                <TextField
                  id="vendor"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.vendor}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      vendor: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="vendor_No">
                  Vendor No
                </InputLabel>
                <TextField
                  id="vendor_No"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.vendor_No}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      vendor_No: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="manufacturer">
                  Manufacturer
                </InputLabel>
                <TextField
                  id="manufacturer"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.manufacturer}
                  onChange={(e) =>
                    setFormData({ ...formData, manufacturer: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="manufacturer_No">
                  Manufacturer No
                </InputLabel>
                <TextField
                  id="manufacturer_No"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.manufacturer_No}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      manufacturer_No: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="lot_No">
                  Lot No
                </InputLabel>
                <TextField
                  id="lot_No"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.lot_No}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      lot_No: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="line_No">
                  Line No
                </InputLabel>
                <TextField
                  id="line_No"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.line_No}
                  onChange={(e) =>
                    setFormData({ ...formData, line_No: e.target.value })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="qty">
                  Quantity
                </InputLabel>
                <TextField
                  id="qty"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.qty}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      qty: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="unitPrice">
                  Unit Price
                </InputLabel>
                <TextField
                  id="unitPrice"
                  fullWidth
                  className={"form-input"}
                  variant="outlined"
                  value={formData.unitPrice}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      unitPrice: e.target.value,
                    })
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="select-active">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  className={"form-input"}
                  id="select-active"
                  value={formData.status}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Blocked</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button
                  disabled={loader}
                  onClick={() => submitForm()}
                  variant="contained"
                >
                  Update Lot Line Desc
                  {loader && <Loader color={"#fff"} />}
                </Button>
                <ButtonThemeProvider>
                  <Button
                    disabled={loader}
                    onClick={() => navigate("/invLotLineDesc")}
                    color="grey"
                    variant="contained"
                  >
                    Back
                  </Button>
                </ButtonThemeProvider>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </DashboardLayout>
  );
};

export default EditInvLotLineDesc;
