import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetPatients = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.PATIENTS.CRUD_PATIENTS}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreatePatient = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.PATIENTS.CRUD_PATIENTS}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetPatient = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.PATIENTS.CRUD_PATIENTS}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const updatePatient = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.PATIENTS.CRUD_PATIENTS}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
