import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GetSystem } from "../../../services/systems.handlers";
import { TableHead } from "@mui/material";
import { Link } from "react-router-dom";

const GeneratorDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const authToken = useSelector((state) => state.user.authToken);
  
  return (
    <DashboardLayout>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
              <TableRow>
              <TableCell colspan="4">Generator Details - {params.id} </TableCell>
               
              </TableRow>
              <TableRow
                    
                  >
                  
                    <TableCell>{""}</TableCell>
                    <TableCell>{"Mechanicals"}</TableCell>
                    <TableCell>Electricals</TableCell>
                    <TableCell>Accessories</TableCell>
                   
                  </TableRow>
          </TableHead>
          
          <TableBody>
          <TableRow
                    hover
                  >
                   <TableCell>{"Sub-Asmbly 1"}</TableCell>
                     
                    <TableCell><Link to={"/sub-assembly/"+params.id}>{"Top Enclosure"}<br />{"(ME Sub-Assmbly)"}</Link></TableCell>
                    <TableCell>PCB <br />(HW Sub-Assmbly)</TableCell>
                    <TableCell>Pwr Supply <br />(HW Sub-Assmbly)</TableCell>
                 
                  </TableRow>
                  <TableRow
                    hover
                  >
                  
                  <TableCell>{"Sub-Asmbly 2"}</TableCell>
                  <TableCell>{"Bottom Enclosure"}<br />{"(ME Sub-Assmbly)"}</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Cables <br />(HW Sub-Assmbly)</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                  >
                  
                  <TableCell>{"Sub-Asmbly 3"}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  </TableRow>
          </TableBody>
         
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default GeneratorDetail;
