import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetProblemReports = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_PROBLEM_REPORT}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddProblemReports = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_PROBLEM_REPORT}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetByProblemReports = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_PROBLEM_REPORT}/${id}`,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateProblemReports = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_PROBLEM_REPORT}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ProblemReportForm = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_RREPORT_FORM}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetProblemReportForm = async (headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_RREPORT_FORM}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetByIdProblemReportForm = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_RREPORT_FORM}/${id}`,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateProblemReportForm = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.CRUD_RREPORT_FORM}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const ProblemReportCount = async (dateTime, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.PROBLEM_REPORT_ADMIN.PROBLEM_REPORT_COUNT}?date=${dateTime}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
