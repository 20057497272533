import React from "react";
import { Routes, Route } from "react-router";
import ForgotPassword from "../Screens/Boarding/ForgotPassword";
import Login from "../Screens/Boarding/Login";
import ResetPassword from "../Screens/Boarding/ResetPassword";
import AddOrganization from "../Screens/Dashboard/Organization/AddOrganization";
import Dashboard from "../Screens/Dashboard/Organization";
import AddEmployee from "../Screens/Dashboard/Employee/AddEmployee";
import Employee from "../Screens/Dashboard/Employee/Employee";
import LoggedOutRoute from "./loggedOutRoutes";
import LoggedInRoute from "./loggedInRoutes";
import TabsToServer from "../Screens/Dashboard/Server";
import EditEmployee from "../Screens/Dashboard/Employee/EditEmployee";
import EditOrganization from "../Screens/Dashboard/Organization/EditOrganization";
import EmployeeLogs from "../Screens/Dashboard/Logs/EmployeeLogs";
import Stores from "../Screens/Dashboard/Stores/Stores";
import AddStores from "../Screens/Dashboard/Stores/AddStores";
import EditStores from "../Screens/Dashboard/Stores/EditStores";
import ChangePassword from "../Screens/Dashboard/ChangePassword";
import OrganisationReport from "../Screens/Dashboard/Reports/OrganisationReport";
import StoreReport from "../Screens/Dashboard/Reports/StoreReport";
import EmployeeLogsID from "../Screens/Dashboard/Logs/EmployeeLogsID";
import AddPatient from "../Screens/Dashboard/Patient/AddPatient";
import Patient from "../Screens/Dashboard/Patient/Patient";
import EditPatient from "../Screens/Dashboard/Patient/EditPatient";
import AddMmSystem from "../Screens/Dashboard/MmSystem/AddMmSystem";
import MmSystem from "../Screens/Dashboard/MmSystem/MmSystem";
import EditMmSystem from "../Screens/Dashboard/MmSystem/EditMmSystem";
import AddMmDevice from "../Screens/Dashboard/MmDevice/AddMmDevice";
import MmDevice from "../Screens/Dashboard/MmDevice/MmDevice";
import EditMmDevice from "../Screens/Dashboard/MmDevice/EditMmDevice";
import AddOrgEmployee from "../Screens/Dashboard/OrgEmployee/AddOrgEmployee";
import OrgEmployee from "../Screens/Dashboard/OrgEmployee";
import EditOrgEmployee from "../Screens/Dashboard/OrgEmployee/EditOrgEmployee";
import AddComponent from "../Screens/Dashboard/Component/AddComponent";
import Component from "../Screens/Dashboard/Component";
import EditComponent from "../Screens/Dashboard/Component/EditComponent";
import InvoiceRules from "../Screens/Dashboard/InvoiceRules";
import AddInvoiceRules from "../Screens/Dashboard/InvoiceRules/AddInvoiceRules";
import EditInvoiceRules from "../Screens/Dashboard/InvoiceRules/EditInvoiceRules";
import BillingCalculation from "../Screens/Dashboard/BillingCalculation";
import AddBillingCalculation from "../Screens/Dashboard/BillingCalculation/AddBillingCalculation";
import EditBillingCalculation from "../Screens/Dashboard/BillingCalculation/EditBillingCalculation";
import BillingSummary from "../Screens/Dashboard/BillingSummary/BillingSummary";
import AddBillingSummary from "../Screens/Dashboard/BillingSummary/AddBillingSummary";
import EditBillingSummary from "../Screens/Dashboard/BillingSummary/EditBillingSummary";
import Store_Organisation_Report from "../Screens/Dashboard/Reports/Store_Organisation_Report";
import Store_Organisation_System_Report from "../Screens/Dashboard/Reports/Store_Organisation_System_Report";
import StoreDoctorReport from "../Screens/Dashboard/Reports/StoreDoctorReport";
import Organisation_Billing_Report from "../Screens/Dashboard/Reports/Organisation_Billing_Report";
import System_Report from "../Screens/Dashboard/Reports/System_Report";
import DeviceReport from "../Screens/Dashboard/Reports/DeviceReport";
import ContactInfo from "../Screens/Dashboard/ContactInfo";
import AddSystem from "../Screens/Dashboard/System/AddSystem";
import System from "../Screens/Dashboard/System/System";
import EditSystem from "../Screens/Dashboard/System/EditSystem";
import AddInvAbarlaPartRef from "../Screens/Dashboard/InvAbarlaPartRef/AddInvAbarlaPartRef";
import InvAbarlaPartRef from "../Screens/Dashboard/InvAbarlaPartRef/InvAbarlaPartRef";
import EditInvAbarlaPartRef from "../Screens/Dashboard/InvAbarlaPartRef/EditInvAbarlaPartRef";
import AddInvLotLineDesc from "../Screens/Dashboard/InvLotLineDesc/AddInvLotLineDesc";
import InvLotLineDesc from "../Screens/Dashboard/InvLotLineDesc/InvLotLineDesc";
import EditInvLotLineDesc from "../Screens/Dashboard/InvLotLineDesc/EditInvLotLineDesc";
import InvAssemblyCategoryType from "../Screens/Dashboard/InvAssemblyCategoryType/InvAssemblyCategoryType";
import InvCategoryType from "../Screens/Dashboard/InvCategoryType/InvCategoryType";
import AddInvCategoryType from "../Screens/Dashboard/InvCategoryType/AddInvCategoryType";
import EditInvCategoryType from "../Screens/Dashboard/InvCategoryType/EditInvCategoryType";
import AddInvAssemblyCategoryType from "../Screens/Dashboard/InvAssemblyCategoryType/AddInvAssemblyCategoryType";
import EditInvAssemblyCategoryType from "../Screens/Dashboard/InvAssemblyCategoryType/EditInvAssemblyCategoryType";
import InvPartCategoryType from "../Screens/Dashboard/InvPartCategoryType/InvPartCategoryType";
import EditInvPartCategoryType from "../Screens/Dashboard/InvPartCategoryType/EditInvPartCategoryType";
import AddInvPartCategoryType from "../Screens/Dashboard/InvPartCategoryType/AddInvPartCategoryType";
import SystemDetail from "../Screens/Dashboard/System/SystemDetail";
import SubAssembly from "../Screens/Dashboard/System/SubAssembly";
import GenMech from "../Screens/Dashboard/System/GenMech";
import Electricals from "../Screens/Dashboard/System/Electricals";
import GeneratorDetail from "../Screens/Dashboard/System/GeneratorDetail";
import GenPlastic from "../Screens/Dashboard/System/GenPlastic";
import UploadDoc from "../Screens/Dashboard/System/UploadDoc";
import Systems from "../Screens/Dashboard/System/Systems";
import AddSystems from "../Screens/Dashboard/System/AddSystems";
import SystemsDetailPage from "../Screens/Dashboard/System/SystemsDetailPage";
import EditSystems from "../Screens/Dashboard/System/EditSystems";
import Generator from "../Screens/Dashboard/System/Generator";
import GeneratorDetailPage from "../Screens/Dashboard/System/GeneratorDetailPage";
import EditGenerator from "../Screens/Dashboard/System/EditGenerator";
import Headset from "../Screens/Dashboard/System/Headset";
import EditHeadset from "../Screens/Dashboard/System/EditHeadset";
import HeadsetDetailPage from "../Screens/Dashboard/System/HeadsetDetailPage";
import Tablet from "../Screens/Dashboard/System/Tablet";
import EditTablet from "../Screens/Dashboard/System/EditTablet";
import TabletDetailPage from "../Screens/Dashboard/System/TabletDetailPage";
import PartNumber from "../Screens/Dashboard/System/PartNumber";
import EditPartNumber from "../Screens/Dashboard/System/EditPartNumber";
import RevNumber from "../Screens/Dashboard/System/RevNumber";
import EditRevNumber from "../Screens/Dashboard/System/EditRevNumber";
import GeneratorPcb from "../Screens/Dashboard/System/GeneratorPcb";
import HeadsetPcb from "../Screens/Dashboard/System/HeadsetPcb";
import EditGeneratorPcb from "../Screens/Dashboard/System/EditGeneratorPcb";
import GeneratorPcbDetailPage from "../Screens/Dashboard/System/GeneratorPcbDetailPage";
import EditHeadsetPcb from "../Screens/Dashboard/System/EditHeadsetPcb";
import HeadsetPcbDetailPage from "../Screens/Dashboard/System/HeadsetPcbDetailPage";
import Cable from "../Screens/Dashboard/System/Cables";
import EditCable from "../Screens/Dashboard/System/EditCables";
import EditCables from "../Screens/Dashboard/System/EditCables";
import CablesDetailPage from "../Screens/Dashboard/System/CablesDetailPage";
import TreatmentDataPage from "../Screens/Dashboard/Reports/TreatmentDataPage";
import AddGenerator from "../Screens/Dashboard/System/AddGenerator";
import ViewGenerator from "../Screens/Dashboard/System/ViewGenerator";
import AddHeadset from "../Screens/Dashboard/System/AddHeadset";
import ViewHeadset from "../Screens/Dashboard/System/ViewHeadset";
import AddTablet from "../Screens/Dashboard/System/AddTablet";
import ViewTablet from "../Screens/Dashboard/System/ViewTablet";
import SystemAddSystems from "../Screens/Dashboard/System/SystemAddSystems";
import GeneratorAddGenerator from "../Screens/Dashboard/System/GeneratorAddGenerator";
import HeadsetAddHeadset from "../Screens/Dashboard/System/HeadsetAddHeadset";
import TabletAddTablet from "../Screens/Dashboard/System/TabletAddTablet";
import ProblemReportAdmin from "../Screens/Dashboard/ProblemReportAdmin/ProblemReportAdmin";
import EditProblemReportAdmin from "../Screens/Dashboard/ProblemReportAdmin/EditProblemReportAdmin";
import ProblemReportForm from "../Screens/Dashboard/ProblemReportAdmin/ProblemReportForm";
import EditProblemReportForm from "../Screens/Dashboard/ProblemReportAdmin/EditProblemReportFrom";
import AddProblemReportForm from "../Screens/Dashboard/ProblemReportAdmin/AddProblemReportFrom";
import BillingGenerate from "../Screens/Dashboard/BillingCalculation/BillingGenerate";
import GenerateInvoice from "../Screens/Dashboard/BillingCalculation/GenerateInvoice";
import AttachSystemToStore from "../Screens/Dashboard/Stores/AttachSystemToStore";
import RemoveSystemToStore from "../Screens/Dashboard/Stores/RemoveSystemToStore";
import RemoveStoreToOrg from "../Screens/Dashboard/Organization/RemoveStoreToOrg";
import StoreBillingSummary from "../Screens/Dashboard/BillingSummary/StoreBillingSummary";
import OutStandingRecords from "../Screens/Dashboard/BillingSummary/OutStandingRecords";
import SystemUsageReports from "../Screens/Dashboard/Reports/SystemUsageReports";
import SystemUsageScreen from "../Screens/Dashboard/Reports/SystemUsageScreen";
import SystemUsagePage from "../Screens/Dashboard/Reports/SystemUsagePage";
import SystemUsageDetailPage from "../Screens/Dashboard/Reports/SystemUsageDetailPage";
import AttachEmployeeToSystem from "../Screens/Dashboard/OrgEmployee/AttachEmployeeToSystem";
import AttachEmployee from "../Screens/Dashboard/OrgEmployee/AttachEmployee";
import SideBarLayout from "../Layout/SideBarLayout";
import FilesHaveError from "../Screens/Dashboard/Reports/FilesHaveError";
import NewFormatExcelFile from "../Screens/Dashboard/NewFormatExcelFile/Index";
import EditExcelFile from "../Screens/Dashboard/NewFormatExcelFile/EditExcelFile";
import PrivateUser from "../Screens/Dashboard/PrivateUser";
import { useSelector } from "react-redux";
import ProdectedRoute from "./prodectedRoutes";

const AppRoutes = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <LoggedOutRoute>
            <Login />
          </LoggedOutRoute>
        }
      />
      <Route
        exact
        path="/forgot_password"
        element={
          <LoggedOutRoute>
            <ForgotPassword />
          </LoggedOutRoute>
        }
      />
      <Route
        exact
        path="/reset_password"
        element={
          <LoggedOutRoute>
            <ResetPassword />
          </LoggedOutRoute>
        }
      />

      <Route // this perent route to render sidebar for all children's
        element={
          <LoggedInRoute>
            <SideBarLayout />
          </LoggedInRoute>
        }
      >
        <Route
          exact
          path="/dashboard"
          element={
            <LoggedInRoute>
              <Dashboard />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_organization/:id"
          element={
            <LoggedInRoute>
              <AddOrganization />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_employee"
          element={
            <LoggedInRoute>
              <AddEmployee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/employee"
          element={
            <LoggedInRoute>
              <Employee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/tabsToServer"
          element={
            <LoggedInRoute>
              <TabsToServer />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateEmployee/:id"
          element={
            <LoggedInRoute>
              <EditEmployee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateOrganization/:id"
          element={
            <LoggedInRoute>
              <EditOrganization />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/employeeLogs"
          element={
            <LoggedInRoute>
              <EmployeeLogs />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/employeeLogs/:id"
          element={
            <LoggedInRoute>
              <EmployeeLogsID />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/stores"
          element={
            <LoggedInRoute>
              <Stores />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_stores/:id"
          element={
            <LoggedInRoute>
              <AddStores />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateStores/:id"
          element={
            <LoggedInRoute>
              <EditStores />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/assignEmployeeToSystem"
          element={
            <LoggedInRoute>
              <AttachEmployeeToSystem />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/attachEmployee/:id"
          element={
            <LoggedInRoute>
              <AttachEmployee />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/org_employee"
          element={
            <LoggedInRoute>
              <OrgEmployee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_org_employee"
          element={
            <LoggedInRoute>
              <AddOrgEmployee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateorg_employee/:id"
          element={
            <LoggedInRoute>
              <EditOrgEmployee />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/component"
          element={
            <LoggedInRoute>
              <Component />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_component"
          element={
            <LoggedInRoute>
              <AddComponent />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateComponent/:id"
          element={
            <LoggedInRoute>
              <EditComponent />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/invoice_rules"
          element={
            <LoggedInRoute>
              <InvoiceRules />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_invoice_rules"
          element={
            <LoggedInRoute>
              <AddInvoiceRules />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/update_invoice_rules/:id"
          element={
            <LoggedInRoute>
              <EditInvoiceRules />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/billing_calculation"
          element={
            <LoggedInRoute>
              <BillingCalculation />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_billing_calculation"
          element={
            <LoggedInRoute>
              <AddBillingCalculation />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/update_billing_calculation/:id"
          element={
            <LoggedInRoute>
              <EditBillingCalculation />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/billing_summary"
          element={
            <LoggedInRoute>
              <BillingSummary />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/orgBillingSummary"
          element={
            <LoggedInRoute>
              <StoreBillingSummary />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/outStandingRecords"
          element={
            <LoggedInRoute>
              <OutStandingRecords />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_billing_summary"
          element={
            <LoggedInRoute>
              <AddBillingSummary />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/update_billing_summary/:id"
          element={
            <LoggedInRoute>
              <EditBillingSummary />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_patient"
          element={
            <LoggedInRoute>
              <AddPatient />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/patient"
          element={
            <LoggedInRoute>
              <Patient />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updatePatient/:id"
          element={
            <LoggedInRoute>
              <EditPatient />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_mm_system"
          element={
            <LoggedInRoute>
              <AddMmSystem />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/mm_system"
          element={
            <LoggedInRoute>
              <MmSystem />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateMmSystem/:id"
          element={
            <LoggedInRoute>
              <EditMmSystem />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_mm_device"
          element={
            <LoggedInRoute>
              <AddMmDevice />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/mm_device"
          element={
            <LoggedInRoute>
              <MmDevice />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateMmDevice/:id"
          element={
            <LoggedInRoute>
              <EditMmDevice />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/changePassword"
          element={
            <LoggedInRoute>
              <ChangePassword />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/organisation"
          element={
            <LoggedInRoute>
              <OrganisationReport />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/store_organisation"
          element={
            <LoggedInRoute>
              <Store_Organisation_Report />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/store"
          element={
            <LoggedInRoute>
              <StoreReport />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/store_organisation_system"
          element={
            <LoggedInRoute>
              <Store_Organisation_System_Report />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/store_doctor_report"
          element={
            <LoggedInRoute>
              <StoreDoctorReport />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/organisation_billing_report"
          element={
            <LoggedInRoute>
              <Organisation_Billing_Report />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/system_report"
          element={
            <LoggedInRoute>
              <System_Report />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/device_report"
          element={
            <LoggedInRoute>
              <DeviceReport />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/treatmentData"
          element={
            <LoggedInRoute>
              <TreatmentDataPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/systemUsageReports"
          element={
            <LoggedInRoute>
              {/* <SystemUsageReports /> */}
              <SystemUsagePage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/systemUsage"
          element={
            <LoggedInRoute>
              <SystemUsageScreen />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/systemUsageDetailPage"
          element={
            <LoggedInRoute>
              <SystemUsageDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/reports/errorFiles"
          element={
            <LoggedInRoute>
              <FilesHaveError />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/reports/privateUser"
          element={
            <LoggedInRoute>
              <ProdectedRoute>
                <PrivateUser />
              </ProdectedRoute>
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/contact"
          element={
            <LoggedInRoute>
              <ContactInfo />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_system"
          element={
            <LoggedInRoute>
              <AddSystem />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/system"
          element={
            <LoggedInRoute>
              <System />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateSystem/:id"
          element={
            <LoggedInRoute>
              <EditSystem />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/uploadDoc/:id"
          element={
            <LoggedInRoute>
              <UploadDoc />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/system-detail/:id"
          element={
            <LoggedInRoute>
              <SystemDetail />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/sub-assembly/:id"
          element={
            <LoggedInRoute>
              <SubAssembly />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/gen-plastic/:id"
          element={
            <LoggedInRoute>
              <GenPlastic />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/generator/:id"
          element={
            <LoggedInRoute>
              <GeneratorDetail />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/gen-mech"
          element={
            <LoggedInRoute>
              <GenMech />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/electricals"
          element={
            <LoggedInRoute>
              <Electricals />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_InvAbarlaPartRef"
          element={
            <LoggedInRoute>
              <AddInvAbarlaPartRef />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/invAbarlaPartRef"
          element={
            <LoggedInRoute>
              <InvAbarlaPartRef />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateInvAbarlaPartRef/:id"
          element={
            <LoggedInRoute>
              <EditInvAbarlaPartRef />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_InvLotLineDesc"
          element={
            <LoggedInRoute>
              <AddInvLotLineDesc />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/invLotLineDesc"
          element={
            <LoggedInRoute>
              <InvLotLineDesc />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateInvLotLineDesc/:id"
          element={
            <LoggedInRoute>
              <EditInvLotLineDesc />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/invAssemblyCategoryType"
          element={
            <LoggedInRoute>
              <InvAssemblyCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_InvAssemblyCategoryType"
          element={
            <LoggedInRoute>
              <AddInvAssemblyCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/updateInvAssemblyCategoryType/:id"
          element={
            <LoggedInRoute>
              <EditInvAssemblyCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/invCategoryType"
          element={
            <LoggedInRoute>
              <InvCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_InvCategoryType"
          element={
            <LoggedInRoute>
              <AddInvCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/updateInvCategoryType/:id"
          element={
            <LoggedInRoute>
              <EditInvCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/add_InvPartCategoryType"
          element={
            <LoggedInRoute>
              <AddInvPartCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/updateInvPartCategoryType/:id"
          element={
            <LoggedInRoute>
              <EditInvPartCategoryType />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/invPartCategoryType"
          element={
            <LoggedInRoute>
              <InvPartCategoryType />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/systems"
          element={
            <LoggedInRoute>
              <Systems />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/add_systems"
          element={
            <LoggedInRoute>
              <AddSystems />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/systems-detail-page"
          element={
            <LoggedInRoute>
              <SystemsDetailPage />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/editSystems/:id"
          element={
            <LoggedInRoute>
              <EditSystems />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generator"
          element={
            <LoggedInRoute>
              <Generator />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generator-detail-page"
          element={
            <LoggedInRoute>
              <GeneratorDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateGenerator/:id"
          element={
            <LoggedInRoute>
              <EditGenerator />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/headset"
          element={
            <LoggedInRoute>
              <Headset />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateHeadset/:id"
          element={
            <LoggedInRoute>
              <EditHeadset />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/headset-detail-page"
          element={
            <LoggedInRoute>
              <HeadsetDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/tablet"
          element={
            <LoggedInRoute>
              <Tablet />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateTablet/:id"
          element={
            <LoggedInRoute>
              <EditTablet />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/tablet-detail-page"
          element={
            <LoggedInRoute>
              <TabletDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/part"
          element={
            <LoggedInRoute>
              <PartNumber />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          // path="/updatePartNo/:component_No/:c_Code/:sl_No"
          path="/updatePartNo/:id"
          element={
            <LoggedInRoute>
              <EditPartNumber />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/rev"
          element={
            <LoggedInRoute>
              <RevNumber />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateRevNo/:id"
          element={
            <LoggedInRoute>
              <EditRevNumber />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generatorPcb"
          element={
            <LoggedInRoute>
              <GeneratorPcb />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateGeneratorPcb/:id"
          element={
            <LoggedInRoute>
              <EditGeneratorPcb />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generatorPcb-detail-page/:id"
          element={
            <LoggedInRoute>
              <GeneratorPcbDetailPage />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/headsetPcb"
          element={
            <LoggedInRoute>
              <HeadsetPcb />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateHeadsetPcb/:id"
          element={
            <LoggedInRoute>
              <EditHeadsetPcb />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/headsetPcb-detail-page/:id"
          element={
            <LoggedInRoute>
              <HeadsetPcbDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/cable"
          element={
            <LoggedInRoute>
              <Cable />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateCable/:id"
          element={
            <LoggedInRoute>
              <EditCables />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/cables-detail-page/:id"
          element={
            <LoggedInRoute>
              <CablesDetailPage />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/addGenerator"
          element={
            <LoggedInRoute>
              <AddGenerator />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/viewGenerator"
          element={
            <LoggedInRoute>
              <ViewGenerator />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/addHeadset"
          element={
            <LoggedInRoute>
              <AddHeadset />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/systemAddSystems"
          element={
            <LoggedInRoute>
              <SystemAddSystems />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generatorAddGenerator"
          element={
            <LoggedInRoute>
              <GeneratorAddGenerator />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/headsetAddHeadset"
          element={
            <LoggedInRoute>
              <HeadsetAddHeadset />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/tabletAddTablet"
          element={
            <LoggedInRoute>
              <TabletAddTablet />
            </LoggedInRoute>
          }
        />

        <Route
          exact
          path="/viewHeadset"
          element={
            <LoggedInRoute>
              <ViewHeadset />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/addTablet"
          element={
            <LoggedInRoute>
              <AddTablet />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/viewTablet"
          element={
            <LoggedInRoute>
              <ViewTablet />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/subAssemblies"
          element={
            <LoggedInRoute>
              <NewFormatExcelFile />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/updateExcelFile/:id"
          element={
            <LoggedInRoute>
              <EditExcelFile />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/problemReoprtAdmin"
          element={
            <LoggedInRoute>
              <ProblemReportAdmin />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/editProblemReportAdmin/:id"
          element={
            <LoggedInRoute>
              <EditProblemReportAdmin />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/problemReportForm"
          element={
            <LoggedInRoute>
              <AddProblemReportForm />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/editProblemReportFrom/:id"
          element={
            <LoggedInRoute>
              <EditProblemReportForm />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/problemReportList"
          element={
            <LoggedInRoute>
              <ProblemReportForm />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/billingGenerate"
          element={
            <LoggedInRoute>
              <BillingGenerate />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/generateInvoice"
          element={
            <LoggedInRoute>
              <GenerateInvoice />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/attachSystemToStore/:id"
          element={
            <LoggedInRoute>
              <AttachSystemToStore />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/removeSystemToStore/:id"
          element={
            <LoggedInRoute>
              <RemoveSystemToStore />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="/removeStoreFromStore/:id"
          element={
            <LoggedInRoute>
              <RemoveStoreToOrg />
            </LoggedInRoute>
          }
        />
      </Route>
    </Routes>
  );
};
export default React.memo(AppRoutes);
