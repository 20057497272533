import React, { useEffect } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import LogoImage from "../../assets/images/Logo-header.png";
import InfoIcon from "@mui/icons-material/Info";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HelpIcon from "@mui/icons-material/Help";
import ReportIcon from "@mui/icons-material/Report";
import AdbIcon from "@mui/icons-material/Adb";
import ContactsIcon from "@mui/icons-material/Contacts";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupsIcon from "@mui/icons-material/Groups";
import DevicesIcon from "@mui/icons-material/Devices";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import DevicesOtherSharpIcon from "@mui/icons-material/DevicesOtherSharp";
import ComputerIcon from "@mui/icons-material/Computer";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Groups3Icon from "@mui/icons-material/Groups3";
import AccessibleIcon from "@mui/icons-material/Accessible";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import TableViewIcon from "@mui/icons-material/TableView";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import BallotIcon from "@mui/icons-material/Ballot";
import InputIcon from "@mui/icons-material/Input";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { _admin } from "../../constants";
const drawerWidth = 240;

const drawerMenu = [
  // {
  //   icon: <InfoIcon />,
  //   name: "Customer Management",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <CorporateFareSharpIcon />,
  //       name: "Organizations",
  //       route: "/dashboard",
  //       id: 0,
  //     },
  //     {
  //       icon: <MedicalInformationIcon />,
  //       name: "Store/ Medical Office",
  //       route: "/stores",
  //       id: 1,
  //     },
  //     {
  //       icon: <Groups3Icon />,
  //       // name: "Org Employee",
  //       name: "Store/ Medical Employee",
  //       route: "/org_employee",
  //       id: 2,
  //     },
  //     // {
  //     //   icon: <AccessibleIcon />,
  //     //   name: "Patients",
  //     //   route: "/patient",
  //     // },
  //     {
  //       icon: <Groups3Icon />,
  //       name: "Assign Employee To System",
  //       route: "/assignEmployeeToSystem",
  //       id: 38,
  //     },
  //   ],
  // },
  // {
  //   icon: <HelpIcon />,
  //   name: "Help",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <AdbIcon />,
  //       name: "Debugging",
  //       route: "/tabsToServer",
  //       id: 3,
  //     },
  //     {
  //       icon: <BallotIcon />,
  //       name: "Logs",
  //       route: "/employeeLogs",
  //       id: 4,
  //     },
  //     {
  //       icon: <ContactsIcon />,
  //       name: "Contact Info",
  //       route: "/contact",
  //       id: 5,
  //     },
  //   ],
  // },
  // {
  //   icon: <SupervisorAccountIcon />,
  //   name: "MM Admin",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <GroupsIcon />,
  //       name: "MM Employee",
  //       route: "/employee",
  //       id: 6,
  //     },
  //     // {
  //     //   icon: <DevicesOtherSharpIcon />,
  //     //   name: "MM Systems",
  //     //   route: "/mm_system",
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "MM Devices",
  //     //   route: "/mm_device",
  //     // },
  //     // {
  //     //   icon: <GridViewRoundedIcon />,
  //     //   name: "Component",
  //     //   route: "/component",
  //     // },
  //   ],
  // },
  {
    icon: <AccountBalanceIcon />,
    // name: "Billing Admin",
    name: "Billing",
    route: "/dashboard",
    subMenu: [
      // {
      //   icon: <ReceiptLongIcon />,
      //   name: "Invoice",
      //   route: "/invoice_rules",
      //   id: 7,
      // },
      // {
      //   icon: <ReceiptIcon />,
      //   name: "Billing",
      //   route: "/billing_calculation",
      //   id: 8,
      // },
      // {
      //   icon: <DescriptionSharpIcon />,
      //   // name: "Billing Summary *",
      //   name: "Billing Summary by Invoice *",
      //   route: "/billing_summary",
      //   id: 9,
      // },
      // {
      //   icon: <DescriptionSharpIcon />,
      //   name: "Generate Billing",
      //   route: "/billingGenerate",
      //   id: 10,
      // },
      // {
      //   icon: <DescriptionSharpIcon />,
      //   // name: "Organization Billing Summary",
      //   name: "Billing Summary by Organization ",
      //   route: "/orgBillingSummary",
      //   id: 36,
      // },
      {
        icon: <DevicesIcon />,
        // name: "System Usage Report",
        // name: "Billing Summary by System",
        name: "Invoice Download",
        route: "/reports/systemUsageReports",
        id: 37,
      },

      {
        icon: <DevicesIcon />,
        name: "Private User",
        route: "/reports/privateUser",
        id: 41,
      },
    ],
  },
  // {
  //   icon: <ReportIcon />,
  //   name: "Reports",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <CorporateFareSharpIcon />,
  //       name: "Organization",
  //       route: "/reports/organisation",
  //       id: 11,
  //     },
  //     {
  //       icon: <AddBusinessOutlinedIcon />,
  //       name: "Store Organization",
  //       route: "/reports/store_organisation",
  //       id: 12,
  //     },
  //     {
  //       icon: <StoreMallDirectoryIcon />,
  //       name: "Store",
  //       route: "/reports/store",
  //       id: 13,
  //     },
  //     {
  //       icon: <SettingsSystemDaydreamOutlinedIcon />,
  //       name: "System",
  //       route: "/reports/system_report",
  //       id: 14,
  //     },
  //     {
  //       icon: <ComputerIcon />,
  //       name: "Device",
  //       route: "/reports/device_report",
  //       id: 15,
  //     },
  //     {
  //       icon: <InventoryOutlinedIcon />,
  //       name: "Organization Billing",
  //       route: "/reports/organisation_billing_report",
  //       id: 16,
  //     },
  //     {
  //       icon: <SystemUpdateAltOutlinedIcon />,
  //       name: "Store System",
  //       route: "/reports/store_organisation_system",
  //       id: 17,
  //     },
  //     {
  //       icon: <LocalHospitalIcon />,
  //       name: "Store Doctor",
  //       route: "/reports/store_doctor_report",
  //       id: 18,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Treatment Data",
  //       route: "/reports/treatmentData",
  //       id: 19,
  //     },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "System Usage Report",
  //     //   route: "/reports/systemUsageReports",
  //     //   id: 37,
  //     // },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Error Files",
  //       route: "/reports/errorFiles",
  //       id: 39,
  //     },
  //   ],
  // },
  // {
  //   // icon: <SupervisorAccountIcon />,
  //   icon: <InventoryIcon />,
  //   name: "Inventory",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <SettingsSystemDaydreamOutlinedIcon />,
  //       name: "System",
  //       route: "/system",
  //       id: 20,
  //     },
  //     {
  //       icon: <ArticleIcon />,
  //       name: "Abarla Part",
  //       route: "/invAbarlaPartRef",
  //       id: 21,
  //     },
  //     // {
  //     //   icon: <CalendarViewMonthIcon />,
  //     //   name: "Lot Line",
  //     //   route: "/invLotLineDesc",
  //     // },
  //     // {
  //     //   icon: <CategoryIcon />,
  //     //   name: "Category",
  //     //   route: "/invCategoryType",
  //     // },
  //     {
  //       icon: <TableViewIcon />,
  //       name: "Assembly Category",
  //       route: "/invAssemblyCategoryType",
  //       id: 22,
  //     },
  //     {
  //       icon: <CategoryOutlinedIcon />,
  //       name: "Part Category",
  //       route: "/invPartCategoryType",
  //       id: 23,
  //     },
  //   ],
  // },

  // {
  //   icon: <DevicesOtherSharpIcon />,
  //   name: "Systems",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Systems",
  //       route: "/systems",
  //       id: 24,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Generator",
  //       route: "/generator",
  //       id: 25,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Headset",
  //       route: "/headset",
  //       id: 26,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Tablet",
  //       route: "/tablet",
  //       id: 27,
  //     },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Sub Assemblies",
  //     //   route: "/part",
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Rev",
  //     //   route: "/rev",
  //     // },
  //   ],
  // },
  // {
  //   icon: <DevicesOtherSharpIcon />,
  //   name: "Assemble Product",
  //   route: "/dashboard",
  //   subMenu: [
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Build System",
  //     //   route: "/add_systems",
  //     //   id: 28,
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Build Generator",
  //     //   route: "/generatorAddGenerator",
  //     //   id: 29,
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Build Headset",
  //     //   route: "/headsetAddHeadset",
  //     //   id: 30,
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Build Tablet",
  //     //   route: "/tabletAddTablet",
  //     //   id: 31,
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Sub Assemblies",
  //     //   route: "/part",
  //     //   id: 32,
  //     // },
  //     {
  //       icon: <DevicesIcon />,
  //       // name: "Excel File",
  //       name: "Sub Assemblies",
  //       route: "/subAssemblies",
  //       id: 40,
  //     },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Generator PCB",
  //     //   route: "/generatorPcb",
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Headset PCB",
  //     //   route: "/headsetPcb",
  //     // },
  //     // {
  //     //   icon: <DevicesIcon />,
  //     //   name: "Harness Assy",
  //     //   route: "/cable",
  //     // },
  //   ],
  // },
  // {
  //   icon: <DevicesOtherSharpIcon />,
  //   name: "Problem Report",
  //   route: "/dashboard",
  //   subMenu: [
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Problem Report Specialist",
  //       route: "/problemReoprtAdmin",
  //       id: 33,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Problem Report Form",
  //       route: "/problemReportForm",
  //       id: 34,
  //     },
  //     {
  //       icon: <DevicesIcon />,
  //       name: "Problem Report List",
  //       route: "/problemReportList",
  //       id: 35,
  //     },
  //   ],
  // },
];

const LeftSidebar = () => {
  const [openCollapse, setOpenCollapse] = React.useState([]);
  // const [, setOpenCollapse] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { role } = useSelector((state) => state.user.userData);

  useEffect(() => {
    getSubmenuHandle();
  }, []);

  const onSubMenuCLink = (val) => {
    const index = openCollapse.findIndex((item) => item === val);
    if (index > -1) {
      setOpenCollapse((current) => current.filter((item) => item !== val));
      const data = (current) => current.filter((item) => item !== val);
      localStorage.setItem("@submenu", JSON.stringify(data));
    } else {
      setOpenCollapse((prevSate) => [...prevSate, val]);
      localStorage.setItem("@submenu", JSON.stringify([...openCollapse, val]));
    }
  };

  const getSubmenuHandle = () => {
    let data = localStorage.getItem("@submenu");
    if (data !== null && data !== "undefined") {
      setOpenCollapse(JSON.parse(data));
    }
  };

  const handleOnClick = (i) => {
    setSelectedIndex(i);
  };
  const checkCollapse = (val) => openCollapse.some((item) => item === val);

  const drawer = (
    <div>
      <Toolbar>
        <Link to={"/"}>
          <img src={LogoImage} alt="" />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        {drawerMenu.map((item, index) =>
          item.subMenu && item.subMenu.length > 0 ? (
            <>
              <ListItem key={item.name + index} disablePadding>
                <ListItemButton
                  button
                  onClick={() => onSubMenuCLink(item.name)}
                >
                  <ListItemIcon>
                    {/* <HtmlIcon Tag={item.icon}/> */}
                    {/* <svg data-testid={item.icon}></svg> */}

                    {/* {HtmlIcon(item.icon)} */}
                    {item.icon}
                    {/* {index % 2 === 0 ? <InfoIcon /> : <MailIcon />} */}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {checkCollapse(item.name) === true ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                key={"collapse" + item.name + index}
                in={checkCollapse(item.name)}
                timeout="auto"
                unmountOnExit
              >
                <List className={"submenu"} component="div" disablePadding>
                  {role === _admin.Admin
                    ? item.subMenu.map((sub, i) => (
                        <ListItem key={sub.name + index + i} disablePadding>
                          <ListItemButton
                            component={Link}
                            to={sub.route}
                            selected={selectedIndex === sub.id}
                            onClick={() => handleOnClick(sub.id)}
                          >
                            <ListItemIcon className={"submenu-icon"}>
                              {/* <InboxIcon /> */}
                              {sub.icon}
                            </ListItemIcon>
                            <ListItemText
                              className={"submenu-text"}
                              primary={sub.name}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))
                    : item.subMenu.map(
                        (sub, i) =>
                          role !== _admin.Admin &&
                          sub.id !== 41 && (
                            <ListItem key={sub.name + index + i} disablePadding>
                              <ListItemButton
                                component={Link}
                                to={sub.route}
                                selected={selectedIndex === sub.id}
                                onClick={() => handleOnClick(sub.id)}
                              >
                                <ListItemIcon className={"submenu-icon"}>
                                  {/* <InboxIcon /> */}
                                  {sub.icon}
                                </ListItemIcon>
                                <ListItemText
                                  className={"submenu-text"}
                                  primary={sub.name}
                                />
                              </ListItemButton>
                            </ListItem>
                          )
                      )}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItem key={item.name + index} disablePadding>
              <ListItemButton component={Link} to={item.route}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={container}
        variant="temporary"
        // open={mobileOpen}
        // onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default LeftSidebar;
