import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetStores = async (headers) => {
  return await AxiosInstance.get(`${API_ROUTE.STORES.CRUD_STORES}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const CreateStores = async (payload, headers) => {
  return await AxiosInstance.post(`${API_ROUTE.STORES.CRUD_STORES}`, payload, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetStore = async (id, headers) => {
  return await AxiosInstance.get(`${API_ROUTE.STORES.CRUD_STORES}/${id}`, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetOrganisationStore = async (id, headers) => {
  return await AxiosInstance.get(
    `${API_ROUTE.STORES.STORE_ORGANISATION}=${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateStore = async (id, payload, headers) => {
  return await AxiosInstance.put(
    `${API_ROUTE.STORES.CRUD_STORES}/${id}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const addSystemToStore = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.STORES.ADD_SYSTEM_TO_STORE}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const RemoveSystem = async (payload, headers) => {
  return await AxiosInstance.post(
    `${API_ROUTE.STORES.REMOVE_SYSTEM}`,
    payload,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
